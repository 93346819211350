import { MotifModal, MotifModalHeader, MotifModalBody, MotifModalFooter, MotifButton, MotifFormField, MotifTextArea } from "@ey-xd/motif-react";

// Component to render Edit Prompt modal
export default function EditPromptModal(props) {

    return (
        <MotifModal size="lg" show={props.showEditPromptModal} onClose={()=>props.toggleEditPromptModal({})} className="genericModal modalMotifFooterModal">
            <MotifModalHeader
                closeButtonProps={{
                    'aria-label': 'Close Edit Prompt Modal',
                    title: 'Close Edit Prompt Modal'
                }}
            >
                Edit
            </MotifModalHeader>
            <MotifModalBody>
                <p>
                    Prompt
                </p>
                <MotifFormField>
                    <MotifTextArea 
                        id="txtPromptText"
                        className="prompt-modal-textarea"
                        rows={5}
                        onBlur={function noRefCheck(){}}
                        onChange={(event)=>props.onEditPromptChange(event)}
                        onFocus={function noRefCheck(){}}
                        value={props?.selectedPrompt?.promptText}
                    />
                </MotifFormField>
            </MotifModalBody>
            <MotifModalFooter className="prompt-modal-footer">
                <MotifButton
                    id="btnConfirm"
                    onClick={props.handleEditPrompt}
                    size="medium"
                    type="button"
                    className="prompt-modal-confirm-button"
                >
                    Confirm
                </MotifButton>
                <MotifButton
                    id="btnCancel"
                    onClick={()=>props.toggleEditPromptModal({})}
                    size="medium"
                    type="button"
                    variant="secondary"
                    className="prompt-modal-cancel-button"
                >
                    Cancel
                </MotifButton>
            </MotifModalFooter>
        </MotifModal>
    );
}