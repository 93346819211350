import { ApplicationInsights } from '@microsoft/applicationinsights-web';
import { ReactPlugin } from '@microsoft/applicationinsights-react-js';

const reactPlugin = new ReactPlugin();
const appInsights = new ApplicationInsights({
  config: {
    instrumentationKey: process.env.APP_INSIGHTSKEY? process.env.APP_INSIGHTSKEY : process.env.REACT_APP_APP_INSIGHTSKEY ,
    extensions: [reactPlugin],  
  },
});
appInsights.loadAppInsights();
export { reactPlugin, appInsights };