import React, { useContext, useEffect, useState } from "react";
import {
  MotifButton,
  MotifCheckbox,
  MotifFooter,
  MotifFormField,
  MotifSearch,
  MotifTooltip,
} from "@ey-xd/motif-react";
import MotifModal, {
  MotifModalHeader,
  MotifModalBody,
  MotifModalFooter,
} from "@ey-xd/motif-react/Modal";
import MotifTabNavigation, {
  MotifTabControl,
} from "@ey-xd/motif-react/TabNavigation";
import _ from "lodash";
import Utils from "../../../utils/Utils";
import DocumentService from "../../../services/documentService";
import "./DocumentUploader.css";
import search from "../../../assets/image/search.svg";
import DocumentTable from "./DocumentTable";
import { DashboardContext } from "../../../context/dashboardContext";

const DocumentUploader = (props) => {
  const utils = new Utils();
  const documentservice = new DocumentService();

  const { setIsDocumentUploadProcessInitiated } = useContext(DashboardContext);

  const [documents, setDocuments] = useState([]);
  const [fileSearchValue, setFileSearchValue] = useState("");
  const [totalDocumentsCount, setTotalDocumentsCount] = useState(0);
  const [totalDocumentsSize, setTotalDocumentsSize] = useState(0);
  const [allowRowSelection, setAllowRowSelection] = useState(false);
  const [activeTabStates, setActiveTabStates] = useState("");
  const [selectedDocuments, setSelectedDocuments] = useState([]);
  const [showDocumentRemovedMessage, setShowDocumentRemovedMessage] =
    useState(false);
  const [tableHeight, setTableHeight] = useState(100);

  const tabStates = {
    ShowDocuments: "ShowDocuments",
    ModifyDocuments: "ModifyDocuments",
    DuplicateDocuments: "DuplicateDocuments",
    SpecialCharsDocuments: "SpecialCharsDocuments",
    UnsupportedDocuments: "UnsupportedDocuments",
  };

  useEffect(() => {
    setActiveTabStates(tabStates.ShowDocuments);
    setDocuments(props.documentList);
    updatePageHeight();
  }, [props.documentList]);

  useEffect(() => {
    let totalFilesCount = props?.documentStates?.documentsCount;
    let totalFilesSize = props?.documentStates?.documentsSize;
    let totalCount = documents.length;
    let totalSize = _.sumBy(documents, (doc) => doc?.size) ?? 0;
    setTotalDocumentsCount(totalFilesCount + totalCount);
    setTotalDocumentsSize(totalFilesSize + totalSize);
  }, [documents]);

  /**
   * This method will provides the dynamic width to the document and refrence based on that parent div
   */
  const updatePageHeight = () => {
    setTableHeight(Math.round(window.innerHeight / 4));
  };

  const onSubmitValidDocuments = async () => {
    if (documents.length > 0) {
      const result = await documentservice.uploadLocalDocuments(documents);
      if (result.status === 200) {
        setIsDocumentUploadProcessInitiated(true);
        props.localFileUploadSuccess(true);
      } else {
        props.localFileUploadSuccess(false);
      }
      props.onCloseUploadModal(false);
    }
  };

  const handleDocumentTabClick = (tabId) => {
    hideAllTabContent();
    switch (tabId) {
      case 1:
        document.getElementById("tabDocuments").style.display = "block";
        setActiveTabStates(tabStates.ShowDocuments);
        break;
      case 2:
        document.getElementById("tabDuplicateDocuments").style.display =
          "block";
        setActiveTabStates(tabStates.DuplicateDocuments);
        break;
      case 3:
        document.getElementById("tabSpecialCharsDocuments").style.display =
          "block";
        setActiveTabStates(tabStates.SpecialCharsDocuments);
        break;
      case 4:
        document.getElementById("tabUnsupportedDocuments").style.display =
          "block";
        setActiveTabStates(tabStates.UnsupportedDocuments);
        break;
      default:
        document.getElementById("tabDocuments").style.display = "block";
        setActiveTabStates(tabStates.ShowDocuments);
        break;
    }
  };

  const allowDocumentTableSelection = () => {
    setActiveTabStates(tabStates.ModifyDocuments);
    setAllowRowSelection(true);
  };

  const removeDocumentSelection = () => {
    setActiveTabStates(tabStates.ShowDocuments);
    setAllowRowSelection(false);
  };

  const onSelectDocuments = (documents) => {
    setShowDocumentRemovedMessage(false);
    setSelectedDocuments(documents);
  };

  const hideAllTabContent = () => {
    document.getElementById("tabDocuments").style.display = "none";
    document.getElementById("tabDuplicateDocuments").style.display = "none";
    document.getElementById("tabSpecialCharsDocuments").style.display = "none";
    document.getElementById("tabUnsupportedDocuments").style.display = "none";
  };

  const removeDocuments = () => {
    if (selectedDocuments.length > 0) {
      let filterDocuments = documents;
      _.remove(filterDocuments, (item) =>
        _.some(selectedDocuments, { name: item.name })
      );
      setDocuments([...filterDocuments]);
      removeDocumentSelection();
      setShowDocumentRemovedMessage(true);
      setSelectedDocuments([]);
    }
  };

  return (
    <MotifModal
      id="documentUploadModal"
      show={true}
      size="xl"
      focusTrapOptions={{
        tabbableOptions: {
          displayCheck: "none",
        },
      }}
      onClose={() => {
        props.onCloseUploadModal(false);
      }}
    >
      <MotifModalHeader
        closeButtonProps={{
          "aria-label": "Close",
          title: "Close",
        }}
      >
        Uploading from local drive - Make selection
      </MotifModalHeader>
      <MotifModalBody>
        <div className="divider-container">
          <div className="upload-doc-divider"></div>
        </div>
        <div className="modal-uploader-body">
          <div className="document-uploader-tabs">
            <MotifTabNavigation defaultActiveKey={0} id="documentUploaderTabs">
              <MotifTabControl
                id="uploadDocuments"
                onClick={() => handleDocumentTabClick(1)}
              >
                Documents
              </MotifTabControl>
              {/* <MotifTabControl
                  id="uploadDuplicateDocuments"
                  onClick={() => handleDocumentTabClick(2)}
                >
                  Duplicates
                </MotifTabControl>
                <MotifTabControl
                  id="uploadSpecialCharsDocuments"
                  onClick={() => handleDocumentTabClick(3)}
                >
                  Special characters
                </MotifTabControl>
                <MotifTabControl
                  id="uploadUnsupportedDocuments"
                  onClick={() => handleDocumentTabClick(4)}
                >
                  Unsupported
                </MotifTabControl> */}
            </MotifTabNavigation>
          </div>
          <div className="uploader-modal-body-wrapper">
            {[tabStates.ShowDocuments, tabStates.ModifyDocuments].includes(
              activeTabStates
            ) && (
              <>
                <div className="document-header">
                  <div className="header-wrapper">
                    <span className="document-uploader-title">
                      Document list
                    </span>
                    <span className="document-uploader-description">
                      Click ‘Confirm’ to upload
                    </span>
                  </div>

                  <MotifFormField>
                    <img className="searchIconDocument" src={search} alt="" />
                    <MotifSearch
                      className="searchTxtBoxDocument"
                      aria-label="Search"
                      hideClearButton
                      id="docs-search"
                      placeholder="Search"
                      value={fileSearchValue}
                      onChange={(e) => {
                        setFileSearchValue(e.target.value);
                      }}
                    />
                  </MotifFormField>
                </div>
                <div className="document-checkbox-filter-wrapper">
                  <div className="document-checkbox-filers">
                    <div className="checkbox-filters-box">
                      <div>
                        <MotifCheckbox
                          id="pdfDocFilter"
                          name="pdfDocFilter"
                          className="checkbox-filter"
                        >
                          PDF
                        </MotifCheckbox>
                      </div>
                      <div>
                        <MotifCheckbox
                          id="pptDocFilter"
                          name="pptDocFilter"
                          className="checkbox-filter"
                        >
                          PPTX
                        </MotifCheckbox>
                      </div>
                      <div>
                        <MotifCheckbox
                          id="docDocFilter"
                          name="docDocFilter"
                          className="checkbox-filter"
                        >
                          DOC
                        </MotifCheckbox>
                      </div>
                      <div>
                        <MotifCheckbox
                          id="xlsDocFilter"
                          name="xlsDocFilter"
                          className="checkbox-filter"
                        >
                          XLS
                        </MotifCheckbox>
                      </div>
                      <div>
                        <MotifCheckbox
                          id="txtDocFilter"
                          name="txtDocFilter"
                          className="checkbox-filter"
                        >
                          TXT
                        </MotifCheckbox>
                      </div>
                    </div>
                    <span className="checkbox-filter-text">
                      Select to filter by file type
                    </span>
                  </div>
                  <div className="document-states-wrapper">
                    <span className="document-states-value">
                      {totalDocumentsCount}/
                      {props?.documentStates?.maxDocumentCount}
                    </span>
                    <span className="document-states-value">
                      {utils.getBytesInMB(totalDocumentsSize)}MB/
                      {props?.documentStates?.maxDocumentSize}GB
                    </span>
                  </div>
                </div>
              </>
            )}

            <div className="document-uploader-body">
              <div id="tabDocuments" className="document-tabs">
                <div
                  className="document-uploader-document-table"
                  style={{ height: `${tableHeight}px` }}
                >
                  <DocumentTable
                    documentType={"documents"}
                    documents={documents}
                    allowSelection={allowRowSelection}
                    onDocumentsSelection={onSelectDocuments}
                    showMessage={showDocumentRemovedMessage}
                  />
                </div>
              </div>
              <div
                id="tabDuplicateDocuments"
                className="document-tabs hiddenTabContent"
              >
                <h3>Duplicate Documents</h3>
              </div>
              <div
                id="tabSpecialCharsDocuments"
                className="document-tabs hiddenTabContent"
              >
                <h3>Special Chars Documents</h3>
              </div>
              <div
                id="tabUnsupportedDocuments"
                className="document-tabs hiddenTabContent"
              >
                <h3>Unsupported Documents</h3>
              </div>
            </div>
          </div>
        </div>
      </MotifModalBody>
      <MotifFooter>
        <div className="document-uploader-footer">
          {activeTabStates === tabStates.ShowDocuments && (
            <>
              <MotifButton
                label="btnModify"
                onClick={allowDocumentTableSelection}
                size="medium"
                type="button"
                variant="secondary"
              >
                Modify selection
              </MotifButton>
            </>
          )}
          {[
            tabStates.ShowDocuments,
            tabStates.SpecialCharsDocuments,
            tabStates.UnsupportedDocuments,
          ].includes(activeTabStates) && (
            <div className="document-upload-primary-buttons">
              <MotifButton
                label="btnModify"
                size="medium"
                type="button"
                variant="secondary"
                onClick={() => {
                  props.onCloseUploadModal(false);
                }}
              >
                Cancel
              </MotifButton>
              <MotifButton
                size="large"
                type="button"
                disabled={documents.length <= 0}
                onClick={onSubmitValidDocuments}
              >
                Confirm
              </MotifButton>
            </div>
          )}

          {activeTabStates === tabStates.ModifyDocuments && (
            <div className="modify-documents-footer-wrapper">
              <span className="modify-selection-text">Modify selection</span>
              {selectedDocuments?.length <= 0 && (
                <MotifTooltip
                  trigger={
                    <MotifButton
                      label="btnModify"
                      size="medium"
                      type="button"
                      variant="secondary"
                      disabled={selectedDocuments.length <= 0}
                    >
                      Remove documents
                    </MotifButton>
                  }
                >
                  Select at least one document to continue
                </MotifTooltip>
              )}

              {selectedDocuments?.length > 0 && (
                <MotifTooltip
                  trigger={
                    <MotifButton
                      label="btnModify"
                      size="medium"
                      type="button"
                      variant="secondary"
                      onClick={removeDocuments}
                      className="doc-upload-remove-documents-button"
                    >
                      Remove documents
                    </MotifButton>
                  }
                >
                  Click to remove selected documents
                </MotifTooltip>
              )}

              <MotifButton
                label="btnModify"
                size="medium"
                type="button"
                variant="secondary"
                onClick={removeDocumentSelection}
              >
                Back
              </MotifButton>
            </div>
          )}
        </div>
      </MotifFooter>
    </MotifModal>
  );
};
export default DocumentUploader;
