import React, { useEffect, useState, useContext } from "react";
import "../../CreateProject/AddMember.css";
import {
  Providers,
  SimpleProvider,
  ProviderState,
} from "@microsoft/mgt-element";
import GraphService from "../../../services/graphService";
import MemberService from "../../../services/memberService";
import {
  MotifTable,
  MotifSelect,
  MotifOption,
  MotifButton,
  MotifToast
} from "@ey-xd/motif-react";
import "../ProjectDashboard.css";
import { PeoplePicker } from "@microsoft/mgt-react";
import { groupConfig } from "../../../config/groupConfig";
import avatarImage from "../../../assets/image/avatarDefault.png";
import cancelIcon from "../../../assets/image/cancel.svg";
import { MotifIconButton } from "@ey-xd/motif-react/Button";
import BreadCrumb from "../../common/BreadCrumb";
import LoaderComponent from "../../common/LoaderComponent";
import { DashboardContext } from "../../../context/dashboardContext";
import Utils from "../../../utils/Utils.js";
import RolePopUp from "./RolePopUp";
import DeleteConfirm from "./DeleteConfirm";

export function EditMember() {
  //Get Project Name
  const projectName = sessionStorage.getItem("DashboardProjectName");
  let [editrowData, setRowData] = useState([]);
  let [saveDisable, setSaveDisable] = useState(true);
  const [selectedPeople, setSelectedPeople] = useState([]);
  const [showMessage, setshowMessage] = useState(false);
  const [showRemoveMemberMessage, setshowRemoveMemberMessage] = useState(false);  
  const [showLoader, setShowLoader] = useState(false);
  const [showDeletedComplete, setshowDeletedComplete] = useState(false);
  const [documentName, setdocumentName] = useState("");
  const [disableControlByRole, setDisableControlByRole] = useState(false);
  const {   
    isDocumentDeleteProcessInitiated,
    setIsDocumentDeleteProcessInitiated
  } = useContext(DashboardContext);
  const utils = new Utils();
  const projectRole = localStorage.getItem("userRole");
  const [showDeleteConfirmPopup, setShowDeleteConfirmPopup] = useState(false);
  const [userStatus, setUserStatus] = useState(""); // Add userStatus state
  let allgroups = [];
  const [memberName, setmemberName] = useState("");
  const [memberParams, setMemberParams] = useState([]);
  //Intialize Token for Graph Api
  useEffect(() => {
    Providers.globalProvider = new SimpleProvider((scopes) => {
      const graphService = new GraphService();
      const graphApiToken = graphService.getToken();
      return Promise.resolve(graphApiToken);
    });

    //const groupName = "non-sat"; // Hardcoded for testing, replace with your logic
    const groupName = localStorage.getItem("groupName").toLowerCase();
    setUserStatus(groupName);
    if(projectRole && (projectRole === "Viewer" || projectRole === "User (Non-SaT)" || projectRole === "User (SaT)"))
    {
      setDisableControlByRole(true);
    }
    fetchMemberDetails();
  }, []);

  // Fetch All the member details that are assigned to the project
  const fetchMemberDetails = async () => {
    let dataReturn = [];
    setRowData([]);

    Providers.globalProvider.setState(ProviderState.SignedIn);
    const ProjectiId = sessionStorage.getItem("DashboardProjectId");
    const memberService = new MemberService();
    let data = await memberService.GetProjectDetailById(ProjectiId);
    if (data) {    
      setSaveDisable(true);
      dataReturn = data;
      const graphService = new GraphService();
      let getMembersAdded = [];
      const myRoleUpdated = dataReturn.filter(
        (member) => member.email == sessionStorage.getItem("Email")
      );
      // If role changed from admin to User non-sat by same user
      if(myRoleUpdated && (myRoleUpdated[0].role === "Viewer" || myRoleUpdated[0].role === "User (Non-SaT)" || myRoleUpdated[0].role === "User (SaT)"))
        {
          localStorage.setItem('userRole', myRoleUpdated[0].role);
          setDisableControlByRole(true);
        }
      for (const element of dataReturn) {
        const userbymail = await graphService.getUserDetailsByInput(
          element.email
        );
        const userAvatar = await graphService.getUserAvatar(
          userbymail.value[0].id
        );
        let newRow = [];
        // if no data added in the add member page  then assign the owner details here
        if (element.role === "Owner") {
          newRow = {
            FullName: userbymail.value[0].displayName,
            Title: userbymail.value[0].jobTitle,
            Role: "Owner",
            isFixed: true,
            Email: userbymail.value[0].mail,
            FirstName: userbymail.value[0].givenName,
            LastName: userbymail.value[0].surname,
            Project_UUID: sessionStorage.getItem("DashboardProjectId"),
            ProjectName: sessionStorage.getItem("DashboardProjectName"),
            Avatar: userAvatar,
            GroupName: "",
            isAlreadySaved: true,
          };
        } else {
          if (element.groupName === "SAT") {
            let roleAssigned="";
            if(element.role !="Admin")
            {
              roleAssigned = "User (SaT)"
            }else{
               roleAssigned = "Admin"
            }
            newRow = {
              FullName: element.fullName,
              Title: element.title,
              Role: roleAssigned,
              isFixed: false,
              Email: element.email,
              FirstName: element.firstName,
              LastName: element.lastName,
              Project_UUID: element.project_UUID,
              ProjectName: sessionStorage.getItem("DashboardProjectName"),
              Avatar: userAvatar,
              GroupName: element.groupName,
              isAlreadySaved: true,
              isUpdated: false,
            };
          } else {
            
            newRow = {
              FullName: element.fullName,
              Title: element.title,
              Role: "User (Non-SaT)",
              isFixed: true,
              Email: element.email,
              FirstName: element.firstName,
              LastName: element.lastName,
              Project_UUID: element.project_UUID,
              ProjectName: sessionStorage.getItem("DashboardProjectName"),
              Avatar: userAvatar,
              GroupName: element.groupName,
              isAlreadySaved: true,
              isUpdated: false,
            };
          }
        }
        getMembersAdded.push(newRow);
      }
      setRowData((prevState) => [...prevState, ...getMembersAdded]);
    }
  };
  
  const deleteMemberCall = async (memberRequest) => {
    const memberService = new MemberService();
    await memberService.DeleteMember(memberRequest);
  }
 // Delete record
 useEffect(() => {
  if(showDeletedComplete){  
    if (memberParams.data.isAlreadySaved) {
      const memberFilter = editrowData.filter(
        (member) => member.Email === memberParams.data.Email
      );
      const memberRequest = memberFilter.map((member) => {
        const { Avatar, isFixed, isAlreadySaved, isUpdated, ...rest } = member;
        return rest;
      });
      //Delete Member
      deleteMemberCall(memberRequest[0]);
    }
    const UpdatedMembersList = editrowData.filter(
      (member) => member.Email !== memberParams.data.Email
    );  
    setRowData(UpdatedMembersList);
    setshowDeletedComplete(false);
    setshowRemoveMemberMessage(true);
    setSaveDisable(true);
  }
  
}, [showDeletedComplete]);


  // Enable disable Save button based on Row updated
  useEffect(() => {
    const anyMemberUpdate = editrowData.filter(
      (member) => member.isUpdated === true
    );
    if (anyMemberUpdate.length > 0) {
      setSaveDisable(false);
    }
  }, [editrowData]);

  // Save Member details to Database
  const saveData = async () => {
    setShowLoader(true);

    var memberdata = editrowData.map((member) => {
      const { Avatar, isFixed, ...rest } = member;
      return rest;
    });
    const memberService = new MemberService();
    
    
   //Code - Kingshuk Banu
   let data = await memberService.GetProjectDetailById(sessionStorage.getItem("DashboardProjectId"));
    if (data) {
      
      for (var row of memberdata) {
        for (var element of data) {
          
            if((row.Email.toLowerCase().toString().trim() == element.email.toLowerCase().toString().trim())
              &&(row.Role.toLowerCase().toString().trim() == element.role.toLowerCase().toString().trim()))
              {
                row.isAlreadySaved = true;
                row.isUpdated = false;
                break;
              }
        }
    }}
    //Code ended
    const memberRequest =memberdata.filter(
      (member) => member.isAlreadySaved == false || member.isUpdated == true 
    );
    await memberService.AddMembers(memberRequest);
    //fetchMemberDetails();
    const updatededitrowData = editrowData.map((member) => {      
        return { ...member, isAlreadySaved: true, isUpdated : false };
      return member;
    });   
    setRowData(updatededitrowData);
    setSaveDisable(true);
    setShowLoader(false);
  };

  // Role dropdown changed to set the save button enable
  const handleCellValueChanged = () => {
    setSaveDisable(false);
  };

  
  // To delete a member from UI /DB. Input is row
  const deleteMember = async (params) => {
    setShowDeleteConfirmPopup(true);
    setmemberName(params.data.FullName);
    setMemberParams(params)
  };

  //This method returns the user group data
  const getUserGroups = async (url) => {
    const graphService = new GraphService();
    const accessToken = await graphService.getToken();
    const requestOptions = {
      method: 'GET',
      headers: {
        'Authorization': `Bearer ${accessToken}`,
        'Strict-Transport-Security': 'max-age=31536000; includeSubDomains'
      }
    };
  
    // Add this in header : ConsistencyLevel and value as Eventual
    //https://graph.microsoft.com/v1.0/users/userid/memberOf/microsoft.graph.group?$count=true&$orderby=displayName&$filter=startswith(displayName, 'S-UG-report-Author-platform-admin-d')
    // Request to get the groups assigned to 
    const response = await fetch(`${url}`, requestOptions);

    if (response.ok) {
      const userGroupData = await response.json();
      allgroups = allgroups.concat(userGroupData.value);
      const nextLink = userGroupData['@odata.nextLink'];
      if (nextLink) {
        await getUserGroups(nextLink);
      }
      return allgroups;

    }
  }
  // This function is to select the member from people picker
  const handleSelectionChanged = async (people) => {
    setshowMessage(false);
    const alreadyAdded = editrowData.filter((data) =>
      data.Email.toLowerCase().includes(
        people.detail[0].userPrincipalName.toLowerCase()
      )
    );
    if (alreadyAdded.length > 0) {
      setSelectedPeople([]);
      setshowMessage(true);
    } else {
      setSelectedPeople([]);
      const graphService = new GraphService();
      const userGroupData = await graphService.getUserGroupByMail(
        people.detail[0].id
      );
      const nextLink = userGroupData['@odata.nextLink'];
      let allGroupDetails= [];
      allGroupDetails = allGroupDetails.concat(userGroupData.value);
      if (nextLink) {
        const userGroupDetails=  await getUserGroups(nextLink);
        allGroupDetails = allGroupDetails.concat(userGroupDetails);
      }
      // Filter for SAT and Non-SAT
      let isSatUser = false;
      const groupAssigned = allGroupDetails.filter((group) =>
        utils.isSatGroup(group.displayName) ||
        utils.isPlatformAdminGroup(group.displayName)
      );
      if (groupAssigned.length > 0) {
        if ((utils.isSatGroup(groupAssigned[0].displayName) || utils.isPlatformAdminGroup(groupAssigned[0].displayName))) {
          isSatUser = true;
        }
      }
      let newRow = [];
      if (isSatUser) {
        newRow = {
          FullName: people.detail[0].displayName,
          Title: people.detail[0].jobTitle,
          Role: "Admin",
          isFixed: false,
          Email: people.detail[0].userPrincipalName,
          FirstName: people.detail[0].givenName,
          LastName: people.detail[0].surname,
          Project_UUID: sessionStorage.getItem("DashboardProjectId"),
          ProjectName: sessionStorage.getItem("DashboardProjectName"),
          Avatar: people.detail[0].personImage,
          GroupName: "SAT",
          isAlreadySaved: false,
        };
      } else {
        newRow = {
          FullName: people.detail[0].displayName,
          Title: people.detail[0].jobTitle,
          Role: "User (Non-SaT)",
          isFixed: true,
          Email: people.detail[0].userPrincipalName,
          FirstName: people.detail[0].givenName,
          LastName: people.detail[0].surname,
          Project_UUID: sessionStorage.getItem("DashboardProjectId"),
          ProjectName: sessionStorage.getItem("DashboardProjectName"),
          Avatar: people.detail[0].personImage,
          GroupName: "Non-SAT",
          isAlreadySaved: false,
        };
      }
      setRowData([...editrowData, newRow]);
      setSaveDisable(false);
    }
  };

  // This function is for closing the toaster message
  const oncloseclick = () => {
    setshowMessage(false);
  };

  // This function is for closing the toaster message after remove member
  const oncloseRemoveclick = () => {
    setshowRemoveMemberMessage(false);
  };

  // Grid  Table header names
  const colDefs = [
    {
      headerName: "Name",
      field: "FullName",
      width: 400,
      wrapText: true,
      autoHeight: true,
      resizable: true,
      cellRenderer: (params) => {
        return (
          <PeoplePickerAvatarRenderer
            value={params}
          ></PeoplePickerAvatarRenderer>
        );
      },
    },
    {
      field: "Title",
      width: 400,
      resizable: true,
      headerComponentParams: (params) => {
        return { template: `<div>Title` };
      },
    },
    {
      headerComponentFramework: RolePopUp,
      field: "Role",
      width: 250,
      resizable: true,
      cellStyle: { width: 200, overflow: "visible" },
      cellRenderer: (params) => {
        if (
          params.data.Role === "Owner" ||
          ((params.data.Role === "Viewer" || params.data.Role === "User (Non-SaT)") && params.data.isFixed)
        ) {
          return params.value;
        } else {
          return (
            <DropdownRenderer
              value={params}
              onCellValueChanged={handleCellValueChanged}
              disabled={disableControlByRole}
            ></DropdownRenderer>
          );
        }
      },
    },
    {
      headerName: "",
      field: "",
      resizable: true,
      width: 100,
      cellRendererFramework: (params) => {
        if (params.data.Role === "Owner") {
          return "";
        } else {
          return (
            <div style={{ marginTop: "8px" }} >
              {!disableControlByRole && (
              <MotifIconButton onClick={() => deleteMember(params)} disabled={disableControlByRole}>
                <img src={cancelIcon} alt="Delete Member" disabled={disableControlByRole} />                
              </MotifIconButton>
                )}
            </div>
          );
        }
      },
    },
  ];

  // Custom components for Dropdown and Avatar in motif table
  const frameworkComponents = {
    dropdownRenderer: DropdownRenderer,
    peoplePickerAvatarRenderer: PeoplePickerAvatarRenderer,
  };

  const onMemberGridReady = (event) => {
    event.api.sizeColumnsToFit();
  };

  return (
    <div className={`projectDashboardContainer ${userStatus === "non-sat" ? "" : "activeMembers"}`}>
    {showLoader && <LoaderComponent />}
      <div className="HeaderLayout">
        <BreadCrumb projectName={projectName} pageName="Members" />
        <div className="HeaderContainer">
          <div className="HeaderTitle">
            <span className="HeaderText">Members</span>
          </div>
          <div className="HeaderAction">
         {projectRole && (projectRole !== "Viewer" && projectRole !== "User (Non-SaT)" && projectRole !== "User (SaT)") && 
         <MotifButton onClick={saveData} disabled={saveDisable}>
            Save
         </MotifButton>
            }           
            
          </div>
        </div>
      </div>
      <div className="DashProjFrame2">
        <div className="DashProjTitle">
          <div className="DashProjTextAndSupportingText-member">
            <div className="DashProjText2">
              <div className="selectMember">Select team members</div>
              <br></br>
            </div>
            <div className="TextPicker">
              <PeoplePicker
                className="ms-PeoplePicker"
                placeholder="Search"
                selectionMode="multiple"
                selectionChanged={handleSelectionChanged}
                selectedPeople={selectedPeople}
              ></PeoplePicker>
            </div>
          </div>
        </div>
      </div>
        <div className="memberGrid">
          <MotifTable
            id="MemberTbl"
            columnDefs={colDefs}
            rowData={editrowData}
            frameworkComponents={frameworkComponents}
            onGridReady={onMemberGridReady}
            tooltipShowDelay={0}
          />
          {userStatus === "non-sat" && <div className="hoverMessage">Editing disabled for non-sat users</div>}
        </div>
        {
    showDeleteConfirmPopup && (
     
        <DeleteConfirm  setshowDeletedComplete={setshowDeletedComplete} setShowDeleteConfirmPopup={setShowDeleteConfirmPopup} memberName={memberName} />
    ) 
  }

      {showRemoveMemberMessage && (
        <MotifToast
          variant="success"
          actionName=""
          position="bottom"
          onClose={() => {
            oncloseRemoveclick();
          }}
        >
          <div>Member successfully removed</div>
        </MotifToast>
      )}

{showMessage && (
        <MotifToast
          variant="error"
          actionName=""
          position="bottom"
          onClose={() => {
            oncloseclick();
          }}
        >
          <div>Already Member has been added to the project</div>
        </MotifToast>
      )}
    </div>
    
  );
 
}

// Implementation of custom dropdown in motif table
const DropdownRenderer = (props) => {
  const options = ["Admin", "User (SaT)"];
  const [value, setValue] = useState(props.value.data.Role);

  useEffect(() => {
    setValue(props.value.data.Role);
  }, [props.value.data.Role]);

  const handleChange = (event) => {
    props.onCellValueChanged();
    const Role = event;
    props.value.data.Role = Role;
    setValue(Role);
    if (props.value.data.isAlreadySaved) {
      props.value.data.isUpdated = true;
    }
    props.value.node.setDataValue("Role", Role);
  };

  return (
    <MotifSelect
      className="Input"
      ariaLabelledBy="select-label"
      value={
        props.value != null && props.value.data != null
          ? props.value.data.Role
          : ""
      }
      onChange={handleChange}
      disabled={props.disabled}
    >
      {options.map((option, index) => {
        return (
          <MotifOption value={option} key={index}>
            {option}
          </MotifOption>
        );
      })}
    </MotifSelect>
  );
};

// Avatar implementation in Motif table
const PeoplePickerAvatarRenderer = (value) => {
  const [avatarUrl, setAvatarUrl] = useState(null);
  const [userName, setuserName] = useState(null);
  const [userEmail, setuserEmail] = useState(null);

  const isBlob=(object)=>{
    return object instanceof Blob;
  }

  useEffect(() => {
    if (value.value.data.isAlreadySaved) {

      setuserName(value.value.data.FullName);
      setuserEmail(value.value.data.Email);

      if (value.value.data.Avatar != undefined) {
       const avatarBlob = isBlob(value.value.data.Avatar);
        if(avatarBlob){
          const reader = new FileReader();
          reader.onload = (e) => {
            setAvatarUrl(e.target.result);
          };
          reader.readAsDataURL(value.value.data.Avatar);
         }
         else{
          setAvatarUrl(value.value.data.Avatar);
         }
        
      } else {
        setAvatarUrl(avatarImage);
      }
    } else {
      if (value.value.data.Avatar != undefined) {
        setAvatarUrl(value.value.data.Avatar);
      } else {
        setAvatarUrl(avatarImage);
      }
      setuserName(value.value.data.FullName);
      setuserEmail(value.value.data.Email);
    }
  });

  return (
    <div className="avtColumn">
      {<img src={avatarUrl} alt="Avatar" className="avtImage"></img>}
      {
        <div className="Namedisplay">
          {" "}
          {userName ? userName : ""}
          <div className="Emaildisplay"> {userEmail ? userEmail : ""}</div>
        </div>
      }
    </div>
  );
};

export default EditMember;