import axios from "./axiosWithMsal";
import CommonService from "./commonService.js";

class WorkSpaceService {
  commonService = new CommonService();

  constructor() {
    this.apiUrl = process.env.REACT_APP_API_URL;
  }

  async getPersonalWorkspaceFavoritePrompts(projectId, origin, action) {
    const request = {
      project_UUID: projectId,
      origin: origin,
      action: action,
    };

    try {
      return await axios
        .post(this.apiUrl + "WorkSpace/GetPWFavoritePrompts", request, {
          headers: {
            "Content-type": "application/json; charset=UTF-8",
            Accept: "application/json",
          },
        })
        .then((result) => {
          return result?.data;
        });
    } catch (error) {
      this.commonService.logException(
        error,
        "Project Workspace Component",
        "getPersonalWorkspaceFavoritePrompts"
      );
    }
  }

  async getPWFavoritePromptById(projectId, type, id) {
    const request = {
      project_UUID: projectId,
      type: type,
      id: id,
    };
    try {
      return await axios
        .post(this.apiUrl + "WorkSpace/GetPWFavoritePromptsByID", request, {
          headers: {
            "Content-type": "application/json; charset=UTF-8",
            Accept: "application/json",
          },
        })
        .then((result) => {
          return result?.data;
        });
    } catch (error) {
      this.commonService.logException(
        error,
        "Project Workspace Component",
        "getPWFavoritePromptById"
      );
    }
  }

  async deleteWorkspaceFavoritePrompts(id, type) {
    const request = {
      Type: type,
      Id: id,
    };

    try {
      await axios.delete(this.apiUrl + "WorkSpace/DeleteFavoritePrompt", {
        data: request,
        headers: {
          "Content-Type": "application/json",
        },
      });
    } catch (error) {
      this.commonService.logException(
        error,
        "Project Workspace Component",
        "deleteWorkspaceFavoritePrompts"
      );
    }
  }

  async editWorkspaceFavoritePrompts(promptData) {
    const editPromptrequest = {
      project_UUID: promptData.project_uuid,           
      type: promptData.prompttype,
      id: promptData.promptid,
      title: promptData.title,
      tags: promptData.tags
    };

    const response = await axios
      .post(
        this.apiUrl + "WorkSpace/EditFavPromptTitleTag",
        editPromptrequest
      )
      .catch((error) => {
        this.commonService.logException(
          error,
          "Project Workspace Component",
          "Edit Workspace Favorite Prompts"
        );
      });

    return response.status;
  }

  /**
   * This method calls the api to create personal workspace section
   * @param {string} projectId
   * @param {string} sectionName
   * @returns
   */
  async addSection(projectId, sectionName) {
    let request = {
      project_UUID: projectId,
      sectionTitle: sectionName,
    };
    const response = await axios
      .post(this.apiUrl + "WorkSpace/AddPWSection", request)
      .catch((error) => {
        this.commonService.logException(
          error,
          "Project Workspace Component",
          "addSection"
        );
        return -2;
      });
    return response.data;
  }

  /**
   * This method calls api service to fetch team workspace favorite prompts
   * @param {string} projectId
   * @returns
   */
  async getTeamWorkspaceFavoritePrompts(projectId) {
    try {
      let queryStringParams = {
        Project_UUID: projectId,
      };
      return await axios
        .get(this.apiUrl + "WorkSpace/GetTWFavoritePrompt?ProjectScopeId", {
          headers: {
            "Content-type": "application/json; charset=UTF-8",
            Accept: "application/json",
          },
          params: queryStringParams,
        })
        .then((result) => {
          return result?.data;
        });
    } catch (error) {
      this.commonService.logException(
        error,
        "Project Workspace Component",
        "getTeamWorkspaceFavoritePrompts"
      );
      return null;
    }
  }

  /**
   * This method returns section list depending on Id
   * @returns Array
   */
  async getSectionListById(id) {
    try {
      return await axios
        .get(
          this.apiUrl +
          "WorkSpace/GetPWSectionList?Project_UUID=" +
          id ,
          {
            headers: {
              "Content-type": "application/json; charset=UTF-8",
              Accept: "application/json",
            },
          }
        )
        .then((data) => {
          return data.data;
        });
    } catch (error) {
      this.commonService.logException(
        error,
        "Project Workspace Component",
        "Get Section List"
      );
    }
  }

  /**
   * This method returns section list depending on Id
   * @returns Array
   */
  async getMembersDetailsByProject(id) {
    try {
      return await axios
        .get(
          this.apiUrl +
          "Member/GetMembersDetailsByProject?project_UUID=" +
          id ,
          {
            headers: {
              "Content-type": "application/json; charset=UTF-8",
              Accept: "application/json",
            },
          }
        )
        .then((data) => {
          return data.data;
        });
    } catch (error) {
      this.commonService.logException(
        error,
        "Project Workspace Component",
        "Get Member List"
      );
    }
  }

  async addTWFavoritePrompt(promptData) {
    const request = {
      pwSectionId: promptData.prompttext,
      promptFavoriteId: promptData.promptid,
      type: promptData.prompttype,
      title: promptData.title,
      sharedWith: promptData.Email.toString()
    };
    try {
      return await axios
        .post(this.apiUrl + "WorkSpace/AddTWFavoritePrompt", request, {
          headers: {
            "Content-type": "application/json; charset=UTF-8",
            Accept: "application/json",
          },
        })
        .then((result) => {
          return result?.data;
        });
    } catch (error) {
      this.commonService.logException(
        error,
        "Project Workspace Component",
        "AddTWFavoritePrompt"
      );
    }
  }
}
export default WorkSpaceService;
