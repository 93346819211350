import * as XLSX from "xlsx";

class ExcelUtils {
    /**
     * Format column as date column
     * @param worksheet XLSX.worksheet object
     * @param column Column number (zero based)
     * @param dateFormat Date format in string
     */
    formatColumnAsDate = function (worksheet, column, dateFormat) {
        const range = XLSX.utils.decode_range(worksheet['!ref'])
        for (let row = range.s.r; row <= range.e.r; ++row) {
            const ref = XLSX.utils.encode_cell({ r: row, c: column })
            if (worksheet[ref]) {
                worksheet[ref].t = 'd';
                worksheet[ref].d = dateFormat;
            }
        }
    }

    /**
     * Format column as time column
     * @param worksheet XLSX.worksheet object
     * @param column Column number (zero based)
     * @param timeFormat Date format in string
     */
    formatColumnAsTime = function (worksheet, column, timeFormat) {
        const range = XLSX.utils.decode_range(worksheet['!ref'])
        for (let row = range.s.r; row <= range.e.r; ++row) {
            const ref = XLSX.utils.encode_cell({ r: row, c: column })
            if (worksheet[ref]) {
                worksheet[ref].t = 'd';
                worksheet[ref].z = timeFormat;
            }
        }
    }
}

export default ExcelUtils;