import React, { useEffect, useState } from "react";
import {
  MotifPaginationSelect,
  MotifPaginationSelectItem,
} from "@ey-xd/motif-react";
import { MotifToast } from "@ey-xd/motif-react";
import MotifTable from "@ey-xd/motif-react/Table";
import Utils from "../../../utils/Utils";
import docIcon from "../../../assets/image/page.svg";

const DocumentTable = (props) => {
  const utils = new Utils();

  const [documents, setDocuments] = useState([]);
  const [documentGrid, setDocumentGrid] = useState(null);
  const [isDocumentRemoved, setIsDocumentRemoved] = useState(false);

  useEffect(() => {
    setDocuments(props.documents);
    if (documentGrid) {
      documentGrid.api.setRowData(props.documents);
    }
  }, [props.documents]);

  useEffect(() => {
    if (documentGrid) {
      documentGrid.columnApi.setColumnsVisible(["0"], props.allowSelection);
    }
  }, [props.allowSelection]);

  useEffect(() => {
    setIsDocumentRemoved(props.showMessage);
    setTimeout(() => {
      setIsDocumentRemoved(false);
    }, 3000);
  }, [props.showMessage]);

  /**
   * Description
   * -----------
   * defined columns to show the selected local documents
   */
  const [colDefs] = useState([
    {
      field: "",
      headerCheckboxSelection: true,
      checkboxSelection: true,
      width: 80,
      hide: true,
    },
    {
      field: "name",
      width: 400,
      wrapText: true,
      autoHeight: true,
      displayName: "Documents",
      cellRenderer: (params) => (
        <div className="document-uploader-doc-name-wrapper">
          <div className="document-uploader-doc-icon">
            <img src={docIcon} alt={params.data.name} />
          </div>
          {params.data.name}
        </div>
      ),
    },
    {
      field: "size",
      displayName: "Size",
      cellRenderer: (params) => (
        <div>{utils.getBytesInMB(params.data.size)} MB</div>
      ),
    },
  ]);

  const onDocumentSelection = (event) => {
    let selectedFiles = event.api.getSelectedRows();
    props.onDocumentsSelection(selectedFiles);
  };

  const onGridReady = (events) => {
    setDocumentGrid(events);
  };

  return (
    <>
      <MotifTable
        rowData={documents}
        columnDefs={colDefs}
        suppressCellFocus
        suppressRowClickSelection
        debounceVerticalScrollbar={true}
        rowSelection="multiple"
        onGridReady={(event) => onGridReady(event)}
        onRowSelected={(event) => onDocumentSelection(event)}
      />

      {isDocumentRemoved && (
        <MotifToast
          variant="info"
          actionName=""
          position="bottom"
          onClose={() => {
            setIsDocumentRemoved(false);
          }}
        >
          <div>
            Documents removed. Selected documents have been removed
            successfully.
          </div>
        </MotifToast>
      )}
    </>
  );
};
export default DocumentTable;
