import React, { useContext, useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { DashboardContext } from "../../context/dashboardContext";
import { paansConfig } from "../../config/paansConfig";
import { MotifButton, MotifTooltip } from "@ey-xd/motif-react";
import styles from "./dashboard.module.scss";
import logo from "../../assets/image/logo.svg";
import setting from "../../assets/image/settings.svg";
import gridMinus from "../../assets/image/grid-minus.svg";
import bannerImage1 from "../../assets/image/bannerimage.png";
import DashSuperAdmin from "./dashSuperAdmin";
import avatarImage from "../../assets/image/default-user-icon.jpg";
import Footer from "../common/Footer.js";
import logOut from "../../assets/image/log-out.svg";
import { useMsal } from '@azure/msal-react';
export function DashboardPlatformAdmin(props) {
  const navigate = useNavigate();
  const [isPaansAccepted, setPaansAccepted] = useState(false);
  const [showUserMenu, setShowUserMenu] = useState(false);
  let { paans } = useContext(DashboardContext);
  const { instance } = useMsal();

  useEffect(() => {
    if (Object.keys(paans).length && !paans.hasAccepted) {
      const urlPaans = paansConfig.PAANSEndpoint + paans.redirectUrl;
      window.location.href = urlPaans;
    } else if (Object.keys(paans).length && paans.hasAccepted) {
      setPaansAccepted(true);
    }
  });

  const navigateConfigureSettings = () => {
    // 👇️ navigate to /
    navigate("/ConfigureSetting");
  };

  const handleClickProjDashboard = () => {
    navigate("/Dashboard");
  };

  /**
   * Description
   * -----------
   * This method shows the username on avatar image
   */
  const showLoggedInUserMenu = () => {
    setShowUserMenu(true);
    document.body.addEventListener("click", hideLoggedInUserMenu);
  };

  /**
  * Description
  * -----------
  * This method hides the username on avatar image
  */
  const hideLoggedInUserMenu = () => {
    setShowUserMenu(false);
  };

  /**
  * Description
  * -----------
  * This method logout from Azure Ad and navigate to logout page
  */
  const handleLogOut = (e) => {
    e.preventDefault();
    localStorage.clear();
    sessionStorage.clear();
    const logoutRequest = {
      account: instance.getActiveAccount(),
      postLogoutRedirectUri: "/LogOut",
    };
    instance.logout(logoutRequest);
  };

  return (
    isPaansAccepted && (
      <div className={styles.container}>
        <div className={styles.header}>
          <div className={styles.logomenu}>
            <div className={styles.logoDiv}>
              <div className={styles.logo}>
                <img src={logo} alt=""></img>
              </div>
            </div>
            <div className={styles.projectTitle}>
              Digital Diligence Assistant
            </div>
          </div>
          <div className={styles.profileImageContainer}>
            <div className={styles.NavDropdown}>
              <div className={styles.Dropdown}>
                {showUserMenu && (
                  <div className="headerContainer">
                    <div className="userMenupopupcontainer">
                      <div className="userMenuPopup">
                        <div className="usermenuwidthscratch">
                          <div className="usermenuwidth">
                            <div className="userMenuCont">
                              <div className="usermenuchane">
                                <div className="usermenushow">
                                  <div className="displaymenu">
                                    <div className="userMenuAvatar">
                                      {
                                        <img
                                          src={avatarImage}
                                          alt="Avatar"
                                          draggable="false"
                                          className="userAvatar"
                                        ></img>
                                      }
                                      {
                                        <div className="userAvatarUserMenuItem">
                                          {" "}
                                          <b>
                                            {sessionStorage.getItem(
                                              "CurrentUserName"
                                            )}
                                          </b>
                                          <div className="userAvatarEmailMenuItem">
                                            {" "}
                                            {sessionStorage.getItem("Email")}
                                          </div>
                                        </div>
                                      }
                                    </div>
                                    <div className="Divider1 divider" />
                                    {" "}
                                    <div>
                                      <img className='logoutimg' alt='icon' src={logOut} />
                                      <a href="#"
                                        onClick={handleLogOut}
                                        className="logout"
                                        target="_blank"
                                        rel="noopeer noreferrer"
                                      > Log out
                                      </a>
                                    </div>
                                  </div>
                                </div>
                                <div />
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                )}
                <div className={styles.Avatar}
                  id="divUserAvatar"
                  onMouseOver={showLoggedInUserMenu}
                  onMouseDown={hideLoggedInUserMenu}
                >
                  <img
                    className={styles.userAvatar}
                    src={avatarImage}
                    alt=""
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className={styles.dashboard}>
          <div className={styles.pageHeader}>
            <div className={styles.headerContent}>
              <div className={styles.headerTitle}>
                <div className={styles.headerText}>
                  <div className={styles.headerTextDiv}>
                    <div className={styles.headerValue}>Admin Dashboard</div>
                  </div>
                </div>
              </div>
              <div className={styles.headerAction}>
                <MotifButton
                  className={styles.switchToProjectDashboard}
                  onClick={handleClickProjDashboard}
                >
                  <img src={gridMinus} alt=""></img>
                  <span className={styles.txtSwitchProjDash}>
                    Switch to Project Dashboard
                  </span>
                </MotifButton>
                <MotifButton
                  className={styles.createProject}
                  onClick={navigateConfigureSettings}
                >
                  <img src={setting} alt=""></img>
                  Configure Settings
                </MotifButton>
              </div>
            </div>
          </div>
        </div>

        <div className={styles.dashboard}>
          <div className={styles.bannerDiv}>
            <div className={styles.bannerImage}>
              <img
                className={styles.platformAdminBanner}
                src={bannerImage1}
                draggable="false"
                alt=""
              />
            </div>
            <div className={styles.HeadingAndSubheading}>
              <div className={styles.Subheading}>Getting started</div>
              <div className={styles.HeadingAndText}>
                <div className={styles.Heading}>
                  Hey, here is an overview of your project engagements
                </div>
                <div className={styles.SupportingText}>
                  Learn all about the projects available to you.{" "}
                </div>
              </div>
            </div>
          </div>
        </div>
        <div>
          <DashSuperAdmin></DashSuperAdmin>
        </div>
        <Footer></Footer>
      </div>
    )
  );
}

export default DashboardPlatformAdmin;
