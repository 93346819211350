import "./InstantInsights.css";
import refreshIcon from "../../../assets/image/refresh-double.svg";
const ResponseNotAvailable = (props) => {
  const handleAcknowledgeRefreshIconClick = () => {
    props.setShowAcknowledgeRefreshPopup(true);
    props.setPromptForAcknowledgement(props.promptnResponse);
  };

  return (
    <div className="responseNotAvailable-Maindiv">
      <div className="accaordian-header-container">
        <div className="accordian-header">
          <div className="accordian-header-div">
            <div className="responseNotAvailable-div3">
              {!props.promptnResponse.isRefreshAcknowledged ? (
                <img
                  className="tree-icon-refresh"
                  src={refreshIcon}
                  onClick={() => {
                    handleAcknowledgeRefreshIconClick();
                  }}
                ></img>
              ) : (
                <></>
              )}
              {props.promptnResponse.promptTitle}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
export default ResponseNotAvailable;
