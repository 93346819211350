/**
 * View Source Documents modal
 * Created on 27-05-24
 */
import React, { useEffect, useState, useCallback, useRef } from "react";
import MotifModal, {
  MotifModalHeader,
  MotifModalBody,
  MotifModalFooter,
} from "@ey-xd/motif-react/Modal";
import { MotifIconButton } from "@ey-xd/motif-react/Button";
import MotifSelect, { MotifOption } from "@ey-xd/motif-react/Select";
import MotifAccordion, {
  MotifAccordionTrigger,
  MotifAccordionContent,
} from "@ey-xd/motif-react/Accordion";
import { Document, Page, pdfjs } from "react-pdf";
import pdfjsWorker from "pdfjs-dist/build/pdf.worker.entry";
import "react-pdf/dist/esm/Page/AnnotationLayer.css";
import "react-pdf/dist/esm/Page/TextLayer.css";
import _ from "lodash";
import { MotifInput, MotifTooltip } from "@ey-xd/motif-react";

//  Custom imports
import "./ViewSourceDocsModal.css";
import DownloadDocument from "../../../utils/downloadDocument";
import nav_arrow_left from "../../../assets/image/nav_arrow_left.svg";
import nav_arrow_right from "../../../assets/image/nav_arrow_right.svg";
import download from "../../../assets/image/download.svg";
import tick from "../../../assets/image/tick.svg";
import noLinkIcon from "../../../assets/image/no-link.svg";
import DocumentService from "../../../services/documentService";
import CommonService from "../../../services/commonService";

//  React pdf initialization
/* pdfjs.GlobalWorkerOptions.workerSrc = new URL(
  "pdfjs-dist/build/pdf.worker.min.js",
  import.meta.url
).toString(); */
pdfjs.GlobalWorkerOptions.workerSrc = pdfjsWorker;

const ViewSourceDocsModal = (props) => {
  const [varReferences, setVarReferences] = useState([]);
  const [fileName, setFileName] = useState(null);
  const [pages, setPages] = useState([]);
  const [numPages, setNumPages] = useState(null);
  const [pageNumber, setPageNumber] = useState(1);
  const [searchText, setSearchText] = useState(``);
  const [document, setDocument] = useState(null);
  const [selectedRef, setSelectedRef] = useState(null);
  const [selectedAccordion, setSelectedAccordion] = useState(null);
  const [deletedDocuments, setDeletedDocuments] = useState([]);
  const [isDocLoaded, setIsDocLoaded] = useState(false);
  const [pageWidth, setPageWidth] = useState(0);
  const [modalBodyHeight, setModalBodyHeight] = useState('100%');
  const [modalSrcRefHeight, setModalSrcRefHeight] = useState('100%');
  const [currentPageNumber, setCurrentPageNumber] = useState(1);
  const containerRef = useRef(null);
  const containerBodyRef = useRef(null);

  const downloadDocument = new DownloadDocument();
  const validFileExtensions = [".docx", ".doc", ".ppt", ".pptx", ".pdf"];
  const documentservice = new DocumentService();
  const commonService = new CommonService();
  const msgLoading = "Loading PDF please wait...";
  const [msgNoData, setMsgNoData] = useState(
    "File type not supported in preview"
  );
  const msgNoDocs = "No source documents.";

  /**
   * PDF Viewer related functions
   * @param {*} offset
   * @returns
   */
  const changePage = (offset) => {
    setPageNumber((prevPageNumber) => prevPageNumber + offset);
    setCurrentPageNumber((prevPageNumber) => prevPageNumber + offset);
  }
  
  const previousPage = () => changePage(-1);
  const nextPage = () => changePage(1);
  const handleOnLoadError = (err) => {
    commonService.logException(
      err,
      "View Source Documents Component",
      "Handle Document onLoadError"
    );
    setIsDocLoaded(true);
  };

  /**
   * This method will call when document loads
   * @param {number} numPages will provide the toatal number of pages available 
   */
  const onDocumentLoadSuccess = ({ numPages }) => {
    setNumPages(numPages);
    updatePageWidth();
    setPageNumber(1);
    setCurrentPageNumber(1);
    let vPages = [];
    for (let index = 1; index <= numPages; index++) {
      vPages.push({ value: index, label: index });
    }
    setPages(vPages);
    setIsDocLoaded(true);
  };

  /**
   * This method will provides the dynamic width to the document and refrence based on that parent div
   */
  const updatePageWidth = () => {
    if (containerBodyRef.current) {
      const containerHeight = containerBodyRef.current.offsetHeight;
      setModalBodyHeight(containerHeight-100+'px');
      setModalSrcRefHeight(containerHeight-65+'px')
    }
    if (containerRef.current) {
      const containerWidth = containerRef.current.offsetWidth;
      setPageWidth(containerWidth-10);
    }
  };

  /**
   * Highlight Pattern in PDF
   * @param {*} text
   * @param {*} pattern
   * @returns
   */
  const highlightPattern = (text, pattern) => {
    if (
      text.trim() &&
      pattern.toLowerCase().includes(text.toLowerCase()) &&
      text.split(" ").length > 5
    ) {
      return `<mark>${text}</mark>`;
    }
  };

  /**
   * Custom Text renderer for PDF to highlight text
   */
  const textRenderer = useCallback(
    (textItem) => {
      const vPageNumbers = _.filter(varReferences, function (item) {
        return item.fileName === fileName;
      });
      if (
        vPageNumbers.length > 0 &&
        vPageNumbers[0].pages.length > 0 &&
        vPageNumbers[0].pages.includes(textItem.pageNumber.toString())
      ) {
        return highlightPattern(textItem.str, searchText);
      }
    },
    [searchText, fileName]
  );

  /**
   * To handle page change from the page input text
   * @param {*} e
   */
  const goToPageNumber = (e) => {
    if(e.target.value !== "" && e.target.value !== "0"){
      if(parseInt(e.target.value) >= 1 && parseInt(e.target.value) <= pages.length){
        setPageNumber(parseInt(e.target.value));
        setCurrentPageNumber(parseInt(e.target.value));
      }
    } 
  }
  /**
   * Hook to format references and chunks initially
   */
  useEffect(() => {
    if (props.references) {
      formatReferences(props.references);
      formatChunks(props.chunks);
      getDeletedDcoumnets();
    }
  }, [props.references, props.chunks]);

  /**
   * Hook to set initial file name
   */
  useEffect(() => {
    getInitFileName(varReferences);
  }, [varReferences]);

  /**
   * To get the document url for the file name to pass to PDF viewer
   */
  useEffect(() => {
    if (fileName) {
      getDocumentUrl(fileName);
    }
  }, [fileName]);

  /**
   * This useEffect is used to show/hide the modal
   */
  useEffect(() => {
    props.setOpenModal(props.openShow);
  }, [props.setOpenModal]);

  /**
   * To format references
   * @param {*} vResponse
   */
  const formatReferences = (vResponse) => {
    const vFiles = vResponse;
    let vReferences = [];
    if (vFiles) {
      if (typeof vFiles === "string") {
        const filesArr = vFiles.split(",");
        for (const file of filesArr) {
          if (file) {
            const pageNoArr = file.split(":");
            let vFileName;
            let vPages = [];
            for (let i = 0; i < pageNoArr.length; i++) {
              if (i === 0) {
                vFileName = pageNoArr[i];
              } else {
                vPages.push(pageNoArr[i]);
              }
            }
            vReferences.push({
              fileName: vFileName,
              pages: vPages,
            });
          }
        }
      } else {
        for (const element of vFiles) {
          let vIsBroken = false;
          if (element.isBroken) {
            if (typeof element.isBroken === "string") {
              if (element.isBroken === "true") {
                vIsBroken = true;
              } else {
                vIsBroken = false;
              }
            } else if (element.isBroken) {
              vIsBroken = true;
            } else {
              vIsBroken = false;
            }
          }
          vReferences.push({
            fileName: element.fileName || element.FileName,
            pages: element.pageNumber || element.PageNumber,
            isBroken: vIsBroken,
          });
        }
      }
    }
    setVarReferences(vReferences);
  };

  /**
   * To format chunks
   * @param {*} vChunks
   */
  const formatChunks = (vChunks) => {
    let strChunks = "";
    if (typeof vChunks === "string" && vChunks !== "") {
      try {
        vChunks = JSON.parse(vChunks);
      } catch (e) {}
    }
    if (vChunks) {
      for (const element of vChunks) {
        strChunks += " " + element;
      }
    }
    setSearchText(strChunks);
  };

  /**
   * Function to set initial file name
   * @param {*} vRefs
   * @returns
   */
  const getInitFileName = (vRefs) => {
    if (vRefs) {
      for (const vRef of vRefs) {
        for (const vExt of validFileExtensions) {
          if (vRef.fileName.indexOf(vExt) !== -1) {
            setFileName(vRef.fileName);
            return;
          }
        }
      }
    }
    if (!fileName) {
      if (vRefs.length > 0) {
        setMsgNoData("File type not supported in preview");
        setIsDocLoaded(true);
      }
    }
  };

  /**
   * Function to get Document url
   * @param {*} vFile
   */
  const getDocumentUrl = (vFile) => {
    if (vFile && vFile.indexOf(".pdf") === -1) {
      vFile = vFile.substr(0, vFile.indexOf(".")) + ".pdf";
    }
    downloadDocument.getDownloadUrl(vFile).then((res) => {
      if (res) {
        setDocument(res);
      } else {
        setMsgNoData("PDF file is either deleted or unable to load.");
        setIsDocLoaded(true);
      }
    });
  };

  /**
   * Function to navigate to page
   * @param {*} vPageNo
   * @param {*} vFileName
   * @param {*} vPageIndex
   */
  const navigateToPage = (vPageNo, vFileName, vPageIndex) => {
    if (fileName != vFileName) {
      setFileName(vFileName);
      getDocumentUrl(vFileName);
    }
    setPageNumber(parseInt(vPageNo));
    setCurrentPageNumber(parseInt(vPageNo));
    setSelectedRef(vPageIndex);
  };

  /**
   * Check if valid file name
   */
  const isValidFileName = (vFileName) => {
    if (vFileName) {
      for (const element of validFileExtensions) {
        if (vFileName.toLowerCase().indexOf(element) !== -1) {
          return true;
        }
      }
    }
    return false;
  };

  /**
   * Check if document deleted
   */
  const isDocumentDeleted = (vFileName) => {
    if (vFileName) {
      for (const element of deletedDocuments) {
        if (element === vFileName) {
          return true;
        }
      }
    }
    return false;
  };

  /**
   * Get deleted documents
   */
  const getDeletedDcoumnets = async () => {
    const vDeletedDocNames = [];
    const vDocuments = await documentservice.getDocuments(
      sessionStorage.getItem("DashboardProjectId")
    );
    if (vDocuments.length > 0) {
      const vDeletedDocs = _.filter(vDocuments, function (item) {
        return item.status === "Deleted";
      });
      if (vDeletedDocs.length > 0) {
        for (const element of vDeletedDocs) {
          vDeletedDocNames.push(element.documentName);
        }
      }
    }
    setDeletedDocuments(vDeletedDocNames);
  };

  return (
    <div>
      <MotifModal
        id="sourceDocumentModal"
        className="source-document-modal"
        show={props.setOpenModal}
        onClose={() => {
          props.setOpenModal(!props.openShow);
        }}
        focusTrapOptions={{
          tabbableOptions: {
            displayCheck: "none",
          },
        }}
        size="xl"
      >
        <MotifModalHeader className="modal-header-container">
          View Source Documents
        </MotifModalHeader>
        <MotifModalBody>
          <div className="source-document-body">
            <div className="source-document-container" ref={containerBodyRef}>
              <div className="source-document-title">{fileName}</div>
              <div  ref={containerRef} className="source-document-content" style={{height:modalBodyHeight}}>
                { props.references ?
                    <Document
                      file={document}
                      onLoadSuccess={onDocumentLoadSuccess}
                      className="document-viewer"
                      loading={msgLoading}
                      onLoadError={handleOnLoadError}
                      noData={isDocLoaded ? msgNoData : msgLoading}
                    >
                      <Page
                        pageNumber={pageNumber}
                        canvasBackground="white"
                        scale={1}
                        customTextRenderer={textRenderer}
                        width={pageWidth}
                      />
                    </Document>
                    : <div>{msgNoDocs}</div>
                  }
              </div>
              <div className="source-document-footer">
                <div className="paginatorWrapper">
                  <MotifIconButton
                    aria-label="Previous Page"
                    disabled={pageNumber <= 1}
                    onClick={previousPage}
                    className="iconPrevious"
                  >
                    <img src={nav_arrow_left} alt="Arrow Left" />
                  </MotifIconButton>
                  <div className="pageSelect">
                    {(pages.length > 0) &&
                      <MotifInput
                                    label="inputprojectname"
                                    onChange={(e)=>setCurrentPageNumber(e.target.value)}
                                    data-testid="inputprojectname"
                                    className='pageInput'
                                    value={currentPageNumber}
                                    hideClearButton={true}
                                    onKeyDown={(e) => {
                                      if (e.key === "Enter")
                                          goToPageNumber(e)
                                      }}
                                      width={30}
                                />
                    }
                  </div>
                  <div className="txtPageNo">/{numPages || "--"}</div>
                  <MotifIconButton
                    aria-label="Next Page"
                    disabled={pageNumber >= numPages}
                    onClick={nextPage}
                    className="iconNext"
                  >
                    <img src={nav_arrow_right} alt="Arrow Left" />
                  </MotifIconButton>
                </div>
             
              </div>
            </div>
            <div className="source-document-reference-container">
              <div className="reference-container-layout">
                <div className="reference-header-container">
                  Source documents
                </div>
                <div className="srcDocsWrapper">
                  <div className="source-wrapper" style={{height:modalSrcRefHeight}}>
                  {varReferences.map((vRef, vRefIndex) => (
                    <div className="srcDocsGroup" key={vRefIndex}>
                      {(isDocumentDeleted(vRef.fileName) || vRef.isBroken) && (
                        <div className="brokenLinkAccord">
                          {vRef.fileName}
                          <MotifTooltip
                            trigger={
                              <img
                                src={noLinkIcon}
                                alt="Document deleted"
                                className="iconNoLink"
                              ></img>
                            }
                          >
                            Document deleted
                          </MotifTooltip>
                        </div>
                      )}
                      {!(isDocumentDeleted(vRef.fileName) || vRef.isBroken) &&
                        isValidFileName(vRef.fileName) && (
                          <MotifAccordion
                            id={`refAccordion${vRefIndex}`}
                            onClose={() => setSelectedAccordion(null)}
                            onOpen={() => setSelectedAccordion(vRefIndex)}
                            style={{
                              flex: "1",
                            }}
                            alignIconRight={true}
                            useChevronIcon={true}
                            className={
                              selectedAccordion === vRefIndex
                                ? "blue-highlight"
                                : ""
                            }
                          >
                            <MotifAccordionTrigger>
                              <div className="accordionTitle">
                                {vRef.fileName}
                              </div>
                              <MotifIconButton
                                onClick={(e) => {
                                  e.stopPropagation();
                                  downloadDocument.handleDownload(
                                    vRef.fileName
                                  );
                                }}
                                className="btnDownload"
                              >
                                <img
                                  src={download}
                                  alt="download source documents"
                                ></img>
                              </MotifIconButton>
                            </MotifAccordionTrigger>
                            <MotifAccordionContent className="srcDocsContent">
                              {vRef.pages.map((vPage, vPageIndex) => (
                                <div key={vRefIndex + vPageIndex}>
                                  <MotifIconButton
                                    aria-label="Reference"
                                    onClick={() =>
                                      navigateToPage(
                                        vPage,
                                        vRef.fileName,
                                        vRefIndex + "~" + vPageIndex
                                      )
                                    }
                                    className="referenceLink"
                                  >
                                    Reference {vPageIndex + 1}: page {vPage}
                                    {selectedRef ===
                                      vRefIndex + "~" + vPageIndex && (
                                      <img src={tick} className="iconTick" />
                                    )}
                                  </MotifIconButton>
                                </div>
                              ))}
                            </MotifAccordionContent>
                          </MotifAccordion>
                        )}
                      {!(isDocumentDeleted(vRef.fileName) || vRef.isBroken) &&
                        !isValidFileName(vRef.fileName) && (
                          <div className="disabledAccord">{vRef.fileName}</div>
                        )}
                    </div>
                  ))}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </MotifModalBody>
      </MotifModal>
    </div>
  );
};
export default ViewSourceDocsModal;
