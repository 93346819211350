import React from "react";
import AddDocument from "../../CreateProject/AddDocuments/AddDocument";
import "../ProjectDashboard.css";
import BreadCrumb from "../../common/BreadCrumb";

class DocumentLibrary extends React.Component {
  constructor() {
    super();
    this.state = {
      projectName: sessionStorage.getItem("DashboardProjectName"),
    };
  }

  render() {
    return (
      <div className="ProjectDashboardPageContainer">
        <div className="HeaderLayout">
        <BreadCrumb
          projectName={this.state.projectName}
          pageName="Document Library"
        />
        <div className="HeaderContainer">
          <div className="HeaderTitle">
            <span className="HeaderText">Document Library</span>
          </div>
          <div className="HeaderAction">
            
          </div>
        </div>
      </div>
        
        <div className="EditProjectEditContent">
          <div className="EditProjectEditTitle">
            <div className="EditProjectEditTextAndSupportingText">
              <AddDocument classcheck="Library" isDocumentLibrary={true} />
            </div>
          </div>
        </div>
      </div>
    );
  }
}
export default DocumentLibrary;
