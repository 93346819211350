import { useEffect, useState, useContext, useRef } from "react";
import explore from "../../../assets/image/exploredesign.svg";
import senddiagonal from "../../../assets/image/senddiagonal.svg";
import dashboardnav from "../../../assets/image/dashboardnav.svg";
import expandprompt from "../../../assets/image/expand.svg";
import { v4 as uuidv4 } from "uuid";
import _ from "lodash";
import {
  MotifFormField,
  MotifButton,
  MotifBreadcrumb,
  MotifBreadcrumbItem,
  MotifInput,
  MotifTooltip
} from "@ey-xd/motif-react";
import { MotifIconButton } from '@ey-xd/motif-react/Button';
import "../ExploreData/ExploreData.css";
import "../ProjectDashboard.css";
import ExloreRightPanal from "./ExploreDataRightPanal";
import ExplorePhase from "./ExplorePhase";
import { DashboardContext } from "../../../context/dashboardContext";
import { useLocation, useOutletContext } from "react-router-dom";
import { dashboardDataConstants } from "../../dashboard/constants";
import MemberService from '../../../services/memberService';
import React, { useCallback } from 'react';
import BreadCrumb from "../../common/BreadCrumb";
import {MotifRichTextEditor} from '@ey-xd/motif-react'
import ExpandPromptModal from "./ExpandPromptModal";

const ExploretData = () => {
  const location = useLocation();
  const [askQuestion, setAskQuestion] = useState("");
  const [questions, setQuestions] = useState([]);
  const [askQuestionClick, setaskQuestionClick] = useState(true);
  const [askQuestionDisable, setaskQuestionDisable] = useState(true);
  const [confPublic, SetConfPublic] = useState("C");
  const [publicClick, setPublicClick] = useState(false);
  const [confClick, setconfClick] = useState(true);
  const [isSubmitpromt, setIsSubmitPrompt] = useState(false);
  const [showPromptHistory, setShowPromptHistory] = useState(false);
  const [isSrcDocActive, setIsSrcDocActive] = useState(false);
  const projectName = sessionStorage.getItem("DashboardProjectName");
  const {
    viewPrompt,
    isNewPromptCoversation,
    setIsNewPromptCoversation,
    hidePromptSearchInput,
    viewInstantPrompt
  } = useContext(DashboardContext);
  const [selectedSourceDocs, setSelectedSourceDocs] = useState("");
  const outletContext = useOutletContext();
  const memberService = new MemberService();
  const email = sessionStorage.getItem("Email");
  let { setIsPromptSubmitted } = useContext(DashboardContext);
  const [showInstantModal, setShowInstantModal] = useState(false);
  const [selectedDocDeleted, setSelectedDocDeleted] = useState(false);
  
  const [questionBar, setQuestionBar] = useState("");

  const [expandPromptModal, setExpandPromptModal] = useState(false);
  const promptRef = useRef(null);

  useEffect(()=>{
    setAskQuestion(questionBar);
    console.log("askQuestion", questionBar);
  }, [questionBar]);
  
  const handlePromptSubmit = (event) => {
    // On Enter press (with no shift key) submit the prompt
    if(event.code === 'Enter' && !event.shiftKey){
      onSendPrompt();
    }
  };

  useEffect(() => {
    const promptEditor = promptRef.current;
    const numberedBtn = promptEditor.querySelector(`button[value="ordered"]`);
    const bulletBtn = promptEditor.querySelector(`button[value="bullet"]`);
        // Add a title attribute to the button
      if(numberedBtn) {numberedBtn.setAttribute("title", "Numbered list");}
      if(bulletBtn){ bulletBtn.setAttribute("title", "Bulleted list");}
  }, []);


  const handlePromptChange = (prompt) => {
    setQuestionBar(prompt);
    setAskQuestion(prompt);
  }

  const motifReactEditorModules = {
    toolbar: [[{
      list: 'ordered'
    }, {
      list: 'bullet'
    }, 
  ]],
    clipboard: {
      matchVisual: false
    }
  };

  const viewExpandPrompt = () => {
    setExpandPromptModal(true);
  };


  useEffect(() => {
    return () => {
      if (location.state?.promptText !== undefined) {
        location.state.promptText = "";
      }
    };
  }, []);

  useEffect(() => {
    if (
      location.state?.promptText !== "" &&
      location.state?.promptText !== undefined
    ) {
      setAskQuestion(location.state?.promptText);
      document.getElementById("question")?.focus();
      outletContext.highlightExploreDataNavigationOption();
    }
    if (isSubmitpromt === true) {
      setAskQuestion("");
    }
    setSelectedDocDeleted(false);
  }, [questions]);

  useEffect(() => {
    if (viewInstantPrompt && viewInstantPrompt?.instantInsightFavoritePromptId) {
      setShowInstantModal(true);
    }
  }, [viewInstantPrompt]);

  useEffect(() => {
    if (viewPrompt && viewPrompt?.requestContent) {
      let quests = [];
      quests.push(viewPrompt.requestContent);
      setQuestions(quests);
      setAskQuestion("");
      setShowPromptHistory(true);
      if (viewPrompt?.isPublicConfidential === "C") {
        SetConfPublic("C");
        setPublicClick(false);
        setconfClick(true);
      } else {
        SetConfPublic("P");
        setPublicClick(true);
        setconfClick(false);
      }
    }
  }, [viewPrompt]);

  useEffect(() => {
    if (isNewPromptCoversation) {
      setQuestions([]);
      setIsNewPromptCoversation(false);
    }
  }, [isNewPromptCoversation]);

  useEffect(() => {
    if (selectedDocDeleted) {
      setQuestions([]);
      window.location.reload();
    }
  }, [selectedDocDeleted]);

  useEffect(() => {
    setUserProjectRole()
  }, []);

  const setUserProjectRole = async () => {
    let memberList = await memberService.GetProjectDetailById(sessionStorage.getItem('DashboardProjectId'));
    let memberDetail = memberList.filter(item => item.email.includes(email));
    localStorage.setItem('userRole', memberDetail[0].role);
  }


  const OnChangeQuestion = (event) => {
    event.preventDefault();
    console.log("event", event);
    setAskQuestion(event.target.value);
    setIsSubmitPrompt(false);
  };

  /**
   * Description
   * -----------
   * This function is set the Source document tab as active. When user focus on ask a question input field.
   */
  const handleQuestionFocus = (isSrcDocActive) => {
    setIsSrcDocActive(isSrcDocActive);
  }

  const onSendPrompt = () => {
    if (askQuestion !== "") {
      if (questions.length === 0) {
        if (sessionStorage.getItem("PromptConversationId")) {
          sessionStorage.removeItem("PromptConversationId");
        }
        sessionStorage.setItem("PromptConversationId", uuidv4());
      }
      if (showPromptHistory) {
        setShowPromptHistory(false);
      }
      setQuestions([...questions, askQuestion]);
      setQuestionBar("");
      setAskQuestion("");
      setIsSubmitPrompt(true);
      setIsPromptSubmitted(true);
    }
  };
  const AddPromptToQuestionBar = (EditPrompt = String) => {
    setAskQuestion(EditPrompt);
  };

  const selctedSourceDocs = useCallback((selectdocs) => {
    const selectedDocs = selectdocs.filter(item => item.selected === true).length;
    setaskQuestionDisable(selectedDocs === 0);
    const resultitems = selectdocs?.filter((item) => item?.selected);
    const docnames = resultitems?.map((item) => item.documentName).join(', ');
    setSelectedSourceDocs(docnames);
  }, []);

  const isContentEmpty = () => {

    const tempDiv = document.createElement('div');
    tempDiv.innerHTML = askQuestion;
    const textContent = tempDiv.textContent || tempDiv.innerText || '';
    return textContent.trim()==="";
  };

  return (
    <div>
      {/* <ExploreContextProvider> */}
      <div className="HeaderLayout">
      <BreadCrumb
          projectName={projectName}
          pageName="Explore your data"
        />
        <div className="HeaderContainer">
          <div className="HeaderTitle">
            <span className="HeaderText">Explore your data</span>
          </div>
          <div className="HeaderAction">
            
          </div>
        </div>
      </div>
      <div className="explore-data-left-right-panel">
        {!showInstantModal && (
          <div className="explore-data-container" id="id-scroll-explore-data-container">
            <div className="explore-data-container-child">
              {questions.length === 0 && (
                <div>
                  <div className="explore-data-container-image-container">
                    <img src={explore} alt="explore your data" />
                    <div className="explore-data-container-image-container-child">
                      <div className="explore-data-container-image-container-text">

                        Please select documents you'd like to start searching with
                      </div>
                    </div>
                  </div>
                </div>
              )}

              {questions.length > 0 && !selectedDocDeleted &&
                questions.map((question, index) => {
                  return (
                    <div className="explore-prompt-phase-parent" key={index}>
                      <ExplorePhase
                        question={question}
                        publicConf={confPublic}
                        addprompt={AddPromptToQuestionBar}
                        isPromptSubmit={isSubmitpromt}
                        showPromotHistory={showPromptHistory}
                        selectedDocNames={selectedSourceDocs}
                        showInstantModal={showInstantModal}
                        setShowInstantModal={setShowInstantModal}
                        setSelectedDocDeleted={setSelectedDocDeleted}
                        setIsSrcDocActive={setIsSrcDocActive}
                      />
                    </div>
                  );
                })}
              {
                !hidePromptSearchInput && (
                  <div className="explore-data-container-promptarea-container">
                    <div className="explore-data-container-promptarea-buttons">
                      <MotifButton
                        className={
                          confClick ? "confpublic ActivePublic " : "ActiveConf"
                        }
                        onClick={(e) => {
                          SetConfPublic("C");
                          setPublicClick(false);
                          setconfClick(true);
                          (selectedSourceDocs === '') ? setaskQuestionDisable(true) : setaskQuestionDisable(false);
                        }}
                      >
                        Confidential
                      </MotifButton>

                      <MotifButton
                        className={
                          publicClick
                            ? "confpublic ActivePublic  marginpublic"
                            : "ActiveConf marginpublic"
                        }
                        onClick={(e) => {
                          SetConfPublic("P");
                          setPublicClick(true);
                          setconfClick(false);
                          setaskQuestionDisable(false);
                        }}
                      >
                        Public
                      </MotifButton>
                    </div>

                    <div className="explore-data-container-prompt-question-container">

                      <MotifTooltip
                        placement="top"
                        id="noDocumentSelectTooltip"
                        hide={askQuestionDisable ? false : true}
                        trigger={
                          <MotifFormField
                          style={{
                            width: "94%",
                            marginLeft: "20px",
                            cursor: askQuestionDisable ? "not-allowed" : null,
                          }}
                        >
                          <div ref={promptRef}>
                            <MotifRichTextEditor 
                              id="explore-prompt-box-editor"
                              placeholder="Ask a question..." 
                              value={askQuestion}
                              modules = {motifReactEditorModules}
                              onKeyDown ={handlePromptSubmit}
                              onChange={handlePromptChange}
                              readOnly={askQuestionDisable}
                              onKeyPress={(e) => { if (e.key === 'Enter') { onSendPrompt() } }}
                              onFocus={(e) => { handleQuestionFocus(true) }}
                            />
                          </div>

                          <MotifIconButton
                            className="btnExpandPrompt"
                            onClick={() => viewExpandPrompt()}
                            disabled={askQuestionDisable}
                          >
                            <MotifTooltip
                              placement="top"
                              trigger={<img src={expandprompt} alt="" />}
                            >
                              Expand
                            </MotifTooltip>
                            </MotifIconButton>
                            <MotifTooltip
                              hide={!askQuestionDisable}
                              placement="top"
                              trigger={
                                <MotifIconButton
                                  onClick={() => onSendPrompt()}
                                  disabled={askQuestionDisable || isContentEmpty()}
                                  className="btnSendPrompt"
                                >
                                  <img src={senddiagonal} alt="" className="iconSendPrompt"/>
                                </MotifIconButton>
                              }
                            >
                              Documents not selected
                            </MotifTooltip>
                          </MotifFormField>
                        }
                      >
                        <b>No files selected. </b>
                        <br /> Please select a file from the 'Source documents tab'
                      </MotifTooltip>
                      <div className="explore-data-container-response-note">
                        Please always be mindful of your obligations as an EY Professional and use Digital Diligence Assistant
                        responsibly in line with the&nbsp;
                        <a
                          className="exploreDataLinkText"
                          href={dashboardDataConstants.eyAiPrinciples.url}
                          target="_blank"
                          rel="noopeer noreferrer"
                        >
                          {dashboardDataConstants.eyAiPrinciples.name}
                        </a>,&nbsp;
                        <a
                          className="exploreDataLinkText"
                          href={dashboardDataConstants.msAcceptableUsePolicy.url}
                          target="_blank"
                          rel="noopeer noreferrer"
                        >
                          {dashboardDataConstants.msAcceptableUsePolicy.name}
                        </a>,&nbsp;
                        <a
                          className="exploreDataLinkText"
                          href={dashboardDataConstants.msCodeOfConductOpenAIServices.url}
                          target="_blank"
                          rel="noopeer noreferrer"
                        >
                          {dashboardDataConstants.msCodeOfConductOpenAIServices.name}
                        </a>
                        &nbsp;and the&nbsp;
                        <a
                          className="exploreDataLinkText"
                          href={dashboardDataConstants.enagagementManagement.url}
                          target="_blank"
                          rel="noopeer noreferrer"
                        >
                          {dashboardDataConstants.enagagementManagement.name}
                        </a>
                        . Always make sure that you (a) have the required rights and permissions
                        to use data with Digital Diligence Assistant and (b) validate any outputs prior to its use. Use of
                        Digital Diligence Assistant must be in line with SaT Service Line requirements as outlined in the&nbsp;
                        <a
                          className="exploreDataLinkText"
                          href={dashboardDataConstants.enagagementManagement.url}
                          target="_blank"
                          rel="noopeer noreferrer"
                        >
                          {dashboardDataConstants.enagagementManagement.name}
                        </a>
                        &nbsp;and any EY work product using such output must be reviewed for accuracy and validity and follow all
                        country and/or SaT Service Line specific quality processes and guidelines.
                      </div>

                    </div>
                  </div>
                )
              }


            </div>
          </div>)}
        {showInstantModal && (
          <div className="explore-instant-container">
            <ExplorePhase
              showInstantModal={showInstantModal}
              setShowInstantModal={setShowInstantModal}
            />
          </div>
        )}
        <div className="explore-data-right-panel">
          <ExloreRightPanal
            publicOrConfidential={confPublic}
            selectdocs={selctedSourceDocs}
            isSrcDocActive={isSrcDocActive}
            handleQuestionFocus={handleQuestionFocus}
          />
        </div>
        {expandPromptModal && (
          <div>
          <ExpandPromptModal
            expandPromptModal={expandPromptModal}
            setExpandPromptModal={setExpandPromptModal}
            questionBar={questionBar}
            setQuestionBar={setQuestionBar}
            />
          </div>
        )}
      </div>
    </div>
  );
};

export default ExploretData;
