import axios from "./axiosWithMsal";
import { appInsights } from "./appInsights";
import CommonService from "./commonService.js";

class InstantInsightsService {
  commonService = new CommonService();
  constructor() {
    this.apiUrl = this.commonService.getAPIUrl();
  }

  /**
   * This method returns structured data of prompts and responses.
   * @returns Array
   */
  async getPromptsnResponsesById(id, projectId, topicId) {
    try {
      return await axios
        .get(
          this.apiUrl +
          "InstantInsights/GetPlatformPromptHistoryDetails?SubSectionId=" +
          id +
          "&Project_UUID=" +
          projectId +
          "&TopicId=" + topicId,
          {
            headers: {
              "Content-type": "application/json; charset=UTF-8",
              Accept: "application/json",
            },
          }
        )
        .then((data) => {
          return data.data;
        });
    } catch (error) {
      appInsights.trackException({
        error: error,
        properties: {
          Component: "Insights Component",
          Method: "getInsightTreeData",
        },
      });
    }
  }

  /**
   * This method posts request to server for refreshing insights.
   * @param {object} payload 
   */
  refreshInsights = (payload) => {
    try {
      axios.post(this.apiUrl + "InstantInsights/RefershInstantInsightPrompts", payload, {
        headers: {
          "Content-type": "application/json; charset=UTF-8",
          Accept: "application/json",
        },
      });
    } catch (error) {
      appInsights.trackException({
        error: error,
        properties: {
          Component: "RefreshInsightsModal Component",
          Method: "refreshInsights",
        },
      });
    }
  };

  /**
   * Api call to refresh Instant insights based on scopeid and projectId 
   * @param {*} scopeid and ProjectiId
   */

  refreshInsightsManually = (scopeId, project_UUID) => {
    try {
      axios.post(this.apiUrl + "InstantInsights/RunInitialInstantInsights", {
        ScopeId: scopeId,
        Project_UUID: project_UUID
      }, {
        headers: {
          "Content-type": "application/json; charset=UTF-8",
          Accept: "application/json",
        },
      });
    } catch (error) {
      appInsights.trackException({
        error: error,
        properties: {
          Component: "RefreshInsightsModal Component",
          Method: "refreshInsightsManually",
        },
      });
    }
  };

  /**
   * This method posts request to server for acknowledging refresh of insights.
   * @param {object} payload 
   */
  acknowledgeRefresh = async (id, level, projectScopeId) => {
    try {
      return await axios.post(this.apiUrl + "InstantInsights/AcknowledgeRefresh", {
        id: id,
        level: level,
        projectScopeId: projectScopeId
      }, {
        headers: {
          "Content-type": "application/json; charset=UTF-8",
          Accept: "application/json",
        },
      });
    } catch (error) {
      appInsights.trackException({
        error: error,
        properties: {
          Component: "Acknowledge refresh Component",
          Method: "acknowledgeRefresh",
        },
      });
    }
  };

  /**
   * This method returns structured data for insights tree
   * @returns Object
   */
  getInsightTreeData = async (scopeId = String, projectId = String, projectScopeId = String) => {
    try {
      return await axios
        .get(
          this.apiUrl +
          "InstantInsights/GetScopeTreeDetails?ScopeId=" +
          scopeId +
          "&Project_UUID=" + projectId +
          "&ProjectScopeId=" + projectScopeId,
          {
            headers: {
              "Content-type": "application/json; charset=UTF-8",
              Accept: "application/json",
            },
          }
        )
        .then((data) => {
          const datareturn = data?.data;
          return datareturn;
        });
    } catch (error) {
      this.commonService.logException(
        error,
        "Instant Insights Component",
        "GetScopeDetailById"
      );
    }
  };

  /**
   * This method returns true/false based on the refresh instant insights run
   * @returns Object
   */
  GetRefreshInstantInsightFlag = async (projectScopeId) => {
    try {
      return await axios
        .get(
          this.apiUrl +
          "InstantInsights/GetRefreshInstantInsightFlag?ProjectScopeId=" +
          projectScopeId,
          {
            headers: {
              "Content-type": "application/json; charset=UTF-8",
              Accept: "application/json",
            },
          }
        )
        .then((data) => {
          const datareturn = data?.data;
          return datareturn;
        });
    } catch (error) {
      this.commonService.logException(
        error,
        "Instant Insights Component",
        "GetRefreshInstantInsightFlag"
      );
    }
  };

  /**
   * Api call to Get all the members based on a projectId
   * @param {*} ProjectiId
   * @returns Object
   */
  async GetScopeDetailById(ProjectiId = String) {
    try {
      return await axios
        .get(
          this.apiUrl +
          "InstantInsights/GetScopeDetails?project_UUID=" +
          ProjectiId +
          "",
          {
            headers: {
              "Content-type": "application/json; charset=UTF-8",
              Accept: "application/json",
            },
          }
        )
        .then((data) => {
          const datareturn = data?.data;
          return datareturn;
        });
    } catch (error) {
      this.commonService.logException(
        error,
        "Instant Insights Component",
        "GetScopeDetailById"
      );
    }
  }

  // Api call to update the favorite content of instant insight
  // Input: instantInsightPromptHistoryId and favContent
  //OutPut: Update the fav content of the instant insight prompt history
  async UpsertFavoritePromptContent(
    instantInsightPromptHistoryId,
    instantInsightFavoritePromptId,
    favContent,
    topicId
  ) {
    const request = {
      instantInsightPromptHistoryId: instantInsightPromptHistoryId,
      instantInsightFavoritePromptId: instantInsightFavoritePromptId,
      favoriteContent: favContent,
      email: sessionStorage.getItem("Email"),
      topicId: topicId
    };
    try {
      return await axios
        .post(
          this.apiUrl + "InstantInsights/UpsertInstantInsightFavoriteContent",
          request,
          {
            headers: {
              "Content-type": "application/json; charset=UTF-8",
              Accept: "application/json",
            },
          }
        )
        .then((response) => {
          return response.data;
        });
    } catch (error) {
      this.commonService.logException(
        error,
        "Instant Insights Component",
        "Upsert Favorite Prompt Content"
      );
      return false;
    }
  }

  // Api call to delete the favorite content of instant insight
  // Input: instantInsightFavoritePromptId
  //OutPut: Delete the fav content of the instant insight prompt history
  async deleteFavoritePrompt(instantInsightFavoritePromptId) {
    let response = true;
    await axios
      .delete(
        this.apiUrl +
        "InstantInsights/DeleteInstantInsightFavoritePrompts?instantInsightFavoritePromptId=" +
        instantInsightFavoritePromptId +
        "",
        {
          headers: {
            "Content-type": "application/json; charset=UTF-8",
          },
        }
      )
      .catch((error) => {
        this.commonService.logException(
          error,
          "Instant Insights Component",
          "Delete Favorite Prompt"
        );
        response = false;
      });

    return response;
  }

  // Api call to get pdf file export of instant insight response
  //OutPut: returns the exported file in pdf format
  async exportInstantInsight(exportRequest) {
    try {
      return await axios
        .post(
          this.apiUrl + "InstantInsights/ExportInstantInsight",
          exportRequest,
          {
            headers: {
              "Content-type": "application/json; charset=UTF-8",
              Accept: "application/json",
            },
            responseType: "blob"
          }
        )
        .then((response) => {
          return response;
        });
    } catch (error) {
      this.commonService.logException(
        error,
        "Instant Insights Component",
        "Export Instant Insight  Content"
      );
      return false;
    }
  }

  async upsertLikeOrDislikePromptContent(
    instantInsightPromptHistoryId,
    userActionValue
  ) {
    const request = {
      PromptHistoryId: instantInsightPromptHistoryId,
      UserAction: userActionValue,
      Email: sessionStorage.getItem("Email")
    };
    try {
      return await axios
        .post(
          this.apiUrl + "InstantInsights/UpsertInstantInsightUserAction",
          request,
          {
            headers: {
              "Content-type": "application/json; charset=UTF-8",
              Accept: "application/json",
            },
          }
        )
        .then((response) => {
          return response.data;
        });
    } catch (error) {
      this.commonService.logException(
        error,
        "Instant Insights Component",
        "Upsert User Action Prompt Content"
      );
      return false;
    }
  }

  async getThumbsupAndThumbsdownCounts(projectId, subSectionId) {
    try {
      let url = this.apiUrl +
        "InstantInsights/GetUserActionCount?Project_UUID=" +
        projectId + "&SubSectionId=" + subSectionId;

      return await axios
        .get(
          url,
          {
            headers: {
              "Content-type": "application/json; charset=UTF-8",
              Accept: "application/json",
            },
          }
        )
        .then((data) => {
          return data.data;
        });
    } catch (error) {
      appInsights.trackException({
        error: error,
        properties: {
          Component: "Insights Component",
          Method: "getThumbsupAndThumbsdownCounts",
        },
      });
    }
  }

  /**
   * Api call to Get all last refreshed instant insights
   * @param {string} projectId
   * @param {number} projectScopeId
   * @returns Object
   */
  async GetLastRefreshedInstantInsights(projectScopeId, projectId) {
    try {
      return await axios
        .get(
          this.apiUrl +
          "InstantInsights/GetLastRefreshInstantInsight?ProjectScopeId=" +
          projectScopeId +
          "&Project_UUID=" + projectId,
          {
            headers: {
              "Content-type": "application/json; charset=UTF-8",
              Accept: "application/json",
            },
          }
        )
        .then((result) => {
          return result?.data;
        });
    } catch (error) {
      this.commonService.logException(
        error,
        "Instant Insights Component",
        "GetRefreshedInstantInsights"
      );
    }
  }

}
export default InstantInsightsService;
