import MotifModal, { MotifModalHeader, MotifModalBody } from '@ey-xd/motif-react/Modal';
import { MotifModalFooter,MotifSelect,MotifOption,MotifTooltip,MotifFormField,MotifLabel } from "@ey-xd/motif-react";
import MotifButton from '@ey-xd/motif-react/Button';
import React, { useState, useEffect, useContext } from "react";
import arrowleftblack from "../../assets/image/arrow-left-black.svg";
import tick from "../../assets/image/tick.svg";
import progress from "../../assets/image/progress.svg";
import "./CreateProjectPopup.css";
import { dashboardDataConstants } from "./constants";
import information from "../../assets/image/information.svg";
import EngagementLocationService from "../../services/engagementLocationService";
import searchIcon from "../../assets/image/search.svg";
import { DashboardContext } from "../../context/dashboardContext";

export function CreateProjectPopup({ setOpenModal, setAcknow }) {
    const [open, setOpen] = useState(true);
    const [showEngagementDisclaimer, setShowEngagementDisclaimer] = useState(true);
    const [showProjApproval, setShowProjApproval] = useState(false);
    const [showAIDisclaimer, setShowAIDisclaimer] = useState(false);
    const constantData = require("../../data/constant.js");
    const [locationList, setLocationList] = useState([]);
    const engagementLocationService = new EngagementLocationService();
    const [engagementLocation, setEngagementLocation] = useState();
    const [isEngagementLabelVisible, setIsEngagementLabelVisible] = useState(true);
    const [locationDisable, setLocationDisable] = useState(true);
    const { setProjectCountry } = useContext(DashboardContext);

    /**
     * Description
     * -----------
     * This useffect is checking if  EngagementLocation available in localstorage and then set in variable
     */
    useEffect(() => {
        LocationListData();
    }, []);
     /**
     * Description
     * -----------
     * This method is getting engagementlocation from config and setting them in list
     */
    const LocationListData = async () => {
        let result = await engagementLocationService.getEngagementLocationData();
        if (result) {
        setLocationList(result);
        }
    }
    /**
   * Description
   * -----------
   * This method is to close the Disclaimer popup
   */
    const onCloseClick = () => {
        setOpen(false);
        setOpenModal(false);
    }
    /**
   * Description
   * -----------
   * This method is to back from second page of the Disclaimer popup
   */
    const onBackClick = () => {
        if (showProjApproval === true) {
            setShowEngagementDisclaimer(true);
            setShowProjApproval(false);
        }
        if (showAIDisclaimer === true) {
            setShowProjApproval(true);
            setShowAIDisclaimer(false);
        }

    }
    /**
  * Description
  * -----------
  * This method is to go to the create project page from the Disclaimer popup
  */
    const onAcknowledgeClick = () => {
        if (showEngagementDisclaimer === true) {
            setShowProjApproval(true);
            setShowAIDisclaimer(false);
            setShowEngagementDisclaimer(false);
            if (engagementLocation !== "") {
                const locationObj = locationList.filter(item => item.content === engagementLocation)[0];
                setProjectCountry(locationObj?.id);
                sessionStorage.setItem("ProjectCountry", locationObj?.id);
                localStorage.setItem('EngagementLocation', JSON.stringify(locationObj));
            }
        }
        if (showProjApproval === true) {
            setShowProjApproval(false);
            setShowAIDisclaimer(true);
            setShowEngagementDisclaimer(false);
        }
        if (showAIDisclaimer === true) {
            setAcknow();
        }
    }
    /**
          * Description
          * -----------
          * This method is to call when user selects any country
          * Parameters:
          * data: this one is onChange event parameter to get the selected text
          */
    const onEngagementLocationChange = (e) => {
        if (e !== null) {
            setEngagementLocation(e);
            setLocationDisable(false);
            setIsEngagementLabelVisible(false);
        }
        else {
            setLocationDisable(false);            
        }
    }

    return (

        <div >
            <MotifModal id="CreatePopUppopupmodal" show={open} focusTrapOptions={{
                    tabbableOptions: {
                        displayCheck: 'none'
                    }
                }}
                onClose={() => { onCloseClick() }}
                size='lg'
                className="createProjectPopupModalHeight genericModal modalMotifFooterModal"
            >
                <MotifModalHeader
                    closeButtonProps={{
                        'aria-label': 'Close',
                        title: 'Close'
                    }}
                >
                    <div className="CreatePopUpModalTitle" >Important to know when creating a new project</div>

                </MotifModalHeader>
                <MotifModalBody>
                    <React.Fragment>
                        {showEngagementDisclaimer &&
                            <div>
                                <div className="CreatePopUpContentBlock" >
                                    <div className="CreatePopUpProgress" >
                                        <div className="CreatePopUpText">Step 1 of 3</div>
                                        <div className="CreatePopUpProgressStepIcon" >
                                            <div className="CreatePopUpContent" >
                                                 <img alt='icon'  src={tick}></img>
                                            </div>

                                        </div>
                                        <div className="CreatePopUpProgressStepIcon" >
                                            <div className="CreatePopUpContent" >
                                                <img alt='icon' src={progress}></img>
                                            </div>
                                        </div>
                                        <div className="CreatePopUpProgressStepIcon" >
                                            <div className="CreatePopUpContent" >
                                                <img alt='icon' src={progress}></img>
                                            </div>
                                        </div>
                                    </div>
                                </div>


                                <div className="aligned">
                                    <span className='CreatePopUpProjDisclaimer'>What is the engagement location of your project?</span>
                                </div>
                                <div className="aligned">
                                    <span className='selectEngagementTitle'>Select engagement location</span>
                                </div>
                                <div className='selectEngagement'>
                                <MotifFormField>
                                    <MotifSelect
                                            id='create-select-location'
                                            label="createengagementlocation"
                                            ariaLabelledBy="select-label"
                                            onChange={onEngagementLocationChange}
                                            placeholder="Search or select Location"
                                            value={engagementLocation}
                                            searchPlaceholder='Search or select Location'
                                            showSelectAllButton={true}
                                            filter={true}
                                            visibleOptions={5}
                                        >
                                            {locationList.map(item => {
                                                return (
                                                    <MotifOption key={item.id} className={item.content} value={item.content} label={item.content}
                                                        disabled={constantData.restrictedLocation.indexOf(item.content) > -1 ? true : false}
                                                    >
                                                        <MotifTooltip
                                                            allowClick={false}
                                                            hide={constantData.restrictedLocation.indexOf(item.content) > -1 ? false : true}
                                                            trigger={
                                                                <span >
                                                                    {
                                                                        (() => {
                                                                            return <>{item.content}
                                                                                {constantData.restrictedLocation.indexOf(item.content) > -1 ?
                                                                                    <img alt="imgAlt" className="engagementLocationImage" src={information}></img>
                                                                                    : null
                                                                                }</>
                                                                        })()
                                                                    }
                                                                </span>
                                                            }>
                                                            <div className="engagementLocationTooltipText">
                                                                {constantData.restrictedTooltipData}
                                                            </div>
                                                        </MotifTooltip>
                                                    </MotifOption>
                                                );
                                            })}
                                        </MotifSelect>
                                    </MotifFormField>
                                </div>
                                <div>
                                    <span className='CreatePopUpProjDetail'>This is the location of the EY member firm that holds the engagement agreement with the client - it is not the location of the Target / Client company itself.</span>
                                </div>
                            </div>
                        }
                        {showProjApproval &&
                            <div>
                                <div className="CreatePopUpContentBlock" >
                                    <div className="CreatePopUpProgress" >
                                        <div className="CreatePopUpText">Step 2 of 3</div>
                                        <div className="CreatePopUpProgressStepIcon" >
                                            <div className="CreatePopUpContent" >
                                                 <img alt='icon'  src={tick}></img>
                                            </div>

                                        </div>
                                        <div className="CreatePopUpProgressStepIcon" >
                                            <div className="CreatePopUpContent" >
                                                 <img alt='icon'  src={tick}></img>
                                            </div>

                                        </div>
                                        <div className="CreatePopUpProgressStepIcon" >
                                            <div className="CreatePopUpContent" >
                                                <img alt='icon' src={progress}></img>
                                            </div>
                                        </div>
                                    </div>
                                </div>


                                <div className="aligned">
                                    <span className='CreatePopUpProjDisclaimer'>Permissibility for use disclaimer<br /></span>
                                </div>
                                <div className="aligned">
                                    <span className='CreatePopUpProjDetail'>
                                        {constantData.ProjectApprovalTextPart1}&nbsp;
                                        <a
                                            className="createProjectPopupLinkText"
                                            href={dashboardDataConstants.enagagementManagement.url}
                                            target="_blank"
                                            rel="noopeer noreferrer"
                                        >
                                            {dashboardDataConstants.enagagementManagement.name}
                                        </a>&nbsp;
                                        {constantData.ProjectApprovalTextPart2}
                                    </span>
                                </div>
                            </div>
                        }
                        {showAIDisclaimer &&
                            <div>
                                <div className="CreatePopUpContentBlock" >
                                    <div className="CreatePopUpProgress" >
                                        <div className="CreatePopUpText">Step 3 of 3</div>
                                        <div className="CreatePopUpProgressStepIcon" >
                                            <div className="CreatePopUpContent" >
                                                 <img alt='icon'  src={tick}></img>
                                            </div>

                                        </div>
                                        <div className="CreatePopUpProgressStepIcon" >
                                            <div className="CreatePopUpContent" >
                                                 <img alt='icon'  src={tick}></img>
                                            </div>

                                        </div>
                                        <div className="CreatePopUpProgressStepIcon" >
                                            <div className="CreatePopUpContent" >
                                        <img alt='icon'  src={tick}></img>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className="aligned">
                                    <span className='CreatePopUpProjDisclaimer'>Ai disclaimer<br /></span>
                                </div>
                                <div className="aligned">
                                    <span className='CreatePopUpProjDetail'>
                                        {constantData.ProjectAIDisclaimerTextPart1}&nbsp;
                                        <a
                                            className="createProjectPopupLinkText"
                                            href={dashboardDataConstants.eyAiPrinciples.url}
                                            target="_blank"
                                            rel="noopeer noreferrer"
                                        >
                                            {dashboardDataConstants.eyAiPrinciples.name}
                                        </a>,&nbsp;
                                        <a
                                            className="createProjectPopupLinkText"
                                            href={dashboardDataConstants.msAcceptableUsePolicy.url}
                                            target="_blank"
                                            rel="noopeer noreferrer"
                                        >
                                            {dashboardDataConstants.msAcceptableUsePolicy.name}
                                        </a>,&nbsp;
                                        <a
                                            className="createProjectPopupLinkText"
                                            href={dashboardDataConstants.msCodeOfConductOpenAIServices.url}
                                            target="_blank"
                                            rel="noopeer noreferrer"
                                        >
                                            {dashboardDataConstants.msCodeOfConductOpenAIServices.name}
                                        </a>
                                        &nbsp;and the&nbsp;
                                        <a
                                            className="createProjectPopupLinkText"
                                            href={dashboardDataConstants.enagagementManagement.url}
                                            target="_blank"
                                            rel="noopeer noreferrer"
                                        >
                                            {dashboardDataConstants.enagagementManagement.name}
                                        </a>.&nbsp;
                                        {constantData.ProjectAIDisclaimerTextPart2}
                                    </span>
                                </div>
                            </div>
                        }
                    </React.Fragment>
                </MotifModalBody>
                <MotifModalFooter>
                    <div className="BadgeFooterButtonContent" >
                        <div className="EditProjectEditTitle" >
                            <div className="EditProjectEditTextAndSupportingText" >
                                <div className="EditProjectProjectHeaderText" >
                                    {showEngagementDisclaimer && (
                                    <div>                                    
                                        <a
                                            className="createProjectPopupLinkText"
                                            href={dashboardDataConstants.enagagementManagement.url}
                                            target="_blank"
                                            rel="noopeer noreferrer"
                                        >
                                            {dashboardDataConstants.enagagementManagement.name}
                                        </a>
                                    </div>
                                )}
                                </div>
                            </div>
                        </div>
                        <div className="EditProjectEditActions" >
                            <div>
                                {(showAIDisclaimer || showProjApproval) &&
                                    <div className="EditProjectEditCancelText" >
                                        <MotifButton
                                            className=""
                                            onClick={() => { onBackClick() }}
                                            variant="secondary"
                                        >
                                            <img alt='icon' src={arrowleftblack} className='iconBack'></img>
                                            Back
                                        </MotifButton>
                                    </div>
                                }
                            </div>
                            <div className="" >
                            {(showEngagementDisclaimer || showProjApproval ||showAIDisclaimer  ) &&
                                    <div >
                                        <MotifButton
                                            onClick={() => { onAcknowledgeClick() }}
                                            disabled={locationDisable}
                                        >
                                    {(showProjApproval || showAIDisclaimer)   && (
                                                <>
                                                    Acknowledge
                                                </>
                                            )}
                                            {showEngagementDisclaimer && (
                                                <>
                                                    Confirm
                                                </>
                                            )}
                                        </MotifButton>
                                    </div>
                                }
                            </div>
                        </div>
                    </div>
                </MotifModalFooter>

            </MotifModal>
        </div >
    )
}

export default CreateProjectPopup;