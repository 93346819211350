import React, {useEffect, useState} from "react";

import { MotifButton, MotifToast} from "@ey-xd/motif-react";
import plus from "../../../../assets/image/plus.svg";
import noResponseImage from "../../../../assets/image/noDocument.png";
import { useLocation } from "react-router-dom";

const TeamFavoriteResponseDetail = (props) => {
  const location = useLocation();
  const sectionShared = location.state && location.state.sectionShared;
  const [showSharedResponseMsg, setShowSharedResponseMsg] = useState(false);

  useEffect(() => {
    /**
     * Show shared response message if section is shared.
     */
    setShowSharedResponseMsg(sectionShared ? true:false);
    window.history.replaceState({}, '')

    /**
     * Hide tost message after sometime if user not clicked on close button of the  message
     */
    setTimeout(() => {
      setShowSharedResponseMsg(false);
    }, 3000);
  }, []);

  /**
   * This method is to open section modal
   */
  const openCreateSection = () => {
    props.openSection();
  };

  /**
   * This function helps to close toast message
   */
  const onCloseTostMessageclick = () => {
    setShowSharedResponseMsg(false);
  };

  return (
    <div className="no-response-wrapper">
      <img src={noResponseImage} alt="No Response Selected"></img>
      <div className="no-response-text-container">
        <div className="no-response">Team Workspace not started yet</div>
        <div className="no-response-text">
          Add a section to start collaborating with your team.
        </div>
        <div className="btn-div">
          <MotifButton
            className="add-section-button"
            data-testid="btnCreateSection" 
            onClick={openCreateSection}
          >
            <img src={plus} alt="add section icon" />
            Create Section
          </MotifButton>
        </div>
      </div>
      {showSharedResponseMsg && (
            <MotifToast
              id="shareSuccessMessage"
              variant="success"
              position="bottom"
              supportingText="Test"
              onClose={onCloseTostMessageclick}
            >
              <div className="toast-message-header">Response added</div>
              <div className="toast-message-body">Response has been successfully added.</div>
            </MotifToast>
          )}
    </div>
  );
};
export default TeamFavoriteResponseDetail;
