import React from "react";
import {
  MotifModal,
  MotifModalHeader,
  MotifModalBody,
  MotifButton,
} from "@ey-xd/motif-react";
import "./EditProgressPopup.css";
import icon_warning from "../../../../assets/image/warning.svg";

export function EditProgressPopup({ onClose, onSave, onDontSave }) {
  return (
    <div>
      <MotifModal
        id="EditProgressPopup"
        className="EditProgressPopup"
        variant="alt"
        show={true}
        onClose={() => {
          onDontSave(true);
        }}
      >
        <MotifModalHeader>
          <div className="warning-symbol-container">
            <img className="warningCircle" alt="icon" src={icon_warning} />
          </div>
          <div className="head-div">Edits in progress</div>
        </MotifModalHeader>
        <MotifModalBody>
          <div className="sub-container">
            <div className="row-container">&nbsp;</div>
            <div className="row-container">
              <div className="Label">
                Edit mode currently active, select ‘Save’ to close and save
                changes.
              </div>
            </div>
            <div className="row-container">
              <div className="buttonGroup">
                <div class="row-cell">
                  <MotifButton
                    className="button"
                    id="closeButton"
                    variant="secondary"
                    onClick={() => {
                      onDontSave(false);
                    }}
                    size="medium"
                    type="button"
                  >
                    Don’t save
                  </MotifButton>
                </div>
                <div class="row-cell">
                  <MotifButton
                    onClick={onSave}
                    className="button"
                    id="shareButton"
                    variant="primary"
                    size="medium"
                    type="button"
                  >
                    Save
                  </MotifButton>
                </div>
              </div>
            </div>
          </div>
        </MotifModalBody>
      </MotifModal>
    </div>
  );
}

export default EditProgressPopup;
