import ExploreService from "../services/exploreService";

class DownloadDocument {
    exploreservice = new ExploreService();
       /**
     * Description
     * -----------
     * This method is to download the file
     * Parameters:
     * fileName: file which needs to be download
     */
    async  handleDownload(fileName) {
        const response = await this.exploreservice.GetDownlodedDocument(
        sessionStorage.getItem("DashboardProjectId"),
        fileName
        );
        if (response) {
        const type = response.headers.get("content-type");
        const blob = new Blob([response.data], { type: type });
        let url = window.URL.createObjectURL(blob);
        let a = document.createElement("a");
        a.href = url;
        a.download = fileName;
        a.click();
        }
    }
    
    /**
     * To get Documents PDF url
     * @param {*} fileName 
     * @returns 
     */
    async  getDownloadUrl(fileName) {
        if (fileName) {
            const response = await this.exploreservice.GetDownlodedDocument(
                sessionStorage.getItem("DashboardProjectId"),
                fileName
            );
            if (response) {
                const type = response.headers.get("content-type");
                const blob = new Blob([response.data], { type: type });
                let url = window.URL.createObjectURL(blob);
                return url;
            }
        }
    }
}
export default DownloadDocument;