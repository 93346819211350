import React, { useEffect, useState } from "react";
import "./AddMember.css";
import {
  Providers,
  SimpleProvider,
  ProviderState,
} from "@microsoft/mgt-element";
import GraphService from "../../services/graphService";
import MemberService from "../../services/memberService";
import MotifTable from "@ey-xd/motif-react/Table";
import axios from "axios";
import { MotifSelect, MotifOption, MotifToast } from "@ey-xd/motif-react";
import { MotifIconButton } from "@ey-xd/motif-react/Button";
import "./ProjectDetail.css";
import { PeoplePicker } from "@microsoft/mgt-react";
import { groupConfig } from "../../config/groupConfig";
import avatarImage from "../../assets/image/avatarDefault.png";
import cancelIcon from "../../assets/image/cancel.svg";
import RolePopUp from "../ProjectDashboard/Members/RolePopUp";
import DeleteConfirm from "../ProjectDashboard/Members/DeleteConfirm";
import Utils from "../../utils/Utils";

export function AddMember({ setIsMember }) {
  let [rowData, setRowData] = useState([]);
  const [selectedPeople, setSelectedPeople] = useState([]);
  const [showMessage, setshowMessage] = useState(false);
  const [showMemberMessage, setshowMemberMessage] = useState(false);
  const [showRemoveMemberMessage, setshowRemoveMemberMessage] = useState(false);  
  const [showDeleteConfirmPopup, setShowDeleteConfirmPopup] = useState(false);
  const [memberName, setmemberName] = useState("");
  const [memberParams, setMemberParams] = useState([]);
  const [showDeletedComplete, setshowDeletedComplete] = useState(false);
  const utils = new Utils();

  let apiUrl = process.env.REACT_APP_API_URL;
  let allgroups = [];

  // Refresh Data when any new row added and send updated data to Save the members
  useEffect(() => {
    if (rowData.length > 1) {
      const memberRequest = rowData.map((member) => {
        const {
          Avatar,
          isFixed,
          ...rest
        } = member;
        return rest;
      });
      setIsMember(true, memberRequest);
    }
  }, [rowData]);

  //Display the create project message for 3 seconds and then disappers
  useEffect(() => {
    setTimeout(() => {
      setshowMessage(false);
    }, 3000);
  }, []);

  // On Loading of page from Create peoject or from back button from Upload Document.
  // This will fectch the data from the DB and show on Motif Table
  useEffect(() => {
    // To use Graph Api for people picker
    Providers.globalProvider = new SimpleProvider((scopes) => {
      const graphService = new GraphService();
      const graphApiToken = graphService.getToken();
      return Promise.resolve(graphApiToken);
    });
    const fetchOwnerDetails = async () => {
      Providers.globalProvider.setState(ProviderState.SignedIn);
      const back = sessionStorage.getItem("isBackFromUpload");
      // When Back button clicked from Upload Document
      if (back === "true") {
        let datareturn = [];
        Providers.globalProvider.setState(ProviderState.SignedIn);
        const ProjectiId = sessionStorage.getItem("DashboardProjectId");
        axios
          .get(
            apiUrl +
              "Member/GetMembersDetailsByProject?project_UUID=" +
              ProjectiId +
              "",
            {
              headers: {
                "Content-type": "application/json; charset=UTF-8",
                Accept: "application/json",
              },
            }
          )
          .then(async (data) => {
            datareturn = data?.data;
            //Build People object with DB details and Graph Api details
            const graphService = new GraphService();
            let getMembersAdded = [];
            for (const element of datareturn) {
              const userbymail = await graphService.getUserDetailsByInput(
                element.email
              );
              const userAvatar = await graphService.getUserAvatar(
                userbymail.value[0].id
              );
              let newRow = [];
              // if no data added in the add member page  then assign the owner details here
              if (element.role == null || element.role === "Owner") {
                newRow = {
                  FullName: userbymail.value[0].displayName,
                  Title: userbymail.value[0].jobTitle,
                  Role: "Owner",
                  isFixed: true,
                  Email: userbymail.value[0].mail,
                  FirstName: userbymail.value[0].givenName,
                  LastName: userbymail.value[0].surname,
                  Project_UUID: sessionStorage.getItem("DashboardProjectId"),
                  ProjectName: sessionStorage.getItem("DashboardProjectName"),
                  Avatar: userAvatar,
                  GroupName: "",
                };
              } else {
                if (element.groupName == "SAT") {
                  let roleAssigned="";
                  if(element.role !="Admin")
                  {
                    roleAssigned = "User (SaT)"
                  }else{
                     roleAssigned = "Admin"
                  }
                  newRow = {
                    FullName: element.fullName,
                    Title: element.title,
                    Role: roleAssigned,
                    isFixed: false,
                    Email: element.email,
                    FirstName: element.firstName,
                    LastName: element.lastName,
                    Project_UUID: element.project_UUID,
                    ProjectName: sessionStorage.getItem("DashboardProjectName"),
                    Avatar: userAvatar,
                    GroupName: element.groupName,
                    isAlreadySaved: true,
                    isUpdated: false,
                  };
                } else {
                  newRow = {
                    FullName: element.fullName,
                    Title: element.title,
                    Role: "User (Non-SaT)",
                    isFixed: true,
                    Email: element.email,
                    FirstName: element.firstName,
                    LastName: element.lastName,
                    Project_UUID: element.project_UUID,
                    ProjectName: sessionStorage.getItem("DashboardProjectName"),
                    Avatar: userAvatar,
                    GroupName: element.groupName,
                    isAlreadySaved: true,
                    isUpdated: false,
                  };
                }
              }

              getMembersAdded.push(newRow);
            }
            setRowData((prevState) => [...prevState, ...getMembersAdded]);
            sessionStorage.setItem("isBackFromUpload", false);
          });
      } else {
        // Create Project add Owner Details
        const graphService = new GraphService();
        const userbymail = await graphService.getUserDetailsByInput(
          sessionStorage.getItem("Email")
        );
        const userAvatar = await graphService.getUserAvatar(
          userbymail.value[0].id
        );
        const newRow = {
          FullName: userbymail.value[0].displayName,
          Title: userbymail.value[0].jobTitle,
          Role: "Owner",
          isFixed: true,
          Email: userbymail.value[0].mail,
          FirstName: userbymail.value[0].givenName,
          LastName: userbymail.value[0].surname,
          Project_UUID: sessionStorage.getItem("DashboardProjectId"),
          ProjectName: sessionStorage.getItem("DashboardProjectName"),
          Avatar: userAvatar,
          GroupName: "",
        };
        setRowData([...rowData, newRow]);
        if (sessionStorage.getItem("ProjectCreatedMessage") !== "") {
          setshowMessage(true);
        }
      }
    };
    fetchOwnerDetails();
  }, []);

  const deleteMemberCall = async (memberRequest) => {
    const memberService = new MemberService();
    await memberService.DeleteMember(memberRequest);
  }
 // Delete record when confirm button clicked
 useEffect(() => {
  if(showDeletedComplete){  
    if (memberParams.data.isAlreadySaved) {
      const memberFilter = rowData.filter(
        (member) => member.Email === memberParams.data.Email
      );
      const memberRequest = memberFilter.map((member) => {
        const { Avatar, isFixed, isAlreadySaved, isUpdated, ...rest } = member;
        return rest;
      });
      //Delete Member
      deleteMemberCall(memberRequest[0]);
    }
    const UpdatedMembersList = rowData.filter(
      (member) => member.Email !== memberParams.data.Email
    );  
   
    setshowDeletedComplete(false);
    setshowRemoveMemberMessage(true);
    if (UpdatedMembersList.length == 1) {
      setIsMember(false);
    }
    setRowData(UpdatedMembersList);
  }
  
}, [showDeletedComplete]);
  // This method will delete the member from DB when any user is saved. And delete the member on UI which is not saved.
  // Input is Member object
  const deleteMember = async (params) => {
    // Call Pop up for confirmation  
    setShowDeleteConfirmPopup(true);
    setmemberName(params.data.FullName);
    setMemberParams(params)
    
    
  };

 // This function is for closing the toaster message after remove member
 const oncloseRemoveclick = () => {
  setshowRemoveMemberMessage(false);
};
  //This method returns the user group data
  const getUserGroups = async (url) => {
    const graphService = new GraphService();
    const accessToken = await graphService.getToken();
    const requestOptions = {
      method: 'GET',
      headers: {
        'Authorization': `Bearer ${accessToken}`,
        'Strict-Transport-Security': 'max-age=31536000; includeSubDomains'
      }
    };
  
    // Add this in header : ConsistencyLevel and value as Eventual
    //https://graph.microsoft.com/v1.0/users/userid/memberOf/microsoft.graph.group?$count=true&$orderby=displayName&$filter=startswith(displayName, 'S-UG-report-Author-platform-admin-d')
    // Request to get the groups assigned to 
    const response = await fetch(`${url}`, requestOptions);

    if (response.ok) {
      const userGroupData = await response.json();
      allgroups = allgroups.concat(userGroupData.value);
      const nextLink = userGroupData['@odata.nextLink'];
      if (nextLink) {
        await getUserGroups(nextLink);
      }
      return allgroups;

    }
  }
  // This function will handle the Role dropdown changes and this will change the text of Next button
  const handleCellValueChanged = () => {
    setIsMember(true, rowData);
  };
  // This function is used to add the member data to the motif Grid. By calling Graph apis
  const handleSelectionChanged = async (people) => {
    setshowMemberMessage(false);
    const alreadyAdded = rowData.filter((data) =>
      data.Email.toLowerCase().includes(
        people.detail[0].userPrincipalName.toLowerCase()
      )
    );
    // Check if duplicate member has added
    if (alreadyAdded.length > 0) {
      setSelectedPeople([]);
      setshowMemberMessage(true);
    } else {
      setSelectedPeople([]);
      const graphService = new GraphService();
      const userGroupData = await graphService.getUserGroupByMail(
        people.detail[0].id
      );

      const nextLink = userGroupData['@odata.nextLink'];
      let allGroupDetails= [];
      allGroupDetails = allGroupDetails.concat(userGroupData.value);
      if (nextLink) {
        const userGroupDetails=  await getUserGroups(nextLink);
        allGroupDetails = allGroupDetails.concat(userGroupDetails);
      }

      // Filter for SAT and Non-SAT
      let isSatUser = false;
      const groupAssigned = allGroupDetails.filter((group) =>
        utils.isSatGroup(group.displayName) ||
        utils.isPlatformAdminGroup(group.displayName) 
      );
      if (groupAssigned.length > 0) {
        if ((utils.isSatGroup(groupAssigned[0].displayName) || utils.isPlatformAdminGroup(groupAssigned[0].displayName))) {
          isSatUser = true;
        }
      }
      let newRow = [];
      if (isSatUser) {
        newRow = {
          FullName: people.detail[0].displayName,
          Title: people.detail[0].jobTitle,
          Role: "Admin",
          isFixed: false,
          Email: people.detail[0].userPrincipalName,
          FirstName: people.detail[0].givenName,
          LastName: people.detail[0].surname,
          Project_UUID: sessionStorage.getItem("DashboardProjectId"),
          ProjectName: sessionStorage.getItem("DashboardProjectName"),
          Avatar: people.detail[0].personImage,
          GroupName: "SAT",
          isAlreadySaved: false,
        };
      } else {
        newRow = {
          FullName: people.detail[0].displayName,
          Title: people.detail[0].jobTitle,
          Role: "User (Non-SaT)",
          isFixed: true,
          Email: people.detail[0].userPrincipalName,
          FirstName: people.detail[0].givenName,
          LastName: people.detail[0].surname,
          Project_UUID: sessionStorage.getItem("DashboardProjectId"),
          ProjectName: sessionStorage.getItem("DashboardProjectName"),
          Avatar: people.detail[0].personImage,
          GroupName: "Non-SAT",
          isAlreadySaved: false,
        };
      }
      setRowData([...rowData, newRow]);
      setIsMember(true);
    }
  };
  // This function is for closing the toaster message
  const oncloseclick = () => {
    setshowMessage(false);
  };
  const oncloseMemberclick = () => {
    setshowMemberMessage(false);
  };
  // Motif table Headers
  const colDefs = [
    {
      field: "FullName",
      width: 400,
      cellRenderer: (params) => {
        return (
          <PeoplePickerAvatarRenderer
            value={params}
          ></PeoplePickerAvatarRenderer>
        );
      },
    },
    {
      field: "Title",
      width: 400,
      headerComponentParams: (params) => {
        return { template: `<div>Title` };
      },
    },
    {
      headerComponentFramework: RolePopUp,
      field: "Role",
      cellStyle: { width: 200, overflow: "visible" },
      cellRenderer: (params) => {
        if (
          params.data.Role === "Owner" ||
          ((params.data.Role === "Viewer" || params.data.Role === "User (Non-SaT)") && params.data.isFixed)
        ) {
          return params.value;
        } else {
          return (
            <DropdownRenderer
              value={params}
              onCellValueChanged={handleCellValueChanged}
            ></DropdownRenderer>
          );
        }
      },
    },
    {
      headerName: "",
      field: "",
      width: 100,
      cellRendererFramework: (params) => {
        if (params.data.Role === "Owner") {
          return "";
        } else {
          return (
            <div style={{ marginTop: "8px" }}>
              <MotifIconButton onClick={() => deleteMember(params)}>
                <img src={cancelIcon} />
              </MotifIconButton>
            </div>
          );
        }
      },
    },
  ];
  // This will intiate the Custome components for Motif table such as Dropdown and Avatar
  const frameworkComponents = {
    dropdownRenderer: DropdownRenderer,
    peoplePickerAvatarRenderer: PeoplePickerAvatarRenderer,
  };
  return (
    <div>
      <div className="Frame1">
        <div className="Title1">
          <div className="TextAndSupportingText">
            <div className="Text2">
              <div className="Text6">Add Members</div>
            </div>
          </div>
        </div>
        <div className="Frame2">
          <div className="TextAndSupportingText">
            <div className="Text2">
              <div className="selectMember">Select team members</div>
              <br></br>
            </div>
          </div>
        </div>

        <div className="Frame5">
          <div className="TextAndSupportingText">
            <div className="Text2">
              <div className="TextPicker">
                <PeoplePicker
                  className="ms-PeoplePicker"
                  placeholder="Search"
                  selectionMode="multiple"
                  selectionChanged={handleSelectionChanged}
                  selectedPeople={selectedPeople}
                ></PeoplePicker>
              </div>
            </div>
          </div>
        </div>

        <div className="divMotifTable">
          <MotifTable
            id="MemberTbl"
            columnDefs={colDefs}
            rowData={rowData}
            frameworkComponents={frameworkComponents}
          />
          {showMemberMessage && (
            <MotifToast
              variant="error"
              actionName=""
              position="bottom"
              onClose={() => {
                oncloseMemberclick();
              }}
            >
              <div>Already Member has been added to the project</div>
            </MotifToast>
          )}
          {
    showDeleteConfirmPopup && (
     
        <DeleteConfirm  setshowDeletedComplete={setshowDeletedComplete} setShowDeleteConfirmPopup={setShowDeleteConfirmPopup} memberName={memberName} />
    ) 
  }

      {showRemoveMemberMessage && (
        <MotifToast
          variant="success"
          actionName=""
          position="bottom"
          onClose={() => {
            oncloseRemoveclick();
          }}
        >
          <div>Member successfully removed</div>
        </MotifToast>
      )}
          {showMessage && (
            <MotifToast
              variant="success"
              actionName=""
              onClose={() => {
                oncloseclick();
              }}
            >
              <div>Successfully Created Project</div>
              <div>
                The project details have been saved for{" "}
                {sessionStorage.getItem("DashboardProjectName")}
              </div>
            </MotifToast>
          )}
        </div>
      </div>
    </div>
  );
}
// Motif table custom Component for Dropdowm
const DropdownRenderer = (props) => {
  // Options for Dropdown
  const options = ["Admin", "User (SaT)"];
  const [value, setValue] = useState(props.value.data.Role);

  useEffect(() => {
    setValue(props.value.data.Role);
    props.onCellValueChanged();
  }, [props.value.data.Role]);

  // On selected change of Dropdown the value will be assigend to rowData
  const handleChange = (event) => {
    const Role = event;
    props.value.data.Role = Role;
    if (props.value.data.isAlreadySaved) {
      props.value.data.isUpdated = true;
    }
    props.value.node.setDataValue("Role", Role);
  };
  return (
    <MotifSelect
      className="Input"
      ariaLabelledBy="select-label"
      value={
        props.value != null
          ? props.value.data != null
            ? props.value.data.Role
            : ""
          : ""
      }
      onChange={handleChange}
    >
      {options.map((option) => {
        return <MotifOption value={option}>{option}</MotifOption>;
      })}
    </MotifSelect>
  );
};
// Custom Renderer component for Avatar showing in Motif table
const PeoplePickerAvatarRenderer = (value) => {
  const [avatarUrl, setAvatarUrl] = useState(null);
  const [userName, setuserName] = useState(null);
  const [userEmail, setuserEmail] = useState(null);
  useEffect(() => {
    // If member is already saved
    if (value.value.data.isAlreadySaved) {
      setuserName(value.value.data.FullName);
      setuserEmail(value.value.data.Email);

      if (value.value.data.Avatar != undefined) {
        const reader = new FileReader();
        reader.onload = (e) => {
          setAvatarUrl(e.target.result);
        };
        reader.readAsDataURL(value.value.data.Avatar);
      } else {
        setAvatarUrl(avatarImage);
      }
    } else {
      //If it is a owner of the roject
      if (value.value.data.Role == "Owner") {
        setuserName(value.value.data.FullName);
        setuserEmail(value.value.data.Email);
        if (value.value.data.Avatar != undefined) {
          // Read the image from Graph Api and show on UI
          const reader = new FileReader();
          reader.onload = (e) => {
            setAvatarUrl(e.target.result);
          };
          reader.readAsDataURL(value.value.data.Avatar);
        } else {
          setAvatarUrl(avatarImage);
        }
      } else {
        if (value.value.data.Avatar != undefined) {
          setAvatarUrl(value.value.data.Avatar);
        } else {
          setAvatarUrl(avatarImage);
        }
        setuserName(value.value.data.FullName);
        setuserEmail(value.value.data.Email);
      }
    }
  });

  return (
    <div className="avtColumn">
      {<img src={avatarUrl} alt="Avatar" className="avtImage"></img>}
      {
        <div className="Namedisplay">
          {" "}
          {userName ? userName : ""}
          <div className="Emaildisplay"> {userEmail ? userEmail : ""}</div>
        </div>
      }
    </div>
  );
};

export default AddMember;
