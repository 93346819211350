import { useEffect, useState, useContext } from "react";
import _ from "lodash";
import { useNavigate } from "react-router-dom";
import MotifSearch from "@ey-xd/motif-react/Search";
import {
  MotifButton,
  MotifCheckbox,
  MotifFormField,
  MotifToast,
  MotifSelect,
  MotifOption
} from "@ey-xd/motif-react";
import { MotifButtonGroup, MotifIconButton } from "@ey-xd/motif-react/Button";
import { v4 as uuidv4 } from "uuid";
import "../ExploreData/ExploreData.css";
import noDocumentImg from "../../../assets/image/noDocument.png";
import noLinkIcon from "../../../assets/image/no-link.svg";
import cancelIcon from "../../../assets/image/cancel.svg";
import favoriteIcon from "../../../assets/image/favorite.svg";
import { DashboardContext } from "../../../context/dashboardContext.js";
import Utils from "../../../utils/Utils.js";
import PublicSearchIcon from "../../../assets/image/open-in-browser.svg";
import ExploreDataService from "../../../services/exploreDataService.js";
import { explorerDataConstants } from "./constants.js";
import search from "../../../assets/image/search.svg";
import upload from "../../../assets/image/upload.svg";
import download from "../../../assets/image/download.svg";
import DownloadDocument from "../../../utils/downloadDocument";
import "./ExploreData.css";
import { InstantInsightTopics } from "../../../data/constant";

const ExloreRightPanal = ({
  publicOrConfidential,
  selectdocs,
  isSrcDocActive,
  handleQuestionFocus,
}) => {
  const navigate = useNavigate();
  const utils = new Utils();
  const exploreDataService = new ExploreDataService();
  const projectMasterData = require("../../../data/projectMasterData.js");
  const [srcdocClick, setSrcDocClick] = useState(true);

  const [historyClick, setHistoryClick] = useState(false);
  const [docs, setDocs] = useState([]);
  const [selectedDocs, setSelectedDocs] = useState([]);
  const [searchTxt, setSearchTxt] = useState("");
  const [searchActive, setSearchActive] = useState(false);
  const [readyDocuments, setReadyDocuments] = useState([]);
  const [warningVisible, setWarningVisible] = useState(false);
  const [favorClick, setFavorClick] = useState(false);
  const [searchFavPrompt, setSearchFavPrompt] = useState("");
  const [isSrcDocDrpDwnOpen, setIsSrcDocDrpDwnOpen] = useState(false);
  const [favoriteType, setFavoriteType] = useState("");
  const [favoriteTypeList, setFavoriteTypeList] = useState([]);
  const [favoritesPrompts, setFavoritesPrompts] = useState([]);
  const [filteredFavoritesPrompts, setFilteredFavoritesPrompts] = useState([]);
  const [currentPromptType, setCurrentPromptType] = useState("");
  const [warningDocumentVisible, setwarningDocumentVisible] = useState(true);
  const [historyPrompts, setHistoryPrompts] = useState([]);
  const [searchHisPrompt, setSearchHisPrompt] = useState("");
  const [filteredHistoryPrompts, setFilteredHistoryPrompts] = useState([]);
  const [currentPromptTypeHistory, setCurrentPromptTypeHistory] = useState("");
  const [isInstantInsightDisable, setInstantInsightDisable] = useState(false);
  const [instantInsightPrompts, setInstantInsightPrompts] = useState([]);
  const [filteredInstantInsightPrompts, setFilteredInstantInsightPrompts] = useState([]);
  const [isExplore, setIsExplore] = useState(true);
  const [isInstantInsight, setInstantInsight] = useState(false);
  
  const [srcDocDrpDwnItems, setSrcDocDrpDwnItems] = useState(
    explorerDataConstants.Types
  );
  const [filterTypes, setFilterTypes] = useState([]);
  const downloadDocument = new DownloadDocument();
  const [list, setList] = useState('favorites-prompts-list1');
  let {
    GetDocuments,
    getAlldocuments,
    setViewPrompt,
    setIsNewPromptCoversation,
    isRefreshFavorites,
    setIsRefreshFavorites,
    setViewInstantPrompt
  } = useContext(DashboardContext);

  /**
   * Description
   * -----------
   * This useEffect is used fetch the documents.
   */
  useEffect(() => {
    const getDocs = async () => {
      await GetDocuments(sessionStorage.getItem("DashboardProjectId"));
    };
    getDocs();
    setCurrentPromptType("C");
    setCurrentPromptTypeHistory("C");
    setFavoriteTypeList(projectMasterData.FavoriteTypeConfig);
  }, []);

  /**
   * Description
   * -----------
   * This useEffect is used filter the prompts from the search input for history prompt tab.
   */
  useEffect(() => {
    let Prompts = _.filter(historyPrompts, function (item) {
      return (
        item.requestContent
          ?.toLowerCase()
          .includes(searchHisPrompt.toLowerCase()) ||
        item.responseContent
          ?.toLowerCase()
          ?.includes(searchHisPrompt.toLowerCase())
      );
    });
    setFilteredHistoryPrompts(Prompts);
  }, [searchHisPrompt]);

  /**
   * Description
   * -----------
   * This useEffect is used filter the prompts from the search input for favorite prompt tab.
   */
  useEffect(() => {
    if (isExplore) {
      let prompts = _.filter(favoritesPrompts, function (item) {
        return (
          item.requestContent
            ?.toLowerCase()
            ?.includes(searchFavPrompt.toLowerCase()) ||
          item.responseContent
            ?.toLowerCase()
            ?.includes(searchFavPrompt.toLowerCase())
        );
      });
      setFilteredFavoritesPrompts(prompts);
    }
    else {
      let prompts = _.filter(instantInsightPrompts, function (item) {
        return (
          item.platformPromptText
            ?.toLowerCase()
            ?.includes(searchFavPrompt.toLowerCase())
        );
      });
      setFilteredInstantInsightPrompts(prompts);
    }
  }, [searchFavPrompt]);


    /**
   * Description
   * -----------
   * This useEffect is use to fetch the prompts from the backend based on selection of tab.
   */
    useEffect(() => {
      if (favorClick) {
        getPromptDetails("F", currentPromptType);
        getInstantInsightPromptDetails();
        setFavoriteType(projectMasterData.FavoriteTypeConfig[0].content);
        setIsExplore(true);
        setInstantInsight(false);
      } else if (historyClick) {
        getPromptDetails("H", currentPromptTypeHistory);
      }
    }, [favorClick, currentPromptType, historyClick, currentPromptTypeHistory]);

  /**
   * Description
   * -----------
   * This useEffect is use to set the document tab active.
   */
  useEffect(() => {
    if (isSrcDocActive) {
      setSrcDocClick(true);
      setHistoryClick(false);
    }
  }, [isSrcDocActive]);

  /**
   * Description
   * -----------
   * This method is used to get the prompt details.
   * Parameters:
   * promptType: view of the prompt favorite or history.
   * dataType: type of the prompt confidential or public.
   */
  const getPromptDetails = async (promptType, dataType) => {
    let confidentialHistoryprompts = await exploreDataService.GetPromptDetails(
      sessionStorage.getItem("DashboardProjectId"),
      promptType,
      dataType
    );
    if (confidentialHistoryprompts.length > 0) {
      confidentialHistoryprompts.forEach(function (prompt) {
        let documentList = [];
        let documentDetails = [];
        if (dataType === "P") {
          documentDetails = prompt.source?.split(",");
        } else {
          documentDetails = prompt.fileStatus?.split(",");
        }
        if (documentDetails?.length) {
          documentDetails.forEach(function (doc) {
            if (dataType === "P") {
              documentList.push({
                documentName: doc,
                isAvailable: true,
              });
            } else {
              let docInfo = doc.split(":");
              documentList.push({
                documentName: docInfo[0],
                isAvailable: docInfo[1] === "1",
              });
            }
          });
        }
        prompt.documentList = documentList;
      });
    }
    if (favorClick) {
      setFavoritesPrompts(confidentialHistoryprompts);
      setFilteredFavoritesPrompts(confidentialHistoryprompts);
    }
    if (historyClick) {
      setHistoryPrompts(confidentialHistoryprompts);
      setFilteredHistoryPrompts(confidentialHistoryprompts);
    }
  };


  /**
   * Description
   * -----------
   * This method is used to get the instant insight prompt details.
   */
  const getInstantInsightPromptDetails = async () => {
    let instantInsightprompts = await exploreDataService.GetInstantInsightFavoritePromptsByProject(
      sessionStorage.getItem("DashboardProjectId")
    );
    setInstantInsightPrompts(instantInsightprompts);
    setFilteredInstantInsightPrompts(instantInsightprompts);
  }

   /**
   * Description
   * -----------
   * This method search prompts in favorite prompt tab
   */
   const searchFavoritePrompt = (e) => {
    setSearchFavPrompt(e.target.value);
  };

  /**
   * Description
   * -----------
   * This method sets default prompt type for favorite prompt tab
   */
  const isActivePromptTypeButton = () => {
    return currentPromptType === "C";
  };

  /**
   * Description
   * -----------
   * This method search prompts in history prompt tab
   */
  const searchHistoryPrompt = (e) => {
    setSearchHisPrompt(e.target.value);
  };

  /**
   * Description
   * -----------
   * This method sets default prompt type for history prompt tab
   */
  const isActivePromptTypeButtonHistory = () => {
    return currentPromptTypeHistory === "C";
  };

    /**
   * Description
   * -----------
   * This method is used to toggle to confidential and public in favourite tab
   */
    const changePromptType = () => {
      if (currentPromptType === "C") {
        if (!isInstantInsight) {
          setCurrentPromptType("P");
          setInstantInsightDisable(true);
        }
      } else {
        setCurrentPromptType("C");
        setInstantInsightDisable(false);
      }
    };

  /**
   * Description
   * -----------
   * This method is used to toggle to confidential and public in history tab
   */
  const changePromptTypeHistory = () => {
    if (currentPromptTypeHistory === "C") {
      setCurrentPromptTypeHistory("P");
    } else {
      setCurrentPromptTypeHistory("C");
    }
  };

  /**
   * Description
   * -----------
   * This method is used to delete the prompts from the history.
   * Parameters:
   * event: button click event object.
   * promptId: prompt id which needs to delete.
   */
  const deletePrompt = async (event, promptId) => {
    event.stopPropagation();
    const exploreDataService = new ExploreDataService();
    await exploreDataService.DeletePrompt(promptId);
    getPromptDetails("H", currentPromptTypeHistory);
  };

  /**
   * Description
   * -----------
   * This method is used to view the prompt from favorite and history prompts list.
   * Parameters:
   * prompt: selected prompt values
   */
  const showPrompt = (prompt) => {
    setViewPrompt(prompt);
  };

  const showInstantPrompt = (prompt) => {
    setViewInstantPrompt(prompt);
  };


  /**
   * Description
   * -----------
   * This useEffect is used to get docs from backend API calla
   */
  useEffect(() => {
    let allReadyDocs = getAlldocuments.filter(function (doc) {
      return doc.status === "Complete";
    });
    allReadyDocs.map((item) => (item.selected = false));
    setReadyDocuments(allReadyDocs);
    setDocs(allReadyDocs);
  }, [getAlldocuments]);

  /**
   * Description
   * -----------
   * This method reset the conversation chat and stare new.
   */
  const createNewConversation = () => {
    setIsNewPromptCoversation(false);
    if (sessionStorage.getItem("PromptConversationId")) {
      sessionStorage.removeItem("PromptConversationId");
    }
    sessionStorage.setItem("PromptConversationId", uuidv4());
    setIsNewPromptCoversation(true);
    setSrcDocClick(true);
    setFavorClick(false);
    setHistoryClick(false);
  };

  /**
   * Description
   * -----------
   * document selection checkbox change event
   * Parameters:
   * e: checkbox change event
   * itemID: documenet id
   */
  const onChecked = (e, itemID) => {
    const currentIndex = srcDocDrpDwnItems.findIndex(
      (item) => item.id === itemID
    );
    const currentItem = srcDocDrpDwnItems.find((item) => item.id === itemID);

    let checked = !currentItem.checked;

    if (currentItem.type === "All") {
      if (checked) {
        setWarningVisible(true);
      } else {
        setWarningVisible(false);
      }
    }

    if (currentItem.type === "All") {
      let newSrcDrpDwnItems = srcDocDrpDwnItems;
      let filtTypes = [];
      newSrcDrpDwnItems.forEach((iter) => {
        iter.checked = checked;
      });
      let newFilteredDocs = docs;
      if (checked) {
        newFilteredDocs.forEach((item) => {
          item.selected = true;
        });
        filtTypes = newSrcDrpDwnItems
          .filter((itm) => itm.type !== "All")
          .map((object) => object.type);
      } else {
        newFilteredDocs.forEach((item) => {
          item.selected = false;
        });
        filtTypes = [];
      }
      setFilterTypes(filtTypes);
      setSrcDocDrpDwnItems(newSrcDrpDwnItems);
      setDocs(newFilteredDocs);
      toggleToAllWarning(newSrcDrpDwnItems);
    } else {
      const updatedItem = {
        ...srcDocDrpDwnItems[currentIndex],
        checked: !currentItem.checked,
      };
      const newSrcDrpDwnItems = [...srcDocDrpDwnItems];
      newSrcDrpDwnItems[currentIndex] = updatedItem;
      setFilterTypes(
        newSrcDrpDwnItems
          .filter((itm) => itm.type !== "All" && itm.checked)
          .map((object) => object.type)
      );
      let newFilteredDocs = docs;
      if (checked) {
        newFilteredDocs.forEach((item) => {
          if (
            updatedItem.actualType.toLowerCase() ===
            item.documentName.split(".").pop().toLowerCase()
          ) {
            item.selected = true;
          }
        });
      } else {
        newFilteredDocs.forEach((item) => {
          if (
            updatedItem.actualType.toLowerCase() ===
            item.documentName.split(".").pop().toLowerCase()
          ) {
            item.selected = false;
          }
        });
        newSrcDrpDwnItems.forEach((item) => {
          if (item.type === "All") {
            item.checked = false;
          }
        });
        const selectAllIndex = srcDocDrpDwnItems.findIndex(
          (item) => item.type === "All"
        );
        const selectAllItem = {
          ...srcDocDrpDwnItems[selectAllIndex],
          checked: false,
        };
        newSrcDrpDwnItems[selectAllIndex] = selectAllItem;
      }
      setSrcDocDrpDwnItems(newSrcDrpDwnItems);
      setDocs(newFilteredDocs);
      toggleToAllWarning(newSrcDrpDwnItems);
    }
    if (docs?.length > 0) {
      selectdocs(docs);
      let selectedDocs = docs.filter((doc) => doc.selected === true);
      setSelectedDocs(selectedDocs);
      if (docs.filter((doc) => doc.selected === true).length > 0) {
        setwarningDocumentVisible(false);
      } else {
        setwarningDocumentVisible(true);
      }
    }
  };
  /**
   * Description
   * -----------
   * This method filter out the docuements
   * Parameters:
   * currentItem: selected document item
   */
  const getLastSelectedType = (currentItem) => {
    let docsWithSelectedType = docs.filter((item) => {
      return (
        item.documentName.split(".").pop() ===
        currentItem.documentName.split(".").pop()
      );
    });
    return docsWithSelectedType.filter((item) => {
      return item.selected === true;
    }).length;
  };

  /**
   * Description
   * -----------
   * Doc selection from source document tab
   * Parameters:
   * e: checkbox change event
   * docId: documenet id
   */

  const onDocChange = (e, docId) => {
    const currentIndex = docs.findIndex((item) => item.documentId === docId);
    const currentItem = docs[currentIndex];
    const checked = !currentItem.selected;
    const updatedDoc = { ...currentItem, selected: checked };
    const newDocs = [...docs];
    newDocs[currentIndex] = updatedDoc;
    setDocs(newDocs);

    const newSelectedDocs = checked
      ? [...selectedDocs, updatedDoc]
      : selectedDocs.filter((doc) => doc.documentId !== docId);
    setSelectedDocs(newSelectedDocs);

    const docSelectCount = newSelectedDocs.length;
    setwarningDocumentVisible(docSelectCount === 0);

    let noOfTypedDocs = getLastSelectedType(currentItem);
    if (noOfTypedDocs === 1 && !checked) {
      let item = currentItem.documentName.split(".").pop();
      if (item === explorerDataConstants.excelComparator) {
        item = explorerDataConstants.dropdownExcelValue;
      } else if (item === explorerDataConstants.docComparator) {
        item = explorerDataConstants.dropdownDocValue;
      }
      let newSrcDrpDwnItems = srcDocDrpDwnItems.map((itm) => ({
        ...itm,
        checked:
          itm.type.toLowerCase() === item.toLowerCase() ? false : itm.checked,
      }));
      let newFilterTypes = filterTypes.filter(
        (itm) => itm.toLowerCase() !== item.toLowerCase()
      );
      setSrcDocDrpDwnItems(newSrcDrpDwnItems);
      setFilterTypes(newFilterTypes);
    }
  };

  /**
   * Description
   * -----------
   * This useEffect is used to filter the selected documents
   */
  useEffect(() => {
    // Reset search when searchActive becomes false
    const updatedDocs = readyDocuments.map((doc) => {
      const selected = selectedDocs.some(
        (selectedDoc) => selectedDoc.documentId === doc.documentId
      );
      return { ...doc, selected };
    });
    if (!searchActive) {
      setDocs(updatedDocs);
    } else {
      const searchTerm = searchTxt.toLowerCase();
      const filteredDocs = readyDocuments
        .filter((doc) => doc.documentName.toLowerCase().includes(searchTerm))
        .map((doc) => {
          const selected = selectedDocs.some(
            (selectedDoc) => selectedDoc.documentId === doc.documentId
          );
          return { ...doc, selected };
        });
      setDocs(filteredDocs);
    }
    // Update the parent component or context with the selected documents
    selectdocs(updatedDocs.filter((doc) => doc.selected));
  }, [searchActive, searchTxt, readyDocuments, selectedDocs, selectdocs]);

  const handleSearch = (e) => {
    const searchTerm = e.target.value.toLowerCase();
    setSearchTxt(searchTerm);
    setSearchActive(true);

    if (searchTerm !== "") {
      const filteredDocs = readyDocuments
        .filter((doc) => doc.documentName.toLowerCase().includes(searchTerm))
        .map((doc) => {
          const selected = selectedDocs.some(
            (selectedDoc) => selectedDoc.documentId === doc.documentId
          );
          return { ...doc, selected };
        });
      setDocs(filteredDocs);
    } else {
      const updatedDocs = readyDocuments.map((doc) => {
        const selected = selectedDocs.some(
          (selectedDoc) => selectedDoc.documentId === doc.documentId
        );
        return { ...doc, selected };
      });
      setDocs(updatedDocs);
    }
  };

   /**
  * Description
  * -----------
  * Favourite type selection change event
  * Parameters:
  * e: dropdown change event
  */
   const onFavouriteTypeChange = (e) => {
    if (e === projectMasterData.FavoriteTypeConfig[0].content) {
      setIsExplore(true);
      setInstantInsight(false);
    }
    else {
      setIsExplore(false);
      setInstantInsight(true);
    }
  };


  /**
   * Description
   * -----------
   * All selected from the check box list
   * Parameters:
   * e: checkbox selected items
   */
  const toggleToAllWarning = (srcDrpDwnItems) => {
    const checkdrpitems = srcDrpDwnItems.filter((item) => item.type !== "All");
    const drpSelectCount = checkdrpitems.filter(
      (item) => item.checked === true
    ).length;
    if (drpSelectCount === 5) {
      setWarningVisible(true);
    } else {
      setWarningVisible(false);
    }
  };

  useEffect(() => {
    if (isRefreshFavorites) {
      getPromptDetails("F", currentPromptType);
      setIsRefreshFavorites(false);
    }
  }, [isRefreshFavorites])

  /**
   * This method returns a topic name based on id from constant value
   * @param topicId: sections topic id 
   * @returns string topic name
   */
  const getSectionName = (topicId)=> {
    return InstantInsightTopics.find(topic=>topic.id=== topicId).name;
  }

  return (
    <div className="right-panel-container">
      <div className="right-panel-container-newprompt-container">
        <div className="right-panel-container-newprompt-heder">
          <MotifButton
            className="right-panel-container-newprompt-text"
            onClick={createNewConversation}
          >
            + New Conversation
          </MotifButton>
        </div>
      </div>

      {
        (() => {
          if (srcdocClick) {
            //display source doc section
            if (publicOrConfidential === "C") {

              if (docs.length === 0 && !searchActive) {
                // display no documents section
                return (
                  <>
                    <img className="no-doc-found-image" src={noDocumentImg} alt=""></img>
                    <div className="no-doc-found-txt">No documents found</div>
                    <div className="no-doc-instruction-txt">
                      Please upload your documents in the document library
                    </div>
                    <div className="no-doc-button">
                      <MotifButton
                        onClick={(e) => {
                          navigate("/ProjectDashboard/DocumentLibrary", { state: { showDocLibrary: true } });
                        }}
                        className="motif-upload-doc-button"
                      >
                        <img src={upload} alt="upload document icon" />
                        Upload Document
                      </MotifButton>
                    </div>
                  </>
                )
              }
              else {
                if (docs.length > 0 || searchActive) {
                  //show docs
                  return (
                    <div className="source-doc-container">
                      <div className="select-file-type-title">Select documents by file type</div>
                      <div className="checkbox-list-filetypes">
                        {
                          srcDocDrpDwnItems.map((item) => {
                            return (
                              <MotifCheckbox
                                id={item.id}
                                value={item.type}
                                name={item.type}
                                checked={item.checked}
                                onChange={(e) => onChecked(e, item.id)}
                              >
                                <span>{item.type}</span>
                              </MotifCheckbox>
                            );
                          })
                        }
                      </div>
                      {warningDocumentVisible ? (<MotifToast
                        id="ToastClose"
                        className="warning-toast"
                        variant="warning"
                        actionName=""
                        position=""
                        onClose={() => {
                          setwarningDocumentVisible(false);
                        }}
                      >
                        <div>Select documents to begin your search.</div>
                      </MotifToast>
                      ) : (
                        <></>
                      )}
                      {warningVisible ? (
                        <MotifToast
                          className="warning-toast"
                          variant="warning"
                          actionName=""
                          position=""
                          onClose={() => {
                            setWarningVisible(false);
                          }}
                        >
                          <div>
                            This selection may result in the response taking a
                            greater time to generate results.
                          </div>
                        </MotifToast>
                      ) : (
                        <></>
                      )}
                      <div className="uploaded-docs-container">
                        <div className="right-panel-doc-count">
                          <span className="right-panel-doc-count-text">
                            All uploaded documents
                          </span>
                          <span className="right-panel-doc-count-digit">
                            {selectedDocs.length}/{readyDocuments.length}
                          </span>
                        </div>
                        <span className="select-files">
                          Select files to begin your search
                        </span>
                        <MotifFormField className="search-field">
                          <img className="search-icon" src={search} alt="" />
                          <MotifSearch
                            className="search-text-box"
                            aria-label="Search"
                            hideClearButton
                            id="states-search2"
                            placeholder="Search file..."
                            onChange={handleSearch}
                            value={searchTxt}
                          />
                        </MotifFormField>
                        <div className="right-panel-docs-container-with-noselection">
                          {docs.map((doc) => {
                            return (
                              <div key={doc.documentId} className="right-panel-doc-item">
                                <MotifCheckbox
                                  id={doc.documentId}
                                  value={doc.documentName}
                                  name={doc.documentName}
                                  className="right-panel-doc-checkbox"
                                  checked={doc.selected}
                                  onChange={(e) => onDocChange(e, doc.documentId)}
                                >
                                  <span className="right-panel-doc-name">
                                    {doc.documentName}
                                  </span>
                                </MotifCheckbox>
                                {(localStorage.getItem("userRole") !==
                                "Viewer" && localStorage.getItem('userRole') !== "User (Non-SaT)") && (
                                  <MotifIconButton
                                    id="downloadButton"
                                    className="right-panel-download-button"
                                    onClick={(e) => {
                                      e.stopPropagation(); // Prevents the click event from bubbling up to the checkbox
                                      downloadDocument.handleDownload(doc.documentName);
                                    }}
                                  >
                                    <img
                                      src={download}
                                      alt="download source documents"
                                    />
                                  </MotifIconButton>
                                )}
                              </div>
                            );
                          })}
                        </div>
                      </div>
                    </div>
                  );
                }
              }
            } else {
              //display public section
              return (
                <div className="public_source_document">
                  <div className="wrapper-public-section">
                    <img
                      className="public_source_document_icon"
                      src={PublicSearchIcon}
                      alt=""
                    />
                    <div className="public-search-box">
                      <p className="txt-public-search-enabled">
                        Public search enabled
                      </p>
                      <p className="txt-confidential-doc">
                        Digital Diligence Assistant is hosted in a secure and
                        private EY environment, which means that all information
                        you submit as a prompt will be completely private to you,
                        not accessible by any other EY member nor able to re-train
                        or affect the model in any way. However, you are
                        responsible for ensuring you have the required rights and
                        permissions to use the data you provide as prompts.
                      </p>
                      <p className="txt-confidential-doc">
                        Digital Diligence Assistant leverages a GPT-4-Turbo model 
                        last updated December 2023, and its ability
                        to be updated / re-trained is disabled. It has not
                        specifically been trained on EY data and cannot provide
                        any EY specific answers.
                      </p>
                      <p className="txt-confidential-doc">
                        The public search option returns a response based on the
                        knowledge of the GPT-4-Turbo model  and does not
                        consider documents included in the Document Library.
                      </p>

                    </div>
                  </div>
                </div>
              );
            }
          }
          else if (favorClick) {
            //display favourites section
            return (
              <>
                <div className="right-panel-favorites">
                  <MotifButtonGroup id="favourite-button-type">
                    <MotifButton
                      className={`prompt-type-button ${isActivePromptTypeButton() ? "active-button" : ""
                        }`}
                      onClick={changePromptType}
                    >
                      Confidential
                    </MotifButton>
                    <MotifButton
                      className={`prompt-type-button ${!isActivePromptTypeButton() ? "active-button" : ""
                        }`}
                      onClick={changePromptType}
                      disabled={isInstantInsight ? true : false}
                    >
                      Public
                    </MotifButton>
                  </MotifButtonGroup>
                  <div className="dropdown-title">
                    Select Favorites from
                  </div>

                  <div id="selectfavoriteType" >
                    <MotifFormField>
                      <MotifSelect
                        label="selectfavorite"
                        className="selectfavoriteInput"
                        ariaLabelledBy="select-label"
                        onChange={onFavouriteTypeChange}
                        value={favoriteType}
                        select="Select Favourite Tap"
                        searchPlaceholder={favoriteType}
                        filter={true}
                        required
                      >
                        {favoriteTypeList.map(item => {
                          return (
                            <MotifOption className={item.content} value={item.content} key={item.id}
                              disabled={(isInstantInsightDisable && projectMasterData.FavoriteTypeConfig[1].content.indexOf(item.content) > -1) ? true : false}
                            >
                              {item.content}
                            </MotifOption>
                          );
                        })}
                      </MotifSelect>
                    </MotifFormField>
                  </div>
                  <div >
                    <MotifFormField>
                      <img className="explore-rp-search-icon" src={search} alt="" />
                      <MotifSearch
                        aria-label="Search"
                        hideClearButton
                        id="states-search"
                        className="explore-rp-search-textbox"
                        onChange={searchFavoritePrompt}
                        onSearchButtonClick={function noRefCheck() { }}
                        onSelect={function noRefCheck() { }}
                        placeholder="Search"
                        searchButtonProps={{
                          title: "Search",
                        }}
                        value={searchFavPrompt}
                      />
                    </MotifFormField>
                  </div>
                </div>

                {
                  isExplore &&
                  <div className={list}>
                    {filteredFavoritesPrompts.length > 0 ? (
                      filteredFavoritesPrompts.map((prompt) => (
                        <div
                          className="favorites-prompts"
                          onClick={() => showPrompt(prompt)}
                        >
                          <div className="favorites-prompts-content">
                            <div className="favorites-prompts-header">
                              <div className="favorites-prompt-title">
                                {prompt.promptLibraryName}
                              </div>
                              <div className="favorites-prompt-question"
                               dangerouslySetInnerHTML={{
                                __html: prompt.requestContent,
                              }}>
                              </div>
                            </div>
                            <div className="favorites-star">
                              <img
                                className="favorites-star-icon"
                                src={favoriteIcon}
                                alt=""
                              ></img>
                            </div>
                          </div>
                          <div className="favorites-footer-date">
                            <div className="favorites-date">
                              {utils.convertDateToUTCTimeStampWithDayName(utils.convertDateToUTCTimeStamp(prompt.createTS, true), false)}
                            </div>
                            {prompt?.modifiedResponse && (
                              <div className="flagEdited">
                                Edited
                              </div>
                            )}
                            {prompt?.isBroken === "Y" && (
                              <div className="favorites-broken-link">
                                <div className="favorites-broken-link-icon"></div>
                                <img src={noLinkIcon} alt="" />
                              </div>
                            )}
                          </div>
                        </div>
                      ))
                    ) : (
                      <div>No Favorite Prompts!</div>
                    )}
                  </div>
                }
                {
                  isInstantInsight &&
                  <div className={list}>
                    {filteredInstantInsightPrompts.length > 0 ? (
                      filteredInstantInsightPrompts.map((prompt) => (
                        <div
                          className="favorites-prompts"
                          onClick={() => showInstantPrompt(prompt)}
                        >
                          <div className="favorites-prompts-content">
                            <div className="favorites-prompts-header">
                              <div className="favorites-prompt-title">
                                {prompt.platformPromptTitle}
                              </div>
                              <div className="favorites-prompt-question">
                                {getSectionName(prompt.topicId)}
                              </div>
                            </div>
                            <div className="favorites-star">
                              <img
                                className="favorites-star-icon"
                                src={favoriteIcon}
                                alt=""
                              ></img>
                            </div>
                          </div>
                          <div className="favorites-footer-date">
                            <div className="favorites-date">
                              {utils.formatDate(prompt.createTS)}
                            </div>
                            {prompt?.modifiedResponse && (
                              <div className="flagEdited">
                                Edited
                              </div>
                            )}
                            {prompt?.isBroken === "Y" && (
                              <div className="favorites-broken-link">
                                <div className="favorites-broken-link-icon"></div>
                                <img src={noLinkIcon} alt="" />
                              </div>
                            )}
                          </div>
                        </div>
                      ))
                    ) : (
                      <div>No Favorite Prompts!</div>
                    )}
                  </div>
                }
              </>
            )
          }
          else if (historyClick) {
            //display history section
            return (
              <>
                <div className="right-panel-history">
                  <MotifFormField>
                    <MotifButtonGroup>
                      <MotifButton
                        className={`prompt-type-button ${isActivePromptTypeButtonHistory() ? "active-button" : ""
                          }`}
                        onClick={changePromptTypeHistory}
                      >
                        Confidential
                      </MotifButton>
                      <MotifButton
                        className={`prompt-type-button ${!isActivePromptTypeButtonHistory() ? "active-button" : ""
                          }`}
                        onClick={changePromptTypeHistory}
                      >
                        Public
                      </MotifButton>
                    </MotifButtonGroup>
                  </MotifFormField>
                  <MotifFormField id="favourite-history-search">
                    <img className="explore-rp-search-icon" src={search} alt="" />
                    <MotifSearch
                      aria-label="Search"
                      hideClearButton
                      id="states-search"
                      className="explore-rp-search-textbox"
                      onChange={searchHistoryPrompt}
                      onSearchButtonClick={function noRefCheck() { }}
                      onSelect={function noRefCheck() { }}
                      placeholder="Search"
                      searchButtonProps={{
                        title: "Search",
                      }}
                      value={searchHisPrompt}
                    />
                  </MotifFormField>
                </div>
                <div className={list}>
                  {filteredHistoryPrompts.length > 0 ? (
                    filteredHistoryPrompts.map((prompt) => (
                      <div
                        className="favorites-prompts"
                        onClick={(e) => { e.preventDefault(); showPrompt(prompt); }}
                      >
                        <div className="favorites-prompts-content">
                          <div className="favorites-prompts-header">
                            <div className="favorites-prompt-title">
                              {prompt.promptLibraryName}
                            </div>
                            <div className="favorites-prompt-question"
                            dangerouslySetInnerHTML={{
                              __html: prompt.requestContent,
                            }}>
                            </div>
                          </div>
                          <div className="favorites-star">
                            <img
                              className="favorites-star-icon"
                              src={cancelIcon}
                              onClick={(e) => deletePrompt(e, prompt.promptId)}
                              alt=""
                            ></img>
                          </div>
                        </div>
                        <div className="favorites-footer-date">
                          <div className="favorites-date">
                            {utils.convertDateToUTCTimeStampWithDayName(utils.convertDateToUTCTimeStamp(prompt.createTS, true), false)}
                          </div>
                          {prompt?.modifiedResponse && (
                            <div className="flagEdited">
                              Edited
                            </div>
                          )}
                          {prompt?.isBroken === "Y" && (
                            <div className="favorites-broken-link">
                              <div className="favorites-broken-link-icon"></div>
                              <img src={noLinkIcon} alt="" />
                            </div>
                          )}
                        </div>
                      </div>
                    ))
                  ) : (
                    <div>No Prompts in History!</div>
                  )}
                </div>
              </>
            )
          }
        })()
      }

      <div className="right-panel-nav-container-row">
        <div className="right-panel-nav-source-doc-container-explore">
          <div
            className={
              srcdocClick
                ? "right-panel-active-card right-panel-active-select right-panel-nav-source-doc-div"
                : "right-panel-active-card right-panel-nav-source-doc-div"
            }
            onClick={() => {
              setSrcDocClick(true);
              setFavorClick(false);
              setHistoryClick(false);
            }}
          >
            <div className="right-panel-nav-source-doc">Source documents</div>
          </div>
          <div
            id="divFavorites"
            className={
              favorClick
                ? "right-panel-active-card right-panel-active-select right-panel-container-favourate-tab-div"
                : "right-panel-active-card right-panel-container-favourate-tab-div"
            }
            onClick={() => {
              setSrcDocClick(false);
              setFavorClick(true);
              setHistoryClick(false);
              handleQuestionFocus(false);
              setList('favorites-prompts-list1')
            }}
          >
            <div className="right-panel-container-favourate-tab">Favorites</div>
          </div>
          <div
            className={
              historyClick
                ? "right-panel-active-card right-panel-active-select right-panel-container-history-tab-div"
                : "right-panel-active-card right-panel-container-history-tab-div"
            }
            onClick={() => {
              setSrcDocClick(false);
              setFavorClick(false);
              setHistoryClick(true);
              handleQuestionFocus(false);
              setList('favorites-prompts-list2')
            }}
          >
            <div className="right-panel-container-favourate-tab">History</div>
          </div>
        </div>
      </div>
    </div>
  );
};
export default ExloreRightPanal;
