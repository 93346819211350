import React from "react";

import "./InProgressStyle.css";

export default function InProgressBar() {
    return (
        <div class="progress-container">
            <div class="progress-bar"></div>
        </div>
    )
}