import { appInsights } from "../services/appInsights.js";

class CommonService {
  logException(error, componentName, methodName, requestObject = null) {
    appInsights.trackException({
      error: error,
      properties: {
        Component: componentName,
        Method: methodName,
        ProjectName: sessionStorage.getItem("DashboardProjectName"),
        ProjectUUID: sessionStorage.getItem("DashboardProjectId"),
        UserId: sessionStorage.getItem("Email"),
        payload: requestObject,
        errorData: error?.response?.data,
      },
    });
  }

  getAPIUrl(dataHostLocationId) {
    let hostingEnv = process.env.REACT_APP_HOSTING_ENV;
    if(!dataHostLocationId) {
      dataHostLocationId = localStorage.getItem("currentDataHostingLocation");
    }

    if (hostingEnv === "UAT" || hostingEnv === "PROD") {
      if (dataHostLocationId === '1') {
        return process.env.REACT_APP_API_URL_US;
      } else if (dataHostLocationId === '2') {
        return process.env.REACT_APP_API_URL_EU;
      } else {
        return process.env.REACT_APP_API_URL_AU;
      }
    } else if (hostingEnv === "QA") {
      if (dataHostLocationId === '1') {
        return process.env.REACT_APP_API_URL_US;
      } else if (dataHostLocationId === '2') {
        return process.env.REACT_APP_API_URL_EU;
      } else {
        return process.env.REACT_APP_API_URL_EU;
      }
    } else {
      return process.env.REACT_APP_API_URL;
    }
  }
}
export default CommonService;
