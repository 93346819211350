import React, { useState } from "react";
import TeamFavoriteResponses from "./TeamFavoriteResponses";
import TeamFavoriteResponseDetail from "./TeamFavoriteResponseDetail";
import "./TeamWorkspace.css";

const TeamWorkspace = () => {
  const [openSectionModal, setOpenSectionModal] = useState(false);

  /**
   * This method is to open section modal
   */
  const openCreateSection = () => {
    setOpenSectionModal(true);
  };

  return (
    <div className="motif-row row-with-full-height pw-section-container">
      <div className="motif-col-xs-4 motif-col-sm-1">
        <TeamFavoriteResponses openSectionModal={openSectionModal} closeSectionModal={setOpenSectionModal} />
      </div>
      <div className="motif-col-sm-3 row-with-full-height">
        <TeamFavoriteResponseDetail openSection={openCreateSection} />
      </div>
    </div>
  );
};
export default TeamWorkspace;
