import { appInsights } from "./appInsights";
import CommonService from "./commonService.js";
class EngagementLocationService {
    commonService = new CommonService();
    constructor() {
        this.projectMasterData = require("../data/projectMasterData.js");
    }
   /**
   * Description
   * -----------
   * This method is used to get location list from config,remove the duplicates and sort by name.
   */
    async getEngagementLocationData() {
        try {           
            const uniqueLocationList = this.projectMasterData.CountryConfig
                .map(e => e['content'])
                // store the keys of the unique objects
                .map((e, i, final) => final.indexOf(e) === i && i)
                // eliminate the dead keys & store unique objects
                .filter(e => this.projectMasterData.CountryConfig[e]).map(e => this.projectMasterData.CountryConfig[e]);
            
            
                return uniqueLocationList.sort((a, b) => a.content.localeCompare(b.content));
        }
        catch (error) {
            appInsights.trackException({
                error: error,
                properties: {
                    Component: "Engagement Location Component",
                    Method: "getEngagementLocationData"
                },
            });
        }
    }
};
export default EngagementLocationService