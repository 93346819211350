import React from "react";
import "./EditProject.css";
import "../ProjectDashboard.css";
import { ProjectDetail } from "../../CreateProject/ProjectDetail.js";
import { Navigate } from "react-router-dom";
import {
  MotifButton
} from "@ey-xd/motif-react";
import { ApiManager } from "../../../services/apimanager.js";
import BreadCrumb from "../../common/BreadCrumb";
const actionData = require("../../../data/model/actiontype.js");
const requestData = require("../../../data/model/request.js");
let CreateDataList = requestData.CreateData;
let SharedData = requestData.SharedData;

class EditProject extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      show: true,
      navigateToProjectDetail: false,
      disableSave: false
    };
    this.ActionDataList = actionData.ActionData;
    this.ActionDataList.create = false;
    this.ActionDataList.edit = true;
    this.ActionDataList.explore = false;
    this.ActionDataList.projectId =
      sessionStorage.getItem("DashboardProjectId");
    this.ActionDataList.projectName = sessionStorage.getItem(
      "DashboardProjectName"
    );
    this.editProjectDetail = this.editProjectDetail.bind(this);
    this.UpdateDetail = {};
    this.EditResponse = "";
    this.constantData = require("../../../data/constant.js");
  }
  /*
   * Description
   * -----------
   * This method is called to disable the portion of project detail controls during edit
   * Parameters:
   * IsDisable: this one to disable the edit button when required
   * Detail: this one is to get the member that has been added by user
   */
  disableSave = (IsDisable = Boolean, Detail = CreateDataList, Shared = SharedData) => {
    this.UpdateDetail = Detail;
    this.buttonEnabled = IsDisable;
    this.setState({ disableSave: IsDisable });
    //Kingshuk's Code to Disable Save if engagementId is exactly 8 Characters
    if(CreateDataList.category === 1 && CreateDataList.engagementId.length < 8)
      {
        this.setState({ disableSave: true });
      }
    if(CreateDataList.category === 1 && Shared.invalidengagementID) {  this.setState({ disableSave: true }); 
    this.setState({ disa: true });}
    //Code-Ended  
    
  };
  /*
   * Description
   * -----------
   * This method is called to cancel the edit button action and move back to the project detail page
   */
  onCancelButtonClick = () => {
    this.setState({ navigateToProjectDetail: true });
  };
  /*
   * Description
   * -----------
   * This method is called to save the edited data using api and get the response
   */
  editProjectDetail = async () => {
    this.EditResponse = await ApiManager.getInstance().CreateNewProject(
      CreateDataList
    );
    if (this.EditResponse == 200 || this.EditResponse == 201) {
      sessionStorage.setItem('DashboardProjectName', CreateDataList.projectName);
      this.setState({ navigateToProjectDetail: true });
    }
  };

  render() {
    return (
      <>
        <div className="ProjectDashboardPageContainer">
          <div className="HeaderLayout">
            <BreadCrumb projectName={this.ActionDataList.projectName} />
            <div className="HeaderContainer">
              <div className="HeaderTitle">
                <span className="HeaderText">
                  {this.ActionDataList.projectName}
                </span>
              </div>
              <div className="HeaderAction">
                {this.state.show && (
                  <>
                    <MotifButton
                      label="btncancel"
                      className="CancelButton"
                      onClick={() => this.onCancelButtonClick()}
                    >
                      Cancel
                    </MotifButton>
                  </>
                )}
                <MotifButton
                  label="btnsave"
                  onClick={() => this.editProjectDetail()}
                  disabled={this.state.disableSave}
                >
                  {this.state.show ? <>Save</> : <>Edit</>}
                </MotifButton>
              </div>
            </div>
          </div>
          <div className="EditProjectEditContent">
            {this.state.show ? (
                <ProjectDetail
                  disablecheck={this.disableSave}
                  actiontype={this.ActionDataList}
                ></ProjectDetail>
            ) : null}
          </div>
        </div>
        {/* <div class="EditProjectEditPageHeader">
          <div class="EditProjectBreadcrumb">
            <div class="EditProjectBreadcrumbBase">
              <MotifBreadcrumb
                triggerButtonProps={{
                  "aria-label": "Custom Trigger Button aria-label",
                }}
              >
                <MotifBreadcrumbItem>
                  <a href="/Dashboard" className="dashboard-breadcrumb">
                    Dashboard
                  </a>
                </MotifBreadcrumbItem>
              </MotifBreadcrumb>
              <div class="EditProjectNavArrowRight">
                <img src={dashboardnav} alt="icon"></img>
              </div>
              <div class="EditProjectBreadcrumbItem">
                <div class="EditProjectText1">
                  {this.ActionDataList.projectName}
                </div>
              </div>
            </div>
          </div>

          <div className="EditProjectEditContent">
            <div class="EditProjectEditTitle">
              <div class="EditProjectEditTextAndSupportingText">
                <div class="EditProjectProjectHeaderText">
                  <div class="EditProjectProjectHeaderText1">
                    {this.ActionDataList.projectName}
                  </div>
                </div>
              </div>
            </div>
            <div class="EditProjectEditActions">
              <div class="EditProjectEditCancelButton">
                <div class="EditProjectEditCancelButtonBase">
                  <div class="EditProjectEditCancelText">
                    {this.state.show ? (
                      <>
                        <MotifButton
                          label="btncancel"
                          className="CancelButton"
                          onClick={() => this.onCancelButtonClick()}
                        >
                          Cancel
                        </MotifButton>
                      </>
                    ) : null}
                  </div>
                </div>
              </div>
              <div class="EditProjectEditSaveButton">
                <div class="EditProjectEditSaveButtonBase">
                  <div>
                    <MotifButton
                      label="btnsave"
                      onClick={() => this.editProjectDetail()}
                    >
                      {this.state.show ? <>Save</> : <>Edit</>}
                    </MotifButton>
                  </div>
                </div>
              </div>

              {this.state.show ? (
                <div>
                  <ProjectDetail
                    disablecheck={this.disableSave}
                    actiontype={this.ActionDataList}
                  ></ProjectDetail>
                </div>
              ) : null}
            </div>
          </div>
        </div> */}
        {this.state.navigateToProjectDetail && (
          <Navigate to="../Projectdetail"></Navigate>
        )}
      </>
    );
  }
}
export default EditProject;
