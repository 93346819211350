import axios from "../services/axiosWithMsal";
import CommonService from "./commonService.js";

class UserLogService {
  commonService = new CommonService();
  constructor() {
    this.apiUrl = process.env.REACT_APP_API_URL;
    this.apiUrlUS = process.env.REACT_APP_API_URL_US;
    this.apiUrlEU = process.env.REACT_APP_API_URL_EU;
    this.apiUrlAU = process.env.REACT_APP_API_URL_AU;
    this.hostingEnv = process.env.REACT_APP_HOSTING_ENV;
  }
  // Method to Add user log details who has logged in to the system
  addUserLogDetail = async (userLogRequest) => {
    await axios
      .post(this.apiUrl + "UserLog/AddUserLogDetail", userLogRequest)
      .catch((error) => {
        this.commonService.logException(error, "User Log", "Add user logs");
      });
  };

  //Get User Log details
  async getUserLogDetails() {
    const apiEndPoint = `UserLog/GetUserLogs`;

    if (this.hostingEnv === "DEV") {
      const response = await axios
        .get(this.apiUrlEU + apiEndPoint, {
          headers: { "Content-type": "application/json; charset=UTF-8" },
        })
        .catch((error) => {
          this.commonService.logException(error, "User Log", "Get user logs");
        });
    }
    else if (this.hostingEnv === "QA") {
      const response1 = await axios
        .get(this.apiUrlEU + apiEndPoint, {
          headers: { "Content-type": "application/json; charset=UTF-8" },
        })
        .catch((error) => {
          this.commonService.logException(error, "User Log", "Get user logs");
        });

      const response2 = await axios
        .get(this.apiUrlUS + apiEndPoint, {
          headers: { "Content-type": "application/json; charset=UTF-8" },
        })
        .catch((error) => {
          this.commonService.logException(error, "User Log", "Get user logs");
        });
        return [...response1?.data,...response2?.data];
    }
    else if (this.hostingEnv === "UAT" || this.hostingEnv === "PROD") {
      const response1 = await axios
      .get(this.apiUrlEU + apiEndPoint, {
        headers: { "Content-type": "application/json; charset=UTF-8" },
      })
      .catch((error) => {
        this.commonService.logException(error, "User Log", "Get user logs");
      });

    const response2 = await axios
      .get(this.apiUrlUS + apiEndPoint, {
        headers: { "Content-type": "application/json; charset=UTF-8" },
      })
      .catch((error) => {
        this.commonService.logException(error, "User Log", "Get user logs");
      });
    const response3 = await axios
      .get(this.apiUrlAU + apiEndPoint, {
        headers: { "Content-type": "application/json; charset=UTF-8" },
      })
      .catch((error) => {
        this.commonService.logException(error, "User Log", "Get user logs");
      });
      return [...response1?.data,...response2?.data,...response3?.data];
    }
  }
}

export default UserLogService;
