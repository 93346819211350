import React, { useEffect, useState } from "react";
import { MotifButton } from "@ey-xd/motif-react";
import _ from "lodash";
import MotifModal, { MotifModalHeader, MotifModalBody, MotifModalFooter } from "@ey-xd/motif-react/Modal";
import ProjectScopeTree from "./ProjectScopeTree";
import InstantInsightsService from "../../../services/instantInsightService";
import DocumentService from "../../../services/documentService";
import Utils from "../../../utils/Utils";
import refreshIcon from "../../../assets/image/icon_refresh.svg";
import information from "../../../assets/image/information.svg";
import "./RefreshInsightsModal.css";

export default function RefreshInsightsModal(props) {

    const [updatedTreeData, setUpdatedTreeData] = useState([]);
    const [refreshTreeData, setRefreshTreeData] = useState(null);
    const [latestDocumentDate, setLatestDocumentDate] = useState('');
    const instantInsightsService = new InstantInsightsService();
    const documentservice = new DocumentService();
    const utils = new Utils();
    const [refreshInsightsPayload, setRefreshInsightsPayload] = useState({
        projectScopeId: props.projectScopeId,
        scopeName: props.projectScopeName,
        sections: []
    });

    useEffect(()=>{
        getDocumentLibraryUpdateDate();
        getRefreshedInstantInsights();
    },[props.data.treeData])

    /**
     * This method will calls the method to construct tree data with disabled subsection if already refreshed
     * @returns treeData object with disabled subsections
     */
    const getRefreshedInstantInsights = async () => {
        let exportedTreeData = await constructTreeData({..._.cloneDeep(props.data.treeData)}) ;
        setRefreshTreeData(exportedTreeData);
    }

    /**
     * This method will construct tree data with disabled subsection if already refreshed
     * @param {object} treeData 
     * @returns treeData object with disabled subsections
     */
    const constructTreeData = async (treeData)=>{
        let lastRefreshedInsights = await instantInsightsService.GetLastRefreshedInstantInsights(props.projectScopeId, sessionStorage.getItem("DashboardProjectId"));
        if (lastRefreshedInsights?.length) {
            lastRefreshedInsights.forEach((refreshedData) => {
                let disableDataResult = JSON.parse(refreshedData);
                disableDataResult.Sections.forEach((section)=>{
                    section.SubSections.forEach((subSection)=>{
                        compareSubSectionData(treeData, subSection);
                    })
                })
            })
        }
        return treeData;
    }

    /**
     * Get latest document update date
     */
    const getDocumentLibraryUpdateDate = async() => {
        let latestUpdateDate = "";
        const uploadDocuments = await documentservice.getDocuments(sessionStorage.getItem("DashboardProjectId"));
        if (uploadDocuments?.length > 0) {
            const latestDocument = uploadDocuments.reduce((pre, cur) => {
                const maxCurDate = cur.fileUploadTS > cur.modifiedDate ? cur.fileUploadTS : cur.modifiedDate;
                const maxPreDate = pre.fileUploadTS > pre.modifiedDate ? pre.fileUploadTS : pre.modifiedDate;
                return maxCurDate > maxPreDate ? cur : pre;
            });
            latestUpdateDate = latestDocument.fileUploadTS > latestDocument.modifiedDate ? latestDocument.fileUploadTS : latestDocument.modifiedDate;
        }
        setLatestDocumentDate(utils.formatDateCustom(latestUpdateDate)+ " (UTC)");
    }
    
    /**
     * This method will compare with existing refreshed subsection and add disabled property if required.
     * @param {object} treeData 
     * @param {object} subSection 
     */
    const compareSubSectionData = (treeData, subSection)=>{
        treeData.children?.forEach((reportType)=>{
            reportType.children?.forEach((reportSection)=>{
                reportSection.children?.forEach((reportSubSection)=> {
                    if(subSection.SubSectionId===reportSubSection.id){
                        reportSubSection.disabled = true;
                    }
                })
                reportSection.disabled = _.every(reportSection.children, 'disabled');
            })
            reportType.disabled = _.every(reportType.children, 'disabled');
        })
    }

    /**
     * This method resets the payload and initializes it.
     */
    const resetPayload = () => {
        setRefreshInsightsPayload({
            projectScopeId: props.projectScopeId,
            scopeName: props.projectScopeName,
            sections: []
        })
    }

    /**
     * This method traverses the tree and generates the payload using the selected nodes.
     * @param {object} node 
     */
    const traverseAndGeneratePayload = (node) => {
        if (!node.children || node.children.length === 0) {
            // Leaf node
            if (node.isSelected) {
                let parentFound = refreshInsightsPayload.sections.find(section => section.sectionId === node.parent.id);
                if (parentFound) {
                    parentFound.subSections.push({
                        subSectionId: node.id,
                        subSectionName: node.value
                    })
                }
                else {
                    refreshInsightsPayload.sections.push({
                        sectionId: node.parent.id,
                        sectionName: node.parent.value,
                        subSections: [
                            {
                                subSectionId: node.id,
                                subSectionName: node.value
                            }
                        ]
                    })
                }
            }
        }
        else {
            node.children.map((child) => traverseAndGeneratePayload(child))
        }
    }

    /**
     * This method handles refresh insights button click.
     */
    const handleRefreshInsightsBtnClick = () => {
        traverseAndGeneratePayload(updatedTreeData);
        instantInsightsService.refreshInsights(refreshInsightsPayload);
        props.setShowRefreshInsightsModal(false);
        if(refreshInsightsPayload.sections.length > 0){
            props.setHasAllResponsesGenerated(false);
        }
        resetPayload();
    }

    /**
     * This method handles cancel button click.
     */
    const handleCancelBtnClick = () => {
        props.setShowRefreshInsightsModal(false);
        resetPayload();
    }

    
    return (
        <MotifModal
            id="motifModalRefreshInsights"
            className="genericModal startIconClose modalMotifFooterModal modalFooterBorder"
            size="lg"
            show={true}
            focusTrapOptions={{
                tabbableOptions: {
                    displayCheck: "none",
                },
            }}
            onClose={() => {
                props.setShowRefreshInsightsModal(false);
            }}
        >
            <MotifModalHeader
                closeButtonProps={{
                    "aria-label": "Close",
                    title: "Close",
                }}
            >
                <img src={refreshIcon}></img>
                <div>
                    Refresh Insights
                </div>
            </MotifModalHeader>
            <MotifModalBody>
                <div>
                    Refresh Insights takes time to process, please ensure that you are only selecting the sections that require an update. The Refresh Insights button will be disabled for all the users until the update process completes.
                </div>
                <div className="refresh-info-message">
                    <img className="icon-info" src={information}></img>
                    <div>
                        Last change to the Document Library occured on {latestDocumentDate}. Insights updated post this date are not available for refresh.
                    </div>
                </div>
                <div className="tree-div">
                    {
                        refreshTreeData?.children?.length && (
                            <ProjectScopeTree treeValue={props.data.value} showLines={true} treeData={refreshTreeData} bubbleBackData={setUpdatedTreeData} showCheckbox={true}></ProjectScopeTree>
                        )
                    }
                </div>
            </MotifModalBody>
            <MotifModalFooter>
                <div className="btns-div">
                    <div>
                        <MotifButton id="btnCancel" variant="primary-alt" onClick={() => { handleCancelBtnClick() }}>Cancel</MotifButton>
                    </div>
                    <div>
                        <MotifButton id="btnRefresh" onClick={() => { handleRefreshInsightsBtnClick() }}>Refresh Insights</MotifButton>
                    </div>
                </div>
            </MotifModalFooter>
        </MotifModal>
    )
}