export const PromptData =
{
    promptid:String,
    convid:String,
    project_uuid:String,
    prompttype: String,
    title: String,
    tags: String,
    prompttext: String,
    IsFavOrSaved: String,
    Email: String
}