import axios from "../services/axiosWithMsal";
import CommonService from "./commonService";
class ClosePopUpService {
  commonService = new CommonService();
  constructor() {
    this.apiUrl = process.env.REACT_APP_API_URL;
  }

  async CloseProject(projectId, dataHostingLocationId) {
    this.apiUrl = this.commonService.getAPIUrl(dataHostingLocationId);
    const userCloseProjectRequest = {
      project_UUID: projectId
    };
    const response = await axios
    .post(this.apiUrl + "Project/CloseProject", userCloseProjectRequest, {
      headers : {
        "Content-type": "application/json; charset=UTF-8",
      }
    });
    return response;
  }
}

export default ClosePopUpService;
