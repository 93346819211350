const blankNavData = require("../data/model/projectdashnavtype");
let data = blankNavData.ProjectDashNavType;

const setBlankDashboardMenu = {
    setBlankNavMenu() {
        data.edit = false;
        data.explore = false;
        data.documentlibrary = false;
        data.members = false;
        data.projectdetails = false;
        data.promptlibraries = false;
    }
}
const setSession = {
    setDashboardDefault() {
        setBlankDashboardMenu.setBlankNavMenu();
        sessionStorage.setItem('DashboardProjectId', "");
        sessionStorage.setItem('DashboardProjectName', "");
        sessionStorage.setItem('ProjectDashboardMenu', "");
        sessionStorage.setItem('ProjectCreatedMessage', "");
        sessionStorage.setItem('CloseProjectId', "");
    },
    setEditDetails(EditProjectId=String,EditProjectName=String) {
        sessionStorage.setItem('DashboardProjectId',EditProjectId);
        sessionStorage.setItem('DashboardProjectName',EditProjectName);
    },
    setEditMenu() {
        setBlankDashboardMenu.setBlankNavMenu();
        data.edit=true;
        sessionStorage.setItem('ProjectDashboardMenu', JSON.stringify(data));
    },
    setExploreMenu() {
        setBlankDashboardMenu.setBlankNavMenu();
        data.explore=true;
        sessionStorage.setItem('ProjectDashboardMenu', JSON.stringify(data));
    },
    setDocumentLibraryMenu() {
        setBlankDashboardMenu.setBlankNavMenu();
        data.documentlibrary=true;
        sessionStorage.setItem('ProjectDashboardMenu', JSON.stringify(data));
    },
    setMembersMenu() {
        setBlankDashboardMenu.setBlankNavMenu();
        data.members=true;
        sessionStorage.setItem('ProjectDashboardMenu', JSON.stringify(data));
    },
    setProjectDetailsMenu() {
        setBlankDashboardMenu.setBlankNavMenu();
        data.projectdetails=true;
        sessionStorage.setItem('ProjectDashboardMenu', JSON.stringify(data));
    },
    setPromptLibrariesMenu() {
        setBlankDashboardMenu.setBlankNavMenu();
        data.promptlibraries=true;
        sessionStorage.setItem('ProjectDashboardMenu', JSON.stringify(data));
    },
    setBlankMessage() {
        sessionStorage.setItem('ProjectCreatedMessage', "");
    },
    setCloseProjectId(projectId=String) {
        sessionStorage.setItem('CloseProjectId', projectId);
    },
    setBlankCloseProjectId() {
        sessionStorage.setItem('CloseProjectId', "");
    },
}
export { setSession as default }