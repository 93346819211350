import { useEffect } from "react";
import "../ExploreData/ExploreData.css";
import MotifModal, {
  MotifModalHeader,
  MotifModalBody,
} from "@ey-xd/motif-react/Modal";

const ExploreDocumentModalPublic = ({
  openShow,
  setOpenModal,
  documentNames,
}) => {
  /**
   * Description
   * -----------
   * This useEffect is used to show/hide the modal
   */
  useEffect(() => {
    setOpenModal(openShow);
  }, [setOpenModal]);

  return (
    <div>
      <MotifModal
        id="MotifModalpubilcId"
        className="motif-modal-public"
        show={setOpenModal}
        focusTrapOptions={{
          tabbableOptions: {
            displayCheck: "none",
          },
        }}
        onClose={() => {
          setOpenModal(!openShow);
        }}
      >
        <MotifModalHeader
          closeButtonProps={{
            "aria-label": "Custom Close Button aria-label",
            title: "Custom Close Button title",
          }}
        >
          Response from {documentNames?.length} public sources
        </MotifModalHeader>
        <MotifModalBody>
          <div className="motif-modal-public-body">
            {documentNames.map((item) => {
              return (
                <>
                  <div key={item}>
                    <span className="motif-modal-public-body-span">
                      <a href={item.documentName} target="_blank" rel="noreferrer">
                        {item.documentName}
                      </a>
                    </span>
                  </div>
                </>
              );
            })}
          </div>
        </MotifModalBody>
      </MotifModal>
    </div>
  );
};

export default ExploreDocumentModalPublic;
