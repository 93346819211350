import React, { useEffect, useState } from "react";
import { MotifButton } from "@ey-xd/motif-react";
import "./InstantInsights.css";
import addSelectionIcon from "../../../assets/image/add-selection.svg";
import selectionIcon from "../../../assets/image/selection.svg";
import modifySelectionIcon from "../../../assets/image/modify-selection.svg";
import removeSelectionIcon from "../../../assets/image/remove-selection.svg";

const MarkFavResponseModel = (props) => {
  const [isAddMarkFavAction, setIsAddMarkFavAction] = useState(false);
  const [isEntireFavClick, setIsEntireFavClick] = useState(false);
  const [isPartialFavClick, setIsPartialFavClick] = useState(false);
  const [isModifyFavClick, setIsModifyFavClick] = useState(false);
  const [isRemoveFavClick, setIsRemoveFavClick] = useState(false);

  useEffect(() => {
    setIsAddMarkFavAction(props.isAddMarkFavAction);
  }, []);

  /**
   * This method calls when mark partial button click
   */
  const markPartialResponse = () => {
    setIsPartialFavClick(true);
    setIsEntireFavClick(false);
    props.onClickMarkPartialResponse();
  };

  /**
   * This method calls when mark entire button click
   */
  const markEntireResponse = () => {
    setIsEntireFavClick(true);
    setIsPartialFavClick(false);
    props.onClickMarkEntireResponse();
  };

  /**
   * This method calls when modify favorite response button clicked
   */
  const modifyFavResponse = () => {
    setIsModifyFavClick(true);
    setIsRemoveFavClick(false);
    setIsAddMarkFavAction(true);
    props.onClickModifyFavResponse();
  };

  /**
   * This method calls when remove favorite response button clicked
   */
  const removeFavResponse = () => {
    setIsRemoveFavClick(true);
    setIsModifyFavClick(false);
    props.onClickRemoveFavResponse();
  };

  /**
   * This method calls when cancel button click
   */
  const cancelMarkResponse = () => {
    props.onCancelMarkResponse();
  };

  /**
   * This method calls when confirm button click
   */
  const confirmMarkResponse = () => {
    props.onConfirmSelectionText();
  };

  return (
    <div className="favorite-response-modal-container">
      {isAddMarkFavAction && (
        <div className="mark-fav-options">
          <div className="mark-fav-option-text">
            Please make a selection to mark as favorite.
          </div>
          <div className="mark-fav-option-buttons">
            <MotifButton
              className={`${
                isEntireFavClick
                  ? "mark-fav-option-selected"
                  : "mark-fav-option"
              }`}
              onClick={markEntireResponse}
            >
              <img src={addSelectionIcon} alt="add selection"></img>
              Mark entire response
            </MotifButton>
            <MotifButton
              className={`${
                isPartialFavClick
                  ? "mark-fav-option-selected"
                  : "mark-fav-option"
              }`}
              onClick={markPartialResponse}
            >
              <img src={selectionIcon} alt="selection"></img>
              Mark part of the response
            </MotifButton>
          </div>
        </div>
      )}

      {!isAddMarkFavAction && (
        <div className="mark-fav-options">
          <div className="mark-fav-option-text">
            Highlighted area in the response is marked as Favorite.
          </div>
          <div className="mark-fav-option-buttons">
            <MotifButton
              className={`${
                isModifyFavClick
                  ? "mark-fav-option-selected"
                  : "mark-fav-option"
              }`}
              onClick={modifyFavResponse}
            >
              <img src={modifySelectionIcon} alt="add selection"></img>
              Modify Selection
            </MotifButton>
            <MotifButton
              className={`${
                isRemoveFavClick
                  ? "mark-fav-option-selected"
                  : "mark-fav-option"
              }`}
              onClick={removeFavResponse}
            >
              <img src={removeSelectionIcon} alt="selection"></img>
              Remove selection
            </MotifButton>
          </div>
        </div>
      )}
      <div className="mark-fav-confirmation">
        <MotifButton className="CancelButton" onClick={cancelMarkResponse}>
          Cancel
        </MotifButton>
        <MotifButton
          onClick={confirmMarkResponse}
          disabled={
            !(
              isEntireFavClick ||
              isPartialFavClick ||
              isModifyFavClick ||
              isRemoveFavClick
            )
          }
        >
          Confirm
        </MotifButton>
      </div>
    </div>
  );
};
export default MarkFavResponseModel;
