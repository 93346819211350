import { MotifModal, MotifModalHeader, MotifModalBody, MotifModalFooter, MotifButton } from "@ey-xd/motif-react";
import icon_no from '../../../assets/image/icon_no.svg';

// Component to render Delete Prompt modal
export default function DeletePromptModal(props) {

    return (
        <MotifModal size="sm" variant="alt" show={props.showDeletePromptModal} onClose={()=>props.toggleDeletePromptModal({})} className="genericModal modalNoHeaderBorder modalMotifFooterModal">
            <MotifModalHeader
                closeButtonProps={{
                    'aria-label': 'Close Delete Prompt Modal',
                    title: 'Close Delete Prompt Modal'
                }}
            >
                <img
                    src={icon_no}
                    alt="No"
                />
            </MotifModalHeader>
            <MotifModalBody>
                <span className="motif-h5">
                    Remove Prompt
                </span>
                <div className="motif-body prompt-delete-modal-body-text">
                    Would you like to remove the prompt from Personal Prompt Library?
                </div>
                <div className="motif-body">
                    Changes made cannot be reversed.
                </div>
            </MotifModalBody>
            <MotifModalFooter className="prompt-modal-footer">
                <MotifButton
                    id="btnConfirm"
                    onClick={props.handleDeletePrompt}
                    size="medium"
                    type="button"
                    className="prompt-modal-confirm-button motif-button-danger"
                    variant="warn"
                >
                    Confirm
                </MotifButton>
                <MotifButton
                    id="btnCancel"
                    onClick={()=>props.toggleDeletePromptModal({})}
                    size="medium"
                    type="button"
                    variant="secondary"
                    className="prompt-modal-cancel-button"
                >
                    Cancel
                </MotifButton>
            </MotifModalFooter>
        </MotifModal>
    );
}