import React, { useState } from "react";
import FavoriteResponses from "./FavoriteResponses";
import FavoriteResponseDetail from "./FavoriteResponseDetail";
import noResponseImage from "../../../../assets/image/noDocument.png";

const PersonalWorkspace = () => {
  const [isFilterSelected, setIsFilterSelected] = useState(false);

  const onFilterChange = () => {
    setIsFilterSelected(true);
  };

  return (
    <div className="motif-row row-with-full-height pw-section-container">
      <div className="motif-col-xs-4 motif-col-sm-1">
        <FavoriteResponses onFavoriteResponseFilterChange={onFilterChange} />
      </div>
      <div className="motif-col-sm-3 row-with-full-height">
        {isFilterSelected ? (
          <FavoriteResponseDetail />
        ) : (
          <div className="no-response-wrapper">
            <img src={noResponseImage} alt="No Response Selected"></img>
            <div className="no-response-text-container">
              <div className="no-response">No selection made</div>
              <div className="no-response-text">
                Please make a selection from the left pane
              </div>
            </div>
          </div>
        )}
      </div>
    </div>
  );
};
export default PersonalWorkspace;
