export const dashboardDataConstants = {
    privacyNotice: 
    {
        name: 'Privacy notice',
        url: 'https://paans-ui-uat.ey.com/viewnotice?a=94910419-6A5F-47F9-8E91-67607678B1C0&r=Global&l=EN&pt=1',
    },
    enagagementManagement: 
    {
        name: 'Engagement Management Guidance',
        url: 'https://discovercontent.ey.net/pod24022wgsjejjr'
    },
    eyAiPrinciples: 
    {
        name: 'EY AI Principles',
        url: 'https://sites.ey.com/sites/Artificialintelligence/SitePages/AI-Principles.aspx'
    },
    msAcceptableUsePolicy:
    {
        name:'Microsoft Acceptable Use Policy',
        url:'https://www.microsoft.com/licensing/terms/product/ForOnlineServices/all'
    },
    msCodeOfConductOpenAIServices:
    {
        name:'Microsoft Code of Conduct for OpenAI Services',
        url:'https://learn.microsoft.com/en-us/legal/cognitive-services/openai/code-of-conduct'
    }
}