import React, { useEffect, useState } from "react";
import { MotifButton } from "@ey-xd/motif-react";
import _ from "lodash";
import MotifModal, { MotifModalHeader, MotifModalBody, MotifModalFooter } from "@ey-xd/motif-react/Modal";
import noIcon from "../../../../assets/image/No.svg";
import "../../InstantInsights/RefreshInsightsModal.css";
import WorkSpaceService from "../../../../services/workspaceService";

export default function FavoriteDeleteModal(props) {
    
    const workspaceService = new WorkSpaceService();
    /**
     * This method handles delete favorite prompt button click.
     */
    const handleDeleteBtnClick = async(prompt) => { 
        await workspaceService.deleteWorkspaceFavoritePrompts(prompt.id,prompt.type);     
        props.setShowDeleteModal(false); 
        props.setShowDeletePrompt(true);     
    }

    /**
     * This method handles cancel button click.
     */
    const handleCancelBtnClick = () => {
        props.setShowDeleteModal(false);        
    }
    
    return (
        <MotifModal
            id="motifModalRefreshInsights"
            className="motif-modal-confidential"
            size="sm"
            show={true}
            focusTrapOptions={{
                tabbableOptions: {
                    displayCheck: "none",
                },
            }}
            onClose={() => {
                props.setShowDeleteModal(false);
            }}
        >
            <MotifModalHeader
                closeButtonProps={{
                    "aria-label": "Custom Close Button aria-label",
                    title: "Close popup",
                }}
            >
                <img className="icon-refresh" src={noIcon}></img>
                <div>
                Remove from Favorites
                </div>
            </MotifModalHeader>
            <MotifModalBody>
                <div className="info-div">
                    <div>
                    Please confirm you would like to remove {props.favoriteResponse.title} from Favorites. The action cannot be undone.
                    </div>
                </div>
               
            </MotifModalBody>
            <MotifModalFooter>
                <div className="btns-div">
                    <div>
                        <MotifButton id="btnCancel" variant="primary-alt" onClick={() => { handleCancelBtnClick() }}>Cancel</MotifButton>
                    </div>
                    <div>
                        <MotifButton id="btnRefresh" onClick={() => { handleDeleteBtnClick(props.favoriteResponse) }}>Confirm</MotifButton>
                    </div>
                </div>
            </MotifModalFooter>
        </MotifModal>
    )
}