import MotifModal, { MotifModalHeader, MotifModalBody } from '@ey-xd/motif-react/Modal';
import { MotifModalFooter } from "@ey-xd/motif-react";
import MotifButton from '@ey-xd/motif-react/Button';
import React, { useState } from "react";
import warningCircle from '../../assets/image/warning-circle.svg';
import "./ClosePopup.css";
export function ClosePopup({ setOpenModal, setconfirm }) {
    const [open, setOpen] = useState(true);
    /**
   * Description
   * -----------
   * This method is to close the close project popup
   */
    const onCloseClick = () => {
        setOpen(false);
        setOpenModal(false);
    }

    return (

        <div>
            <MotifModal id="closepopupmodal" show={open} size="md" focusTrapOptions={{
                    tabbableOptions: {
                        displayCheck: 'none'
                    }
                }}
                onClose={() => { onCloseClick() }}
                className="genericModal modalNoHeaderBorder modalMotifFooterModal"
            >
                <MotifModalHeader
                    className='closeModalHeader'
                    closeButtonProps={{
                        'aria-label': 'Close',
                        title: 'Close'
                    }}
                >
                   <div className='closeSymbolContainer'>
                    <img className='warningCircle' alt='icon' src={warningCircle} />
                   </div>
                </MotifModalHeader>
                <MotifModalBody>
                    <React.Fragment key=".0">
                        <div className='closeProjText'>Close Project Permanently</div>
                        <div id="closeProjectLines">
                            <span >
                                Closing the project will remove all the user access and deletes all data associated with the project including the documents. However, all prompts saved to a user's Personal Prompt Library will be retained. Do you want to proceed?
                            </span>
                        </div>
                    </React.Fragment>
                </MotifModalBody>
                <MotifModalFooter className='modalFooter'>
                    <React.Fragment key=".0">
                        <MotifButton
                            label="btnConfirm"
                            onClick={() => { setconfirm() }}
                            size="medium"
                            type="button"
                            className='btnConfirm'
                        >
                            Close Project
                        </MotifButton>
                        <MotifButton
                            label="btnCancel"
                            onClick={() => { onCloseClick() }}
                            size="medium"
                            type="button"
                            variant="secondary"
                            className='btnCancel'
                        >
                            Cancel
                        </MotifButton>
                    </React.Fragment>
                </MotifModalFooter>

            </MotifModal>
        </div>
    )
}

export default ClosePopup;