import axios from "./axiosWithMsal";
import { PromptType } from "../data/constant";
import CommonService from "./commonService.js";

// Service to handle all API calls related prompt library
class PromptService {
  commonService = new CommonService();
  constructor() {
    this.apiUrl = this.commonService.getAPIUrl();
  }

  /* Invoke API to fetch all personal prompts for user
       Returns - An array of prompt object
    */
  async getPersonalPrompts() {
    const apiEndPoint = `PromptLibrary/GetPromptsFromLibrary`;

    const response = await axios
      .get(this.apiUrl + apiEndPoint, {
        headers: { "Content-type": "application/json; charset=UTF-8" },
        params: { promptType: PromptType.Personal },
      })
      .catch((error) => {
        this.commonService.logException(
          error,
          "Personal Prompt Library",
          "Get personal prompt"
        );
      });

    return response.data;
  }

  /* Invoke API to fetch all SaT Best Practice prompts for user
       Returns - An array of prompt object
    */
  async getSaTBestPracticePrompts() {
    const apiEndPoint = `PromptLibrary/GetPromptsFromLibrary`;

    const response = await axios
      .get(this.apiUrl + apiEndPoint, {
        headers: { "Content-type": "application/json; charset=UTF-8" },
        params: { promptType: PromptType.SatBestPractice },
      })
      .catch((error) => {
        this.commonService.logException(
          error,
          "Personal Prompt Library",
          "Get SAT best practice prompt"
        );
      });

    return response.data;
  }

  /* Invoke API to delete given prompt
       @Params -
       promptId - PromptId of prompt to be deleted
    */
  async deletePersonalPrompt(promptId) {
    const apiEndPoint = `PromptLibrary/DeletePromptFromLibrary/` + promptId;

    const response = await axios
      .delete(this.apiUrl + apiEndPoint)
      .catch((error) => {
        this.commonService.logException(
          error,
          "Personal Prompt Library",
          "Delete personal prompt"
        );
      });

    return response;
  }

  /* Invoke API to fetch edit personal prompt for user
       @Params -
       prompt - Prompt object containing updated prompt text
    */
  async editPersonalPrompt(prompt) {
    const apiEndPoint = `PromptLibrary/EditPromptFromLibrary`;

    const response = await axios
      .put(this.apiUrl + apiEndPoint, {
        promptId: prompt.promptId,
        promptText: prompt.promptText,
      })
      .catch((error) => {
        this.commonService.logException(
          error,
          "Personal Prompt Library",
          "Edit personal prompt"
        );
      });

    return response;
  }
}

export default PromptService;
