import React, { useEffect, useState, useContext } from "react";
import { DashboardContext } from "../../context/dashboardContext";
import Utils from "../../utils/Utils";
import _ from "lodash";
import { MotifToast } from "@ey-xd/motif-react";
import DocumentService from "../../services/documentService";
import loadingIndicatorIcon from "../../assets/image/loading-indicator-circles.svg";
import checkBlueIcon from "../../assets/image/check-blue.svg";
import doubleCheckIcon from "../../assets/image/double-check.svg";

const CommonComponent = () => {
  const {
    isDocumentDeleteProcessInitiated,
    setIsDocumentDeleteProcessInitiated,
    isDocumentUploadProcessInitiated,
    setIsDocumentUploadProcessInitiated,
    setShowDocumentUploadMessage,
    showDocumentUploadMessage,
    isLocalUploadInitiated,
    isSharepointUploadInitiated,
    setDocumentList,
    setIsLocalUploadInitiated,
    setIsSharepointUploadInitiated,
    documentUploadSubmitted,
  } = useContext(DashboardContext);
  const utils = new Utils();
  let interval = null;
  const documentservice = new DocumentService();

  const [documentName, setdocumentName] = useState("");
  const [showDeletedComplete, setshowDeletedComplete] = useState(false);
  const [isUploadInProgressDisplayed, setIsUploadInProgressDisplayed] =
    useState(false);
  const [showUploadInitiated, setShowUploadInitiated] = useState(false);
  const [showUploadInPorgress, setShowUploadInPorgress] = useState(false);
  const [showUploadComplete, setShowUploadComplete] = useState(false);

  /**
   * Description
   * -----------
   * This useEffect will use to initiate the process to check the status of the recently deleted document.
   */
  useEffect(() => {
    if (isDocumentDeleteProcessInitiated) {
      let deleteDocInterval = setInterval(async () => {
        const documentName = await utils.getdeleteDocumentName();
        if (documentName.length !== 0) {
          setdocumentName(documentName);
          setshowDeletedComplete(true);
          setIsDocumentDeleteProcessInitiated(false);
          clearInterval(deleteDocInterval);
        }
      }, 1000 * process.env.REACT_APP_POLLING_TIME_IN_SECOND);
      return () => {
        clearInterval(deleteDocInterval);
      };
    }
  }, [isDocumentDeleteProcessInitiated]);

  /**
   * This use effect will call on component load to show the upload message
   */
  useEffect(() => {
    async function getUploadProgressData() {
      let documentProcessDetails =
        await documentservice.getDocumentUploadProcessDetail(
          sessionStorage.getItem("DashboardProjectId")
        );
      if (documentProcessDetails) {
        if (documentProcessDetails?.flag === "True") {
          if (documentProcessDetails?.type === "Local") {
            setIsLocalUploadInitiated(true);
            setIsSharepointUploadInitiated(false);
            setIsDocumentUploadProcessInitiated(true);
            getDocumentDetails();
          } else {
            setIsSharepointUploadInitiated(true);
            setIsLocalUploadInitiated(false);
            setIsDocumentUploadProcessInitiated(true);
          }
        }
      }
    }
    getUploadProgressData();
  }, []);
  /**
   * Description
   * -----------
   * This useEffect will use to initiate the process to check the status of the recently uploaded document.
   */
  useEffect(() => {
    showUploadMessageProcess();
  }, [isDocumentUploadProcessInitiated, documentUploadSubmitted]);

  /**
   * Description
   * -----------
   * This useEffect will check if document message is not displayed and will show message document upload in progress.
   */
  useEffect(() => {
    if (showDocumentUploadMessage && isUploadInProgressDisplayed) {
      setIsUploadInProgressDisplayed(false);
      setShowUploadInitiated(false);
      setShowUploadInPorgress(true);
    }
  }, [showDocumentUploadMessage]);

  const showUploadMessageProcess = () => {
    if (isDocumentUploadProcessInitiated) {
      setShowUploadInitiated(false);
      setShowUploadInPorgress(false);
      if (isLocalUploadInitiated) {
        setShowUploadInitiated(true);
      }
      if (isSharepointUploadInitiated) {
        setShowUploadInPorgress(true);
      }
      interval = setInterval(() => {
        getDocumentDetails();
      }, 1000 * process.env.REACT_APP_POLLING_TIME_IN_SECOND);

      return () => {
        clearInterval(interval);
      };
    }
  };

  /**
   * This method will return the document details with its status to show the upload message based on status.
   */
  const getDocumentDetails = async () => {
    const uploadDocuments = await documentservice.getDocuments(
      sessionStorage.getItem("DashboardProjectId")
    );
    if (uploadDocuments.length > 0) {
      setDocumentList(uploadDocuments);
      const notCompletedDocs = _.filter(uploadDocuments, function (item) {
        return item.status === "InProgress" || item.status === "Processing";
      });
      if (notCompletedDocs.length > 0) {
        if (!showDocumentUploadMessage) {
          setShowDocumentUploadMessage(true);
          setIsUploadInProgressDisplayed(true);
        }
      } else {
        setShowUploadInitiated(false);
        setShowUploadInPorgress(false);
        setShowUploadComplete(true);
        setIsDocumentUploadProcessInitiated(false);
        setIsLocalUploadInitiated(false);
        setIsSharepointUploadInitiated(false);
        setShowDocumentUploadMessage(false);
        if (interval) {
          clearInterval(interval);
        }
        documentservice.deleteFileUploadProgress(
          sessionStorage.getItem("DashboardProjectId")
        );
      }
    }
  };

  return (
    <div>
      {showDeletedComplete && (
        <MotifToast
          variant="success"
          actionName=""
          position="bottom"
          onClose={() => {
            setshowDeletedComplete(false);
          }}
        >
          <div>{documentName} has been successfully deleted.</div>
        </MotifToast>
      )}
      {showUploadInitiated && (
        <MotifToast
          id="toastUploadInitiated"
          variant="error"
          actionName=""
          position="top"
          onClose={() => {
            setShowUploadInitiated(false);
          }}
          closeButtonProps={{ style: { display: "none" } }}
          icons={{
            toast: (
              <img
                src={loadingIndicatorIcon}
                alt=""
                className="rotate-icon"
              ></img>
            ),
          }}
        >
          <div>
            Upload in progress. You will be notified when upload has been
            completed. Do not exit Digital Diligence Assistant.
          </div>
        </MotifToast>
      )}
      {showUploadComplete && (
        <MotifToast
          id="toastUploadComplete"
          variant="success"
          actionName=""
          position="top"
          onClose={() => {
            setShowUploadComplete(false);
          }}
          icons={{ toast: <img src={doubleCheckIcon} alt=""></img> }}
        >
          <div>
            Processing Complete. Your documents are now ready for prompt.
          </div>
        </MotifToast>
      )}
      {showUploadInPorgress && (
        <MotifToast
          id="toastLocalUploadInPorgress"
          variant="info"
          actionName=""
          position="top"
          onClose={() => {
            setShowUploadInPorgress(false);
          }}
          closeButtonProps={{ style: { display: "none" } }}
          icons={{ toast: <img src={checkBlueIcon} alt=""></img> }}
        >
          <div>
            Upload complete. Getting your documents ready for prompt. You can
            now exit Digital Diligence Assistant.
          </div>
        </MotifToast>
      )}
    </div>
  );
};

export default CommonComponent;
