import React, { useEffect, useState } from "react";
import {
  MotifCheckbox,
  MotifPaginationSelect,
  MotifPaginationSelectItem,
  MotifSearch,
  MotifToast,
  MotifTooltip,
  MotifDropdown
} from "@ey-xd/motif-react";
import { MotifIconButton } from "@ey-xd/motif-react/Button";
import MotifTable from "@ey-xd/motif-react/Table";
import _ from "lodash";
import Right from "../../../assets/image/Right.svg";
import check from "../../../assets/image/check.svg";
import clouderror from "../../../assets/image/clouderror.svg";
import noDocumentImg from "../../../assets/image/noDocument.png";
import page from "../../../assets/image/page.svg";
import reloadImg from "../../../assets/image/refresh-double.svg";
import DocumentService from "../../../services/documentService";
import Utils from "../../../utils/Utils";
import "./AddDocument.css";
import { DocumentDeleteConfirmModal } from "./DocumentDeleteConfirmModal";
import LoaderComponent from "../../common/LoaderComponent";
import searchIcon from "../../../assets/image/search.svg";
import { MotifDropdownItem } from "@ey-xd/motif-react/Dropdown";
import moreHoriz from "../../../assets/image/more-horiz.svg";
import DownloadDocument from "../../../utils/downloadDocument";

const FilesUploadedGrid = ({
  fileCountcheck,
  openShow,
  filesLimit,
  fileSizeLimit,
  documentList,
  deleteInitialted,
  reloadDocuments
}) => {
  const documentservice = new DocumentService();
  const utils = new Utils();

  let InititaData = [];
  const [searchText, setSearchText] = useState("");
  const [pagesize, SetPageSize] = useState(10);
  const [temRowData, SetTemRowData] = useState([]);
  const [rowData, setRowData] = useState([]);
  const [isPdfFilter, setIsPdfFilter] = useState(false);
  const [isDocFilter, setIsDocFilter] = useState(false);
  const [isPptFilter, setIsPptFilter] = useState(false);
  const [isXlsFilter, setIsXlsFilter] = useState(false);
  const [isTxtFilter, setIsTxtFilter] = useState(false);
  const [fileLimitSize, setFileLimitSize] = useState(0);
  const [selectedExtensions, setSelectedExtensions] = useState([]);
  const [gridshow, setGridShow] = useState(false);
  const [deleteDocumentUUID, setDeleteDocumentUUID] = useState("");
  const [deleteDocumentName, setDeleteDocumentName] = useState("");
  const [showDocumentDeleteConfirmModal, setShowDocumentDeleteConfirmModal] =
    useState(false);
  const [availableDocumentCount,setAvailableDocumentCount]= useState(0);
  const [showLoader, setShowLoader] = useState(false);
  const downloadDocument=new DownloadDocument();

  /**
   * Description
   * -----------
   * This useEffect resets the filters and document list.
   */
  useEffect(() => {
    getUploadedDocuments();
    setSelectedExtensions([]);
    setIsPdfFilter(false);
    setIsDocFilter(false);
    setIsPptFilter(false);
    setIsXlsFilter(false);
    setIsTxtFilter(false);
  }, []);

  /**
   * Description
   * -----------
   * This useEffect will call get document api to fetch the docs.
   */
  useEffect(() => {
    if (!openShow) {
      getUploadedDocuments();
    }
  }, [openShow]);


  /**
   * Description
   * -----------
   * This useEffect will call get document api to reload the documents.
   */
  useEffect(() => {
      getUploadedDocuments();
  }, [reloadDocuments]);

  /**
   * Description
   * -----------
   * This useEffect will call the filter document by the extension.
   */
  useEffect(() => {
    filterDocumentsWithExtension();
  }, [selectedExtensions]);

  /**
   * Description
   * -----------
   * This useEffect will check the total file size of document list.
   */
  useEffect(() => {
    if (documentList) {
      checkFileSize(documentList);
      setRowData(documentList);
      SetTemRowData(documentList);
      localStorage.setItem("getdocsdata", JSON.stringify(InititaData));
    }
  }, [documentList]);

  /**
   * Description
   * -----------
   * Get the documents which are uploaded and set it to rowdata to display grid.
   */
  const getUploadedDocuments = async () => {
    setShowLoader(true);
    InititaData = await documentservice.getDocuments(
      sessionStorage.getItem("DashboardProjectId")
    );
    checkFileSize(InititaData);
    setRowData(InititaData);
    SetTemRowData(InititaData);
    setShowLoader(false);
    localStorage.setItem("getdocsdata", JSON.stringify(InititaData));
  };

  //refresh the documents grid
  const reloadUploadedDocuments = () => {
    getUploadedDocuments();
  };

  /**
   * Description
   * -----------
   * caluculates the total documnets uploaded size and count and send back to Adddocument component.
   * Parameters:
   * data: data contains the uploaded doc records.
   */
  function checkFileSize(data) {
    if (data?.length > 0) {
      let count = 0;
      let size = 0;
      data.forEach((item) => {
        if (item.fileSize !== "" && item.status !== "Deleted" && item.status !== "Error") {
          size += parseInt(item.fileSize);
          count++;
        }
      });
      setFileLimitSize(size);
      setAvailableDocumentCount(count);
      fileCountcheck(data.length, size, data);
    } else {
      setFileLimitSize(0);
      setAvailableDocumentCount(0);
      let arary = [];
      fileCountcheck(0, 0, arary);
    }
  }

  /**
   * Description
   * -----------
   * This method formats the required date of grid column before displaying.
   * Parameters:
   * params: contains the date.
   */
  const dateFormatter = (params) => {
    return utils.formatDateCustom(params.value);
  };

  /**
   * Description
   * -----------
   * This is custom cell renderer to display document status complete, pending or failed.
   * Parameters:
   * value: contains document status.
   */
  const statusResultRenderer = ({ value }) => (
    <span className="add-document-file-status-div">
      {(() => {
        if (value === "Complete") {
          return (
            <span className="add-document-file-status">
              <img src={check} alt={`${value}`} /> Ready{" "}
            </span>
          );
        } else if (value === "InProgress") {
          return (
            <span className="add-document-file-status">
              <img src={Right} alt={`${value}`} /> Uploading{" "}
            </span>
          );
        } else if (value === "Processing") {
          return (
            <span className="add-document-file-status">
              <img src={Right} alt={`${value}`} /> Processing{" "}
            </span>
          );
        } else if (value === "Error") {
          return (
            <span className="add-document-file-status file-upload-error-message">
              <img src={clouderror} alt={`${value}`} /> Failed to upload
            </span>
          );
        } else if (value === "Delete InProgress") {
          return (
            <MotifTooltip
              placement="bottom"
              trigger={
                <span className="add-document-file-status">
                  Deletion In Progress
                </span>
              }
            >
              Prompt responses may still <br /> reference this document until
              <br />
              deletion is confirmed.
            </MotifTooltip>
          );
        } else if (value === "Deleted") {
          return <span className="add-document-file-status">Deleted</span>;
        }
      })()}
    </span>
  );

  /**
   * Description
   * -----------
   * This is defined column to bind a document grid.
   */
  const [colDefs] = useState([
    {
      field: "documentName",
      displayName: "Files",
      width: 300,
      cellRenderer: (params) => (
        <div className="grid-document-name">
          <img src={page} alt={params.data.documentName} />{" "}
          {params.data.documentName}
        </div>
      ),
      wrapText: true,
      autoHeight: true,
    },
    {
      field: "fileUploadTS",
      displayName: "Date (UTC)",
      width: 150,
      valueFormatter: dateFormatter,
      
    },
    {
      field: "ownerName",
      displayName: "Member",
      wrapText: true,
      autoHeight: true,
      width:"210",
    },
    {
      field: "status",
      displayName: "Status",
      cellRendererFramework: statusResultRenderer,
      width:160,
      wrapText: true,
      autoHeight: true,
    },
    {
      field: "reason",
      displayName:"Notes",
      width: 165,
      wrapText: true,
      autoHeight: true,
      cellRendererFramework: (params) => {
        if (params.data.status === "Error") {
          return (
            <span
              className="add-document-file-status file-upload-error-message"
              title={params.data.errorMessage}
            >
              {params.data.errorMessage}
            </span>
          );
        } else if(params.data.warningMessage === "Permissions restricted"){
          return (
            <MotifTooltip
              id="permission"
              placement="left"
              hideCloseButton={true}
              trigger={
                <span
                  className="add-document-file-status file-upload-warning-message"
                >
                  {params.data.warningMessage}
                </span>}>
                The file has permission restrictions and therefore information<br /> will not be extracted from tables, charts and images.
            </MotifTooltip>
            
          );
        }else {
          return <span></span>;
        }
      },
      
    },
    {
      field: "action",
      displayName:"Action",
      width: 100,
      cellRendererFramework: (params) => {
        if (
          params.data.status === "Delete InProgress" ||
          params.data.status === "Deleted"
        ) {
          return "";
        } else {
          return (
            <div key={params.data.documentId}>
              <div className="add-document-grid-action-button-div">
              {params.data.status === "Complete" && ((localStorage.getItem('userRole') !== "User (Non-SaT)") && ( localStorage.getItem('userRole') !== "Viewer")) &&
              <MotifDropdown
              aria-labelledby="dropdown-trigger-1"
              handleClickOutside={() => closeMenuClick(params.data.document_UUID)}
              id={`dropdown` + params.data.document_UUID}
              placement="bottom-left"
              trigger={
                <MotifIconButton aria-label="dropdown-start"
                  id={`dropdown${params.data.documentName}`}
                  onClick={() => openMenuClick(params.data.document_UUID)}>
                  <img src={moreHoriz} alt="" />
                </MotifIconButton>}
            >
              <span className="document-download-delete" >
                {
                  (() => {
                    return <>
                      {params.data.status === "Complete" && ((localStorage.getItem('userRole') !== "User (Non-SaT)") && ( localStorage.getItem('userRole') !== "Viewer")) &&
                        <MotifDropdownItem onClick={() => downloadDocument.handleDownload(params.data.documentName)}>
                          Download
                        </MotifDropdownItem>
                      }
                      <MotifDropdownItem onClick={(e) => {
                        showPopUp(
                          true,
                          params.data.document_UUID,
                          params.data.documentName
                        );
                      }}>
                        Delete
                      </MotifDropdownItem>
                    </>
                  })()
                }
              </span>

            </MotifDropdown>
             }
              </div>
            </div>
          );
        }
      },
    },
  ]);

  /**
   * Description
   * -----------
   * This method filters the document grid based on input.
   * Parameters:
   * event: event object the the search input.
   */
  function updateFilter(event) {
    event.preventDefault();
    if (event.target.value !== "") {
      setGridShow(true);
    }
    setSearchText(event.target.value);
    let searchValue = event.target.value;
    if (searchValue !== "") {
      searchValue = searchValue.toString().toLowerCase().trim();
      let filteredDocs = _.filter(temRowData, function (item) {
        return (
          item.documentName.toLowerCase().includes(searchValue) ||
          item.status.toLowerCase().includes(searchValue)
        );
      });
      setRowData(filteredDocs);
    } else {
      setRowData(temRowData);
    }
  }

  /**
   * Description
   * -----------
   * File extension based filter for the grid.
   */
  const filterDocumentsWithExtension = () => {
    if (temRowData.length > 0) {
      setGridShow(true);
    }
    if (selectedExtensions.length !== 0 && temRowData.length > 0) {
      let data = _.filter(temRowData, function (item) {
        return (
          selectedExtensions.indexOf(
            utils.getFileExtension(item.documentName)
          ) > -1
        );
      });
      setRowData(data);
    } else {
      setRowData(temRowData);
    }
  };

  /**
   * Description
   * -----------
   * This method filters the documents by its extension.
   * Parameters:
   * extension: selected filter extension value.
   * checked: extension checkbox filter selection value.
   */
  const filterDocuments = (extension, checked) => {
    if (extension.length > 0) {
      _.forEach(extension, function (ext) {
        switch (ext) {
          case "pdf":
            setIsPdfFilter(checked);
            break;
          case "ppt":
          case "pptx":
            setIsPptFilter(checked);
            break;
          case "doc":
          case "docx":
            setIsDocFilter(checked);
            break;
          case "xls":
          case "xlsx":
            setIsXlsFilter(checked);
            break;
          case "txt":
            setIsTxtFilter(checked);
            break;
          default:
            break;
        }
      });
    }

    if (checked) {
      let arrayExtension = [...selectedExtensions, ...extension];
      setSelectedExtensions(_.union(arrayExtension));
    } else {
      let removeExtensionArray = _.filter(selectedExtensions, function (e) {
        return extension.indexOf(e) <= -1;
      });
      setSelectedExtensions(removeExtensionArray);
    }
  };

  /**
   * Description
   * -----------
   * This method shows modal popup setup before deleting the document.
   * Parameters:
   * isVisible: parameter to show or hide document delete confirmation modal
   * documentId: contains document id
   * documentName: contains document name
   */
  const showPopUp = (isVisible, documentId, documentName) => {
    setDeleteDocumentUUID(documentId);
    setDeleteDocumentName(documentName);
    setShowDocumentDeleteConfirmModal(isVisible);
  };

  /**
   * Description
   * -----------
   * This method delete the selected document.
   * Parameters:
   * documentUUID: contains document id which needs to be deleted
   * documentName: contains document name
   */
  const deleteSelectedDocument = (documentUUID, documentName) => {
    documentservice.deleteDocument(
      sessionStorage.getItem("DashboardProjectId"),
      documentUUID,
      documentName
    );
    setTimeout(function () {
      getUploadedDocuments();
    }, 1000);
    setShowDocumentDeleteConfirmModal(false);
    deleteInitialted(true);
  };

  /**
   * Description
   * -----------
   * This method grey out Deleted document row
   */
  const getRowClass = (params) => {
    return params.data.status === "Delete InProgress" ||
      params.data.status === "Deleted"
      ? "greyed-out-row"
      : "";
  };
  /**
 * Description
 * -----------
 * This method is to open the edit menu of the project
 * Parameters:
 * index: This parameter is to access the dropdown based on the project index
 */
  const openMenuClick = (index) => {
    let menuItem = document.getElementById("dropdown" + index);
    if (menuItem) {
      menuItem.style.display = "block";
      menuItem.style.position = "relative";
    }
  };
  /**
     * Description
     * -----------
     * This method is to close the edit menu of the project
     * Parameters:
     * index: This parameter is to access the dropdown based on the project index
     */
  const closeMenuClick = (index) => {
    let menuItem = document.getElementById("dropdown" + index);
    if (menuItem) {
      menuItem.style.display = "none";
    }
  };

  return (
    <>
      {showLoader && <LoaderComponent />}
      {temRowData?.length > 0 && (
        <div className="Frame53401">
          <div className="Frame54261">
            <div className="Table1">
              <div className="CardHeader1">
                <div className="CardTitle1">
                  <div className="TextAndSupportingText1">
                    <div className="text12">
                      <div className="Text13">
                        Select files to explore your data
                      </div>
                    </div>
                  </div>
                </div>
                <div className="CardActions1">
                  <div className="Button2">
                    <img
                      className="searchIcon"
                      src={searchIcon}
                      alt="search Icon"
                    />
                    <MotifSearch
                      aria-label="Search"
                      hideClearButton
                      id="states-search2"
                      className="searchTxtBox"
                      onChange={(e) => {
                        updateFilter(e);
                      }}
                      onSelect={function noRefCheck() {}}
                      placeholder="Search"
                      value={searchText}
                    />
                  </div>
                </div>
              </div>

              <div className="main-motif-table-filter">
                <div className="doc-filter-container">
                  <div className="doc-filters">
                    <div>
                      <MotifCheckbox
                        className="ChkAll1"
                        id="filter-pdfFilter"
                        name="filter-pdfFilter"
                        onChange={(e) => {
                          filterDocuments(["pdf"], e.target.checked);
                        }}
                        checked={isPdfFilter}
                      >
                        PDF
                      </MotifCheckbox>
                    </div>
                    <div>
                      <MotifCheckbox
                        className="ChkAll1"
                        id="checkbox-pptFilter"
                        name="checkbox-pptFilter"
                        onChange={(e) => {
                          filterDocuments(["ppt", "pptx"], e.target.checked);
                        }}
                        checked={isPptFilter}
                      >
                        PPTX
                      </MotifCheckbox>
                    </div>
                    <div>
                      <MotifCheckbox
                        className="ChkAll1"
                        id="checkbox-docFilter"
                        name="checkbox-docFilter"
                        onChange={(e) => {
                          filterDocuments(["doc", "docx"], e.target.checked);
                        }}
                        checked={isDocFilter}
                      >
                        DOC
                      </MotifCheckbox>
                    </div>
                    <div>
                      <MotifCheckbox
                        className="ChkAll1"
                        id="checkbox-xlsFilter"
                        name="checkbox-xlsFilter"
                        onChange={(e) => {
                          filterDocuments(["xls", "xlsx"], e.target.checked);
                        }}
                        checked={isXlsFilter}
                      >
                        XLS
                      </MotifCheckbox>
                    </div>
                    <div>
                      <MotifCheckbox
                        className="ChkAll1"
                        id="checkbox-txtFilter"
                        name="checkbox-txtFilter"
                        onChange={(e) => {
                          filterDocuments(["txt"], e.target.checked);
                        }}
                        checked={isTxtFilter}
                      >
                        TXT
                      </MotifCheckbox>
                    </div>
                  </div>
                  <div className="doc-filter-text">
                    Select to filter the document library by file type.
                  </div>
                </div>
                <div className="doc-details-container">
                  <div className="doc-details-states">
                    {availableDocumentCount}/{filesLimit}
                  </div>
                  <div className="doc-details-states">
                    {utils.getBytesInMB(fileLimitSize)} MB/
                    {utils.getBytesInMB(fileSizeLimit)} MB
                  </div>
                  <div className="reload-doc-image-button">
                    <MotifIconButton
                      onClick={reloadUploadedDocuments}
                      alt="reload document table1"
                    >
                      <img src={reloadImg} alt="reload document table" />
                    </MotifIconButton>
                  </div>
                </div>
              </div>

              <div className="upload-files-grid-container" id="uploadtable">
                <MotifTable
                  rowData={rowData}
                  columnDefs={colDefs}
                  getRowClass={getRowClass}
                  suppressCellFocus
                  suppressRowClickSelection
                  pagination
                  paginationPageSize={pagesize}
                  paginationSelect={
                    <MotifPaginationSelect>
                      <MotifPaginationSelectItem
                        onClick={function noRefCheck() {
                          SetPageSize(10);
                        }}
                        selected
                      >
                        Show 10
                      </MotifPaginationSelectItem>
                      <MotifPaginationSelectItem
                        onClick={function noRefCheck() {
                          SetPageSize(15);
                        }}
                      >
                        Show 15
                      </MotifPaginationSelectItem>
                      <MotifPaginationSelectItem
                        onClick={function noRefCheck() {
                          SetPageSize(20);
                        }}
                      >
                        Show 20
                      </MotifPaginationSelectItem>
                    </MotifPaginationSelect>
                  }
                />
              </div>
            </div>
          </div>
        </div>
      )}
      {rowData?.length === 0 && !gridshow && (
        <div className="Frame534012">
          <div className="Frame681">
            <div className="Frame691"></div>
            <div className="EmptyState1">
              <div className="EmptyState2">
                <div className="Content2">
                  <img src={noDocumentImg} alt="no documentimage"></img>
                  <div className="TextAndSupportingText">
                    <div className="Text15">No documents found</div>
                    <div className="nodoctext">
                      Please upload your documents in the document library
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="Rectangle5361"></div>
        </div>
      )}

      {showDocumentDeleteConfirmModal && (
        <DocumentDeleteConfirmModal
          setOpenModal={showPopUp}
          setConfirm={deleteSelectedDocument}
          documentUUID={deleteDocumentUUID}
          documentName={deleteDocumentName}
        ></DocumentDeleteConfirmModal>
      )}

      
    </>
  );
};

export default FilesUploadedGrid;
