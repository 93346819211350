/**
 * Function component to display response data as tabs (original response and modified response).
 * Created On: 14-05-2024
 */
import MotifTabNavigation, { MotifTabControl } from '@ey-xd/motif-react/TabNavigation';
import { useContext, useEffect, useState } from "react";
import { MotifTooltip } from "@ey-xd/motif-react";
import PropTypes from 'prop-types';

import "./Response.css";
import EditResponse from "./EditResponse";
import Utils from '../../../utils/Utils';
import LikeDislike from '../../common/LikeDislike';
import { MotifIconButton } from '@ey-xd/motif-react/components/button';
import { DashboardContext } from '../../../context/dashboardContext';

const Response = (props) => {
  const { oResponse, onCopy, copytag } = props;
  const [modifiedResponse, setModifiedResponse] = useState(null);
  const tabIds = { original: "originalTab", modified: "modifiedTab" };
  const utils = new Utils();
  const { showViewSourceDocIconOnEditButtonClick } = useContext(DashboardContext);

  useEffect(() => {
    setModifiedResponse(props.mResponse);
  }, [props.oResponse, props.mResponse]);

  const handleTabClick = (tab, promptId) => {
    switch (tab) {
      case tabIds.original + promptId:
        document.getElementById(tabIds.original + promptId).style.display = "block";
        document.getElementById(tabIds.modified + promptId).style.display = "none";
        document.getElementById('viewSourceDocument' + promptId).style.display = "block";
        showHideViewSourceDocumentButton(oResponse?.responseContent, modifiedResponse, promptId, true);
        break;
      case tabIds.modified + promptId:
        document.getElementById(tabIds.original + promptId).style.display = "none";
        document.getElementById(tabIds.modified + promptId).style.display = "block";
        document.getElementById('viewSourceDocument' + promptId).style.display = "none";
        break;
      default:
    }
  };

  const showHideViewSourceDocumentButton = (originalResponse, modifiedResponse, promptId, isOriginalClicked) => {
    let viewSourceDocIconOnEditButtonClick = showViewSourceDocIconOnEditButtonClick ?? {};
    var result = (originalResponse && modifiedResponse) ?
      ((viewSourceDocIconOnEditButtonClick[`viewSourceDocument${promptId}`] !== undefined
        && viewSourceDocIconOnEditButtonClick[`viewSourceDocument${promptId}`] === false)
        ? 'explore-prompt-response-actions-donot-show'
        : 'explore-prompt-response-actions explore-prompt-response-actions-originalresponse') :
      ((viewSourceDocIconOnEditButtonClick[`viewSourceDocument${promptId}`] !== undefined
        && viewSourceDocIconOnEditButtonClick[`viewSourceDocument${promptId}`] === false)
        ? 'explore-prompt-response-actions-donot-show'
        : 'explore-prompt-response-actions');

      if (result === 'explore-prompt-response-actions-donot-show' && isOriginalClicked === true) {
        document.getElementById('viewSourceDocument' + promptId).className = 'explore-prompt-response-actions explore-prompt-response-actions-originalresponse';
      } else if (result === 'explore-prompt-response-actions-donot-show' && isOriginalClicked === false) {
        document.getElementById('viewSourceDocument' + promptId).className = 'explore-prompt-response-actions';
      }
    return result;
  }

  /**
   * This method updates the modified response with latest value if user has saved the modified response.
   * @param response: latest modified response
   */
  const updateModifiedResponse = (response) => {
    setModifiedResponse(response);
    props.onModifiedResponseUpdate(oResponse?.promptId, response);
  };

  /**
   * This method updates the user action.
   * @param userAction: updated user action
   * @param oResponse: oResponse object
   */
  const updateUserAction = (userAction, oResponse) => {
    // Add your logic here to update the user action
    oResponse.userAction = userAction;
  };

  return modifiedResponse ? (
    <div>
      <MotifTabNavigation
        className="responseTabNavigation"
        defaultActiveKey={0}
      >
        <MotifTabControl onClick={() => handleTabClick(`originalTab${oResponse.promptId}`, oResponse.promptId)} data-testid="testIdOriginalResponseTab">
          Original Response
        </MotifTabControl>
        <MotifTabControl onClick={() => handleTabClick(`modifiedTab${oResponse.promptId}`, oResponse.promptId)} data-testid="testIdModifiedResponseTab">
          Modified Response
        </MotifTabControl>
      </MotifTabNavigation>
      <div
        id={`originalTab${oResponse.promptId}`}
        className="originalRespTabContainer"
        data-testid="testIdOriginalTab"
      >
        <div className="explore-prompt-question-response-container-child">
          <div
            className={
              "explore-prompt-question-response divResponse" +
              oResponse.promptId
            }
            dangerouslySetInnerHTML={{
              __html: utils.markdownToHtml(oResponse.responseContent),
            }}
          ></div>

        </div>
        <div className="explore-prompt-copy-response-icon">
          {/* TODO: Update oResponse object userAction on click of update the like or dislike. */}
          <LikeDislike data={{
            promptHistoryId: oResponse.promptId,
            projectId: oResponse.project_UUID,
            userAction: oResponse.userAction
          }} type='exploreservice'
            onChangeUserAction={(userAction) => { updateUserAction(userAction, oResponse) }} />

          <div className="gapbetweenicons">
            <MotifTooltip
              trigger={
                <MotifIconButton data-testid="testIdOriginalResponseCopy">
                  <img src={copytag}
                    className="icon edit"
                    id="testIdOriginalResponseCopy2"
                    alt="Copy"
                    onClick={(e) => {
                      e.preventDefault();
                      onCopy("divResponse" + oResponse.promptId);
                    }} />
                </MotifIconButton>}>
              <div className="tooltip-useraction">
                Copy
              </div>
            </MotifTooltip>
          </div>
        </div>
      </div>
      <div
        id={`modifiedTab${oResponse.promptId}`}
        className="modifiedRespTabContainer"
        data-testid="testIdModifiedTab"
      >
        <EditResponse
          response={utils.markdownToHtml(modifiedResponse)}
          promptId={oResponse?.promptId}
          userAction={oResponse?.userAction}
          isModifiedResponse={true}
          onCopy={onCopy}
          onModifiedResponseUpdate={updateModifiedResponse}
        />
      </div>
    </div >
  ) : (
    <EditResponse
      response={utils.markdownToHtml(oResponse?.responseContent)}
      promptId={oResponse?.promptId}
      userAction={oResponse?.userAction}
      isModifiedResponse={false}
      onCopy={onCopy}
      onModifiedResponseUpdate={updateModifiedResponse}
    />
  );
};

Response.propTypes = {
  oResponse: PropTypes.object.isRequired,
  mResponse: PropTypes.string.isRequired,
  onCopy: PropTypes.func.isRequired,
  copytag: PropTypes.element.isRequired
};

export default Response;
