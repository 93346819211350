import { useState, Fragment, useEffect } from "react";
import { MotifSearch, MotifBadge, MotifFormField } from "@ey-xd/motif-react";
import Prompt from "./Prompt";
import search from "../../../assets/image/search.svg";

// Component to render search & list all prompts within prompt library
export default function PromptLibrary(props){
    const [searchPromptText, setSearchPromptText] = useState("");
    const [filteredPrompts, setFilteredPrompts] = useState([]);
        
    useEffect(()=>{
        // Set the initial prompts as filtered prompts from props after every change of props
        setFilteredPrompts(props?.prompts);
    }, [props?.prompts]);

    useEffect(()=>{
        // Filter prompts based on search
        if(props && props.prompts) {
            const filteredPromptList = props.prompts.filter((prompt)=>{
                const promptTitle = prompt.promptTitle.toLowerCase();
                const promptText = prompt.promptText.toLowerCase();
                const searchText = searchPromptText.toLowerCase();
                return (promptTitle.includes(searchText) || promptText.includes(searchText));
            });
            setFilteredPrompts(filteredPromptList);
        }
    }, [searchPromptText]);

    // Set search prompt text to state on change
    function handleSearchPrompt(event){
        setSearchPromptText(event.target.value);
    }

    return (
        <Fragment>
            <div className="motif-row prompt-library">
                <div className="motif-col-lg-4 motif-col-sm-2 motif-col-xs-2">
                    <MotifFormField>
                        <img className="prompt-search-icon" src={search} />
                        <MotifSearch 
                            id="txtSearch"
                            className="prompt-search-textbox"
                            placeholder="Search"
                            onChange={(event) => handleSearchPrompt(event)}
                            value={searchPromptText}>
                        </MotifSearch>
                    </MotifFormField>
                </div>
                <div className="motif-col-lg-8 motif-col-sm-2 motif-col-xs-2">
                    <MotifBadge className="motif-form-field prompt-library-badge" value={filteredPrompts?.length} />
                </div>
            </div>

            {
                filteredPrompts && filteredPrompts.map((prompt, index)=>{
                    if(index%3 === 0) {
                        return (
                            <div key={index} className="prompt-row">
                                <div className="prompt-box">
                                    <Prompt prompt={filteredPrompts[index]} toggleDeletePromptModal={props.toggleDeletePromptModal} 
                                    toggleEditPromptModal={props.toggleEditPromptModal} toggleViewPromptModal={props.toggleViewPromptModal}
                                    handleUsePrompt={props.handleUsePrompt}/>
                                </div>
                                <div className="prompt-box">
                                    { filteredPrompts.length>index+1 && <Prompt prompt={filteredPrompts[index+1]} toggleDeletePromptModal={props.toggleDeletePromptModal} 
                                    toggleEditPromptModal={props.toggleEditPromptModal} toggleViewPromptModal={props.toggleViewPromptModal}
                                    handleUsePrompt={props.handleUsePrompt}/> }
                                </div>
                                <div className="prompt-box">
                                    { filteredPrompts.length>index+2 && <Prompt prompt={filteredPrompts[index+2]} toggleDeletePromptModal={props.toggleDeletePromptModal} 
                                    toggleEditPromptModal={props.toggleEditPromptModal} toggleViewPromptModal={props.toggleViewPromptModal}
                                    handleUsePrompt={props.handleUsePrompt}/> }
                                </div>
                            </div>
                        );
                    }
                })
            }
        </Fragment>
    );
}