import MotifAccordion, {
  MotifAccordionTrigger,
  MotifAccordionContent,
} from "@ey-xd/motif-react/Accordion";
import React from "react";

const SectionList = (props) => {
  return (
    <MotifAccordion alignIconRight={true} useChevronIcon={true}>
      <MotifAccordionTrigger>
        {props.section.sectionTitle}
      </MotifAccordionTrigger>
      <MotifAccordionContent>
        <div className="tw-favorite-tile">No response available</div>
      </MotifAccordionContent>
    </MotifAccordion>
  );
};
export default SectionList;
