import dashboardnav from "../../assets/image/dashboardnav.svg";
import "./BreadCrumb.css";

export function BreadCrumb(props) {
  return (
    <>
      <div className="DashProjBreadcrumb">
        <div className="DashProjBreadcrumbBase">
          <div className="DashProjBreadcrumbItem">
            <div className="dashboard-breadcrumb">
            <a href="/Dashboard" className="dashboard-breadcrumb">
                Dashboard
              </a>
            </div>
          </div>

          <div className="DashProjNavArrowRight">
            <img src={dashboardnav} alt=""></img>
          </div>
          <div className="DashProjBreadcrumbItem">
            <div className={`${props?.pageName ? "breadcrum-project" : ""}`}>
              {props.projectName}
            </div>
          </div>
          {props?.pageName && (
            <>
              <div className="DashProjNavArrowRight">
                <img src={dashboardnav} alt=""></img>
              </div>
              <div className="DashProjBreadcrumbItem">
                <div className="DashProjText1">{props?.pageName}</div>
              </div>
            </>
          )}
        </div>
      </div>
    </>
  );
}
export default BreadCrumb;
