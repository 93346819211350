import React, { useState } from "react";
import MotifModal, {
  MotifModalHeader,
  MotifModalBody,
} from "@ey-xd/motif-react/Modal";
import { MotifModalFooter } from "@ey-xd/motif-react";
import MotifButton from "@ey-xd/motif-react/Button";
import "./InstantInsights.css";

const UnFavoriteConfirmationModal = ({
  setOpenModal,
  setConfirm,
  instantInsightFavoritePromptId,
  promptHistoryId,
}) => {
  const [open, setOpen] = useState(true);

  /**
   * Description
   * -----------
   * This method is to close the close project popup
   */
  const onCloseClick = () => {
    setOpen(false);
    setOpenModal(false);
  };

  return (
    <div>
      <MotifModal
        id="UnFavoriteConfirmationModal"
        show={open}
        focusTrapOptions={{
          tabbableOptions: {
            displayCheck: "none",
          },
        }}
        onClose={() => {
          onCloseClick();
        }}
        className="genericModal modalNoHeaderBorder modalMotifFooterModal"
      >
        <MotifModalHeader
          closeButtonProps={{
            "aria-label": "Close",
            title: "Close",
          }}
        >
          Remove from Favorites
        </MotifModalHeader>
        <MotifModalBody>
          <React.Fragment key=".0">
            <span className="modalContent">
              Please confirm that you would like to remove from Favorites. The
              action cannot be undone.
            </span>
          </React.Fragment>
        </MotifModalBody>
        <MotifModalFooter>
          <div className="unfavorite-confirmation-modal-footer">
            <MotifButton
              label="btnCancel"
              onClick={() => {
                onCloseClick();
              }}
              size="medium"
              type="button"
              variant="secondary"
            >
              Cancel
            </MotifButton>
            <MotifButton
              onClick={() => {
                setConfirm(promptHistoryId, instantInsightFavoritePromptId);
              }}
              size="medium"
              type="button"
            >
              Remove
            </MotifButton>
          </div>
        </MotifModalFooter>
      </MotifModal>
    </div>
  );
};
export default UnFavoriteConfirmationModal;
