import React from "react";
import PropTypes from "prop-types";
import { MotifButton } from "@ey-xd/motif-react";
import MotifModal, {
  MotifModalHeader,
  MotifModalBody,
  MotifModalFooter,
} from "@ey-xd/motif-react/Modal";
import InstantInsightsService from "../../../services/instantInsightService";
import refreshIcon from "../../../assets/image/refresh-double.svg";
import "./InstantInsights.css";

export default function AcknowledgeRefresh(props) {
  const instantInsightsService = new InstantInsightsService();
  const node = props.selectedTreeNode;

  const btnAcknowledgeRefreshClick = async () => {
    let level = "";
    let id = 0
    if (props.launchedBy == "tree") {
      if (node && node.nodeType == 1) {
        level = "topic";
        id = node.id;
      }
      else if (node && node.nodeType == 2) {
        level = "section";
        id = node.id;
      }
      else if (node && node.nodeType == 3) {
        level = "subsection";
        id = node.id;
      }
    }
    else {
      level = "prompt";
      id = props.prompt.instantInsightPromptHistoryId;
    }
    await instantInsightsService.acknowledgeRefresh(
      id,
      level,
      props.projectScopeId
    );
    props.refreshTreeData();
    props.setRefreshPrompts(true);
    props.setShowAcknowledgeRefreshPopup(false);
  };

  const btnModalCloseClick = () => {
    props.setShowAcknowledgeRefreshPopup(false);
  };

  return (
    <MotifModal
      id="motifModalAcknowledgeRefresh"
      className="motif-modal-confidential"
      size="md"
      show={true}
      focusTrapOptions={{
        tabbableOptions: {
          displayCheck: "none",
        },
      }}
      onClose={btnModalCloseClick}
    >
      <div className="ii-acknowledgement-header-div">
        <MotifModalHeader
          closeButtonProps={{
            "aria-label": "Custom Close Button aria-label",
            title: "Close popup",
          }}
        >
          <div className="ii-acknowledgement-header-icon-div">
            <img className="icon-refresh" src={refreshIcon}></img>
          </div>
        </MotifModalHeader>
        <div className="ii-acknowledgement-title">Insights refreshed</div>
      </div>

      <MotifModalBody>
        <div className="info-div">
          {props.source === "InstantInsight" && (
            <div>
              Select acknowledge to remove refresh icon. Please review any text
              selected as Favourite and edit Favourite selection if required.
            </div>
          )}
          {props.source === "ProjectWorkspace" && (
            <div>
              This insight has been refreshed within Instant Insights. Select
              'Acknowledge' to remove the refresh icon.
            </div>
          )}
        </div>
      </MotifModalBody>
      <MotifModalFooter>
        <div className="btns-div">
          <div>
            <MotifButton
              id="btnCancel"
              variant="primary-alt"
              onClick={btnModalCloseClick}
            >
              Cancel
            </MotifButton>
          </div>
          <div>
            <MotifButton
              id="btnAcknowledge"
              onClick={btnAcknowledgeRefreshClick}
            >
              Acknowledge
            </MotifButton>
          </div>
        </div>
      </MotifModalFooter>
    </MotifModal>
  );
}

AcknowledgeRefresh.propTypes = {
  setShowAcknowledgeRefreshPopup: PropTypes.func.isRequired,
  setRefreshPrompts: PropTypes.func.isRequired,
};
