import React, { useEffect, useState } from 'react';
import { Outlet, useNavigate } from 'react-router-dom';
import DeepLink from './lib/deepLinks';
import DeepLinkService from './services/deepLinkService';

const DeepLinkManager = () => {
  const navigate = useNavigate();
  const deepLinkService = new DeepLinkService();
  const [loading, setLoading] = useState(true);
  const [resolved, setResolved] = useState(false);

  useEffect(() => {
    if (!resolved) {
      const deepLink = DeepLink();
      const link = deepLink.getDeepLink();
      if (link) {
        const project_UUID = deepLink.getDeepLinkDataRequirements()["project_UUID"];
        deepLinkService.GetProjectDetailById(project_UUID)
          .then((data) => {
            if (data.length > 0) {
              const datareturn = data[0];
              sessionStorage.setItem("DashboardProjectName", datareturn.projectName);
              sessionStorage.setItem("DashboardProjectId", project_UUID);
              setResolved(true);
              navigate(link, { replace: true });
            }
            else {
              setResolved(false);
            }
          })
          .catch((error) => {
            console.error('Failed to fetch project details:', error);
            setResolved(false);
          })
          .finally(() => {
            setLoading(false);
          })
      }
      else {
        setLoading(false);
        setResolved(true);
      }
    }

  }, [navigate, resolved]);

  if (loading) {
    return <div>Loading...</div>;
  }
  
  if (resolved) {
    return <Outlet />;
  }

  return null;

};

export default DeepLinkManager;