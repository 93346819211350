import React, { useEffect, useState } from 'react';
import MotifAccordion, { MotifAccordionTrigger, MotifAccordionContent } from '@ey-xd/motif-react/Accordion';
import { MotifCheckbox, MotifButton, MotifDatePicker, MotifFormField, MotifLabel, MotifSelect, MotifOption } from '@ey-xd/motif-react';
import './FilterPlatformAdmin.css';
const FilterPlatformAdmin = ({ methodname, displayMenu, reloadGridData, filterData }) => {

    const [dateRange, setdateRange] = useState([]);
    const [selectedStartDate, setSelectedStartDate] = useState("");
    const [selectedEndDate, setSelectedEndDate] = useState("");
    const projectMasterData = require("../../data/projectMasterData.js");
    const [projectStatusList, setprojectStatusList] = useState([]);
    const [area, setArea] = useState([]);
    const [areaList, setAreaList] = useState([]);
    const [region, setRegion] = useState([]);
    const [regionList, setRegionList] = useState([]);
    const [country, setCountry] = useState([]);
    const [countryList, setCountryList] = useState([]);

    const [dealSizeList, setDealSizeList] = useState([]);
    const [targetSector, setTargetSector] = useState([]);
    const [targetSectorList, setTargetSectorList] = useState([]);
    const [targetSubSector, setTargetSubSector] = useState([]);
    const [targetSubSectorList, setTargetSubSectorList] = useState([]);
    const [targetSubSubSector, setTargetSubSubSector] = useState([]);
    const [targetSubSubSectorList, setTargetSubSubSectorList] = useState([]);
    const [dealSizeChkAll, setdealSizeChkAll] = useState(false);
    const [isdealCheck, setIsdealCheck] = useState([]);

    const [projectStatusChkAll, setprojectStatusChkAll] = useState(false);
    const [isprojectStatusChk, setprojectStatusChk] = useState([]);

    useEffect(() => {
        if (filterData.length !== 0) {

            // start and end date
            setdateRange([filterData.startDate, filterData.endDate]);
            setSelectedStartDate(filterData.startDate);
            setSelectedEndDate(filterData.endDate);

            // status
            let filteredStatus = Array.from(new Set(filterData.status));
            setprojectStatusChk(filteredStatus);
            if (filterData.status.includes('Open') && filterData.status.includes('Closed')) {
                setprojectStatusChkAll(true);
            }

            // sector
            setTargetSector(filterData.sector);

            // sub-sector
            setTargetSubSectorList(
                projectMasterData.SubSectorConfig.filter(
                    (subsector) => filterData.sector.includes(subsector.sectorId)
                )
            );
            setTargetSubSector(filterData.subsector);

            // sub-sub-sector
            setTargetSubSubSectorList(
                projectMasterData.SubSubSectorConfig.filter(
                    (subsector) => filterData.subsector.includes(subsector.id)
                )
            );
            setTargetSubSubSector(filterData.subsubsector);

            // area
            setArea(filterData.area);

            // region
            setRegionList(projectMasterData.RegionConfig.filter(
                (region) => filterData.area.includes(region.areaId)
            ));
            setRegion(filterData.region);

            // country
            setCountryList(projectMasterData.CountryConfig.filter(
                (country) => filterData.region.includes(country.regionId)
            ));
            setCountry(filterData.country);

            // dealSize
            setIsdealCheck(filterData.dealSize);
        }
    }, [])

    useEffect(() => {

        setTargetSectorList(projectMasterData.SectorConfig);
        setAreaList(projectMasterData.AreaConfig);
        setDealSizeList(projectMasterData.DealConfig);
        setprojectStatusList(projectMasterData.ProjectStatusConfig);
    });

    function clearSelection() {
        setdateRange("");
        setSelectedStartDate('');
        setSelectedEndDate('');
        setTargetSector([]);
        setArea([]);
        setRegion([]);
        setCountry([]);
        setTargetSubSector([]);
        setTargetSubSubSector([]);
        setdealSizeChkAll(false);
        setprojectStatusChkAll(false);
        setIsdealCheck([]);
        setprojectStatusChk([]);
        setTargetSubSectorList([]);
        setTargetSubSubSectorList([]);
        setRegionList([]);
        setCountryList([]);
        reloadGridData();
    }
    function dateFormatDateRange(createdOnDatetime) {
        const d = new Date(createdOnDatetime);
        let day = d.getDate()
        let month = d.getMonth() + 1;
        let year = d.getFullYear();
        let finalDate = month + '/' + day + '/' + year;
        return finalDate;
    }
    function handleDateRange(e) {
        let startDate = new Date(dateFormatDateRange(e[0]));
        let endDate = new Date(dateFormatDateRange(e[1]));
        setSelectedStartDate(startDate);
        setSelectedEndDate(endDate);
        setdateRange([startDate, endDate]);
    }

    const handleClickprojectStatus = e => {
        const { id } = e.target;
        if (isprojectStatusChk.includes(id)) {
            setprojectStatusChk(isprojectStatusChk.filter(item => item !== id));
        }
        else {
            setprojectStatusChk([...isprojectStatusChk, id]);
        }

        if (isprojectStatusChk.length !== projectStatusList.length) {
            setprojectStatusChkAll(false);
        }
    };

    useEffect(() => {
        if (isprojectStatusChk.includes('Open') && isprojectStatusChk.includes('Closed')) {
            setprojectStatusChkAll(true);
        }
    }, [isprojectStatusChk])

    const onprojectStatusChkAllChange = (event) => {
        setprojectStatusChkAll(!projectStatusChkAll);
        setprojectStatusChk(projectStatusList.map(li => li.id));
        if (projectStatusChkAll) {
            setprojectStatusChk([]);
        }

    }


    const handleClick = e => {
        const { id } = e.target;
        if (isdealCheck.includes(id)) {
            setIsdealCheck(isdealCheck.filter(item => item !== id));
        }
        else {
            setIsdealCheck([...isdealCheck, id]);
        }


    };

    const ondealSizeChkAllChange = (event) => {
        setdealSizeChkAll(!dealSizeChkAll);
        setIsdealCheck(dealSizeList.map(li => li.id));
        if (dealSizeChkAll) {
            setIsdealCheck([]);
        }

    }

    const onAreaChange = (data) => {
        setRegion([]);
        setCountry([]);
        setCountryList([]);
        setRegionList([]);
        if (data !== null) {
            let selectedArea = areaList.find((area) => data.includes(area.id));
            if (selectedArea) {
                setArea(data);
                setRegionList(
                    projectMasterData.RegionConfig.filter(
                        (region) => data.includes(region.areaId)
                    )
                );
            }
        }
    }

    const onRegionChange = (data) => {
        setCountry([]);
        setCountryList([]);
        if (data !== null) {

            let selectedRegion = regionList.find((region) => data.includes(region.id));
            if (selectedRegion) {
                setRegion(data);
                setCountryList(
                    projectMasterData.CountryConfig.filter(
                        (country) => data.includes(country.regionId)
                    ).sort(function (a, b) {
                        const textA = a.content.toUpperCase();
                        const textB = b.content.toUpperCase();
                        if(textA < textB){
                            return -1;
                        } else if(textA > textB){
                            return 1;
                        } else {
                            return 0;
                        }
                    })
                );
            }
        }

    }
    const onCountryChange = (e) => {
        setCountry(e);

    }

    const onTargetsectorChange = (data) => {

        setTargetSubSector([]);
        setTargetSubSubSector([]);
        setTargetSubSectorList([]);
        setTargetSubSubSectorList([]);
        if (data !== null) {

            let selectedTarget = targetSectorList.find((target) => data.includes(target.id));
            if (selectedTarget) {
                setTargetSector(data);
                setTargetSubSectorList(
                    projectMasterData.SubSectorConfig.filter(
                        (subsector) => data.includes(subsector.sectorId)
                    )
                );
            }
        }
    }

    const onSubSectorChange = (data) => {
        setTargetSubSubSector([]);
        setTargetSubSubSectorList([]);
        if (data !== null) {
            let selectedsubSector = targetSubSectorList.find((target) => data.includes(target.id));
            if (selectedsubSector) {
                setTargetSubSector(data);
                setTargetSubSubSectorList(
                    projectMasterData.SubSubSectorConfig.filter(
                        (subsector) => data.includes(subsector.sectorId)
                    )
                );
            }
        }

    }


    const onSubSubSectorChange = (data) => {
        setTargetSubSubSector(data);

    }

    function handleApplyClick() {
        let filter = {
            country: country,
            area: area,
            region: region,
            sector: targetSector,
            subsector: targetSubSector,
            subsubsector: targetSubSubSector,
            dealSize: isdealCheck,
            status: isprojectStatusChk,
            startDate: selectedStartDate,
            endDate: selectedEndDate
        }

        methodname(filter);
        displayMenu();
    }


    return (
        <div className='filterwrapper'
        >
            <div
                className='filterwrapperinsider'
            >
                <div
                    className="Frame5407"
                >
                    <div
                        className="Frame5454"
                    >
                        <div
                            className="Frame5406"
                        >
                            <div className='filterwrapperdiv1'
                            >
                                <div className='filterwrapperdiv2'
                                >
                                    <div className='filterwrapperdiv3'
                                    >
                                        <div
                                            className="TextAndSupportingText"
                                        >
                                            <div
                                                className="filterText"
                                            >
                                                Filter Projects
                                            </div>
                                        </div>
                                    </div>
                                    <div
                                        className="XClose"
                                        onClick={clearSelection}
                                    >
                                        Clear

                                    </div>
                                </div>

                            </div>
                            <div className='dateRangediv'
                            >
                                Select Date range
                                <MotifFormField >
                                    <MotifLabel>
                                        Start date - End date
                                    </MotifLabel>
                                    <MotifDatePicker
                                        label='selectdate'
                                        clearIcon={null}
                                        dayPlaceholder="DD"
                                        format="MM-dd-yyyy"
                                        monthPlaceholder="MM"
                                        range="true"
                                        value={dateRange}
                                        yearPlaceholder="YYYY"
                                        onChange={(e) => { handleDateRange(e) }}
                                    />
                                </MotifFormField>

                            </div>
                            <div className='accordiandiv'
                            >
                                <MotifAccordion alignIconRight='true'
                                    useChevronIcon='true' className='MotifAccordiondiv'>
                                    <MotifAccordionTrigger>
                                        Status
                                    </MotifAccordionTrigger>
                                    <MotifAccordionContent>
                                        <MotifCheckbox
                                            label='chkstatus'
                                            id="chkStatusAll"
                                            name="chkStatusAll"
                                            onChange={(e) => { onprojectStatusChkAllChange(e) }}
                                            value="projectStatusSelectAll"
                                            checked={projectStatusChkAll}
                                        >
                                            Select All
                                        </MotifCheckbox>
                                        {projectStatusList.map(item => {
                                            return (
                                                <MotifCheckbox
                                                    className={item.content}
                                                    key={item.id}
                                                    id={item.id}
                                                    value={item.id}

                                                    onChange={(e) => { handleClickprojectStatus(e) }}
                                                    checked={isprojectStatusChk.includes(item.id)}

                                                >
                                                    {item.content}
                                                </MotifCheckbox>
                                            );
                                        })}                                    </MotifAccordionContent>
                                </MotifAccordion>

                            </div>
                            <div
                                className="SectorAccordionMultiLeveldiv"
                            >
                                <MotifAccordion alignIconRight='true'
                                    useChevronIcon='true' className='MotifAccordiondiv'>
                                    <MotifAccordionTrigger>
                                        Sector
                                    </MotifAccordionTrigger>
                                    <MotifAccordionContent>

                                        <div className="Label"> Sector</div>
                                        <div className='sectorStyle'>
                                            <MotifFormField>
                                                <MotifSelect
                                                    label='selectSector'
                                                    id="ddlSector"
                                                    ariaLabelledBy="select-label"

                                                    onChange={(e) => { onTargetsectorChange(e) }}

                                                    value={targetSector}
                                                    multiple='true'
                                                    showSelectAllButton='true'
                                                    i18n={{ deselect: "DeSelect All", select: "Select All" }}
                                                    visibleOptions={5}
                                                >

                                                    {targetSectorList.map(item => {
                                                        return (
                                                            <MotifOption className={item.content} key={item.id} value={item.id}>
                                                                {item.content}
                                                            </MotifOption>
                                                        );
                                                    })}



                                                </MotifSelect>
                                            </MotifFormField>
                                        </div>
                                        <div className="Label"> Sub-Sector</div>
                                        <div className='subSectorStyle'>
                                            <MotifFormField>
                                                <MotifSelect
                                                    label='selectSubSector'
                                                    id="ddlSubSector"
                                                    ariaLabelledBy="select-label"
                                                    onChange={(e) => { onSubSectorChange(e) }}
                                                    value={targetSubSector}
                                                    multiple={targetSubSectorList.length > 0}
                                                    showSelectAllButton={targetSubSectorList.length > 0}
                                                    i18n={{ deselect: "DeSelect All", select: "Select All" }}
                                                    visibleOptions={5}
                                                >

                                                    {targetSubSectorList.map(item => {
                                                        return (
                                                            <MotifOption className={item.content} key={item.id} value={item.id}>
                                                                {item.content}
                                                            </MotifOption>
                                                        );
                                                    })}


                                                </MotifSelect>
                                            </MotifFormField>
                                        </div>
                                        <div className="Label">Sub-sub sector</div>
                                        <div className='subsubSectorStyle'>
                                            <MotifFormField>
                                                <MotifSelect
                                                    label='selectSubSubSector'
                                                    id="ddlSubSubSector"
                                                    ariaLabelledBy="select-label"
                                                    onChange={(e) => { onSubSubSectorChange(e) }}
                                                    value={targetSubSubSector}
                                                    multiple={targetSubSectorList.length > 0}
                                                    showSelectAllButton={targetSubSectorList.length > 0}
                                                    i18n={{ deselect: "DeSelect All", select: "Select All" }}
                                                    visibleOptions={5}
                                                >

                                                    {targetSubSubSectorList.map(item => {
                                                        return (
                                                            <MotifOption className={item.content} key={item.id} value={item.id}>
                                                                {item.content}
                                                            </MotifOption>
                                                        );
                                                    })}


                                                </MotifSelect>
                                            </MotifFormField>
                                        </div>
                                    </MotifAccordionContent>
                                </MotifAccordion>

                            </div>
                            <div
                                className="LocationAccordionMultiLeveldiv"
                            >

                                <MotifAccordion alignIconRight='true'
                                    useChevronIcon='true' className='MotifAccordiondiv'>
                                    <MotifAccordionTrigger>
                                        Location
                                    </MotifAccordionTrigger>
                                    <MotifAccordionContent>

                                        <div className="Label"> Area</div>
                                        <div className='areaStyle'>
                                            <MotifFormField>
                                                <MotifSelect id='ddlArea'
                                                    label='selectArea'
                                                    ariaLabelledBy="select-label"
                                                    onChange={(e) => { onAreaChange(e) }}
                                                    value={area}
                                                    multiple={areaList.length > 0}
                                                    showSelectAllButton={areaList.length > 0}
                                                    i18n={{ deselect: "DeSelect All", select: "Select All" }}
                                                    visibleOptions={5}
                                                >

                                                    {areaList.map(item => {
                                                        return (
                                                            <MotifOption className={item.content} key={item.id} value={item.id}>
                                                                {item.content}
                                                            </MotifOption>
                                                        );
                                                    })}


                                                </MotifSelect>
                                            </MotifFormField>
                                        </div>
                                        <div className="Label"> Region</div>
                                        <div className='regionStyle'>
                                            <MotifFormField>
                                                <MotifSelect id='ddlRegion'
                                                    label='selectRegion'
                                                    ariaLabelledBy="select-label"
                                                    onChange={(e) => { onRegionChange(e) }}
                                                    value={region}
                                                    multiple={regionList.length > 0}
                                                    showSelectAllButton={regionList.length > 0}
                                                    i18n={{ deselect: "DeSelect All", select: "Select All" }}
                                                    visibleOptions={5}
                                                >

                                                    {regionList.map(item => {
                                                        return (
                                                            <MotifOption className={item.content} key={item.id} value={item.id}>
                                                                {item.content}
                                                            </MotifOption>
                                                        );
                                                    })}
                                                </MotifSelect>
                                            </MotifFormField>
                                        </div>

                                        <div className="Label">Country</div>
                                        <div className='countryStyle'>
                                            <MotifFormField>
                                                <MotifSelect id='ddlCountry'
                                                    label='selectCountry'
                                                    ariaLabelledBy="select-label"
                                                    onChange={(e) => { onCountryChange(e) }}
                                                    value={country}
                                                    multiple={countryList.length > 0}
                                                    showSelectAllButton={countryList.length > 0}
                                                    i18n={{ deselect: "DeSelect All", select: "Select All" }}
                                                    visibleOptions={5}
                                                >

                                                    {countryList.map(item => {
                                                        return (
                                                            <MotifOption className={item.content} key={item.id} value={item.id}>
                                                                {item.content}
                                                            </MotifOption>
                                                        );
                                                    })}

                                                </MotifSelect>
                                            </MotifFormField>
                                        </div>
                                    </MotifAccordionContent>
                                </MotifAccordion>
                            </div>
                            <div
                                className="DealSizeAccordiondiv"
                            >
                                <MotifAccordion alignIconRight='true'
                                    useChevronIcon='true' className='MotifAccordiondiv'>
                                    <MotifAccordionTrigger>
                                        Deal Size
                                    </MotifAccordionTrigger>
                                    <MotifAccordionContent>
                                        <MotifCheckbox
                                            label='selectDealSize'
                                            id="chkDealAll"
                                            name="chkDealAll"
                                            onChange={(e) => { ondealSizeChkAllChange(e) }}
                                            value="DealSizeSelectAll"
                                            checked={dealSizeChkAll}
                                        >
                                            Select All
                                        </MotifCheckbox>
                                        {dealSizeList.map(item => {
                                            return (
                                                <MotifCheckbox
                                                    className={item.content}
                                                    key={item.id}
                                                    id={item.id}
                                                    value={item.id}
                                                    onChange={(e) => { handleClick(e) }}
                                                    checked={isdealCheck.includes(item.id)}

                                                >
                                                    {item.content}
                                                </MotifCheckbox>
                                            );
                                        })}


                                    </MotifAccordionContent>
                                </MotifAccordion>


                            </div>
                        </div>
                    </div>
                    <div
                        className="Buttondiv1"
                    >

                        <div
                            className="Closediv"
                        >
                            <MotifButton 
                            variant='secondary'
                            onClick={displayMenu}>
                                Close
                            </MotifButton>

                        </div>
                        <div
                            className="Buttondiv"
                        >
                            <MotifButton onClick={handleApplyClick}>
                                Apply
                            </MotifButton>
                        </div>
                    </div>
                </div>
            </div>
        </div>)
}
export default FilterPlatformAdmin;
