import {
  MotifButton,
  MotifErrorMessage,
  MotifInput,
  MotifModal,
  MotifModalBody,
  MotifModalFooter,
  MotifModalHeader,
} from "@ey-xd/motif-react";
import FormField from "@ey-xd/motif-react/components/forms/form-field";
import React, { useEffect, useState } from "react";
import Utils from "../../../../utils/Utils";
import WorkSpaceService from "../../../../services/workspaceService";

const CreateSectionModal = (props) => {
  let utils = new Utils();
  const workspaceService = new WorkSpaceService();
  const [sectionTitle, setSectionTitle] = useState("");
  const [saveButtonDisable, setSaveButtonDisable] = useState(true);
  const [isContainSpecialCharacters, setIsContainSpecialCharacters] =
    useState(false);
  const [isMaxLengthExceeded, setIsMaxLengthExceeded] = useState(false);
  const [isTitleExists, setIsTitleExists] = useState(false);

  useEffect(() => {
    if (sectionTitle) {
      setSaveButtonDisable(false);
      setIsTitleExists(false);
      if (utils.isContainSpecialCharacters(sectionTitle)) {
        setSaveButtonDisable(true);
        setIsContainSpecialCharacters(true);
      } else {
        setIsContainSpecialCharacters(false);
      }

      if (sectionTitle.length > 30) {
        setSaveButtonDisable(true);
        setIsMaxLengthExceeded(true);
      } else {
        setIsMaxLengthExceeded(false);
      }
    } else {
      setSaveButtonDisable(true);
    }
  }, [sectionTitle]);

  /**
   * This method is to save the section
   */
  const saveSection = async () => {
    let response = await workspaceService.addSection(
      sessionStorage.getItem("DashboardProjectId"),
      sectionTitle
    );
    if (response > 0) {
      props.setShowSectionModal(true);
    } else if (response === -1) {
      setIsTitleExists(true);
    }
  };

  return (
    <MotifModal
      id="motifModalAddSection"
      className="motif-modal-confidential"
      size="sm"
      show={true}
      focusTrapOptions={{
        tabbableOptions: {
          displayCheck: "none",
        },
      }}
      onClose={() => {
        props.setShowSectionModal(false);
      }}
    >
      <MotifModalHeader
        closeButtonProps={{
          "aria-label": "Custom Close Button aria-label",
          title: "Close popup",
        }}
      >
        <div>Section title</div>
      </MotifModalHeader>
      <MotifModalBody>
        <FormField className="create-section-div">
          <span className="create-section-title-span">Title</span>
          <MotifInput
            className="section-input"
            onChange={(e) => setSectionTitle(e.target.value)}
            type="text"
            value={sectionTitle}
            placeholder="Type"
            hideClearButton={true}
          />
          {isContainSpecialCharacters && (
            <MotifErrorMessage>
              Special characters not allowed
            </MotifErrorMessage>
          )}
          {isMaxLengthExceeded && (
            <MotifErrorMessage>
              Maximum limit reached - 30 characters
            </MotifErrorMessage>
          )}
          {isTitleExists && (
            <MotifErrorMessage>Title already exists</MotifErrorMessage>
          )}
        </FormField>
      </MotifModalBody>
      <MotifModalFooter>
        <div className="btns-div">
          <div>
            <MotifButton
              variant="primary-alt"
              onClick={() => props.setShowSectionModal(false)}
            >
              Cancel
            </MotifButton>
          </div>
          <div>
            <MotifButton
              disabled={saveButtonDisable}
              onClick={() => saveSection()}
            >
              Save
            </MotifButton>
          </div>
        </div>
      </MotifModalFooter>
    </MotifModal>
  );
};
export default CreateSectionModal;
