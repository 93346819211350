import React from "react";
import { MotifButton } from "@ey-xd/motif-react";
import MotifModal, {
  MotifModalHeader,
  MotifModalBody,
  MotifModalFooter,
} from "@ey-xd/motif-react/Modal";
import "../../dashboard/ClosePopup.css";
import MemberService from "../../../services/memberService";
import icon_warning from "../../../assets/image/warning-circle.svg";
import "../InstantInsights/InstantInsights.css";

export default function DeleteConfirm(props) {
    const memberService = new MemberService();

  const btnConfirmClick = async () => {   
    props.setshowDeletedComplete(true);
    props.setShowDeleteConfirmPopup(false);
  };

  const btnModalCloseClick = () => {
    props.setShowDeleteConfirmPopup(false);
  };

  return (
    <MotifModal
      id="motifModalAcknowledgeRefresh"
      className="motif-modal-confidential"
      size="md"
      show={true}
      focusTrapOptions={{
        tabbableOptions: {
          displayCheck: "none",
        },
      }}
      onClose={btnModalCloseClick}
    >
      <div className="ii-acknowledgement-header-div">
        <MotifModalHeader
          closeButtonProps={{
            "aria-label": "Custom Close Button aria-label",
            title: "Close popup",
          }}
        >
          <div className="ii-acknowledgement-header-icon-div">
            <img className="icon-refresh" src={icon_warning}></img>
          </div>
        </MotifModalHeader>
        <div className="ii-acknowledgement-title">Remove member</div>
      </div>

      <MotifModalBody>
        <div className="info-div">        
            <div >
              Click 'Confirm' to remove <b>{props.memberName}</b> from the project 
            </div>        
        </div>
      </MotifModalBody>
      <MotifModalFooter>
        <div className="btns-div">
          <div>
            <MotifButton
              id="btnCancel"
              variant="primary-alt"
              onClick={btnModalCloseClick}
            >
              Cancel
            </MotifButton>
          </div>
          <div>
            <MotifButton
              id="btnAcknowledge"
              onClick={btnConfirmClick}
            >
              Confirm
            </MotifButton>
          </div>
        </div>
      </MotifModalFooter>
    </MotifModal>
  );
}

