import MotifModal, { MotifModalHeader, MotifModalBody } from '@ey-xd/motif-react/Modal';
import { MotifInput, MotifModalFooter } from "@ey-xd/motif-react";
import MotifButton from '@ey-xd/motif-react/Button';
import React, { useState } from "react";
import information from "../../../assets/image/information.svg";
const prompt = require("../../../data/model/prompt.js");
export function BadgePopup({ setOpenModal, promptdata = prompt.PromptData, setconfirm }) {
    const [open, setOpen] = useState(true);
    const [prompt, setPrompt] = useState(promptdata.prompttext);
    const [title, setTitle] = useState("");
    const [tag] = useState("");
    /**
   * Description
   * -----------
   * This method is to close popup once the user clicks cross button or cancel
   */
    const onCloseClick = () => {
        setOpen(false);
        setOpenModal(false);
    }
    /**
  * Description
  * -----------
  * This method is to get the prompt that user has given in popup
  * Parameters:
  * e: this one is onChange event parameter to get the input title
  */
    const onPromptChange = (e) => {
        setPrompt(e.target.value);
    };
    /**
    /**
   * Description
   * -----------
   * This method is to get the title that user has given in popup
   * Parameters:
   * e: this one is onChange event parameter to get the input title
   */
    const onTitleChange = (e) => {
        if (e.target.value.length <= 500) {
            setTitle(e.target.value);
        }
    };   
    /**
   * Description
   * -----------
   * This method is to close popup and insert data into database once the user clicks confirm button
   */
    const onConfirmClick = () => {
        promptdata.prompttext = prompt;
        promptdata.title = title;
        promptdata.tags = tag;
        setconfirm(promptdata);
        setOpen(false);
        setOpenModal(false);
    }


    return (

        <div >
            <MotifModal id="badgepopupmodal" show={open} size="md" focusTrapOptions={{
                tabbableOptions: {
                    displayCheck: 'legacy-full'
                }
            }}
                onClose={() => { onCloseClick() }}
                className="genericModal modalHeaderVerticalPadding modalMotifFooterModal"
            >
                <MotifModalHeader
                    closeButtonProps={{
                        'aria-label': 'Close',
                        title: 'Close'
                    }}
                    className="add-prompt-modal-header"
                >
                    Add to Personal Prompt Library
                </MotifModalHeader>
                <MotifModalBody>
                    <React.Fragment>
                        <div className="aligned">
                        <img src={information} alt='icon'/>
                                <span className='spaninfo'>Please ensure confidential information is not included in the prompt below.</span>
                        </div>

                        <div id="divspace">
                            <span >
                                Prompt
                            </span>
                        </div>
                        <div id="divspace">
                            <span>
                                <MotifInput
                                    label="inputprompt"
                                    onChange={onPromptChange}
                                    type='text'
                                    value={prompt}
                                />
                            </span>
                        </div>
                        <div id="divspace">
                            <span >
                                Title
                            </span>
                        </div>
                        <div id="divspace">
                            <span >
                                <MotifInput
                                    label="inputtitle"
                                    onChange={onTitleChange}
                                    type='text'
                                    value={title}
                                />
                            </span>
                        </div>                        
                    </React.Fragment>
                </MotifModalBody>
                <MotifModalFooter>
                    <React.Fragment >
                        <div className="BadgeFooterButtonContent" >
                            <div class="EditProjectEditTitle" >
                                <div class="EditProjectEditTextAndSupportingText" >
                                    <div class="EditProjectProjectHeaderText" >
                                    </div>
                                </div>
                            </div>
                            <div class="EditProjectEditActions" >
                                <div class="EditProjectEditCancelButton" >
                                    <div class="EditProjectEditCancelButtonBase" >
                                        <div class="EditProjectEditCancelText" >
                                            <MotifButton
                                                className=""
                                                onClick={() => { onCloseClick() }}
                                                variant="secondary"
                                            >
                                                Cancel
                                            </MotifButton>
                                        </div>
                                    </div>
                                </div>

                                <div class="EditProjectEditSaveButton" >
                                    <div class="EditProjectEditSaveButtonBase" >
                                        <div >
                                            <MotifButton
                                                onClick={() => { onConfirmClick() }}
                                            >
                                                Save

                                            </MotifButton>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </React.Fragment>

                </MotifModalFooter>

            </MotifModal>
        </div >
    )
}

export default BadgePopup;