export const explorerDataConstants = {
  Types : [
      { id: "id3", type: "PDF", actualType:'Pdf', checked: false },
      { id: "id4", type: "PPT", actualType:'Pptx', checked: false },
      { id: "id2", type: "DOC", actualType:'Docx', checked: false },
      { id: "id5", type: "TXT", actualType:'Txt', checked: false },
      { id: "id6", type: "XLS", actualType:'Xlsx', checked: false },
      { id: "id1", type: "All", actualType:'', checked: false }
    ],
  docComparator: 'docx',
  dropdownDocValue: 'doc',
  excelComparator: 'xlsx',
  dropdownExcelValue: 'excel',
}