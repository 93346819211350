import { Fragment, useEffect, useState, useContext } from "react";
import PromptService from "../../../services/promptService";
import PromptLibrary from "./PromptLibrary";
import DeletePromptModal from "./DeletePromptModal";
import EditPromptModal from "./EditPromptModal";
import ViewPromptModal from "./ViewPromptModal";
import { useNavigate } from "react-router-dom";
import {MotifToast } from "@ey-xd/motif-react";

// Component to render and handle all actions on Personal Prompt Library
const PersonalPromptLibrary = () => {
    const promptService = new PromptService();
    const [personalPrompts, setPersonalPrompts] = useState([]);
    const [showDeletePromptModal, setShowDeletePromptModal] = useState(false);
    const [selectedPrompt, setSelectedPrompt] = useState(null);
    const [showEditPromptModal, setShowEditPromptModal] = useState(false);
    const [showViewPromptModal, setShowViewPromptModal] = useState(false);
    const navigate = useNavigate();
  
  // Invoke API to fetch Personal prompts
    useEffect(() => {
        promptService.getPersonalPrompts().then((response) => {
            setPersonalPrompts(response);
        });
    }, []);

    // Invoke delete prompt API request and refresh the prompt library
    function handleDeletePrompt() {
        promptService.deletePersonalPrompt(selectedPrompt.promptId).then((response) => {
            promptService.getPersonalPrompts().then((response) => {
                setPersonalPrompts(response);
            });
            setShowDeletePromptModal(false);
        });
    }

    // Show/hide delete prompt modal
    function toggleDeletePromptModal(prompt) {
        setSelectedPrompt(prompt);
        setShowDeletePromptModal(!showDeletePromptModal);
    }

    // Invoke delete prompt API request and refresh the prompt library
    function handleEditPrompt() {
        promptService.editPersonalPrompt(selectedPrompt).then((response) => {
            promptService.getPersonalPrompts().then((response) => {
                setPersonalPrompts(response);
            });
            setShowEditPromptModal(false);
        });
    }

    // Show/hide delete prompt modal
    function toggleEditPromptModal(prompt) {
        setSelectedPrompt(prompt);
        setShowEditPromptModal(!showEditPromptModal);
    }

    // Upon edit prompt, update the selected prompt
    function onEditPromptChange(event) {
        let updatedPrompt = Object.assign({}, selectedPrompt);
        updatedPrompt.promptText = event.target.value;
        setSelectedPrompt(updatedPrompt);
    }

    // Show/hide view prompt modal
    function toggleViewPromptModal(prompt) {
        setSelectedPrompt(prompt);
        setShowViewPromptModal(!showViewPromptModal);
    }

    // Navigate user to explore page with current prompt text
    function handleUsePrompt(promptText){
        navigate("/ProjectDashboard/ExploreData", {state:{promptText: promptText}});
    }

    return (
        <Fragment>
            <PromptLibrary prompts={personalPrompts} toggleDeletePromptModal={toggleDeletePromptModal} 
                toggleEditPromptModal={toggleEditPromptModal} toggleViewPromptModal={toggleViewPromptModal}
                handleUsePrompt={handleUsePrompt}/>
            <DeletePromptModal showDeletePromptModal={showDeletePromptModal} toggleDeletePromptModal={toggleDeletePromptModal}
                handleDeletePrompt={handleDeletePrompt}/>
            <EditPromptModal showEditPromptModal={showEditPromptModal} toggleEditPromptModal={toggleEditPromptModal}
                handleEditPrompt={handleEditPrompt} selectedPrompt={selectedPrompt} onEditPromptChange={onEditPromptChange}/>
            <ViewPromptModal prompt={selectedPrompt} showViewPromptModal={showViewPromptModal} toggleViewPromptModal={toggleViewPromptModal}
                handleUsePrompt={handleUsePrompt}/>
        </Fragment>
        
    );
}

export default PersonalPromptLibrary;