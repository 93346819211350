import React, { useRef, useCallback, useEffect, useState } from "react";
import { addHighlight } from "../../lib/addHighlight";
import {
  getOriginalRange,
  getAllSelectedNodes,
  getAllNodesRanges,
} from "../../lib/createRange";
import { deserializeRange, serializeRange } from "../../lib/serialize";
import { sortByPositionAndOffset } from "../../lib/sort";

const TextHighlighter = ({
  htmlString,
  maxSelectionLength,
  minSelectionLength,
  className,
  selectAll,
  onClearSelectAll,
  onSelection,
  currentSelection,
  clearSelection,
  allowSelection
}) => {
  const [selections, setSelections] = useState([]);
  const defaultSelectionWrapperClassName = "bg-blue relative select-none";
  const defaultMinSelectionLength = 3;

  const rootRef = useRef(null);
  const tempRef = useRef(null);
  const div = document.createElement("div");
  tempRef.current = div;
  tempRef.current.innerHTML = htmlString;

  const getWrapper = useCallback((selection) => {
    const span = getSpanElement({
      className: selection.className || defaultSelectionWrapperClassName,
    });
    span.id = selection.id;
    return span;
  }, []);

  /**
   * This method selects the text and create a range selection
   */
  const handleMouseUp = () => {
    if(!allowSelection){
      return;
    }
    const selection = window.getSelection();

    if (!selection) return;
    if (!minSelectionLength) {
      minSelectionLength = defaultMinSelectionLength;
    }
    if (minSelectionLength && selection.toString().length < minSelectionLength)
      return;
    if (maxSelectionLength && selection.toString().length > maxSelectionLength)
      return;

    createSelection(selection.getRangeAt(0));
  };

  /**
   * This method create selection object which contains the selected text and meta information and adds in selection array
   */

  const createSelection = useCallback((range) => {
    const listRange = getAllSelectedNodes(range, tempRef.current);
    setSelectionForNodes(listRange);
  }, []);

  const setSelectionForNodes = (ranges) => {
    let selectionList = [];
    if (ranges.length) {
      ranges.forEach((range) => {
        const expRange = getOriginalRange(range, tempRef.current);
        if (!expRange) return;
        const newSelection = {
          meta: serializeRange(expRange, tempRef.current),
          id: `selection-${Date.now()}-${Math.random()
            .toString(36)
            .substring(2, 9)}`,
        };
        selectionList.push(newSelection);
      });
    }
    addSelection(selectionList);
  };
  /**
   * This method selects the entire text of the htmlString and make selection array.
   */
  useEffect(() => {
    if (selectAll) {
      setSelections([]);
      setSelectAllNodes();
    } 
  }, [selectAll, createSelection]);

  /**
   * This useEffect check the current selection which passed as a prop if it's available then it will add into selections state
   */
  useEffect(() => {
    if (!rootRef.current) return;
    rootRef.current.innerHTML = "";
    rootRef.current.innerHTML = htmlString;
    setSelections([]);
    if (currentSelection) {
      const parsedSelections = JSON.parse(currentSelection);
      if (parsedSelections.length && parsedSelections[0]?.selectAll) {
        setSelectAllNodes();
      } else {
        setSelections(parsedSelections);
      }
    }
  }, [htmlString, currentSelection, clearSelection]);

  /**
   * This useEffect highlight the text if selections state contains the text values
   */
  useEffect(() => {
    if (!rootRef.current) return;
    rootRef.current.innerHTML = "";
    rootRef.current.innerHTML = htmlString;
    const sortedSelections = sortByPositionAndOffset(selections);
    if (sortedSelections && sortedSelections.length) {
      for (let i = 0; i < sortedSelections.length; i++) {
        const item = sortedSelections[i];
        const range = deserializeRange(item.meta, rootRef.current);
        if (range) {
          addHighlight(range, getWrapper(item));
        }
      }
    }
    if (selectAll) {
      onClearSelectAll();
    }
    if (selectAll) {
      onSelection([{ selectAll: true }]);
    } else {
      onSelection(selections);
    }
  }, [selections]);

  const setSelectAllNodes = () => {
    const listRange = getAllNodesRanges(tempRef.current);
    setSelectionForNodes(listRange);
  };

  /**
   * This method add css styles to highligted text
   */
  const getSpanElement = ({ className }) => {
    const span = document.createElement("span");
    if (className) {
      span.className = className;
    }
    return span;
  };

  /**
   * This method adds the selection object into selections state.
   */
  const addSelection = async (selection) => {
    setSelections((prev) => {
      const index = prev.findIndex((item) => item.id === selection.id);
      if (index === -1) {
        return [...prev, ...selection];
      }

      return prev;
    });
  };

  return <div ref={rootRef} onMouseUp={handleMouseUp} className={className} />;
};
export default TextHighlighter;
