import { Fragment, useEffect, useState } from "react";
import PromptService from "../../../services/promptService";
import PromptLibrary from "./PromptLibrary";
import ViewPromptModal from "./ViewPromptModal";
import { useNavigate } from "react-router-dom";

// Component to render and handle all actions on SaT Best Practice Prompt Library
export default function SatBestPracticePromptLibrary() {
    const promptService = new PromptService();
    const [satPrompts, setSatPrompts] = useState([]);
    const [selectedPrompt, setSelectedPrompt] = useState(null);
    const [showViewPromptModal, setShowViewPromptModal] = useState(false);
    const navigate = useNavigate();
    // Invoke API to fetch SaT Best Practice prompts
    useEffect(() => {
        promptService.getSaTBestPracticePrompts().then((response) => {
            setSatPrompts(response);
        });
    }, []);

    // Show/hide view prompt modal
    function toggleViewPromptModal(prompt) {
        setSelectedPrompt(prompt);
        setShowViewPromptModal(!showViewPromptModal);
    }

    // Navigate user to explore page with current prompt text
    function handleUsePrompt(promptText){
        navigate("/ProjectDashboard/ExploreData", {state:{promptText: promptText}});
    }
    
    return (
        <Fragment>
            <PromptLibrary prompts={satPrompts} toggleViewPromptModal={toggleViewPromptModal} handleUsePrompt={handleUsePrompt}/>
            <ViewPromptModal prompt={selectedPrompt} showViewPromptModal={showViewPromptModal} toggleViewPromptModal={toggleViewPromptModal}
            handleUsePrompt={handleUsePrompt}/>
        </Fragment>
    );
}