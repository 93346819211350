import { Fragment, useEffect, useRef, useState } from "react";
import { MotifCard, MotifCardHeader, MotifCardBody, MotifCardFooter, MotifButton, MotifChip } from "@ey-xd/motif-react";
import { MotifIconButton } from "@ey-xd/motif-react/Button";
import editpencil from '../../../assets/image/editpencil.svg';
import trash from '../../../assets/image/trash.svg';
import { PromptType } from "../../../data/constant";
import Utils from "../../../utils/Utils";

// Component to render an individual Prompt
export default function Prompt(props) {
    const dateHelper = new Utils();
    const [showViewMoreButton, setShowViewMoreButton] = useState(false);
    const promptSpanRef = useRef(null);

    // Show/hide view more button for prompt text
    useEffect(() => {
        setShowViewMoreButton(isEllipsisActive(promptSpanRef.current));
    }, [props]);

    // Find if the prompt text is truncated and displayed with ellipsis
    function isEllipsisActive(promptSpan) {
        return promptSpan.offsetHeight < promptSpan.scrollHeight;
    }

    return (
        <Fragment>
            <MotifCard className="prompt-card">
                <MotifCardHeader variant="alt" className="prompt-card-header">
                    {
                        props.prompt.promptType === PromptType.Personal &&
                        <div className="prompt-project-detail-div">
                            <span className="prompt-project-name">{props.prompt.projectName}</span>
                            <MotifChip className={props.prompt.projectStatus === "Open" ? "prompt-project-status-open" : "prompt-project-status-closed"}>
                                {props.prompt.projectStatus}
                            </MotifChip>
                            <span className="prompt-create-date">{dateHelper.convertDateToUTCTimeStampWithDayName(dateHelper.convertDateToUTCTimeStamp(props.prompt.createdDate, true), false)}</span>
                        </div>
                    }

                    <div id="promptTitle" className="motif-h4">
                        {props.prompt.promptTitle}
                    </div>
                </MotifCardHeader>
                <MotifCardBody>
                    <p className="motif-body prompt-card-body" >
                        <span id="promptText" className="motif-truncate motif-truncate-lines prompt-card-text" ref={promptSpanRef} >{props.prompt.promptText}</span>
                        {
                            showViewMoreButton &&
                            <MotifButton id="btnViewMore" variant="text" onClick={() => props.toggleViewPromptModal(props.prompt)}>view more</MotifButton>
                        }
                    </p>
                </MotifCardBody>
                <MotifCardFooter>
                    <div className="motif-row prompt-card-footer" >
                        <div className="motif-col-lg-7">
                            <MotifButton id="btnUsePrompt" type="button" className="prompt-card-use-prompt-button" onClick={() => props.handleUsePrompt(props.prompt.promptText)}>Use Prompt</MotifButton>
                        </div>
                        <div className="motif-col-lg-5">
                            {
                                props.prompt.promptType === PromptType.Personal &&
                                <Fragment>
                                    <MotifIconButton id="btnDeletePrompt" type="button" className="prompt-card-delete-button" onClick={() => props.toggleDeletePromptModal(props.prompt)}>
                                        <img src={trash} />
                                    </MotifIconButton>
                                    <MotifIconButton id="btnEditPrompt" type="button" className="prompt-card-edit-button" onClick={() => props.toggleEditPromptModal(props.prompt)}>
                                        <img src={editpencil} />
                                    </MotifIconButton>
                                </Fragment>
                            }
                        </div>
                    </div>
                </MotifCardFooter>
            </MotifCard>
        </Fragment>
    );
}