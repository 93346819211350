import React, { useState } from "react";
import MotifModal, {
  MotifModalHeader,
  MotifModalBody,
} from "@ey-xd/motif-react/Modal";
import { MotifModalFooter } from "@ey-xd/motif-react";
import MotifButton from "@ey-xd/motif-react/Button";
import "./ShareAlertPopup.css";
import { useNavigate } from "react-router-dom";

export function ShareAlertPopup({ onClose }) {
  const [open, setOpen] = useState(true);
  const navigate = useNavigate();

  /**
   * Description
   * -----------
   * This method is to close the close project popup
   */
  const onCloseClick = () => {
    setOpen(false);
    onClose(false);
  };

  /**
   * This methid redirect to Team Workspace
   */
  const redctToTeamWorkspace = () => {
    navigate("/ProjectDashboard/ProjectWorkspace/Team", {
      state: { tabId: "Team" },
    });
  };

  return (
    <div>
      <MotifModal
        id="secrionAlertmodal"
        className="secrionAlertmodal"
        show={open}
        focusTrapOptions={{
          tabbableOptions: {
            displayCheck: "none",
          },
        }}
        onClose={() => {
          onCloseClick();
        }}
      >
        <MotifModalHeader>
          <div className="share-alert-header-title">
            Share to Team Workspace
          </div>
        </MotifModalHeader>
        <MotifModalBody>
          <div className="shareAlertContentContainer">
            <span className="shareAlertContent">
              No sections available to select. Please create sections within the
              Team Workspace to continue.
            </span>
          </div>
        </MotifModalBody>
        <MotifModalFooter>
          <div className="share-alert-btns-div">
            <div>
              <MotifButton
                variant="primary-alt"
                className="share-alert-cancel-button"
                onClick={() => {
                  onCloseClick();
                }}
              >
                Close
              </MotifButton>
            </div>
            <div>
              <MotifButton
                className="share-alert-go-to-tw-button"
                onClick={() => {
                  redctToTeamWorkspace();
                }}
              >
                Go to Team Workspace
              </MotifButton>
            </div>
          </div>
        </MotifModalFooter>
      </MotifModal>
    </div>
  );
}

export default ShareAlertPopup;
