/**
 * this method highlights the text
 */
export const addHighlight = (range, element) => {
  if (!isHighlightable(range)) {
    return;
  }

  let exists = document.getElementById(element.id);
  if(window.location.href.includes("ExploreData")){
    exists = null;
  }

  if (exists) {
    exists.className = element.className;
    return;
  }

  element.appendChild(range.extractContents());
  range.insertNode(element);
  window.getSelection()?.removeAllRanges();
};

/**
 * This method checks the range is highlightable or not
 */
export const isHighlightable = (range) => {
  const contents = range.cloneContents();
  const hasp = [];
  contents.childNodes.forEach((item) => {
    if (item.nodeName === "P") {
      hasp.push(true);
    } else {
      hasp.push(false);
    }
  });
  return !hasp.includes(true);
};