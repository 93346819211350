import { useEffect, useState, useContext } from "react";
import { MotifTooltip, MotifToast } from "@ey-xd/motif-react";
import { MotifIconButton } from "@ey-xd/motif-react/Button";
import _ from "lodash";

import "../ExploreData/ExploreData.css";
import star from "../../../assets/image/star.svg";
import bookmarkempty from "../../../assets/image/bookmarkempty.svg";
import bookmarkemptyblack from "../../../assets/image/bookmarkemptyblack.svg";
import mailout from "../../../assets/image/mailout.svg";
import editpencil from "../../../assets/image/editpencil.svg";
import peopletag from "../../../assets/image/peopletag.svg";
import infoEmpty from "../../../assets/image/infoempty.svg";
import ExploreDocumentModalPublic from "./ExploreDocumentModalPublic";
import ExploreDocumentModalConfidential from "./ExploreDocumentModalConfidential";
import BadgePopup from "./BadgePopUp";
import ExploreService from "../../../services/exploreService.js";
import Utils from "../../../utils/Utils.js";
import ExploreDataService from "../../../services/exploreDataService.js";
import { DashboardContext } from "../../../context/dashboardContext";
import Response from './Response';
import favoriteIcon from "../../../assets/image/favorite.svg";
import eye_alt from "../../../assets/image/eye_alt.svg";
import ViewSourceDocsModal from "./ViewSourceDocsModal";
import cancelIcon from "../../../assets/image/cancel.svg";
import LikeDislike from "../../common/LikeDislike";
import TextHighlighter from "../../common/TextHighlighter.js";
import copytag from "../../../assets/image/copy.svg";
import moment from "moment";
import UnFavoriteConfirmationModal from "../InstantInsights/UnFavoriteConfirmationModal";
import FavouritePopUp from "./FavouritePopUp";

const ExplorePhase = ({
  question,
  publicConf,
  addprompt,
  isPromptSubmit,
  showPromotHistory,
  selectedDocNames,
  showInstantModal,
  setShowInstantModal,
  setSelectedDocDeleted,
  setIsSrcDocActive
}) => {
  const explorservice = new ExploreService();
  const utils = new Utils();
  const [show, setShow] = useState(false);
  const [showPublic, setShowPublic] = useState(false);
  const [showBadgePopup, setShowBadgePopup] = useState(false);
  const constantData = require("../../../data/constant.js");
  const [position, setPosition] = useState(constantData.StyleType);
  const [promptResponse, setPromptResponse] = useState(null);
  const [promptId, setPromptId] = useState(null);
  const Prompt = require("../../../data/model/prompt.js");
  const [promptDocuments, setPromptDocuments] = useState([]);
  const [favPosition, setFavPosition] = useState(constantData.StyleType);
  const [arrForReqRes, setArrForReqRes] = useState([]);
  const [showFavoritePopup, setShowFavoritePopup] = useState(false);

  let PromptData = Prompt.PromptData;

  const [showStar, setShowStar] = useState(true);
  const [showFavourite, setShowFavourite] = useState(false);
  const [showbookmarkempty, setShowBookMarkEmpty] = useState(true);
  const [showbookmarkemptyblack, setShowBookMarkEmptyBlack] = useState(false);
  const [initialdata, setInitialdata] = useState([]);
  const { 
    viewPrompt,
    setViewPrompt,
    viewInstantPrompt,
    setViewInstantPrompt,
    setLatestPromptId,
    showViewSourceDocIconOnEditButtonClick,
    setShowViewSourceDocIconOnEditButtonClick,
    flipValueToTriggerRerender
  } = useContext(DashboardContext);
  const [message, setMessage] = useState(false);
  const [displaySourceDocsPopup, setDisplaySourceDocsPopup] = useState(false);
  const [displayIISourceDocsPopup, setDisplayIISourceDocsPopup] = useState(false);
  const [chunks, setChunks] = useState([]);
  const [deleteToast, setDeleteToast] = useState(false);
  const [deleteDocData, setDeleteDocData] = useState([]);
  const [showUnFavPopup, setShowUnFavPopup] = useState(false);

  useEffect(() => {
    if (viewInstantPrompt.sourceDocuments) {
      let vChunks = [];
      for (const element of viewInstantPrompt.sourceDocuments) {
        if (element.chunkData) {
          vChunks.push(element.chunkData);
        }
      }
      setChunks(vChunks);
    }
    return () => {
      if (!window.location.href.includes("ExploreData")) {
        setArrForReqRes([]);
        setViewPrompt({});
        setViewInstantPrompt({});
        setShowViewSourceDocIconOnEditButtonClick({});
        setDeleteToast(false);
      }
    };
  }, []);

  /**
   * Description
   * -----------
   * This useEffect is used to show/hide the modal
   */
  useEffect(() => {
    setShowInstantModal(showInstantModal);
  }, [setShowInstantModal]);

  /**
   * This method is for closing instant insight prompt view from favorites
   */
  const instantCloseClick = () => {
    setViewInstantPrompt({});
    setShowInstantModal(!showInstantModal);
  }

  useEffect(() => {
    if (isPromptSubmit) {
      GetResponse();

      // Filling up the req and res in an json array
      let arrReqRes = [];
      arrReqRes.push({
        req: question,
        res: promptResponse,
        mRes: promptResponse?.modifiedResponse,
        publicprivate: publicConf,
        refSources: promptResponse?.refSources && (promptResponse.refSources !== "") && (promptResponse.refSources !== ":") ? promptResponse.refSources : '',
        chunks_text_pdf: promptResponse?.chunks_text_pdf,
        chunks_view_source: promptResponse?.chunks_view_source
      });
      setArrForReqRes(arrReqRes);
      setInitialdata(arrReqRes);
      setLatestPromptId(promptResponse?.promptId);
    }
    if (showPromotHistory) {
      // Filling up the req and res in an json array
      if (promptResponse) {
        promptResponse.reqTime = utils.convertDateToUTCTimeStamp(promptResponse.createTS, true);
        let arrReqRes = [];
        promptResponse.fileList = promptResponse.documentList ?? [];
        setPromptDocuments(promptResponse.fileList);
        arrReqRes.push({
          req: question,
          res: promptResponse,
          mRes: promptResponse?.modifiedResponse,
          publicprivate: publicConf,
          refSources: promptResponse?.refSources && (promptResponse.refSources !== "") && (promptResponse.refSources !== ":") ? promptResponse.refSources : '',
          chunks_text_pdf: promptResponse?.chunks_text_pdf,
          chunks_view_source: promptResponse?.chunks_view_source
        });
        setArrForReqRes(arrReqRes);
        setInitialdata(arrReqRes);
      }
    }
  }, [question, promptResponse]);

  useEffect(() => {
    if (showPromotHistory) {
      if (viewPrompt && viewPrompt?.requestContent) {
        setPromptResponse(viewPrompt);
        setPromptId(viewPrompt.promptId);

        if (viewPrompt?.isFav === "Y") {
          setShowStar(false);
          setShowFavourite(true);
        }
        else {
          setShowStar(true);
          setShowFavourite(false);
        }

        if (viewPrompt?.isSaved === "Y") {
          setShowBookMarkEmpty(false);
          setShowBookMarkEmptyBlack(true);
        }
        else {
          setShowBookMarkEmpty(true);
          setShowBookMarkEmptyBlack(false);
        }

      }
    }
  }, [viewPrompt, showPromotHistory]);

  useEffect(() => {
    document.body.style.overflow = position;
  }, [position]);

  useEffect(() => {
    document.body.style.overflow = favPosition;
  }, [favPosition]);

  const onEditPencilClick = () => {
    addprompt(question);
    setIsSrcDocActive(true);
    document.getElementById("question")?.focus();
  };

  const initializePrompt = () => {
    PromptData.convid = "";
    PromptData.promptid = "";
    PromptData.project_uuid = "";
    PromptData.prompttype = "";
    PromptData.title = "";
    PromptData.tags = "";
    PromptData.prompttext = "";
    PromptData.IsFavOrSaved = "";
    PromptData.Email = "";
  };

  async function onStarButtonClick(vElement) {
    initializePrompt();
    PromptData.IsFavOrSaved = "F";
    PromptData.prompttext = question;
    // PromptData.prompttext = question;
    // showStarPopUp(true);
    if (promptId != null) {
      PromptData.promptid = promptId.toString();
      if (vElement.res.isFav !== "Y") {
        showStarPopUp(true);
      } else {
        setShowUnFavPopup(true);
      }
    }
  }

  async function onStarPopupConfirmClick(PromptData) {
    if (promptId != null) {
     PromptData.promptid = promptId.toString();
     const response = await explorservice.AddPromptToLibrary(PromptData);
     if (response === 204) {
       setShowStar(false);
       setShowFavourite(true);
       viewPrompt.isFav="Y";        
     }
   }
 }

  const onBadgeButtonClick = () => {
    initializePrompt();
    PromptData.project_uuid = sessionStorage.getItem("DashboardProjectId");
    PromptData.Email = sessionStorage.getItem("Email");
    PromptData.prompttext = question;
    showPopUp(true);
  };

  async function onBadgePopupConfirmClick(PromptData) {
    if (promptId != null) {
      PromptData.promptid = promptId.toString();
      const response = await explorservice.AddPromptToLibrary(PromptData);
      if (response === 204) {
        setShowBookMarkEmpty(false);
        setShowBookMarkEmptyBlack(true);
        viewPrompt.isSaved = "Y";
      }
    }
  }

  const showPopUp = (isvisible) => {
    setShowBadgePopup(isvisible);
    if (!isvisible) {
      setPosition("");
    } else {
      setPosition(constantData.StyleType);
    }
  };

  const showStarPopUp = (isvisible) => {
    setShowFavoritePopup(isvisible);
    if (!isvisible) {
      setFavPosition("");
    } else {
      setFavPosition(constantData.StyleType);
    }
  };

  const onDeleteToastClick = () => {
    setDeleteToast(false);
    setSelectedDocDeleted(true);
  };

  async function GetResponse() {
    let response = {};
    let dateTime = utils.convertDateToUTCTimeStamp(new Date(), false);
    try {
      if (initialdata.length === 0) {
        response = await explorservice.GetPromptResponse(
          sessionStorage.getItem("PromptConversationId"),
          sessionStorage.getItem("DashboardProjectId"),
          question,
          "All",
          sessionStorage.getItem("Email"),
          publicConf,
          selectedDocNames
        );

        let resultarray = [];

        /**Description for Below If condition Logic
         * Here the errorCode 101 will get
         * when other userB parallely deleted file at the same time when userA using it in Prompts.
         * along with message we are getting in API as "Requested document is deleted"
         */
        if (response?.errorCode === 101) {
          setDeleteToast(true);
          for (var i = 0; i < response?.data?.length; ++i)
            resultarray.push("'" + response?.data[i] + "'");
          resultarray = resultarray.join(", ");
          setDeleteDocData(resultarray);
        }
        else {
          if (
            response?.fileList &&
            response?.fileList !== "" &&
            response?.fileList !== " "
          ) {
            let docArray = _.uniq(response?.fileList?.split(","));
            docArray.forEach(function (file) {
              resultarray.push({
                documentName: file,
                isAvailable: true,
              });
            });
          }
          setPromptDocuments(resultarray);
          response.promptId = response.promptID;
          setPromptResponse(response);
          setPromptId(response.promptId);
        }
      }
    } catch (error) {
      setPromptDocuments([]);
      response.responseContent =
        "No response available for this request at this time. Please try again";
      response.promptId = "";
      setPromptResponse(response);
      setPromptId("");
    }
    response.reqTime = dateTime;
  }

  const messageClose = () => {
    setMessage(false);
  };

  const sendEmail = async () => {
    if (promptId != null) {
      const exploreDataService = new ExploreDataService();
      const EmailPrompt = [];
      let newRow = [];
      newRow = {
        ProjectName: sessionStorage.getItem("DashboardProjectName"),
        Project_UUID: sessionStorage.getItem("DashboardProjectId"),
        PromptId: promptId,
        UserName: sessionStorage.getItem("CurrentUserName"),
      };
      EmailPrompt.push(newRow);
      await exploreDataService.SendEmailPrompt(EmailPrompt[0]);
      setMessage(true);
    }
  };

  const onCopy = (divClassName) => {
    let r = document.createRange();
    r.selectNode(document.getElementsByClassName(divClassName)[0]);
    window.getSelection().removeAllRanges();
    window.getSelection().addRange(r);
    document.execCommand("copy");
    window.getSelection().removeAllRanges();
  };

  const viewSourceDocs = (vDocType) => {
    if (vDocType === 'ExploreYourData') {
      setDisplaySourceDocsPopup(true);
    } else if (vDocType === 'InstantInsights') {
      setDisplayIISourceDocsPopup(true);
    }
  }

  useEffect(() => {
    setShowViewSourceDocIconOnEditButtonClick(showViewSourceDocIconOnEditButtonClick);
  }, [flipValueToTriggerRerender, showViewSourceDocIconOnEditButtonClick, setShowViewSourceDocIconOnEditButtonClick]);

  const showHideViewSourceDocumentButton = (element) => {
    let viewSourceDocIconOnEditButtonClick = showViewSourceDocIconOnEditButtonClick ?? {};
    var result = (element.res && element.mRes) ?
      ((viewSourceDocIconOnEditButtonClick[`viewSourceDocument${element.res.promptId}`] !== undefined
        && viewSourceDocIconOnEditButtonClick[`viewSourceDocument${element.res.promptId}`] === false)
        ? 'explore-prompt-response-actions-donot-show'
        : 'explore-prompt-response-actions explore-prompt-response-actions-originalresponse') :
      ((viewSourceDocIconOnEditButtonClick[`viewSourceDocument${element.res.promptId}`] !== undefined
        && viewSourceDocIconOnEditButtonClick[`viewSourceDocument${element.res.promptId}`] === false)
        ? 'explore-prompt-response-actions-donot-show'
        : 'explore-prompt-response-actions');
    return result;
  }

  /**
   * This method updates the user action.
   * @param userAction: updated user action
   * @param oResponse: props object
   */
  const updateUserAction = (userAction, viewInstantPrompt) => {
    // Add your logic here to update the user action
    viewInstantPrompt.userAction = userAction;
  };

  /**
   * This method updates the modified response with latest value if user has saved the modified response.
   * @param response: latest modified response
   */
  const updateModifiedResponseExplorePhase = (promptId, response) => {
    arrForReqRes.forEach((x) => {
      if (x.res.promptId === promptId) {
        x.res.modifiedResponse = response;
        x.mRes = response;
      }
    });
  };

  /**
   * This method calls to delete the favorite prompt from backend through api
   */
  const markUnFavoritePromptResponse = async (eydPromptHistoryId, eydFavoritePromptId) => {
    if (promptId) {
      let result = await explorservice.removePromptFromFavorites(promptId);
      if (result) {
        setShowUnFavPopup(false);
        setShowStar(true);
        setShowFavourite(false);
        viewPrompt.isFav = null;
        promptResponse.isFav = null;
      }
    }
  };

  const setUnfavoriteModal = (vOpen) => {
    setShowUnFavPopup(vOpen);
  };

  return (
    <div>
      {!showInstantModal && <div className="explore-prompt-req-res">
        {arrForReqRes.map((element, index) => {
          return (
            <div key={index}>
              {!deleteToast &&
                <div className="explore-prompt-question-container">
                  <div className="explore-prompt-question-container-child">
                    <div className="explore-prompt-question-container-text">
                      You
                    </div>
                    <div className="explore-prompt-question-container-date">
                      {utils.convertDateToUTCTimeStampWithDayName(element.res?.reqTime, true)}
                    </div>
                    <div className="explore-prompt-question-edit">
                      <MotifIconButton onClick={onEditPencilClick}>
                        <img src={editpencil} alt="" />
                      </MotifIconButton>
                    </div>
                  </div>

                  <div className="explore-prompt-question-header">
                    <div className="explore-prompt-question-text"
                    dangerouslySetInnerHTML={{
                      __html: element.req,
                    }}>
                    </div>
                  </div>

                  <div className="explore-prompt-question-action-items">
                    <div className="explore-prompt-question-edit-promt-container">
                      {/* <img src={star} /> */}
                      <MotifTooltip
                        placement="bottom"
                        trigger={
                          <MotifIconButton
                            disabled={promptId === ""}
                            onClick={() => onStarButtonClick(element)}
                          >
                            <div id="StarIcon">
                              {showStar && <img src={star} alt="" />}
                              {showFavourite && <img src={favoriteIcon} alt="" />}
                            </div>
                          </MotifIconButton>
                        }
                      >
                        {showStar ? "Add prompt and response to 'Favorites'" : "Remove prompt and response from 'Favorites'"}
                      </MotifTooltip>
                      {showUnFavPopup && (
                        <UnFavoriteConfirmationModal
                          setOpenModal={(vOpen) => setUnfavoriteModal(vOpen)}
                          setConfirm={markUnFavoritePromptResponse}
                          instantInsightFavoritePromptId={null}
                          promptHistoryId={null}
                        />
                      )}
                      {/* <img src={bookmarkempty} /> */}
                      <MotifTooltip
                        style={{ alignItems: "center" }}
                        placement="bottom"
                        trigger={
                          <MotifIconButton
                            disabled={promptId === ""}
                            onClick={onBadgeButtonClick}
                          >
                            <div id="BookmarkIcon">
                              {showbookmarkempty && (
                                <img src={bookmarkempty} alt="" />
                              )}
                              {showbookmarkemptyblack && (
                                <img src={bookmarkemptyblack} alt="" />
                              )}
                            </div>
                          </MotifIconButton>
                        }
                      >
                        Add prompt to 'Personal <br /> Prompt Library'
                      </MotifTooltip>
                        {showBadgePopup && (
                          <BadgePopup
                            setOpenModal={showPopUp}
                            promptdata={PromptData}
                            setconfirm={onBadgePopupConfirmClick}
                          ></BadgePopup>
                        )}
                        {showFavoritePopup && (
                          <FavouritePopUp
                            setOpenModal={showStarPopUp}
                            promptdata={PromptData}
                            setconfirm={onStarPopupConfirmClick}
                            isExplore={true}
                          ></FavouritePopUp>
                        )}
                      <MotifTooltip
                        style={{ alignItems: "center" }}
                        placement="bottom"
                        trigger={
                          <MotifIconButton disabled={promptId === ""}>
                            <div id="MailIcon">
                              <img
                                src={mailout}
                                alt=""
                                onClick={() => sendEmail()}
                              />
                            </div>
                          </MotifIconButton>
                        }
                      >
                        Share prompt and
                        <br /> response by email
                      </MotifTooltip>
                    </div>
                  </div>
                </div>}

              {deleteToast && (
                <MotifToast
                  variant="error"
                  actionName=""
                  position="bottom"
                  onClose={() => {
                    onDeleteToastClick();
                  }}
                >
                  <div>
                    The {deleteDocData} you are querying has been deleted by another user.
                  </div>
                </MotifToast>
              )}

              {message && (
                <MotifToast
                  icons={{ toast: <img src={mailout} alt=""></img> }}
                  variant="success"
                  actionName=""
                  position="bottom"
                  onClose={() => {
                    messageClose(false);
                  }}
                >
                  <div>
                    Email Sent
                  </div>
                </MotifToast>
              )}
              {show && (
                <ExploreDocumentModalPublic
                  openShow={show}
                  setOpenModal={setShow}
                  documentNames={promptDocuments}
                />
              )}

              {showPublic && (
                <ExploreDocumentModalConfidential
                  openShow={showPublic}
                  setOpenModal={setShowPublic}
                  documents={promptDocuments}
                />
              )}

              {!deleteToast &&
                <div className="explore-prompt-question-response-container">
                  <div className="explore-prompt-question-response-icon">
                    <img src={peopletag} alt="" />
                  </div>
                  {element.res !== "" && element.res ? (
                    <div>
                      <Response oResponse={element.res} mResponse={element.mRes} onCopy={onCopy} copytag={copytag} onModifiedResponseUpdate={updateModifiedResponseExplorePhase} />
                      <div className={showHideViewSourceDocumentButton(element)} id={`viewSourceDocument${element.res.promptId}`}>
                        {
                          (promptDocuments?.length > 0) && element.refSources &&
                          <div>
                            <MotifIconButton variant="secondary" id="explore-view-source-doc-icon" onClick={() => viewSourceDocs('ExploreYourData')}>
                              <div>
                                <span>
                                  <img src={eye_alt} alt="View Source Documents" />
                                  View Source Documents ({promptDocuments?.length})
                                </span>
                              </div>
                            </MotifIconButton>
                            {displaySourceDocsPopup &&
                              <ViewSourceDocsModal
                                openShow={displaySourceDocsPopup}
                                setOpenModal={setDisplaySourceDocsPopup}
                                references={element.refSources}
                                chunks={element.chunks_view_source || element.chunks_text_pdf}
                              />}
                          </div>
                        }
                      </div>
                      <div className="explore-prompt-response-ai-container">
                        <img src={infoEmpty} alt='icon' />
                        <span className="explore-prompt-response-ai-text">AI generated output may contain inaccuracies or errors. Verify output before use.</span>
                      </div>
                    </div>
                  ) : (
                    <div className="col-3">
                      <div className="snippet" data-title="dot-typing">
                        <div className="stage">
                          <div className="dot-typing"></div>
                        </div>
                      </div>
                    </div>
                  )}
                </div>}
            </div>
          );
        })}
      </div>}
      {showInstantModal && (
        <div className="explore-instant-container-child">
          <div className="explore-instant-prompt-header">
            <div className="explore-instant-prompt-text">
              Instant Insight - {viewInstantPrompt?.platformPromptTitle}
            </div>
            <MotifIconButton
              className="motif-icon-button-instant"
              aria-label="Click here to close prompt"
              onClick={(e) => { e.preventDefault(); instantCloseClick(); }}>
              <img src={cancelIcon} alt="Close Prompt" />
            </MotifIconButton>
          </div>
          <div className="explore-instant-prompt-response">
            {viewInstantPrompt?.platformPromptResponse && viewInstantPrompt?.platformPromptResponse !== "" ? (
              <div>
                <div className="explore-prompt-question-response-container-child">
                  {viewInstantPrompt?.favoriteContent ? <div
                    className={
                      "explore-prompt-question-response divresponse" +
                      viewInstantPrompt?.instantInsightFavoritePromptId
                    }
                  ><TextHighlighter
                      htmlString={utils.markdownToHtml(viewInstantPrompt?.platformPromptResponse)}
                      selectAll={false}
                      onClearSelectAll={null}
                      onSelection={() => (null)}
                      currentSelection={viewInstantPrompt?.favoriteContent}
                      clearSelection={false}
                      allowSelection={false}
                    />
                  </div> :
                    <div

                      className={
                        "explore-prompt-question-response divresponse" +
                        viewInstantPrompt?.instantInsightFavoritePromptId
                      }
                      dangerouslySetInnerHTML={{
                        __html: utils.markdownToHtml(viewInstantPrompt?.platformPromptResponse),
                      }}
                    ></div>}
                </div>
                <div className="explore-prompt-response-actions-instantinsights">
                  {

                    /* 944664 - Out of Scope - Thumps up/down option for Red flag items and Management follow up questions.*/
                    // topicId # 1 is "Report Sections"
                    (viewInstantPrompt?.topicId === 1)
                    && (
                      <LikeDislike data={{
                        promptHistoryId: viewInstantPrompt.instantInsightPromptHistoryId,
                        userAction: viewInstantPrompt.userAction, userActionId: viewInstantPrompt.userActionId
                      }} type='instantinsightsservice'
                        onChangeUserAction={(userAction) => { updateUserAction(userAction, viewInstantPrompt) }} />
                    )}
                  {
                    (viewInstantPrompt?.sourceDocuments?.length > 0) &&
                    <div className="viewsourcedoc-alignment-instantinsight">
                      <MotifIconButton variant="secondary" id="explore-view-source-doc-icon-instantinsights"
                        onClick={() => viewSourceDocs('InstantInsights')}>
                        <div>
                          <span>
                            <img src={eye_alt} alt="View Source Documents" />
                            View Source Documents ({viewInstantPrompt?.sourceDocuments?.length})</span>
                        </div>
                      </MotifIconButton>
                      {displayIISourceDocsPopup &&
                        <ViewSourceDocsModal
                          openShow={displayIISourceDocsPopup}
                          setOpenModal={setDisplayIISourceDocsPopup}
                          references={viewInstantPrompt.sourceDocuments}
                          chunks={chunks}
                        />}
                    </div>
                  }
                </div>
                <div className="explore-prompt-response-ai-container">
                  <img src={infoEmpty} alt='icon' />
                  <span className="explore-prompt-response-ai-text">AI generated output may contain inaccuracies or errors. Verify output before use.</span>
                </div>
              </div>
            ) : (
              <div className="col-3">
                <div className="snippet" data-title="dot-typing">
                  <div className="stage">
                    <div className="dot-typing"></div>
                  </div>
                </div>
              </div>
            )}
          </div>
        </div>
      )}
    </div>
  );
};

export default ExplorePhase;
