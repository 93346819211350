import React, { useState, useEffect } from "react";
import {
  MotifModal,
  MotifModalHeader,
  MotifModalBody,
  MotifButton,
  MotifSelect,
  MotifOption,
  MotifInput,
  MotifFormField,
  MotifModalFooter,
  MotifLabel,
} from "@ey-xd/motif-react";
import "./ShareToTWPopup.css";
import { useNavigate } from "react-router-dom";
import truncate from "@ey-xd/motif-react/components/truncate";

const prompt = require("../../../../data/model/prompt.js");

export function ShareToTWPopup({
  onClose,
  sectionList,
  memberList,
  promptdata = prompt.PromptData,
  setconfirm,
}) {
  const [open, setOpen] = useState(true);
  const [dropdownSectionValue, setDropdownSectionValue] = useState("");
  const [txtTitleValue, setTxtTitleValue] = useState(promptdata.title);
  const [dropdownTagMemberValue, setDropdownTagMemberValue] = useState("");
  const navigate = useNavigate();
  const [txtTitleInitValue] = useState(promptdata.title);

  const onCloseClick = () => {
    setOpen(false);
    onClose(false);
    setTxtTitleValue(txtTitleInitValue)
    promptdata.title =txtTitleInitValue;
    
  };

  /**
   * Event handler for Sections Dropdown changes
   * @param {*} value
   */
  const handleDropdownSectionChange = (value) => {
    setDropdownSectionValue(value);
    promptdata.prompttext = value;
  };

  const handleTitleTextBox = (value) => {
    setTxtTitleValue(value.target.value);
    promptdata.title = value.target.value;
  };

  /**
   * Event handler for Tag a Member Dropdown changes
   */
  const handleDropdownTagMemberChange = (value) => {
    var selectClassDivs = document.getElementsByClassName("selectMemberList");
    if (selectClassDivs.length > 0) {
      var selectClassDiv = selectClassDivs[0];
      var motifSelectInputButton =
        selectClassDiv.getElementsByClassName("motif-select-input")[0];
      var motifSelectInputTextDiv =
        motifSelectInputButton.getElementsByClassName(
          "motif-select-input-text"
        )[0];
      if (motifSelectInputTextDiv) {
        setTimeout(() => {
          motifSelectInputTextDiv.textContent =
            "Selected team members (" + value.length + ")";
        }, 1);
      }
    }
    setDropdownTagMemberValue(value);
    promptdata.Email = value;
  };

  /**
   * Function to check if all dropdowns have values selected
   * @returns : Retuns dropdown values state
   */
  const areAllDropdownsSelected = () => {
    return (
      dropdownSectionValue !== "" &&
      txtTitleValue !== "" &&
      dropdownTagMemberValue.length !== 0
    );
  };

  const handleShare = () => {
    setconfirm(promptdata);
    setOpen(false);
    onClose(false);
    navigate("/ProjectDashboard/ProjectWorkspace/Team", {
      state: { sectionShared: true, tabId: "Team" },
    });
  };

  const sectionData = sectionList;

  const UserData = memberList;

  useEffect(() => {
    setTxtTitleValue(txtTitleInitValue)
    console.log(areAllDropdownsSelected())
    areAllDropdownsSelected()
  }, [txtTitleInitValue])

  
  return (
    <div>
      <MotifModal
        id="shareTWPopup"
        className="shareTWPopup"
        show={open}
        focusTrapOptions={{
          tabbableOptions: {
            displayCheck: "none",
          },
        }}
        onClose={() => {
          onCloseClick();
        }}
      >
        <MotifModalHeader>
          <div className="share-alert-header-title">
            Share to Team Workspace
          </div>
        </MotifModalHeader>
        <MotifModalBody>
          <div className="sub-container">
            <div className="row-container">
              <div className="Label">Select section</div>
              <div className="input-row" id="selectionSection">
              <MotifFormField>
                {dropdownSectionValue===""?<MotifLabel>Select Section</MotifLabel>:null}
                <MotifSelect
                  className="Input"
                  ariaLabelledBy="select-label"
                  onChange={handleDropdownSectionChange}
                  visibleOptions={3}
                >
                  {sectionData.map((option) => {
                    return (
                      <MotifOption value={option.sectionId}>
                        {option.sectionTitle}
                      </MotifOption>
                    );
                  })}
                </MotifSelect>
              </MotifFormField>
              </div>
              <div className="hint-text">
                If the section you require is not available here, you can create
                via Team Workspace.
              </div>
            </div>
            <div className="row-container">
              <div className="Label">Title</div>
              <div className="input-row">
                <MotifInput
                  disabled={promptdata.prompttype === "II" ? true : false}
                  id="txtTitle"
                  label="txtSharepoint"
                  type="txtTitle"
                  onChange={handleTitleTextBox}
                  value={txtTitleValue}
                ></MotifInput>
              </div>
            </div>
            <div className="row-container">
              <div className="Label">Tag a member</div>
              <div className="input-row">
                <MotifFormField>
                  {dropdownTagMemberValue===""?<MotifLabel>Select team member</MotifLabel>:null}
                  <MotifSelect
                    className="Input selectMemberList"
                    multiple={true}
                    ariaLabelledBy="select-label"
                    onChange={handleDropdownTagMemberChange}
                    label="selectmember"
                    id="selectmemberid"
                    placeholder="Select team members"
                    visibleOptions={3}
                  >
                    {UserData.map((option) => {
                      return (
                        <MotifOption value={option.email}>
                          {option.fullName}
                        </MotifOption>
                      );
                    })}
                  </MotifSelect>
                </MotifFormField>
              </div>
            </div>
          </div>
        </MotifModalBody>
        <MotifModalFooter>
          <div className="share-alert-btns-div">
            <div>
              <MotifButton
                variant="primary-alt"
                onClick={() => {
                  onCloseClick();
                }}
                className="share-alert-cancel-button"
              >
                Cancel
              </MotifButton>
            </div>
            <div>
              <MotifButton
                className="share-alert-go-to-tw-button"
                disabled={!areAllDropdownsSelected()}
                onClick={handleShare}
              >
                Share
              </MotifButton>
            </div>
          </div>
        </MotifModalFooter>
      </MotifModal>
    </div>
  );
}

export default ShareToTWPopup;
