import { MotifModal, MotifModalHeader, MotifModalBody, MotifModalFooter, MotifButton, MotifChip } from "@ey-xd/motif-react";
import { PromptType } from "../../../data/constant";
import Utils from "../../../utils/Utils";

// Component to render View Prompt modal
export default function ViewPromptModal(props) {

    const dateHelper = new Utils();

    return (
        props.prompt && <MotifModal id="viewPromptModal" size="lg" show={props.showViewPromptModal} onClose={()=>props.toggleViewPromptModal({})} className="genericModal modalMotifFooterModal">
            <MotifModalHeader
                closeButtonProps={{
                    'aria-label': 'Close View Prompt Modal',
                    title: 'Close View Prompt Modal'
                }}
            >
                {
                    props.prompt.promptType === PromptType.Personal &&
                    <div className="prompt-project-detail-div">
                        <span className="prompt-project-name">{props.prompt.projectName}</span>
                        <MotifChip className={props.prompt.projectStatus === "Open" ? "prompt-project-status-open" : "prompt-project-status-closed"}>
                            {props.prompt.projectStatus}
                        </MotifChip>
                        <span className="prompt-create-date">{dateHelper.convertDateToUTCTimeStampWithDayName(dateHelper.convertDateToUTCTimeStamp(props.prompt.createdDate, true), false)}</span>
                    </div>
                }

                <div id="promptTitle" className="motif-h4">
                    {props.prompt.promptTitle}
                </div>
            </MotifModalHeader>
            <MotifModalBody>
                <p className="motif-body">
                    <span className="display-linebreak" id="promptText">{props.prompt.promptText}</span>
                </p>
            </MotifModalBody>
            <MotifModalFooter className="prompt-modal-footer">
                <MotifButton id="btnUsePrompt" type="button" className="prompt-view-modal-use-prompt" onClick={() => props.handleUsePrompt(props.prompt.promptText)}>Use Prompt</MotifButton>
            </MotifModalFooter>
        </MotifModal>
    );
}