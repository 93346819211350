function DeepLink(){

    const links = [];
    const deepLinkDataRequirements = {
        project_UUID:"",
    }

    /**
     * This method orchestrates the deep link set up.
     * @param {string} link 
     */
    function orchestrate(link){
        register(link.pathname);
        setPreLoadState('requestedURL', link.pathname);
        extractQueryParamsFromLink(link);
        setPreLoadState('deepLinkDataRequirements', JSON.stringify(deepLinkDataRequirements));
    }

    /**
     * This method returns deep link if set up.
     * @returns {string | null} Deep link
     */
    function getDeepLink() {
        return localStorage.getItem('requestedURL');
    }

    /**
     * This method returns deep link data requirements object.
     * @returns {object} Deep link data requirements object.
     */
    function getDeepLinkDataRequirements(){
        return JSON.parse(localStorage.getItem('deepLinkDataRequirements'));
    }

    /**
     * This method registers the deep link with application.
     * @param {string} link 
     */
    function register(link) {
        links.push(link);
    }

    /**
     * This method sets the appropriate state required to support deep link before page load.
     */
    function setPreLoadState(key, value) {
        localStorage.setItem(key, value);
    }

    /**
     * This method extracts query params from link and stores it.
     * @param {string} link 
     */
    function extractQueryParamsFromLink(link) {
        const queryParams = new URLSearchParams(link.search);
        if (queryParams.has('p_id')) {
            deepLinkDataRequirements.project_UUID = queryParams.get('p_id');
        }
    }

    /**
     * This method removes deep link.
     */
    function remove() {
        localStorage.removeItem('requestedURL')
    }

    return {
        /** This method orchestrates the deep link set up. */
        orchestrate: orchestrate,
        /** This method returns deep link data requirements object. */
        getDeepLinkDataRequirements: getDeepLinkDataRequirements,
        /** This method returns deep link if set up. */
        getDeepLink: getDeepLink,
        /** This method removes deep link. */
        remove: remove
    }

}

export default DeepLink;