/**
 * This method finds the container
 */
function findContainerByText(text, root) {
  const walker = document.createTreeWalker(root, NodeFilter.SHOW_TEXT, null);
  let node;
  while ((node = walker.nextNode())) {
    if (node.nodeValue?.trim().includes(text.trim())) {
      return node;
    }
  }
  return null;
}

/**
 * This method traverses to next node.
 */
function getNextNode(node) {
  if (!node) {
    return null;
  }

  if (node.firstChild) return node.firstChild;
  while (node) {
    if (node.nextSibling) return node.nextSibling;
    node = node.parentNode;
  }
}

/**
 * This method gives the total container count.
 */
function getNodeCount(range) {
  let count = 1;
  let node = range.startContainer;
  while (node && node !== range.endContainer) {
    if (node.nodeType === Node.ELEMENT_NODE) {
      node = getNextNode(node);
      continue;
    }
    count++;
    node = getNextNode(node);
  }
  return count;
}

/**
 * This method return the manipulated range to highlight the text
 */
export function getOriginalRange(range, root) {
  let firstOffset = 0,
    secondOffset = 0;
  const firstContainer = findContainerByText(
    range.startContainer.textContent ?? "",
    root
  );
  const lastContainter = findContainerByText(
    range.endContainer.textContent ?? "",
    root
  );
  const rangeNodeCount = getNodeCount(range);

  if (rangeNodeCount === 1) {
    const rangeFirstContainerText = range.startContainer.textContent?.substring(
      range.startOffset,
      range.endOffset
    );
    firstOffset =
      firstContainer?.textContent?.indexOf(rangeFirstContainerText ?? "") || 0;
    secondOffset = firstOffset + (rangeFirstContainerText ?? "").length;
  } else {
    const rangeFirstContainerText = range.startContainer.textContent?.substring(
      range.startOffset,
      range.startContainer.textContent.length
    );
    firstOffset =
      firstContainer?.textContent?.indexOf(rangeFirstContainerText ?? "") || 0;

    const rangeLastContainerText = range.endContainer.textContent?.substring(
      0,
      range.endOffset
    );

    secondOffset = rangeLastContainerText?.length || 0;
  }

  if (!firstContainer || !lastContainter) {
    return null;
  }

  const newRange = document.createRange();
  newRange.setStart(firstContainer, firstOffset);
  newRange.setEnd(lastContainter, secondOffset);

  return newRange;
}

/**
 * This method use to get selected line nodes of the range
 */
export function getAllSelectedNodes(range, root) {
  let firstOffset = 0,
    secondOffset = 0;

  let rangeList = [];

  const nodeList = getNodes(range);
  if (nodeList.length > 1) {
    nodeList.forEach((node) => {
      if (node.nodeValue) {
        let firstRangeOffset = 0;
        let lastRangeOffset = 0;
        const firstContainer = findContainerByText(
          node.nodeValue?.trim() ?? "",
          root
        );

        const lastContainter = findContainerByText(
          node.nodeValue?.trim() ?? "",
          root
        );

        if (
          node.isFirstNode
        ) {
          //first selection items
          const rangeFirstContainerText =
            range.startContainer.textContent?.substring(
              range.startOffset,
              range.startContainer.textContent.length
            );
          firstOffset =
            firstContainer?.textContent?.indexOf(
              rangeFirstContainerText ?? ""
            ) || 0;
          firstRangeOffset = firstOffset;
          lastRangeOffset = node.nodeValue.length;
        } else if (
          node.isLastNode
        ) {
          //end selection items
          const rangeLastContainerText =
            range.endContainer.textContent?.substring(0, range.endOffset);

          secondOffset = rangeLastContainerText?.length || 0;
          firstRangeOffset = 0;
          lastRangeOffset = secondOffset;
        } else {
          //mid selection items
          firstRangeOffset = 0;
          lastRangeOffset = node.nodeValue.length;
        }

        const newRange = document.createRange();
        newRange.setStart(firstContainer, firstRangeOffset);
        newRange.setEnd(lastContainter, lastRangeOffset);
        rangeList.push(newRange);
      }
    });
  } else {
    rangeList.push(getOriginalRange(range, root));
  }

  return rangeList;
}

/**
 * This method use to get all the nodes from the range
 */
function getNodes(range) {
  let nodeList = [];
  let node = range.startContainer;
  let isFirstNode = false;

  while (node && node !== range.endContainer) {
    if (node.nodeType === Node.ELEMENT_NODE || node.nodeValue?.trim() === "") {
      node = getNextNode(node);
      continue;
    }
    if(!isFirstNode){
      node.isFirstNode = true;
      isFirstNode = true;
    } else {
      node.isFirstNode = false;
    }
    nodeList.push(node);
    node = getNextNode(node);
  }
  range.endContainer.isLastNode = true;
  nodeList.push(range.endContainer);
  return nodeList;
}

/**
 * This method use to get all the nodes range
 */
export function getAllNodesRanges(root) {
  const walker = document.createTreeWalker(root, NodeFilter.SHOW_TEXT, null);
  let rangeList = [];
  if (walker) {
    if (walker.root?.childNodes.length) {
      const firstNode = walker.root?.childNodes[0];
      const lastNode =
        walker.root?.childNodes[walker.root?.childNodes.length - 1];

      const newRange = document.createRange();
      newRange.setStart(firstNode, 0);
      newRange.setEndAfter(lastNode);

      rangeList = getAllSelectedNodes(newRange, root);
    }
  }
  return rangeList;
}
