import React, { useEffect, useContext, useState, useRef } from "react";

//  Motif controls
import {
  MotifDropdownItem,
  MotifDropdown,
  MotifButton,
  MotifTooltip,
  MotifRichTextEditor,
} from "@ey-xd/motif-react";
import { MotifIconButton } from "@ey-xd/motif-react/Button";
import "../ProjectWorkspace.css";
import noResponseImage from "../../../../assets/image/noDocument.png";
import Utils from "../../../../utils/Utils";
import "./FavoriteResponseDetail.css";
import icon_verified from "../../../../assets/image/twitter-verified-badge.svg";
import icon_edit from "../../../../assets/image/editResponse.svg";
import icon_upload from "../../../../assets/image/upload-black.svg";
import icon_arrow_right from "../../../../assets/image/arrow-right.svg";
import WorkSpaceService from "../../../../services/workspaceService";
import { DashboardContext } from "../../../../context/dashboardContext";
import { ShareAlertPopup } from "../PersonalWorkspace/ShareAlertPopup";
import iconArrowDropdown from "../../../../assets/image/nav-arrow-down.svg";
import ShareToTWPopup from "./ShareToTWPopup";
import icon_cancel from "../../../../assets/image/cancel.svg";
import icon_save from "../../../../assets/image/save-floppy-disk.svg";
import icon_undo from "../../../../assets/image/undo.svg";
import icon_pencil from "../../../../assets/image/design-pencil.svg";

import EditProgressPopup from "./EditProgressPopup";
import {
  eventWrapper,
  isEditable,
} from "@testing-library/user-event/dist/utils";
import ViewSourceDocsModal from "../../ExploreData/ViewSourceDocsModal";

const FavoriteResponseDetail = () => {
  const utils = new Utils();
  const workspaceService = new WorkSpaceService();
  const { selectedPWFavoritePrompt, setSelectedPWFavoritePrompt } =
    useContext(DashboardContext);
  const [promptType, setPromptType] = useState("");
  const [selectedPrompt, setSelectedPrompt] = useState();

  const [isActionDatesDropdownOpen, setIsActionDatesDropdownOpen] =
    useState(false);
  const [showPopupAlert, setShowPopupAlert] = useState(false);
  const [showPopupShare, setShowPopupShare] = useState(false);
  const [isEditMode, setIsEditMode] = useState(false);
  const [showEditConfirm, setShowEditConfirm] = useState(false);
  const [initialText, setInitialText] = useState(false); // initial Text
  const [text, setText] = useState(false); // current text
  const [isEditheadeClose, setIsEditheadeClose] = useState(); // current text
  const [displaySourceDocsPopup, setDisplaySourceDocsPopup] = useState(false);
  const [isShared, setIsShared] = useState();
  const [isTransfered, setIsTransfered] = useState();
  const isEditModeRef = useRef(false);

  const motifReactEditorModules = {
    toolbar: false,
  };
  const textAreaRef = useRef(null);
  const [sectionList, setSectionList] = useState([]);
  const [memberList, setMemberList] = useState([]);
  const Prompt = require("../../../../data/model/prompt.js");
  let PromptData = Prompt.PromptData;

  useEffect(() => {
    if (selectedPWFavoritePrompt) {
      if (selectedPWFavoritePrompt.type) {
        switch (selectedPWFavoritePrompt.type) {
          case "II":
            setPromptType("Instant Insights");
            break;
          case "C":
          case "P":
            setPromptType("Explore your data");
            break;
          default:
          //  do nothing
        }
      }
      if (selectedPWFavoritePrompt) {
        getPWFavoritePrompt(selectedPWFavoritePrompt);

        setIsShared(selectedPWFavoritePrompt.isShared === true ? true : false);
        setIsTransfered(
          selectedPWFavoritePrompt.isTransfered === true ? true : false
        );
      }
      getSectionList();
      getMemberList();
      initializePrompt();
    }
  }, [selectedPWFavoritePrompt]);

  useEffect(() => {
    /**
     * To handle edit popup behaviour when click outside text area for edit
     */
    const handleClickOutsideEditMode = (event) => {
      if (isEditModeRef.current && 
        textAreaRef.current &&
        !textAreaRef.current.contains(event.target)
      ) {
        setShowEditConfirm(true);
      } else {
        setShowEditConfirm(false);
      }
    };

    const addEventListeners = () => {
      document.addEventListener("click", handleClickOutsideEditMode);
    };

    const removeEventListeners = () => {
      document.removeEventListener("click", handleClickOutsideEditMode);
    };

    if (isEditMode) {
      addEventListeners();
    } else {
      removeEventListeners();
      setShowEditConfirm(false);
    }

  }, [isEditMode]);

  const getSectionList = async () => {
    let sectionResult = await workspaceService.getSectionListById(
      sessionStorage.getItem("DashboardProjectId")
    );
    setSectionList(sectionResult);
  };

  const getMemberList = async () => {
    let memberResult = await workspaceService.getMembersDetailsByProject(
      sessionStorage.getItem("DashboardProjectId")
    );
    setMemberList(memberResult);
  };

  const getPWFavoritePrompt = async (vPrompt) => {
    let favoriteResponseResult = await workspaceService.getPWFavoritePromptById(
      sessionStorage.getItem("DashboardProjectId"),
      vPrompt.type,
      vPrompt.id
    );
    if (favoriteResponseResult && favoriteResponseResult.length > 0) {
      setSelectedPrompt(favoriteResponseResult[0]);
      setText(
        favoriteResponseResult[0]?.modifiedResponse
          ? favoriteResponseResult[0]?.modifiedResponse
          : favoriteResponseResult[0]?.response
      );
      setInitialText(
        favoriteResponseResult[0]?.modifiedResponse
          ? favoriteResponseResult[0]?.modifiedResponse
          : favoriteResponseResult[0]?.response
      );
    }
  };

  /**
   * This function open the drop down menu
   *
   */
  const openMenuClick = () => {
    setIsActionDatesDropdownOpen(true);
  };

  /**
   * This function close the drop down menu
   *
   */
  const closeMenuClick = () => {
    setIsActionDatesDropdownOpen(false);
  };

  /**
   * Function to open Alert and close Share workspace popup
   */
  const showSectionAlert = () => {
    setShowPopupAlert(true);
    setShowPopupShare(false);
  };

  /**
   * Function to open  Share workspace popup  and close Alert
   */
  const showSectionSharePopUp = () => {
    setShowPopupAlert(false);
    setShowPopupShare(true);
  };

  /**
   *  Function to close both popups
   */
  const closePopups = () => {
    setShowPopupAlert(false);
    setShowPopupShare(false);
  };

  const checkSectionSelectCondition = () => {
    let conditionSatisfied = false;
    if (sectionList.length > 0) {
      conditionSatisfied = true;
    } else {
      conditionSatisfied = false;
    }
    return conditionSatisfied;
  };

  /**
   * Function for handle edit state
   */
  const startEditing = () => {
    // document.getElementById('txtEditor').focus();
    setIsEditMode(true);
  };

  /**
   * Function for show edit alert when user click on close button
   */
  const handleEditConfirm = () => {
    setShowEditConfirm(true);
  };

  /**
   * Function to handle Text change in edit mode
   */
  const handleTextChange = (event) => {
    setText(event.target.value);
    isEditModeRef.current = true;
  };

  /**
   * To handle edit popup behaviour when click inside text area for edit
   */
  const handleClickInsideEditMode = () => {
    setShowEditConfirm(false);
  };

  /**
   * Function to handle Dont Save
   */
  const dontSaveChanges = (isCloseDirectly) => {
    if (isCloseDirectly) {
      setShowEditConfirm(false);
      isEditModeRef.current = false;
    } else {
      isEditModeRef.current = false;
      setText(initialText);
      setShowEditConfirm(false);
      setIsEditMode(false);
      
      //document.removeEventListener("click", handleClickOutsideEditMode);
    }
  };

  /**
   * Save edit Modify and Transfer data
   */
  const saveChanges = () => {
    setIsEditMode(false);
    setShowEditConfirm(false);
    setInitialText(text);
    isEditModeRef.current = false;
  };

  /**
   * Function close edit progress popup
   */
  // const editPopupClose = () => {
  //   setShowEditConfirm(false)
  //   setIsEditheadeClose(true)
  // }

  const disableIntreaction = isEditMode ? { PointerEvents: "" } : {};

  /**
   * Function to display View Source Documents popup
   */
  const viewSourceDocs = () => {
    setDisplaySourceDocsPopup(true);
  };

  const initializePrompt = () => {
    PromptData.prompttext = "";
    PromptData.promptid = selectedPWFavoritePrompt.id;
    if (selectedPWFavoritePrompt.type === "II") {
      PromptData.prompttype = "II";
    } else {
      PromptData.prompttype = "EYD";
    }
    PromptData.title = selectedPWFavoritePrompt.title;
    PromptData.Email = "";
  };

  async function onShareClick(data) {
    if (data != null) {
      await workspaceService.addTWFavoritePrompt(PromptData);
    }
  }

  return selectedPrompt ? (
    <>
      <div className="motif-row">
        <div className="motif-col-lg-6">
          <div className="response-title-wrapper">
            <div className="response-title">{selectedPrompt?.title}</div>
            <div className="response-date">
              Added on: {utils.getDateWithMMMDYYYY(selectedPrompt?.createTS)}
            </div>
          </div>
        </div>
        <div className="motif-col-lg-6">
          {selectedPrompt?.source && selectedPrompt.source !== "" && (
            <div className="response-top-actions-wrapper">
              <MotifButton
                variant="secondary"
                onClick={() => viewSourceDocs()}
                className="btnViewSourceDocs"
              >
                View Source Documents
              </MotifButton>
              {displaySourceDocsPopup && (
                <ViewSourceDocsModal
                  openShow={displaySourceDocsPopup}
                  setOpenModal={setDisplaySourceDocsPopup}
                  references={
                    selectedPrompt.refSources ||
                    JSON.parse(selectedPrompt.source)
                  }
                  chunks={selectedPrompt.chunkData}
                />
              )}
            </div>
          )}
        </div>
      </div>
      <div className="motif-row">
        <div className="motif-col-lg-6">
          <div className="response-wrapper">
            <div className="motif-row">
              <div className="motif-col-lg-12">
                <div className="response-content-title-wrapper">
                  <div className="motif-row">
                    <div className="motif-col-lg-10">
                      <div className="response-content-title">Original</div>
                      <div className="response-content-sub-title">
                        {promptType ? "Captured from " + promptType : ""}
                      </div>
                    </div>
                    <div className="motif-col-lg-2 alignContentRight">
                      <div className="response-verified-icon-wrapper">
                        <img src={icon_verified} alt="Verified" />
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="motif-row">
              <div className="motif-col-lg-12">
                <div
                  className="response-content-wrapper"
                  dangerouslySetInnerHTML={{
                    __html: utils.markdownToHtml(selectedPrompt?.response),
                  }}
                ></div>
              </div>
            </div>
          </div>
        </div>
        <div className="motif-col-lg-6" style={disableIntreaction}>
          <div className="response-wrapper">
            <div className="motif-row">
              <div className="motif-col-lg-12">
                <div className="response-content-title-wrapper">
                  <div className="motif-row">
                    <div className="motif-col-lg-6">
                      <div className="response-content-title">
                        Modify and Transfer
                      </div>
                      <MotifDropdown
                        ariaLabelledby="Action dates dropdown"
                        placement="bottom-left"
                        open={isActionDatesDropdownOpen}
                        handleClickOutside={() => closeMenuClick()}
                        trigger={
                          <MotifButton
                            onClick={() => openMenuClick()}
                            type="button"
                            id="dropdown-trigger-1"
                            aria-label="Action dates dropdown icon"
                            variant="secondary"
                            disabled={
                              selectedPrompt?.modifiedTS === "" ||
                              selectedPrompt?.transferedTS === "" ||
                              selectedPrompt?.sharedTS === ""
                            }
                            data-testid="testIdBtnActionDatesDropdown"
                          >
                            <span className="response-content-sub-title">
                              Action dates
                            </span>
                            <img
                              src={iconArrowDropdown}
                              alt="Down Arrow"
                              className="iconArrowDropdown"
                            ></img>
                          </MotifButton>
                        }
                      >
                        <MotifDropdownItem type="container" onClick={() => {}}>
                          <div>
                            Last modified:{" "}
                            {utils.getDateWithMMMDYYYYHHMMSS(
                              selectedPrompt?.modifiedTS
                            )}
                          </div>
                        </MotifDropdownItem>
                        <MotifDropdownItem type="container" onClick={() => {}}>
                          <div>
                            Last transferred:{" "}
                            {utils.getDateWithMMMDYYYYHHMMSS(
                              selectedPrompt?.transferedTS
                            )}
                          </div>
                        </MotifDropdownItem>
                        <MotifDropdownItem type="container" onClick={() => {}}>
                          <div>
                            Last shared:{" "}
                            {utils.getDateWithMMMDYYYYHHMMSS(
                              selectedPrompt?.sharedTS
                            )}
                          </div>
                        </MotifDropdownItem>
                      </MotifDropdown>
                    </div>
                    <div className="motif-col-lg-6 alignContentRight">
                      {!isEditMode ? (
                        <MotifIconButton onClick={startEditing}>
                          <div className="response-verified-icon-wrapper">
                            <MotifTooltip
                              trigger={<img src={icon_edit} alt="Edit" />}
                              placement="bottom"
                            >
                              Edit
                            </MotifTooltip>
                          </div>
                        </MotifIconButton>
                      ) : (
                        <div className="edit-container">
                          <MotifIconButton>
                            <div className="response-verified-icon-wrapper">
                              <img src={icon_undo} alt="Undo" />
                            </div>
                          </MotifIconButton>
                          <MotifIconButton>
                            <div className="response-verified-icon-wrapper">
                              <img
                                src={icon_pencil}
                                alt="Convert to Flat Text"
                              />
                            </div>
                          </MotifIconButton>
                          <MotifIconButton>
                            <div className="response-verified-icon-wrapper">
                              <img src={icon_save} alt="Save" />
                            </div>
                          </MotifIconButton>
                          <MotifIconButton onClick={handleEditConfirm}>
                            <div className="response-verified-icon-wrapper">
                              <img
                                src={icon_cancel}
                                alt="Close"
                                onClick={handleEditConfirm}
                              />
                            </div>
                          </MotifIconButton>
                          {showEditConfirm === true && (
                            <EditProgressPopup
                              onClose={dontSaveChanges}
                              onSave={saveChanges}
                              onDontSave={dontSaveChanges}
                            ></EditProgressPopup>
                          )}
                        </div>
                      )}
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="motif-row">
              <div className="motif-col-lg-12">
                {!isEditMode ? (
                  <div
                    className="response-content-wrapper"
                    contenteditable="true"
                    dangerouslySetInnerHTML={{
                      __html: utils.markdownToHtml(text),
                    }}
                  ></div>
                ) : (
                  <div className="response-content-wrapper">
                    <textarea
                      id="txtEditor"
                      value={text}
                      ref={textAreaRef}
                      onChange={handleTextChange}
                      disabled={!isEditMode}
                      onClick={handleClickInsideEditMode}
                    ></textarea>
                  </div>
                )}
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="motif-row">
        <div className="motif-col-lg-12">
          <div className="response-action-wrapper">
            <MotifButton
              variant="secondary"
              disabled={isShared}
              className="btnShare"
              onClick={
                checkSectionSelectCondition()
                  ? showSectionSharePopUp
                  : showSectionAlert
              }
            >
              <img
                src={icon_upload}
                alt="Share to Team Workspace"
                className="iconUpload"
              />
              Share to Team Workspace
            </MotifButton>
            <MotifButton className="btnTransfer" disabled={isTransfered}>
              <img
                src={icon_arrow_right}
                alt="Transfer to PowerPoint"
                className="iconUpload"
              />
              Transfer to PowerPoint
            </MotifButton>
            {showPopupAlert && <ShareAlertPopup onClose={closePopups} />}
            {showPopupShare && (
              <ShareToTWPopup
                onClose={closePopups}
                sectionList={sectionList}
                memberList={memberList}
                promptdata={PromptData}
                setconfirm={onShareClick}
                title = {PromptData.title}
              />
            )}
          </div>
        </div>
      </div>
    </>
  ) : (
    <div className="no-response-wrapper">
      <img src={noResponseImage} alt="No Response Selected"></img>
      <div className="no-response-text-container">
        <div className="no-response">No favorites selected</div>
        <div className="no-response-text">
          Please make a selection from the left pane to view the <br />
          response and engage further with Personal <br />
          Workspace.
        </div>
      </div>
    </div>
  );
};
export default FavoriteResponseDetail;
