import React,{ useState} from 'react';
import {
    MotifTooltip
} from "@ey-xd/motif-react";
import infoMember from "../../../assets/image/info-member.svg";
import cancelRole from "../../../assets/image/cancel-role.svg";
import { MotifIconButton } from "@ey-xd/motif-react/Button";


const RolePopUp = () => {
    const constantData = require("../../../data/constant.js");
    const [showTooltip, setshowTooltip] = useState(false);
     /**
      * Description
      * -----------
      * This method is to call when user click on the tooltip close
      * Parameters:
      * e: this one is onChange event parameter to get the input action
      */
    const onCloseClick = () => {
        if(showTooltip===false)
            {
                setshowTooltip(true);
            }
            else
            {
                setshowTooltip(false);
            }
      }
      /**
      * Description
      * -----------
      * This method is to call when user click on the information icon
      * Parameters:
      * e: this one is onChange event parameter to get the input action
      */
      const onInfoClick = () => {
        setshowTooltip(false);
      }
    return (
        <div id="contentwrapper">
            <div id="divrole">
                Role               
            </div>
            <div id="divtooltip">
                <MotifTooltip
                    contentClassName='role-tooltip'
                    hideCloseButton={true}
                    allowHover={false}
                    hide={showTooltip}
                    placement="bottom"
                    trigger={   
                        <MotifIconButton id="roleimg" onClick={() => onInfoClick()}>
                                <img  src={infoMember} alt=''/>
                            </MotifIconButton>                     
                    }
                >
                    <div id="tooltipwrapper">
                        <div id="wrapper">
                        {constantData.rolePopheader}                            
                            <MotifIconButton id="closeicon" onClick={() => onCloseClick()}>
                                <img  src={cancelRole} alt=''/>
                            </MotifIconButton>                                
                        </div>

                        <div id="tooltiproledetail">
                            <div>{constantData.rolePopUpNonSatPart1}</div>
                            <div className='linespace'>{constantData.rolePopUpNonSatPart2}</div>
                            <div>{constantData.rolePopUpSatPart1}</div>
                            <div className='linespace'>{constantData.rolePopUpSatPart2}</div>
                            <div>{constantData.rolePopUpAdminPart1}</div>
                            <div>{constantData.rolePopUpAdminPart2}</div>
                            <div>{constantData.rolePopUpAdminPart3}</div>
                        </div>
                    </div>
                </MotifTooltip>
            </div>
        </div>
    );
};

export default RolePopUp;