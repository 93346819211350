import { useEffect } from "react";
import "../ExploreData/ExploreData.css";
import MotifModal, {
  MotifModalHeader,
  MotifModalBody,
} from "@ey-xd/motif-react/Modal";
import {MotifIconButton} from "@ey-xd/motif-react/Button";
import page from "../../../assets/image/page.svg";
import download from "../../../assets/image/download.svg";
import DownloadDocument from "../../../utils/downloadDocument";

const ExploreDocumentModalConfidential = ({
  openShow,
  setOpenModal,
  documents
}) => {
  const downloadDocument=new DownloadDocument();

  /**
   * Description
   * -----------
   * This useEffect is used to show/hide the modal
   */
  useEffect(() => {
    setOpenModal(openShow);
  }, [setOpenModal]);
  

  return (
    <div>
      <MotifModal
        id="motifModalConfidential"
        className="motif-modal-confidential"
        show={setOpenModal}
        focusTrapOptions={{
          tabbableOptions: {
            displayCheck: "none",
          },
        }}
        onClose={() => {
          setOpenModal(!openShow);
        }}
      >
        <MotifModalHeader
          closeButtonProps={{
            "aria-label": "Close",
            title: "Close",
          }}
        >
          Response from {documents?.length} confidential sources
        </MotifModalHeader>
        <MotifModalBody>
          <div className="motif-modal-confidential-body">
            <div className="motif-modal-confidential-row">
              {documents.map((item) => {
                return (
                  <>
                    <div className="motif-modal-confidential-row-Container">
                      <div
                        className={`motif-modal-confidential-row-fileIcon ${
                          !item.isAvailable
                            ? "noaction-document-not-available"
                            : ""
                        }`}
                      >
                        <img
                          src={page}
                          data-testid="downloadFile"
                          alt=""
                        />
                      </div>
                      <div
                        className={`motif-modal-confidential-row-text ${
                          !item.isAvailable ? "document-not-available" : ""
                        }`}
                      >
                        {item.documentName}
                      </div>
                      {(localStorage.getItem('userRole') !== "Viewer" && localStorage.getItem('userRole') !== "User (Non-SaT)") && 
                      <MotifIconButton id="downloadButton" onClick={() => downloadDocument.handleDownload(item.documentName)} >
                                      <img src={download} alt="download source documents" ></img>
                      </MotifIconButton>
                      }
                    </div>
                  </>
                );
              })}
            </div>
          </div>
        </MotifModalBody>
      </MotifModal>
    </div>
  );
};
export default ExploreDocumentModalConfidential;
