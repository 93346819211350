import React, { useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { groupConfig } from "../../config/groupConfig";
export function LoginSuccess() {

  const navigate = useNavigate();

  useEffect(() => {

    const grpName = localStorage.getItem('groupNamePlatform');    

    if (grpName === groupConfig.groupNamePlatForm) {
      navigate("/dashboardPlatformAdmin");
    }
    else {
      navigate("/dashboard");
    }

  }, []);

  return <div>redirecting to dashboard</div>;
}
