import React, { useEffect, useState } from "react";
import MotifModal, {
  MotifModalHeader,
  MotifModalBody,
} from "@ey-xd/motif-react/Modal";
import _ from "lodash";
import { MotifModalFooter, MotifToast } from "@ey-xd/motif-react";
import MotifButton from "@ey-xd/motif-react/Button";
import "./InstantInsights.css";
import exportIcon from "../../../assets/image/export.svg";
import ProjectScopeTree from "./ProjectScopeTree";
import InstantInsightsService from "../../../services/instantInsightService";
import LoaderComponent from "../../common/LoaderComponent";

const ExportInsightsModal = (props) => {
  const [updatedTreeData, setUpdatedTreeData] = useState([]);
  const [disableExportButton, setDisableExportButton] = useState(true);
  const [showLoader, setShowLoader] = useState(false);
  const [isExportError, setIsExportError] = useState(false);
  const [exportTreeData, setExportTreeData] = useState(null);
  const instantInsightsService = new InstantInsightsService();
  const sectionEnum = Object.freeze({
    reportSections: "Report Sections",
    redFlagItems: "Red-Flag Items",
    managementFollowup: "Management Follow-up questions",
  });

  let exportInsightsPayload = {
    projectScopeId: props.projectScopeId,
    scopeName: props.projectScopeName,
    project_uuid: sessionStorage.getItem("DashboardProjectId"),
    projectName: sessionStorage.getItem("DashboardProjectName"),
    email: sessionStorage.getItem("Email"),
    sections: [],
    redFlagItems: [],
    managementFollowup: [],
  };

  useEffect(() => {
    let exportedTreeData = removeNodesIfResponseNotAvailable({..._.cloneDeep(props.data.treeData)}) ;
    setExportTreeData(exportedTreeData);
  }, [props.data.treeData]);

  /**
   * This recursive method will delete the section or subsection which does not have response.
   * @param node: section or subsection node 
   * @returns null if there is no response for type 2 or 3 or node value 
   */
  const removeNodesIfResponseNotAvailable = (node) => {

    /** it will identify the leaf node and check its response for section and subsection */
    if (!node.children) {
      return !node.isSubSectionAllowedToExport && (node.nodeType === 2 || node.nodeType === 3) ? null : node;
    }
  
    /** The map method will return only those nodes which has response otherwise null. */
    node.children = node.children.map(removeNodesIfResponseNotAvailable).filter(Boolean);
  
    /** it will return null if there is no childeren for section node */
    if (node.nodeType === 2 && node.children.length === 0) {
      return null;
    }
  
    /** it will return null if section or subsection does not have response  */
    return node.noResponseToPrompt && (node.nodeType === 2 || node.nodeType === 3) && node.children.length === 0
      ? null
      : node;
  };

  useEffect(() => {
    if (updatedTreeData?.children?.length) {
      setDisableExportButton(false);
    } else {
      setDisableExportButton(true);
    }
  }, [updatedTreeData]);

  /**
   * This resets the export payload
   */
  const resetPayload = () => {
    exportInsightsPayload.projectScopeId = props.projectScopeId;
    exportInsightsPayload.scopeName = props.projectScopeName;
    exportInsightsPayload.sections = [];
  };

  /**
   * This method traverse to each node and checks if any node selected and construct the payload.
   * @param node: passes tree node.
   */
  const traverseAndGeneratePayload = (node) => {
    if (!node.children || node.children.length === 0) {
      // Leaf node
      if (node.isSelected) {
        if (node.parent.parent.value === sectionEnum.reportSections) {
          if (!exportInsightsPayload.sections) {
            exportInsightsPayload.sections = [];
          }
          updatePayload(node, "sections");
        } else if (node.parent.parent.value === sectionEnum.redFlagItems) {
          if (!exportInsightsPayload.redFlagItems) {
            exportInsightsPayload.redFlagItems = [];
          }
          updatePayload(node, "redFlagItems");
        } else {
          if (!exportInsightsPayload.managementFollowup) {
            exportInsightsPayload.managementFollowup = [];
          }
          updatePayload(node, "managementFollowup");
        }
      }
    } else {
      node.children.map((child) => traverseAndGeneratePayload(child));
    }
  };

  /**
   * THis method to export the instant insight responses.
   */
  const handleExportInsightsBtnClick = async () => {
    traverseAndGeneratePayload(updatedTreeData);
    setShowLoader(true);
    let response = await instantInsightsService.exportInstantInsight(
      exportInsightsPayload
    );
    if (response) {
      setIsExportError(false);
      const fileName = `${sessionStorage.getItem(
        "DashboardProjectName"
      )}_InstantInsights.pptx`;
      const type = response.headers.get("content-type");
      const blob = new Blob([response.data], { type: type });
      let url = window.URL.createObjectURL(blob);
      let a = document.createElement("a");
      a.href = url;
      a.download = fileName;
      a.click();
    } else {
      setIsExportError(true);
    }
    setShowLoader(false);

    resetPayload();
  };

  /**
   * Description
   * -----------
   * This method is to close the close project popup
   */
  const onCloseClick = () => {
    props.setShowExportInsightsModal(false);
  };

  /**
   * Description
   * -----------
   * This method is to add the subsection values in appropiate section
   */
  const updatePayload = (node, section) => {
    let parentFound = exportInsightsPayload[section].find(
      (section) => section.sectionId === node.parent.id
    );

    if (parentFound) {
      parentFound.subSections.push({
        subSectionId: node.id,
        subSectionName: node.value,
      });
    } else {
      exportInsightsPayload[section].push({
        sectionId: node.parent.id,
        sectionName: node.parent.value,
        subSections: [
          {
            subSectionId: node.id,
            subSectionName: node.value,
          },
        ],
      });
    }
  };

  return (
    <div>
      {showLoader && <LoaderComponent />}
      <MotifModal
        id="motifModalExportInsights"
        className="genericModal startIconClose modalMotifFooterModal"
        size="lg"
        show={true}
        focusTrapOptions={{
          tabbableOptions: {
            displayCheck: "none",
          },
        }}
        onClose={() => {
          onCloseClick();
        }}
      >
        <MotifModalHeader
          closeButtonProps={{
            "aria-label": "Close",
            title: "Close",
          }}
        >
          <div className="instant-insights-header-export">
            <img src={exportIcon} alt="Export Instant Insights"></img>
          </div>
          <div className="instant-insights-export-title">
            Export Instant Insights
          </div>
        </MotifModalHeader>
        <MotifModalBody>
          <span>Please make a selection to export insights.</span>
          {exportTreeData && (
            <div className="tree-div">
              <ProjectScopeTree
                treeValue={props.data.value}
                showLines={true}
                treeData={exportTreeData}
                bubbleBackData={setUpdatedTreeData}
                showCheckbox={true}
              />
            </div>
          )}
        </MotifModalBody>
        <MotifModalFooter>
          <div className="unfavorite-confirmation-modal-footer">
            <MotifButton
              label="btnCancel"
              onClick={() => {
                onCloseClick();
              }}
              size="medium"
              type="button"
              variant="secondary"
            >
              Cancel
            </MotifButton>
            <MotifButton
              onClick={() => {
                handleExportInsightsBtnClick();
              }}
              size="medium"
              type="button"
              disabled={disableExportButton}
            >
              Export
            </MotifButton>
          </div>
        </MotifModalFooter>
      </MotifModal>
      {isExportError && (
        <MotifToast
          variant="warning"
          actionName=""
          position="bottom"
          onClose={() => {
            setIsExportError(false);
          }}
        >
          <div>
            It seems there was an issue downloading the file. Please try again
            later.
          </div>
        </MotifToast>
      )}
    </div>
  );
};
export default ExportInsightsModal;
