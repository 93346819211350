import axios from "../services/axiosWithMsal";
import CommonService from "./commonService.js";

class DeepLinkService {
    commonService = new CommonService();

    constructor() {
        this.apiUrl = this.commonService.getAPIUrl();
    }

    /**
     * This method returns project details by project Id.
     * @param {*} ProjectiId 
     * @returns Project details
     */
    async GetProjectDetailById(ProjectiId) {
        try {
            return await axios
                .get(
                    this.apiUrl + "Project/GetProjectDetails?Project_UUID=" + ProjectiId + "",
                    {
                        headers: {
                            "Content-type": "application/json; charset=UTF-8",
                            Accept: "application/json",
                        },
                    }
                )
                .then((data) => {
                    const datareturn = data?.data;
                    return datareturn;
                });
        } catch (error) {
            this.commonService.logException(
                error,
                "Deep link service",
                "Get Project Detail"
            );
        }
    };
}

export default DeepLinkService;