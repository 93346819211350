import axios from "axios"

import { PublicClientApplication } from "@azure/msal-browser";
import { msalConfig } from "../config/authConfig";

let msalInstance;
if (!msalInstance) {
   msalInstance = new PublicClientApplication(msalConfig);
   (async () => { await msalInstance.initialize(); })()
}

axios.interceptors.request.use(

     async(config) =>{
        const tokenResponse = await msalInstance.acquireTokenSilent({
            scopes:[process.env.APP_API_CLIENTID ? process.env.APP_API_CLIENTID : process.env.REACT_APP_API_CLIENTID],
        });   
        config.headers.Authorization = `Bearer ${tokenResponse.accessToken}`
        return config;
     },
     (error)=>{
        return Promise.reject(error);
     }


);
export default axios;