import { fromRange, toRange } from "xpath-range";
import CommonService from "../services/commonService";

/**
 * This method serializes the range object
 */
export const serializeRange = (range, root) => {
  return JSON.stringify(fromRange(range, root));
};

/**
 * This method deserializes the range string
 */
export const deserializeRange = (path, root) => {
  let commonService = new CommonService();
  const parsed = JSON.parse(path);
  try {
    return toRange(
      parsed.start,
      parsed.startOffset,
      parsed.end,
      parsed.endOffset,
      root
    );
  } catch (error) {
    commonService.logException(
      error,
      "Instant Insights Component",
      "Text Hightlighter"
    );
  }
};
