export const ClientNameMask = "Confidential Project";
export const ConfidentialCheck= "Y";
export const ConfidentialUnCheck= "N";


export const CreateDataFrame= "CreateDataFrame1";
export const EditDataFrame= "EditDataFrame1";
export const ExploreDataFrame= "ExploreDataFrame1";


export const CategoryConfig = [
    { id: 1, content: "Client project" },
    { id: 2, content: "Testing Project" },
  ];

export const DataHostingListConfig = [
    { id: 1, content: "US" },
    { id: 2, content: "Netherlands" },
    { id: 3, content: "Australia" }
  ];

export const ConfidentialConfig = [
    { id: 1, content: "Yes" },
    { id: 2, content: "No" },
  ];
export const SectorConfig = [{
    id: "1",
    content: "Automotive",
}, {
    id: "2",
    content: "Materials",
},
{
    id: "3",
    content: "Financial",
},
{
    id: "4",
    content: "Energy",
},
{
    id: "5",
    content: "Healthcare",
},
{
    id: "6",
    content: "Real Estate",
},
{
    id: "9",
    content: "Consumer Staples",
},
{
    id: "10",
    content: "Consumer Discretionary",
},
{
    id: "11",
    content: "Industrial",
},
{
    id: "12",
    content: "Information Technology",
},
{
    id: "13",
    content: "Communication Services",
},
{
    id: "14",
    content: "Utilities",
}
];

export const SubSectorConfig = [{
    id: "1",
    content: "Auto Components",
    sectorId: "1"
}, {
    id: "2",
    content: "Automobiles",
    sectorId: "1"
}, {
    id: "3",
    content: "Chemicals",
    sectorId: "2"
}, {
    id: "4",
    content: "Construction Materials",
    sectorId: "2"
}, {
    id: "5",
    content: "Containers & Packaging",
    sectorId: "2"
}, {
    id: "6",
    content: "Metals & Mining",
    sectorId: "2"
}, {
    id: "7",
    content: "Paper & Forest Products",
    sectorId: "2"
}, {
    id: "8",
    content: "Banks",
    sectorId: "3"
}, {
    id: "9",
    content: "Thrifts & Mortgage Finance",
    sectorId: "3"
}, {
    id: "10",
    content: "Diversified Financial Services",
    sectorId: "3"
},
{
    id: "11",
    content: "Consumer Finance",
    sectorId: "3"
}, {
    id: "12",
    content: "Capital Markets",
    sectorId: "3"
}, {
    id: "13",
    content: "Mortgage Real Estate Investment ",
    sectorId: "3"
}, {
    id: "14",
    content: "Insurance",
    sectorId: "3"
}, {
    id: "15",
    content: "Energy Equipment & Services",
    sectorId: "4"
},
{
    id: "16",
    content: "Oil, Gas & Consumable Fuels",
    sectorId: "4"
}, {
    id: "17",
    content: "Health Care Equipment & Supplies",
    sectorId: "5"
}, {
    id: "18",
    content: "Health Care Providers & Services",
    sectorId: "5"
}, {
    id: "19",
    content: "Health Care Technology",
    sectorId: "5"
}, {
    id: "20",
    content: "Biotechnology",
    sectorId: "5"
},
{
    id: "21",
    content: "Pharmaceuticals",
    sectorId: "5"
}, {
    id: "22",
    content: "Life Sciences Tools & Services",
    sectorId: "5"
}, {
    id: "23",
    content: "Equity Real Estate",
    sectorId: "6"
}, {
    id: "24",
    content: "Real Estate Management & Development",
    sectorId: "6"
}, {
    id: "25",
    content: "Hotels, Restaurants & Leisure",
    sectorId: "10"
},
{
    id: "26",
    content: "Food & Staples Retailing",
    sectorId: "9"
}, {
    id: "27",
    content: "Beverages",
    sectorId: "9"
}, {
    id: "28",
    content: "Food Products",
    sectorId: "9"
}, {
    id: "29",
    content: "Tobacco",
    sectorId: "9"
}, {
    id: "30",
    content: "Household Products",
    sectorId: "9"
},
{
    id: "31",
    content: "Personal Products",
    sectorId: "9"
}, {
    id: "32",
    content: "Household Durables",
    sectorId: "10"
}, {
    id: "33",
    content: "Leisure Products",
    sectorId: "10"
}, {
    id: "34",
    content: "Textiles, Apparel & Luxury Goods",
    sectorId: "10"
}, {
    id: "35",
    content: "Diversified Consumer Services",
    sectorId: "10"
},
{
    id: "36",
    content: "Distributors",
    sectorId: "10"
}, {
    id: "37",
    content: "Internet & Direct Marketing Retail",
    sectorId: "10"
}, {
    id: "38",
    content: "Multiline Retail",
    sectorId: "10"
}, {
    id: "39",
    content: "Specialty Retail",
    sectorId: "10"
}, {
    id: "40",
    content: "Aerospace & Defense",
    sectorId: "11"
},
{
    id: "41",
    content: "Building Products",
    sectorId: "11"
}, {
    id: "42",
    content: "Construction & Engineering",
    sectorId: "11"
}, {
    id: "43",
    content: "Electrical Equipment",
    sectorId: "11"
}, {
    id: "44",
    content: "Industrial Conglomerates",
    sectorId: "11"
}, {
    id: "45",
    content: "Machinery",
    sectorId: "11"
},
{
    id: "46",
    content: "Trading Companies & Distributors",
    sectorId: "11"
}, {
    id: "47",
    content: "Commercial Services & Supplies",
    sectorId: "11"
}, {
    id: "48",
    content: "Professional Services",
    sectorId: "11"
}, {
    id: "49",
    content: "Air Freight & Logistics",
    sectorId: "11"
}, {
    id: "50",
    content: "Airlines",
    sectorId: "11"
},
{
    id: "51",
    content: "Marine",
    sectorId: "11"
},
{
    id: "52",
    content: "Road & Rail",
    sectorId: "11"
},
{
    id: "53",
    content: "Transportation Infrastructure",
    sectorId: "11"
},
{
    id: "54",
    content: "IT Services",
    sectorId: "12"
},
{
    id: "55",
    content: "Software",
    sectorId: "12"
},
{
    id: "56",
    content: "Communications Equipment",
    sectorId: "12"
},
{
    id: "57",
    content: "Technology Hardware, Storage & Peripherals",
    sectorId: "12"
},
{
    id: "58",
    content: "Electronic Equipment, Instruments & Components",
    sectorId: "12"
},
{
    id: "59",
    content: "Semiconductors & Semiconductor Equipment",
    sectorId: "12"
},
{
    id: "60",
    content: "Diversified Telecommunication Services",
    sectorId: "13"
},
{
    id: "61",
    content: "Wireless Telecommunication Services",
    sectorId: "13"
},
{
    id: "62",
    content: "Electric Utilities",
    sectorId: "14"
},
{
    id: "63",
    content: "Gas Utilities",
    sectorId: "14"
},
{
    id: "64",
    content: "Multi-Utilities",
    sectorId: "14"
},
{
    id: "65",
    content: "Water Utilities",
    sectorId: "14"
},
{
    id: "66",
    content: "Independent Power and Renewable Electricity Producers",
    sectorId: "14"
}
];

export const SubSubSectorConfig = [
    { id: "1", content: "Auto Parts & Equipment", sectorId: "1" },

    { id: "2", content: "Tires & Rubber", sectorId: "1" },

    { id: "3", content: "Automobile Manufacturers", sectorId: "2" },

    { id: "4", content: "Motorcycle Manufacturers", sectorId: "2" },

    { id: "5", content: "Commodity Chemicals", sectorId: "3" },

    { id: "6", content: "Diversified Chemicals", sectorId: "3" },

    { id: "7", content: "Fertilizers & Agricultural Chemicals", sectorId: "3" },

    { id: "8", content: "Industrial Gases", sectorId: "3" },

    { id: "9", content: "Specialty Chemicals", sectorId: "3" },

    { id: "10", content: "Construction Materials", sectorId: "4" },

    { id: "11", content: "Metal & Glass Containers", sectorId: "5" },

    { id: "12", content: "Paper Packaging", sectorId: "5" },

    { id: "13", content: "Aluminum", sectorId: "6" },

    { id: "14", content: "Diversified Metals & Mining", sectorId: "6" },

    { id: "15", content: "Copper", sectorId: "6" },

    { id: "16", content: "Gold", sectorId: "6" },

    { id: "17", content: "Precious Metals & Minerals", sectorId: "6" },

    { id: "18", content: "Silver", sectorId: "6" },

    { id: "19", content: "Steel", sectorId: "6" },

    { id: "20", content: "Forest Products", sectorId: "7" },

    { id: "21", content: "Paper Products", sectorId: "7" },

    { id: "22", content: "Diversified Banks", sectorId: "8" },

    { id: "23", content: "Regional Banks", sectorId: "8" },

    { id: "24", content: "Thrifts & Mortgage Finance", sectorId: "9" },

    { id: "25", content: "Other Diversified Financial Services", sectorId: "10" },

    { id: "26", content: "Multi-Sector Holdings", sectorId: "10" },

    { id: "27", content: "Specialized Finance", sectorId: "10" },

    { id: "28", content: "Consumer Finance", sectorId: "11" },

    { id: "29", content: "Asset Management & Custody Banks", sectorId: "12" },

    { id: "30", content: "Investment Banking & Brokerage", sectorId: "12" },

    { id: "31", content: "Diversified Capital Markets", sectorId: "12" },

    { id: "32", content: "Financial Exchanges & Data", sectorId: "12" },

    { id: "33", content: "Mortgage REITs", sectorId: "13" },

    { id: "34", content: "Insurance Brokers", sectorId: "14" },

    { id: "35", content: "Life & Health Insurance", sectorId: "14" },

    { id: "36", content: "Multi-line Insurance", sectorId: "14" },

    { id: "37", content: "Property & Casualty Insurance", sectorId: "14" },

    { id: "38", content: "Reinsurance", sectorId: "14" },

    { id: "39", content: "Oil & Gas Drilling", sectorId: "15" },

    { id: "40", content: "Oil & Gas Equipment & Services", sectorId: "15" },

    { id: "41", content: "Integrated Oil & Gas", sectorId: "16" },

    { id: "42", content: "Oil & Gas Exploration & Production", sectorId: "16" },

    { id: "43", content: "Oil & Gas Refining & Marketing", sectorId: "16" },

    { id: "44", content: "Oil & Gas Storage & Transportation", sectorId: "16" },

    { id: "45", content: "Coal & Consumable Fuels", sectorId: "16" },

    { id: "46", content: "Health Care Equipment", sectorId: "17" },

    { id: "47", content: "Health Care Supplies", sectorId: "17" },

    { id: "48", content: "Health Care Distributors", sectorId: "18" },

    { id: "49", content: "Health Care  Services", sectorId: "18" },

    { id: "50", content: "Health Care Facilities", sectorId: "18" },

    { id: "51", content: "Managed Health Care", sectorId: "18" },

    { id: "52", content: "Health Care Technology", sectorId: "19" },

    { id: "53", content: "Biotechnology", sectorId: "20" },

    { id: "54", content: "Pharmaceuticals", sectorId: "21" },

    { id: "55", content: "Life Sciences Tools & Services", sectorId: "22" },

    { id: "56", content: "Diversified REITs", sectorId: "23" },

    { id: "57", content: "Industrial REITs", sectorId: "23" },

    { id: "58", content: "Hotel & Resort REITs", sectorId: "23" },

    { id: "59", content: "Office REITs", sectorId: "23" },

    { id: "60", content: "Health Care REITs", sectorId: "23" },

    { id: "61", content: "Residential REITs", sectorId: "23" },

    { id: "62", content: "Retail REITs", sectorId: "23" },

    { id: "63", content: "Specialized REITs", sectorId: "23" },

    { id: "64", content: "Diversified Real Estate Activities", sectorId: "24" },

    { id: "65", content: "Real Estate Operating Companies", sectorId: "24" },

    { id: "66", content: "Real Estate Development", sectorId: "24" },

    { id: "67", content: "Real Estate Services", sectorId: "24" },

    { id: "68", content: "Casinos & Gaming", sectorId: "25" },

    { id: "69", content: "Hotels, Resorts & Cruise Lines", sectorId: "25" },

    { id: "70", content: "Leisure Facilities", sectorId: "25" },

    { id: "71", content: "Hypermarkets & Super Centers", sectorId: "26" },

    { id: "72", content: "Brewers", sectorId: "27" },

    { id: "73", content: "Distillers & Vintners", sectorId: "27" },

    { id: "74", content: "Soft Drinks", sectorId: "27" },

    { id: "75", content: "Agricultural Products", sectorId: "28" },

    { id: "76", content: "Packaged Foods & Meats", sectorId: "28" },

    { id: "77", content: "Tobacco", sectorId: "29" },

    { id: "78", content: "Household Products", sectorId: "30" },

    { id: "79", content: "Personal Products", sectorId: "31" },

    { id: "80", content: "Consumer Electronics", sectorId: "32" },

    { id: "81", content: "Home Furnishings", sectorId: "32" },

    { id: "82", content: "Homebuilding", sectorId: "32" },

    { id: "83", content: "Household Appliances", sectorId: "32" },

    { id: "84", content: "Housewares & Specialties", sectorId: "32" },

    { id: "85", content: "Leisure Products", sectorId: "33" },

    { id: "86", content: "Apparel, Accessories & Luxury Goods", sectorId: "34" },

    { id: "87", content: "Footwear", sectorId: "34" },

    { id: "88", content: "Textiles", sectorId: "34" },

    { id: "89", content: "Education Services", sectorId: "35" },

    { id: "90", content: "Specialized Consumer Services", sectorId: "35" },

    { id: "91", content: "Distributors", sectorId: "36" },

    { id: "92", content: "Internet & Direct Marketing Retail", sectorId: "37" },

    { id: "93", content: "Department Stores", sectorId: "38" },

    { id: "94", content: "General Merchandise Stores", sectorId: "38" },

    { id: "95", content: "Apparel Retail", sectorId: "39" },

    { id: "96", content: "Computer & Electronics Retail", sectorId: "39" },

    { id: "97", content: "Home Improvement Retail", sectorId: "39" },

    { id: "98", content: "Specialty Stores", sectorId: "39" },

    { id: "99", content: "Automotive Retail", sectorId: "39" },

    { id: "100", content: "Homefurnishing Retail", sectorId: "39" },

    { id: "101", content: "Aerospace & Defense", sectorId: "40" },

    { id: "102", content: "Building Products", sectorId: "41" },

    { id: "103", content: "Construction & Engineering", sectorId: "42" },

    { id: "104", content: "Electrical Components & Equipment", sectorId: "43" },

    { id: "105", content: "Heavy Electrical Equipment", sectorId: "43" },

    { id: "106", content: "Industrial Conglomerates", sectorId: "44" },

    { id: "107", content: "Construction Machinery & Heavy Trucks", sectorId: "45" },

    { id: "108", content: "Agricultural & Farm Machinery", sectorId: "45" },

    { id: "109", content: "Industrial Machinery", sectorId: "45" },

    { id: "110", content: "Trading Companies & Distributors", sectorId: "46" },

    { id: "111", content: "Commercial Printing", sectorId: "47" },

    { id: "112", content: "Environmental & Facilities Services", sectorId: "47" },

    { id: "113", content: "Office Services & Supplies", sectorId: "47" },

    { id: "114", content: "Diversified Support Services", sectorId: "47" },

    { id: "115", content: "Security & Alarm Services", sectorId: "47" },

    { id: "116", content: "Human Resource & Employment Services", sectorId: "48" },

    { id: "117", content: "Research & Consulting Services", sectorId: "48" },

    { id: "118", content: "Air Freight & Logistics", sectorId: "49" },

    { id: "119", content: "Airlines", sectorId: "50" },

    { id: "120", content: "Marine", sectorId: "51" },

    { id: "121", content: "Railroads", sectorId: "52" },

    { id: "122", content: "Trucking", sectorId: "52" },

    { id: "123", content: "Airport Services", sectorId: "53" },

    { id: "124", content: "Highways & Railtracks", sectorId: "53" },

    { id: "125", content: "Marine Ports & Services", sectorId: "53" },

    { id: "126", content: "IT Consulting & Other Services", sectorId: "54" },

    { id: "127", content: "Data Processing & Outsourced Services", sectorId: "54" },

    { id: "128", content: "Application Software", sectorId: "55" },

    { id: "129", content: "Systems Software", sectorId: "55" },

    { id: "130", content: "Communications Equipment", sectorId: "56" },

    { id: "131", content: "Technology Hardware, Storage & Peripherals", sectorId: "57" },

    { id: "132", content: "Electronic Equipment & Instruments ", sectorId: "58" },

    { id: "133", content: "Electronic Components", sectorId: "58" },

    { id: "134", content: "Electronic Manufacturing Services", sectorId: "58" },

    { id: "135", content: "Technology Distributors", sectorId: "58" },

    { id: "136", content: "Semiconductor Equipment ", sectorId: "59" },

    { id: "137", content: "Semiconductors", sectorId: "59" },

    { id: "138", content: "Alternative Carriers", sectorId: "60" },

    { id: "139", content: "Integrated Telecommunication Services", sectorId: "60" },

    { id: "140", content: "Wireless Telecommunication Services", sectorId: "61" },

    { id: "141", content: "Electric Utilities", sectorId: "62" },

    { id: "142", content: "Gas Utilities", sectorId: "63" },

    { id: "143", content: "Multi-Utilities", sectorId: "64" },

    { id: "144", content: "Water Utilities", sectorId: "65" },

    { id: "145", content: "Independent Power Producers & Energy Traders", sectorId: "66" },

    { id: "146", content: "Renewable Electricity", sectorId: "66" },

    { id: "147", content: "Restaurants", sectorId: "25" },

    { id: "148", content: "Drug Retail", sectorId: "26" },

    { id: "149", content: "Food Distributors", sectorId: "26" },

    { id: "150", content: "Food Retail", sectorId: "26" }
];

export const AreaConfig = [{
    id: "1",
    content: "Americas",
}, {
    id: "2",
    content: "Asia-Pacific",
},
{
    id: "3",
    content: "EMEIA",
},
];

export const RegionConfig = [{
    id: "1",
    content: "Canada",
    areaId: "1",
}, {
    id: "2",
    content: "FSO Americas",
    areaId: "1",
},
{
    id: "3",
    content: "Israel",
    areaId: "1",
},
{
    id: "4",
    content: "LATAM",
    areaId: "1",
},
{
    id: "5",
    content: "US-Central",
    areaId: "1",
},
{
    id: "6",
    content: "US-East",
    areaId: "1",
},
{
    id: "7",
    content: "US-West",
    areaId: "1",
},
{
    id: "8",
    content: "ASEAN",
    areaId: "2",
},
{
    id: "9",
    content: "FSO APAC",
    areaId: "2",
},
{
    id: "10",
    content: "Greater China",
    areaId: "2",
},
{
    id: "11",
    content: "Japan",
    areaId: "2",
}, {
    id: "12",
    content: "Korea",
    areaId: "2",
},
{
    id: "13",
    content: "Oceania",
    areaId: "2",
},
{
    id: "14",
    content: "Africa",
    areaId: "3",
},
{
    id: "15",
    content: "CESA",
    areaId: "3",
},
{
    id: "16",
    content: "Europe West",
    areaId: "3",
},
{
    id: "17",
    content: "FSO EMEIA",
    areaId: "3",
},
{
    id: "18",
    content: "India",
    areaId: "3",
},
{
    id: "19",
    content: "MENA",
    areaId: "3",
},
{
    id: "20",
    content: "Nordics",
    areaId: "3",
},
{
    id: "21",
    content: "UK and Ireland",
    areaId: "3",
},
{
    id: "22",
    content: "BBC",
    areaId: "1",
},
];

export const CountryConfig = [{ id: "1", content: "Ghana", regionId: "14", dataHostingId:2 },
{ id: "2", content: "Kenya", regionId: "14", dataHostingId:2  },
{ id: "3", content: "Malawi", regionId: "14", dataHostingId:2  },
{ id: "4", content: "Nigeria", regionId: "14", dataHostingId:2  },
{ id: "5", content: "South Africa", regionId: "14", dataHostingId:2  },
{ id: "6", content: "Tanzania", regionId: "14", dataHostingId:2  },
{ id: "7", content: "Uganda", regionId: "14", dataHostingId:2  },
{ id: "8", content: "Zimbabwe", regionId: "14", dataHostingId:2  },
{ id: "9", content: "Indonesia", regionId: "8", dataHostingId:3  },
{ id: "10", content: "Malaysia", regionId: "8", dataHostingId:3 },
{ id: "11", content: "Maldives", regionId: "8", dataHostingId:3 },
{ id: "12", content: "Myanmar", regionId: "8", dataHostingId:3 },
{ id: "13", content: "Philippines", regionId: "8", dataHostingId:3 },
{ id: "14", content: "Singapore", regionId: "8", dataHostingId:3 },
{ id: "15", content: "Sri Lanka", regionId: "8", dataHostingId:3   },
{ id: "16", content: "Thailand", regionId: "8", dataHostingId:3 },
{ id: "17", content: "Vietnam", regionId: "8", dataHostingId:3 },
{ id: "18", content: "Canada", regionId: "1", dataHostingId:2  },
{ id: "19", content: "Armenia", regionId: "15", dataHostingId:2  },
{ id: "21", content: "Bulgaria", regionId: "15", dataHostingId:2  },
{ id: "22", content: "Croatia", regionId: "15", dataHostingId:2  },
{ id: "23", content: "Cyprus", regionId: "15", dataHostingId:2  },
{ id: "24", content: "Czech Republic", regionId: "15", dataHostingId:2  },
{ id: "25", content: "Estonia", regionId: "15", dataHostingId:2  },
{ id: "26", content: "Georgia", regionId: "15", dataHostingId:2  },
{ id: "27", content: "Greece", regionId: "15", dataHostingId:2  },
{ id: "28", content: "Hungary", regionId: "15", dataHostingId:2  },
{ id: "29", content: "Kazakhstan", regionId: "15", dataHostingId:0 },
{ id: "30", content: "Latvia", regionId: "15", dataHostingId:2  },
{ id: "31", content: "Lithuania", regionId: "15", dataHostingId:2  },
{ id: "32", content: "Malta", regionId: "15", dataHostingId:2  },
{ id: "33", content: "Poland", regionId: "15", dataHostingId:2  },
{ id: "34", content: "Romania", regionId: "15", dataHostingId:2  },
{ id: "35", content: "Republic of Serbia", regionId: "15", dataHostingId:2  },
{ id: "36", content: "Slovakia", regionId: "15", dataHostingId:2  },
{ id: "37", content: "Slovenia", regionId: "15", dataHostingId:2  },
{ id: "38", content: "Turkey", regionId: "15", dataHostingId:2  },
{ id: "39", content: "Ukraine", regionId: "15", dataHostingId:2  },
{ id: "40", content: "Austria", regionId: "16", dataHostingId:2  },
{ id: "41", content: "Belgium", regionId: "16", dataHostingId:2  },
{ id: "42", content: "France", regionId: "16", dataHostingId:2  },
{ id: "43", content: "Germany", regionId: "16", dataHostingId:2  },
{ id: "44", content: "Italy", regionId: "16", dataHostingId:2  },
{ id: "45", content: "Ivory Coast", regionId: "16", dataHostingId:2  },
{ id: "46", content: "Morocco", regionId: "16", dataHostingId:2  },
{ id: "47", content: "Netherlands", regionId: "16", dataHostingId:2  },
{ id: "48", content: "Portugal", regionId: "16", dataHostingId:2  },
{ id: "49", content: "Spain", regionId: "16", dataHostingId:2  },
{ id: "50", content: "Switzerland", regionId: "16", dataHostingId:2  },
{ id: "51", content: "Tunisia", regionId: "16", dataHostingId:2  },
{ id: "52", content: "United States", regionId: "2", dataHostingId:1 },
{ id: "53", content: "Australia", regionId: "9", dataHostingId:3  },
{ id: "54", content: "China", regionId: "9", dataHostingId:0 },
{ id: "55", content: "Japan", regionId: "9", dataHostingId:3  },
{ id: "56", content: "Singapore", regionId: "9", dataHostingId:3  },
{ id: "57", content: "Belgium", regionId: "17", dataHostingId:2  },
{ id: "58", content: "France", regionId: "17", dataHostingId:2  },
{ id: "59", content: "Germany", regionId: "17", dataHostingId:2  },
{ id: "60", content: "Ireland", regionId: "17", dataHostingId:2  },
{ id: "61", content: "Italy", regionId: "17", dataHostingId:2  },
{ id: "62", content: "Luxembourg", regionId: "17", dataHostingId:2  },
{ id: "63", content: "Netherlands", regionId: "17", dataHostingId:2  },
{ id: "64", content: "Spain", regionId: "17", dataHostingId:2  },
{ id: "65", content: "Switzerland", regionId: "17", dataHostingId:2  },
{ id: "66", content: "United Kingdom", regionId: "17", dataHostingId:2  },
{ id: "67", content: "China", regionId: "10", dataHostingId:0 },
{ id: "68", content: "Taiwan", regionId: "10", dataHostingId:0 },
{ id: "69", content: "India", regionId: "18", dataHostingId:2  },
{ id: "70", content: "Israel", regionId: "3", dataHostingId:2   },
{ id: "71", content: "Japan", regionId: "11", dataHostingId:3 },
{ id: "72", content: "Republic of Korea", regionId: "12", dataHostingId:3 },
{ id: "84", content: "Argentina", regionId: "4", dataHostingId:1 },
{ id: "85", content: "Brazil", regionId: "4", dataHostingId:1 },
{ id: "86", content: "Chile", regionId: "4", dataHostingId:1 },
{ id: "73", content: "Colombia", regionId: "4", dataHostingId:1 },
{ id: "74", content: "Costa Rica", regionId: "4", dataHostingId:1 },
{ id: "75", content: "Dominican Republic", regionId: "4", dataHostingId:1 },
{ id: "76", content: "Ecuador", regionId: "4", dataHostingId:1 },
{ id: "77", content: "El Salvador", regionId: "4", dataHostingId:1 },
{ id: "87", content: "Bahrain", regionId: "19", dataHostingId:2  },
{ id: "88", content: "Egypt", regionId: "19", dataHostingId:2  },
{ id: "89", content: "Jordan", regionId: "19", dataHostingId:2  },
{ id: "90", content: "Kuwait", regionId: "19", dataHostingId:2  },
{ id: "91", content: "Lebanon", regionId: "19", dataHostingId:2  },
{ id: "92", content: "Oman", regionId: "19", dataHostingId:2  },
{ id: "93", content: "Pakistan", regionId: "19", dataHostingId:2  },
{ id: "94", content: "Qatar", regionId: "19", dataHostingId:2  },
{ id: "95", content: "Saudi Arabia", regionId: "19", dataHostingId:2  },
{ id: "96", content: "United Arab Emirates", regionId: "19", dataHostingId:2  },
{ id: "97", content: "Denmark", regionId: "20", dataHostingId:2  },
{ id: "98", content: "Finland", regionId: "20", dataHostingId:2  },
{ id: "100", content: "Norway", regionId: "20", dataHostingId:2  },
{ id: "101", content: "Sweden", regionId: "20", dataHostingId:2  },
{ id: "102", content: "Australia", regionId: "13", dataHostingId:3 },
{ id: "103", content: "New Zealand", regionId: "13", dataHostingId:3  },
{ id: "104", content: "Ireland", regionId: "21", dataHostingId:2  },
{ id: "105", content: "United Kingdom", regionId: "21", dataHostingId:2  },
{ id: "106", content: "United States", regionId: "5", dataHostingId:1  },
{ id: "107", content: "United States", regionId: "6", dataHostingId:1 },
{ id: "108", content: "United States", regionId: "7", dataHostingId:1 },
{ id: "109", content: "Bosnia and Herzegovina", regionId: "15", dataHostingId:2  },
{ id: "110", content: "Bahamas", regionId: "22", dataHostingId:1  },
{ id: "111", content: "Bermuda", regionId: "22", dataHostingId:1   },
{ id: "112", content: "British Virgin Islands", regionId: "22", dataHostingId:1   },
{ id: "113", content: "Cayman Islands", regionId: "22", dataHostingId:1   },
{ id: "114", content: "Bolivia", regionId: "4", dataHostingId:1 },
{ id: "115", content: "Guatemala", regionId: "4", dataHostingId:1 },
{ id: "116", content: "Mexico", regionId: "4", dataHostingId:1 },
{ id: "117", content: "Nicaragua", regionId: "4", dataHostingId:1 },
{ id: "118", content: "Panama", regionId: "4", dataHostingId:1 },
{ id: "119", content: "Peru", regionId: "4", dataHostingId:1 },
{ id: "120", content: "Uruguay", regionId: "4", dataHostingId:1 },
{ id: "121", content: "Venezuela", regionId: "4", dataHostingId:1 },
{ id: "122", content: "Barbados", regionId: "5", dataHostingId:1 },
{ id: "123", content: "Curacao", regionId: "5", dataHostingId:1 },
{ id: "124", content: "Guyana", regionId: "5", dataHostingId:1 },
{ id: "125", content: "Jamaica", regionId: "5", dataHostingId:1 },
{ id: "126", content: "Trinidad and Tobago", regionId: "5", dataHostingId:1 },
{ id: "127", content: "Mauritius", regionId: "14", dataHostingId:2  },
{ id: "128", content: "Albania", regionId: "15", dataHostingId:2  },
{ id: "129", content: "Azerbaijan", regionId: "15", dataHostingId:2  },
{ id: "130", content: "Uzbekistan", regionId: "15", dataHostingId:2  },
{ id: "131", content: "Mozambique", regionId: "16", dataHostingId:2  },
{ id: "132", content: "Bangladesh", regionId: "18", dataHostingId:2  },
];

export const DealConfig = [{
    id: "1",
    content: "Unknown at this time",
}, {
    id: "2",
    content: "1 to 500,000",
},
{
    id: "3",
    content: "500,000 to 1 million",
},
{
    id: "4",
    content: "1 million to 5 million",
},
{
    id: "5",
    content: "5 million to 10 million",
},
{
    id: "6",
    content: "10 million to 100 million",
},
{
    id: "7",
    content: "100 million to 500 million",
},
{
    id: "8",
    content: "500 million to 1 billion",
},
{
    id: "9",
    content: "1 billion +",
},
];

export const ProjectStatusConfig=[
    {id:'Open',content:"Open"},
    {id:'Closed',content:"Closed"}
];

export const FavoriteTypeConfig = [
    { id: 1, content: "Explore your data" },
    { id: 2, content: "Instant Insights" },
  ]