import React  from "react";
import { MotifButton } from "@ey-xd/motif-react"
import { useNavigate } from "react-router-dom";
import login from "../../assets/image/log-in.svg";
import "../common/Header.css";
import "../dashboard/LogOut.css";
import logo from "../../assets/image/logo.svg";
import vector from "../../assets/image/LogOutGroup.svg";

export function LogOut() {    
  const navigate = useNavigate();

   /**
    * Description
    * -----------
    * This method is to navigate to login page
    */
      const onLoginClick = () => {
        navigate("/");      
      };

    return (
      <>
      <div className="header">
        <div className="logomenu" style={{ display: 'flex' }}>
          <div className="logoDiv">
            <div className="logo">
              <img src={logo} alt="" ></img>
            </div>
          </div>
          <div className="projectTitle">Digital Diligence Assistant</div>
        </div>
    
     
      </div>
      <div className="GroupVectorHeight">
        <div className="GroupVector">
        <img src={vector} alt="" ></img>
        </div>
        <div className="LogoutDiv">
          <div className="LogOutBox">
          <div className="LogOutSucess">
            Log out successful!
            </div>
            <div className="LogOutmessage">
              To access Digital Diligence Assistant,  please use the platform link or click below:
              </div>
              <div className="LogOutBtnDiv">
          <MotifButton
               className="motifLogoutButton"
               onClick={() => {
                onLoginClick();
              }}
              >
                <img src={login} alt="login icon" />
                Log in
              </MotifButton>
          </div>
          </div>
         

          
        </div>
        </div>
       
    </>
  );
}

export default LogOut;