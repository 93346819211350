import React from "react";
import styles from "../dashboard/dashboard.module.scss";
import { dashboardDataConstants } from "../dashboard/constants.js";

export function Footer() {
  return (
    <div className={styles.copyrightContainer}>
      <div className={styles.copyrightSection}>
        <div className={styles.rectangleSection}>
          <div className={styles.copyrightMessage}>
            <a
              className={styles.privacyNotice}
              href={dashboardDataConstants.privacyNotice.url}
              target="_blank"
              rel="noopeer noreferrer"
            >
              {dashboardDataConstants.privacyNotice.name}
            </a>
            <span className={styles.privacyNoticeSeparator}>|</span>
            <a
              className={styles.engagement}
              href={dashboardDataConstants.enagagementManagement.url}
              target="_blank"
              rel="noopeer noreferrer"
            >
              {dashboardDataConstants.enagagementManagement.name}
            </a>
          </div>
        </div>
      </div>
      <div className={styles.rectangleSectionOuter}></div>
    </div>
  );
}
export default Footer;
