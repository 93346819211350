import React, { useState, useEffect, useContext } from "react";
import { useNavigate, useLocation } from "react-router-dom";
import "./Header.css";
import logo from "../../assets/image/logo.svg";
import logOut from "../../assets/image/log-out.svg";
import avatarImage from "../../assets/image/default-user-icon.jpg";
import { MotifFormField, MotifSelect, MotifOption, MotifTooltip } from "@ey-xd/motif-react";
import EngagementLocationService from "../../services/engagementLocationService";
import information from "../../assets/image/information.svg";
import { DashboardContext } from "../../context/dashboardContext";
import { useMsal } from '@azure/msal-react';
export function Header() {
  const navigate = useNavigate();
  const { instance } = useMsal();
  const [showUserMenu, setShowUserMenu] = useState(false);
  const [locationList, setLocationList] = useState([]);
  const [location, setLocation] = useState("");
  const engagementLocationService = new EngagementLocationService();
  const constantData = require("../../data/constant.js");
  const routeLocation = useLocation();
  const [isVisible, setIsVisible] = useState(null);
  let { getProjects } = useContext(DashboardContext);

  /**
  * Description
  * -----------
  * This useffect is checking if  EngagementLocation available in localstorage and then set in variable
  */
  useEffect(() => {
    engagementLocationData();
  }, []);

  /**
  * Description
  * -----------
  * This method is getting engagementlocation from config and setting them in list
  */
  const engagementLocationData = async () => {
    let result = await engagementLocationService.getEngagementLocationData();
    if (result) {
      setLocationList(result);
      if (localStorage.getItem("EngagementLocation")) {
        setLocation(JSON.parse(localStorage.getItem("EngagementLocation")).content);
      }
    }
  }

  /**
   * Description
   * -----------
   * This useffect is to check if the page loaded is dashboard to show the engagementlocation div 
   */

  useEffect(() => {
    setIsVisible(routeLocation.pathname.toUpperCase().match(constantData.dashboardLocation) ? true : false);
  }, [constantData.dashboardLocation, routeLocation.pathname])


  /**
   * Description
   * -----------
   * This method shows the username on avatar image
   */
  const showLoggedInUserMenu = () => {
    setShowUserMenu(true);
    document.body.addEventListener("click", hideLoggedInUserMenu);
  };

  /**
   * Description
   * -----------
   * This method hides the username on avatar image
   */
  const hideLoggedInUserMenu = () => {
    setShowUserMenu(false);
  };

  /*
   * Description
   * -----------
   * This method is to navigate to dashboard page.
   */
  const navigateToDashboard = () => {
    navigate("/Dashboard");
  };

  /**
   * Description
   * -----------
   * This method logout from Azure Ad and navigate to logout page
   */
  const handleLogOut = (e) => {
    e.preventDefault();
    localStorage.clear();
    sessionStorage.clear();
    const logoutRequest = {
      account: instance.getActiveAccount(),
      postLogoutRedirectUri: "/LogOut",
    };
    instance.logout(logoutRequest);
  };

  /**
         * Description
         * -----------
         * This method is to call when user selects any country
         * Parameters:
         * data: this one is onChange event parameter to get the selected text
         */
  const onEngagementLocationChange = (e) => {
    saveEngagementLocation(e);
  }
  /**
    * Description
    * -----------
    * This method is to  save the location details into localstorage
    */
  const saveEngagementLocation = (selectedLocation) => {
    if (selectedLocation !== "") {
      localStorage.setItem('EngagementLocation',
        JSON.stringify(locationList.filter(item => item.content === selectedLocation)[0]));
    }
    //Rebind the dashboard based on the engagement location selected
    getProjects(sessionStorage.getItem("Email"));
  }
  return (
    <>
      <div className="header">
        <div className="logomenu" style={{ display: 'flex' }}>
          <div className="logoDiv">
            <div className="logo">
              <img src={logo} alt="" onClick={navigateToDashboard}></img>
            </div>
          </div>
          <div className="projectTitle">Digital Diligence Assistant</div>
        </div>
        
        <div className="profileImageContainer">
          <div className="NavDropdown">
            <div className="Dropdown">
              {showUserMenu && (
                <div className="headerContainer">
                  <div className="userMenupopupcontainer">
                    <div className="userMenuPopup">
                      <div className="usermenuwidthscratch">
                        <div className="usermenuwidth">
                          <div className="userMenuCont">
                            <div className="usermenuchane">
                              <div className="usermenushow">
                                <div className="displaymenu">
                                  <div className="userMenuAvatar">
                                    {
                                      <img
                                        src={avatarImage}
                                        alt="Avatar"
                                        draggable="false"
                                        className="userAvatar"
                                      ></img>
                                    }
                                    {
                                      <div className="userAvatarUserMenuItem">
                                        {" "}
                                        <b>
                                          {sessionStorage.getItem(
                                            "CurrentUserName"
                                          )}
                                        </b>
                                        <div className="userAvatarEmailMenuItem">
                                          {" "}
                                          {sessionStorage.getItem("Email")}
                                        </div>
                                      </div>
                                    }
                                  </div>
                                  <div className="Divider1 divider" />
                                        {" "}
                                       <div> 
                                    <img className='logoutimg' alt='icon' src={logOut} />
                                    <a href="#"
                                            onClick={handleLogOut}
                                            className="logout"
                                            target="_blank"
                                            rel="noopeer noreferrer"
                                    > Log out
                                        </a>



                                    
                                  </div>
                                </div>
                              </div>
                              <div />
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              )}
              <div
                id="divUserAvatar"
                className="Avatar"
                onMouseOver={showLoggedInUserMenu}
                onMouseDown={hideLoggedInUserMenu}
              >
                <img className="AvatarImage" src={avatarImage} alt="" />
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}
export default Header;
