import React, { useEffect, useState } from "react";
import { MotifButton, MotifFormField, MotifSearch } from "@ey-xd/motif-react";
import CreateSectionModal from "./CreateSectionModal";
import SectionList from "./SectionList";
import WorkSpaceService from "../../../../services/workspaceService";
import search from "../../../../assets/image/search.svg";
import download from "../../../../assets/image/download.svg";
import plus from "../../../../assets/image/plus.svg";
import filterImage from "../../../../assets/image/filter-list.svg";

const TeamFavoriteResponses = (props) => {
  const workspaceService = new WorkSpaceService();
  const [showSectionModal, setShowSectionModal] = useState(false);
  const [pwSectionList, setPWSectionList] = useState([]);

  useEffect(() => {
    if (props.openSectionModal) {
      setShowSectionModal(true);
    }
  }, [props.openSectionModal]);

  /**
   * This method fetches the Section list
   */
  const fetchTeamWorkspaceSection = async () => {
    let teamFavoritePrompts =
      await workspaceService.getTeamWorkspaceFavoritePrompts(
        sessionStorage.getItem("DashboardProjectId")
      );
    setPWSectionList(teamFavoritePrompts);
  };

  useEffect(() => {
    fetchTeamWorkspaceSection();
  }, []);

  /**
   * This method opens the section modal
   */
  const openSectionModal = () => {
    setShowSectionModal(true);
  };

  /**
   * This method is close the section modal
   * @param {boolean} isSubmitted: pass true if section values saves
   */
  const onCloseSectionModal = (isSubmitted) => {
    if (isSubmitted) {
      fetchTeamWorkspaceSection();
    }
    setShowSectionModal(false);
    props.closeSectionModal();
  };

  return (
    <>
      <div className="tw-section-container">
        <div className="tw-section-search-container">
          <div className="tw-section-search-box" id="tw-search-box">
            <MotifFormField>
              <img className="searchFavorite" src={search} alt="" />
              <MotifSearch
                className="searchTxtBoxFavorite"
                aria-label="Search"
                hideClearButton
                id="states-search"
                placeholder="Search"
              />
            </MotifFormField>
            <MotifButton className="export-button-base tw-footer-button">
              <img src={filterImage} alt="export icon" />
            </MotifButton>
          </div>
        </div>
        <div className="tw-section-list-container" id="tw-section-list">
          {pwSectionList.map((section, index) => {
            return <SectionList section={section} />;
          })}
        </div>
        {pwSectionList.length > 0 && (
          <div className="tw-section-container-footer">
            <div className="tw-footer-box">
              <div className="tw-footer-buttons">
                <MotifButton
                  id="btnTWExport"
                  className="export-button-base tw-footer-button"
                >
                  <img src={download} alt="export icon" />
                  Export
                </MotifButton>
                <MotifButton
                  className="tw-footer-button"
                  onClick={openSectionModal}
                >
                  <img src={plus} alt="add section icon" />
                  Create Section
                </MotifButton>
              </div>
            </div>
          </div>
        )}
      </div>

      {showSectionModal && (
        <CreateSectionModal setShowSectionModal={onCloseSectionModal} />
      )}
    </>
  );
};
export default TeamFavoriteResponses;
