import axios from "../services/axiosWithMsal";
import CommonService from "./commonService.js";

class MemberService {
  commonService = new CommonService();
  constructor() {
    this.apiUrl = this.commonService.getAPIUrl();
  }
  // Api call to Get all the members based on a projectId
  // Input: Project UUID
  //OutPut: Members added to the project
  GetProjectDetailById = async (ProjectiId = String, dataHostingLocation = String) => {
    try {
      return await axios
        .get(
          this.apiUrl +
            "Member/GetMembersDetailsByProject?project_UUID=" +
            ProjectiId +
            "",
          {
            headers: {
              "Content-type": "application/json; charset=UTF-8",
              Accept: "application/json",
            },
          }
        )
        .then((data) => {
          const datareturn = data?.data;
          return datareturn;
        });
    } catch (error) {
      this.commonService.logException(
        error,
        "Member Component",
        "GetMembersDetailsByProject"
      );

    }
  };
  // Api call to Add /Update members
  // Input: Members
  AddMembers = async (memberRequest) => {
    await axios
      .post(this.apiUrl + "Member/AddMembers", memberRequest)
      .catch((error) => {
        this.commonService.logException(
          error,
          "Member Component",
          "Add Members"
        );       
      });
  };
  // Api call to delete a member
  // Input: Members
  DeleteMember = async (memberRequest) => {
    await axios
      .post(this.apiUrl + "Member/DeleteMembers", memberRequest, {
        headers : {
          "Content-type": "application/json; charset=UTF-8",
        }
      })
      .catch((error) => {
        this.commonService.logException(
            error,
            "Member Component",
            "DeleteMember"
          );    
      });
  };
}

export default MemberService;
