import React from "react";
import MotifTabNavigation, {
  MotifTabControl,
} from "@ey-xd/motif-react/TabNavigation";
import { useNavigate } from "react-router-dom";
import "./PromptLibraries.css";
import BreadCrumb from "../../common/BreadCrumb";

// Component to render tab control with navigation to prompt libraries
export default function PromptLibraries() {
  const promptTabIds = {
    Personal: "Personal",
    SaTBestPractice: "SaTBestPractice",
  };
  const navigate = useNavigate();
  const projectName = sessionStorage.getItem("DashboardProjectName");

  // Navigate user to corresponding prompt library
  function handlePromptTabClick(tab) {
    switch (tab) {
      case promptTabIds.Personal:
        navigate("Personal");
        break;
      case promptTabIds.SaTBestPractice:
        navigate("Sat");
        break;
    }
  }

  return (
    <>
      <div className="ProjectDashboardPageContainer">
        <div className="HeaderLayout">
          <BreadCrumb projectName={projectName} pageName="Prompt Libraries" />
          <div className="HeaderContainer">
            <div className="HeaderTitle">
              <span className="HeaderText">Prompt Libraries</span>
            </div>
            <div className="HeaderAction"></div>
          </div>
        </div>
        <MotifTabNavigation defaultActiveKey={0}>
          <MotifTabControl
            onClick={() => handlePromptTabClick(promptTabIds.Personal)}
          >
            Personal Prompt Library
          </MotifTabControl>
          <MotifTabControl
            onClick={() => handlePromptTabClick(promptTabIds.SaTBestPractice)}
          >
            SaT Best Practice Prompt Library
          </MotifTabControl>
        </MotifTabNavigation>
      </div>
    </>
  );
}
