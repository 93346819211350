import { useMsal } from "@azure/msal-react";
  import { loginRequest } from "../../config/authConfig";
// Handle Autentication Request
const HandleRedirect = () => {
    const { instance } = useMsal();
      instance
        .loginRedirect({
          ...loginRequest,
          prompt: "create",
        })
        .catch((error) => console.log(error));
    };

    export default HandleRedirect;