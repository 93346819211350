export const CreateData =
{
    category: String,
    paceId: String,
    engagementId: String,
    confidential: String,
    projectName: String,
    clientName:String,
    area: String,
    region: String,
    country: String,
    dealSize: String,
    targetSector: String,
    targetSubSector: String,
    targetSubSubSector: String,
    dataHostingLocation: String,
    description: String,
    editFlag:String,
    projectuuid:String,
    projectScope:Array,
    
}

//Kinghsuk's Code to Edit Engagement ID
export const SharedData = {
    invalidengagementID:Boolean,
    ShowErrorMessageEngagementID:Boolean
}
